import React from 'react';
import classes from './NoDataAvailable.module.css';
import { LanguageContext } from '../../context/LanguageContext';

const NoDataAvailable = () => {
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.no_data_container} d-flex w-100 justify-content-center align-items-center`}>
            <span>
              {ctx.data?.no_data[ctx.language]}
            </span>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default NoDataAvailable;
