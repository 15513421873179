import React from 'react';
import { nanoid } from 'nanoid';
import Links from './blocks/Links';
import Texts from './blocks/Texts';
import Titles from './blocks/Titles';
import classes from './Components.module.css';
import FooterContainer from './blocks/FooterContainer';
import FooterItemContainer from './blocks/FooterItemContainer';
import { LanguageContext } from '../../../context/LanguageContext';
import { footerItemData } from '../../../utils/helpers/footer/footerItemData';

const FooterItems = () => {
  const footerData = (name, lang) => {
    let data = [];
    const links = footerItemData.links();
    const values = footerItemData.values(name, lang, classes.max_width_100);

    if (name && lang) {
      for (let item in values) {
        data.push({
          title: item,
          link: links[item] ?? '',
          value: values[item],
        });
      }
    }
    return data;
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const data = footerData(ctx.data, ctx.language);
        return (
          <FooterContainer>
            <div
              className={`${classes.logo_container} ${classes.display_flex}`}
            >
              <img className='h-100' src='../../favicon.png' alt='' />
            </div>
            {data.map((item, index) => {
              return (
                <FooterItemContainer
                  key={`${item}_${nanoid()}`}
                  className={index === 2 ? `${classes.max_width_100}` : ''}
                >
                  <Titles>
                    {ctx.data &&
                      ctx.data[item.title] &&
                      ctx.data[item.title][ctx.language]}
                  </Titles>
                  {index === 0 ? (
                    <Texts key={`text_${nanoid()}`}>{item.value}</Texts>
                  ) : (
                    <>
                      {item?.value.map((val, indexes) => {
                        return (
                          <Links
                            key={`link_${nanoid()}`}
                            link={item?.link[indexes]}
                            className={index === 2 ? classes.max_width_100 : ''}
                          >
                            {val}
                          </Links>
                        );
                      })}
                    </>
                  )}
                </FooterItemContainer>
              );
            })}
          </FooterContainer>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FooterItems;
