export const textSpaces = {
  changeTextSpace(title, margin, space, iframeItem, text, data, value, key) {
    if (key === value) {
        margin[0].style.paddingLeft = `${space}px`;
        margin[0].style.paddingRight = `${space}px`;
      } else if (iframeItem && iframeItem.length > 0) {
        for (const a of text) {
          a.style[data[title].key] = `${space}px`;
        }
      }
}
}
