import axios from 'axios';
import Cookies from 'js-cookie';
import { downLoadAction } from './DownLoadSlice';

const fetchDownLoad = (productId, type, episode) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const config = {responseType: type === 'book' ?  'arraybuffer' : 'blob'};
      const headers = { Authorization:`Bearer ${token}`
    };
      const url = `${process.env.REACT_APP_DEV_URI}/product/${productId}/download?type=${type}&episode=${episode}`;
      const response = await axios.get(url, { headers, ...config});
      return response;
    };
    try {
      const download = await fetchData();
      return dispatch(
        downLoadAction.downLoadHandler({file:{
          data: download.data, status: download.status
        }})
      );
    } catch (e) {
      
      return e;
    }
  };
};

export default fetchDownLoad;
