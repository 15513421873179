import Video from './video/Video';
import React, { useRef, useState } from 'react';
import classes from './UploadVideo.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import TipMessage from '../../../../components/tipMessage/TipMessage';

const UploadVideo = (props) => {
  const vidRef = useRef(null);
  const [show, setShow] = useState(false)
  const [isPlay, setIsPlay] = useState(false)
  const {onChange, cartoonURL, imageURL, imageSizesAreMatched, errorMessage} = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.upload_video_container} position-relative w-100 mb-4 d-flex flex-wrap align-items-start position-relative`}>
            <label className='position-relative w-100' onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} htmlFor='avatar' >
              <div className={classes.choose_file_container}>
                <span className={`${classes.choose_file} `} >{`${ctx.data?.choose_video_file[ctx.language]} MP4`}</span>
              </div>
            </label>
            <input
              className='w-100 d-none'
              type='file'
              onChange={onChange}
              id='avatar'
              name='avatar'
              accept='video/*'
            />
            <TipMessage show={show} />
            <div className={`${classes.borde_radius_10} ${classes.video_container} ${!cartoonURL && classes.height_335} align-items-center d-flex h-100 w-100`}>
              {cartoonURL ? (
                <>
                  <div
                    className={`${classes.borde_radius_10} ${classes.video_container} w-100 align-items-center d-flex flex-wrap h-100`}
                  >
                    <div style={{ backgroundImage: `url(${imageSizesAreMatched ? imageURL : null})` }} className={` ${classes.background_image} ${classes.img_container_border_radius} ${classes.gap_10} ${classes.upload_img_container} ${!isPlay && classes.default_bg} d-flex flex-wrap  w-100 justify-content-center`}>
                      <Video
                        vidRef={vidRef}
                        id={'cartoon_video'}
                        src={cartoonURL}
                        setIsPlay={setIsPlay}
                        isPlay={isPlay}
                        className={isPlay ? `${classes.img_container} h-100 visible` : `${classes.img_container} h-100 invisible`}
                      />
                    </div>
                    {errorMessage && <span className={`${classes.error_text} text-center w-100`}>{ctx.data?.please_choose_picture_size[ctx.language]}</span>}
                  </div>
                </>
              ) : (
                <label
                  htmlFor='avatar'
                  className={`${classes.upload_img_container} w-100 align-items-center d-flex justify-content-center`}
                >
                  <img
                    className={`${classes.upload_image} w-100`}
                    src='../assets/cartoon/uploadCartoon/uploadCartoon.svg'
                    alt=''
                  />
                </label>
              )}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadVideo;
