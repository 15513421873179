import { createSlice } from '@reduxjs/toolkit';

const initialState = { addWatchLater: {} };

const addWatchLaterSlice = createSlice({
  name: 'addWatchLater',
  initialState,
  reducers: {
    addWatchLaterHandler(state, action) {
      state.addWatchLater = action.payload.addWatchLater;
    },
  },
});
export const addWatchLaterAction = addWatchLaterSlice.actions;
export default addWatchLaterSlice;
