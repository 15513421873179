import React from 'react';

import styles from './AboutUs.module.css';

const AboutCompanyPart = (props) => {
  const { imageUrl, title, description, className } = props;

  return (
    <div className={`p-2 ${styles.about_page_card_container} position-relative`}>
      <div
        className={`d-flex justify-content-center flex-wrap relative ${styles.about_page_card} w-100 h-100 ${styles[className]}`}
      >
        <div className={`${styles.about_page_card_img_container} absolute position-absolute my-0 mx-auto`}>
          <img src={imageUrl} alt={title} className={`h-100 w-100`} />
        </div>
        <div
          className={`d-flex flex-wrap justify-content-center pt-5 pb-4 align-content-start`}
        >
          <h4 className='text-center'>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutCompanyPart;
