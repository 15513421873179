import { createSlice } from '@reduxjs/toolkit';

const initialState = { editedData : {} };

const editUserDataSlice = createSlice({
  name: 'editUserData',
  initialState,
  reducers: {
    editUserDataHandler(state, action) {
      state.editedData = action.payload.editedData;
    },
  },
});
export const editUserDataAction = editUserDataSlice.actions;
export default editUserDataSlice;