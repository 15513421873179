import React from 'react';
import classes from './AudioButtons.module.css';

const Audio = (props) => {
  const { id, disabled, audioHandler } = props;

  return (
      <div className={`${classes.audio_container} d-flex w-100 justify-content-center`}>
        {['prev', 'pause', 'next']?.map((item) => {
          return (
                <div
                  key={`audio_${item}`}
                  className={`${classes.item_container} ${
                    !disabled.includes(item)
                      ? classes.background_main_color
                      : classes.background_gray
                  }`}
                >
                  {
                    <button
                      className={`d-flex align-items-center justify-content-center w-100 text-center h-100 bg-transparent border-0`}
                      onClick={() => audioHandler(item)}
                      id={item === 'pause' ? id : `audio${item}`}
                      disabled={disabled.includes(item)}
                    >
                      <img
                        className={classes.width_15}
                        src={`../assets/audio/${item}.svg`}
                        alt=''
                        id={item === 'pause' ? 'playImg' : `${item}Img`}
                      />
                    </button>
                  }
                </div>
          );
        })}
      </div>
  );
};

export default Audio;
