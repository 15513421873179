import axios from 'axios';

export const getCountry = {
  userCountry(setCountry) {
    axios.get('https://ipapi.co/json/')
    .then((response) => {
      let data = response.data;
      localStorage.setItem('country', data.country.toLowerCase())
      setCountry(data.country.toLowerCase());
    })
    .catch((error) => {});
}
}
