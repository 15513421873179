import React from 'react';
import classes from './Tab.module.css';

const Tab = (props) => {
  const {
    title,
    activeTab,
    onClick,
    classNameTab,
    htmlFor,
    id,
    src,
    show,
    cartoons,
    fs,
  } = props;

  return (
    <button
      id={id}
      className={`${classes.cursor_auto} ${
        activeTab ? `${classes.active_buttons} font-weight-normal` : ''
      } ${
        classes.font_size_18
      } ${classNameTab} d-flex flex-wrap bg-transparent border-0`}
      onClick={onClick}
    >
      <label
        htmlFor={htmlFor}
        className={`${classes.tab} ${classes.tabs} d-flex align-items-center`}
       >
        {show ? (
          title
        ) : (
          <div className={`${classes.language_container} d-flex justify-content-center align-items-center flex-wrap`}>
            {cartoons && (
              <div className={classes.tab_img_container}>
                <img src={src} alt='' />
              </div>
            )}
            <span
              className={`${
                activeTab ? `${classes.active_buttons} font-weight-normal` : ''
              } ${cartoons && classes.font_size_12} ${fs ? fs : ''}`}
            >
              {title}
            </span>
          </div>
        )}
      </label>
    </button>
  );
};

export default Tab;
