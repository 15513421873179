import { createSlice } from '@reduxjs/toolkit';

const initialState = { categories: [] };

const productCategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoriesHandler(state, action) {
      state.categories = action.payload.categories;
    },
  },
});

export const productCategoriesAction = productCategoriesSlice.actions;
export default productCategoriesSlice;
