import { createSlice } from '@reduxjs/toolkit';

const initialState = {updatedToken:{}};

const updateTokenSlice = createSlice({
  name: 'updatedToken',
  initialState,
  reducers: {
    updateTokenHandler(state, actions) {
      state.updatedToken = actions.payload.updatedToken;
    },
  },
});

export const  updateTokenAction = updateTokenSlice.actions;
export default updateTokenSlice;
