import React,  { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './UploadCartoonAgeGroup.module.css';
import UploadCartoonTitle from '../uploadCartoonTitle/UploadCartoonTitle';
import { LanguageContext } from '../../../../../context/LanguageContext';
import ErrorMessage from '../../../../../components/error/ErrorMessage';
import ageGroups from '../../../../../store/ageGroups/AgeGroups';

const UploadCartoonAgeGroup = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false)
  const [ageGroup, setAgeGroup] = useState(false)
  const { onChange, data, type, containsError } = props;
  const ageGroupData = useSelector((state) => state.ageGroups.ageGroups.data);

  useEffect(() => {
    dispatch(ageGroups());
  }, [dispatch]);

  const ageGroupClick = () => {
    setAgeGroup(!ageGroup)
  }

  useEffect(() => {
    if(containsError) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }, [containsError])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.gap_5} flex-wrap position-relative w-100 d-flex`}>
          <UploadCartoonTitle
                setShow={setShow}
                show={show}
                 onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            title={ctx.data?.age_group[ctx.language]}
          />
          <div className={`${classes.gap_5} ${classes.height73} w-100 d-flex flex-wrap`}>
          <div className={`${classes.upload_cartoon_age_group_container} d-flex w-100 align-items-center`}>
            {ageGroupData.map((age) => {
              return (
                <div onClick={ageGroupClick} key={age}>
                  <input
                    className={`${classes.title} ${classes.titleEdit}`}
                    onChange={onChange}
                    type={type}
                    value={age}
                    id={`age_group_cartoon${age}`}
                    name='age_group'
                    hidden
                  />
                  <label
                    htmlFor={`age_group_cartoon${age}`}
                    className={`${classes.min_width_60} text-center justify-content-center d-flex`}
                  >
                    <span
                      className={`${data?.includes(age) ? `${classes.active_age} align-items-center justify-content-center` : ''} ${classes.item} d-flex`}
                      id={`age_group_cartoon${age}`}
                    >
                      {age}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          {isError && <ErrorMessage text={ctx.data?.error_meesage[ctx.language]}/>}
          </div>
        </div>
        )
      }}
    </LanguageContext.Consumer>

  );
};

export default UploadCartoonAgeGroup;
