import axios from 'axios';
import Cookies from 'js-cookie';
import { newPurchasesAction } from './NewPurchasesSlice';

const fetchNewPurchases = (productId) => {
    const token = Cookies.get('auth_token');
    return async (dispatch) => {
      const fetchData = async () => {
        const body = { productId };
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
        const url = `${process.env.REACT_APP_DEV_URI}/user/purchases/new`;
        const response = await axios.post(url, body, { headers });
        return response;
      };
      try {
        const newPurchases = await fetchData();
        return dispatch(newPurchasesAction.newPurchasesHandler({ newPurchases: {data:newPurchases.data, status:newPurchases.status}}));
      } catch (e) {
        return e;
      }
    };
  };
  
  export default fetchNewPurchases;
  