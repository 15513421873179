import React from 'react';
import classes from './CartoonCardImage.module.css';
import CartoonAgeGroup from '../ageGroup/CartoonAgeGroup';
import CartoonDuration from '../duration/CartoonDuration';
import { LanguageContext } from '../../../../../context/LanguageContext';

const CartoonCardImage = (props) => {
  const {
    cartoonImage,
    onMouseEnter,
    onMouseLeave,
    ageGroup,
    duration,
    isHover,
    autoPlay,
  } = props;

  const second = Math.floor(duration / 1000);

  const cartoonDurations = (data, lang) => {
    let cartoonDurationArr = [];
    const name = ['hours', 'minute', 'seconds'];
    const durations = [3600, 60, 1];

    if (data && lang) {
      name.forEach((item, index) => {
        cartoonDurationArr.push({
          time: Math.floor(second / durations[index]),
          type: data[item][lang].slice(0, 1),
          key: data[item]['en'].slice(0, 1),
        });
      });
    }
    return cartoonDurationArr;
  };

  const durationPartsHandler = (cartoonDurationArr) => {
    const durationParts = cartoonDurationArr
      .sort((a, b) => a.key.localeCompare(b.key))
      .filter((item) => item?.time !== 0);
    const cartoonDuration = durationParts?.find((item) => item.key === 'h')
      ? `${durationParts[0]?.time}${durationParts[0]?.type} ${durationParts[1]?.time}${durationParts[1]?.type}`
      : `${durationParts[0]?.time}${durationParts[0]?.type}`;
    return durationParts.length === 0 ? '' : cartoonDuration;
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const cartoonDurationArr = cartoonDurations(ctx.data, ctx.language);
        const cartoonDuration = durationPartsHandler(cartoonDurationArr);
        return (
          <div
            className={`w-100 position-absolute top-0 ${classes.height_160}`}
          >
            <div
              className={`position-absolute ${classes.z_index_1000} ${
                isHover
                  ? classes.cartoon_image_hover
                  : !autoPlay
                  ? classes.image_on_mouse_leave
                  : ''
              } ${classes.cursor_pointer}`}
            >
              <div
                className={`${classes.cartoon_image_container} ${classes.border_radius_10} d-flex justify-content-center  position-relative`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                {ageGroup && <CartoonAgeGroup ageGroup={ageGroup ?? ''} />}
                {cartoonDuration && (
                  <CartoonDuration duration={cartoonDuration ?? ''} />
                )}
                <img
                  className={`${classes.border_radius_10} w-100 h-100`}
                  src={`${process.env.REACT_APP_DEV_URI}${cartoonImage}`}
                  alt=''
                />
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonCardImage;
