import React from 'react';
import styles from './Blocks.module.css';

const Texts = (props) => {
  const {children} = props
  return (
    <span className={`${styles.footer_menus_link} d-flex w-100 align-items-start`}>
      {children}
    </span>
  );
};

export default Texts;
