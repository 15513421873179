import { useParams } from 'react-router-dom';
import classes from './PlayListItems.module.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlayListItem from './playListItem/PlayListItem';
import { LanguageContext } from '../../../../context/LanguageContext';
import fetchUpdatePlayList from '../../../../store/cartoons/updateCartoonPlaylist/FetchUpdatePlaylist';

const PlayListItems = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const updatePlayList = useSelector((state) => state.updatePlayList);
  const scrollableDiv = document.getElementsByClassName('infinite-scroll-component__outerdiv')
  const { playListData, setIsInitialLoded, setIsLoading } = props;
  const checkedPlaylists = JSON.parse(window.sessionStorage.getItem('checkedPlaylists')) ?? []
  
  if(scrollableDiv && scrollableDiv[0]) {
    scrollableDiv[0].style.width = '100%'
  }

  const deleteCartoonHandler = (removeCartoonId) => {
    setIsDelete(true);
    const filteredData = checkedPlaylists?.filter((item) => item !== removeCartoonId)
    window.sessionStorage.setItem('checkedPlaylists', JSON.stringify(filteredData))
    dispatch(fetchUpdatePlayList(params.id, null, null, removeCartoonId));
  };

  useEffect(() => {
    if (isDelete && updatePlayList.updatePlayList.status === 200) {
      window.location.reload();
      setIsDelete(false);
      setIsLoading(true);
    }
  }, [isDelete, updatePlayList, setIsLoading]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            id='scrollableDiv'
            className={`${classes.max_width_800} ${classes.playlist_item_container} ${classes.gap_20} d-flex flex-column w-100 overflow-hidden h-100`}
          >
            {playListData.map((item) => {
              return (
                <PlayListItem
                  src={item?.imageUrl}
                  key={item._id}
                  title={item?.title[ctx.language]}
                  authorName={item?.author[ctx.language]}
                  data={item}
                  playListData={playListData}
                  setIsInitialLoded={setIsInitialLoded}
                  deleteCartoonHandler={() => deleteCartoonHandler(item._id)}
                />
              );
            })}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlayListItems;