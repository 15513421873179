import Cookies from 'js-cookie';
import React, { useContext } from 'react';
import classes from './Language.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import { constantData } from '../../../../utils/helpers/constantData';

const Language = (props) => {
  const {setShow, showLanguages, setShowLanguages, setProfileParametrsShow, show} = props

  const { language, setLanguage } = useContext(LanguageContext);

  const languageHandler = (lang) => {
    if (lang !== language && process.env.REACT_APP_LANGUAGES.split(', ').includes(lang)) {
      Cookies.set('preferedLanguage', lang);
      setLanguage(lang);
    }
    setShow(false);
    setShowLanguages(false);
  };

  return (
    <div className='d-flex align-items-center justify-content-center'>
      <button
        className={`${classes.action_icon_container} border-0 p-0`}
        onClick={
          !showLanguages
            ? () => {
              setShowLanguages(true);
                setShow(false);
                setProfileParametrsShow(false);
              }
            : () => setShowLanguages(false)
        }
      >
        <img src='../../assets/icons/navBarIcons/langusge.svg' alt='' />
      </button>
      <div
        className={
          showLanguages
            ? `${classes.language_data} ${classes.language_data_animated}`
            : classes.language_data
        }
      >
        <div className={classes.languages_container}>
          {!show ? (
            <div className={classes.language_dropdown_visible}>
              <div className={classes.language_container}>
                {constantData.languageData.map((item) => {
                  return (
                    <button
                      className='d-flex justify-content-center'
                      onClick={() => languageHandler(item.lang)}
                      key={item.lang}
                    >
                      <img src={item.img} alt='' />
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Language;
