import axios from 'axios';
import Cookies from 'js-cookie';
import { latestProductsAction } from './LatestProductsSlice';

const fetchLatestProducts = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/product/latest?limit=${limit}&page=${page}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const latestProducts = await fetchData();
      dispatch(latestProductsAction.latestProductsHandler({ latestProducts }));
    } catch (e) {
      return e;
    }
  };
};

export default fetchLatestProducts;
