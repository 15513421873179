import React from 'react';
import Modals from '../Modals';
import classes from './ErrorModal.module.css';

const ErrorModal = (props) => {
  const { show, onHide, errorMessage, img} = props;
  return (
    <Modals
      show={show}
      onHide={onHide}
    >
      <div
        className={`${classes.error_container} justify-content-center d-flex w-100 flex-wrap`}
      >
        <div
          className={`${classes.img_container} justify-content-center d-flex w-100`}
        >
          <img src={img ?? '../../assets/icons/error/signInError.svg'} alt='vinni' className={classes.error_img} />
        </div>
        <div
          className={`${classes.max_width_300} ${classes.text_container} d-flex`}
        >
          <span className='text-center'>{errorMessage}</span>
        </div>
      </div>
    </Modals>
  );
};

export default ErrorModal;
