import { createSlice } from '@reduxjs/toolkit';

const initialState = {singleProduct:{}}
const productSlice = createSlice({
    name:'singleProduct',
    initialState,
    reducers:{
        singleProductHandler(state, action) {
            state.singleProduct = action.payload.singleProduct
        }
    }
})

export const productAction = productSlice.actions
export default productSlice