import Slide from './slide/Slide';
import React, { useContext } from 'react';
import styles from './SlideList.module.css';
import { CarouselContext } from '../../Carousel';

const SlidesList = (props) => {
  const {animation} = props
  const { slideNumber, items } = useContext(CarouselContext);

  return (
    <div className={animation ? `${styles.slide_list} ${styles.fadeInAnimation} d-flex m-auto` : ''}>
      <Slide data={items[slideNumber] && items[slideNumber]} animation={animation} id={items[slideNumber]?.id}/>
    </div>
  );
};

export default SlidesList;
