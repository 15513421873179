import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './PlayListItem.module.css';

const PlaylistItemWithContent = (props) => {
  const [showSeeAll, setShowSeeAll] = useState(false);
  const {
    playlistId,
    marginRight,
    marginLeft,
    id,
    imageUrl,
    index,
    seeAll,
    cartoonCount,
    video,
    title,
  } = props;

  return (
    <Link
      to={`/single-playlist/${playlistId}`}
      className={`${classes.list_item} ${classes.play_list_item} text-decoration-none col-sm-6 col-md-4 col-lg-3 col-12 col-xl-2 ${classes.min}`}
      onMouseEnter={() => setShowSeeAll(true)}
      onMouseLeave={() => setShowSeeAll(false)}
      style={{
        marginRight: `${marginRight}px`,
        marginLeft: `${marginLeft}px`,
      }}
    >
      <div
        id={`bg_${id}`}
        style={{backgroundImage: `url(${process.env.REACT_APP_DEV_URI}${imageUrl})`}}
        className={`${classes.main_div} d-flex align-items-end relative overflow-hidden position-relative`}
      >
        <div
          className={
            showSeeAll
              ? `${classes.play_cartoon} position-absolute d-flex start-0 justify-content-center align-items-center bottom-0 top-0 end-0`
              : ''
          }
          id={`play_${index}`}
        >
          <img
            src='../assets/video/play.svg'
            className={`${classes.icon} ${showSeeAll ? 'd-block' : 'd-none'}`}
            alt='play'
          />
          <p
            className={`${classes.see_all} ${
              showSeeAll ? 'd-block' : 'd-none'
            } d-inline`}
          >
            {seeAll}
          </p>
        </div>
        <div
          className={`${classes.views} d-flex w-100 justify-content-between align-items-center`}
        >
          <img
            src='../assets/video/playlist.svg'
            className={`${classes.icon}`}
            alt='playlist'
          />
          <p className={`${classes.see_all}`}>
            {cartoonCount} {video}
          </p>
        </div>
      </div>
      <h4 className={`${classes.playlist_title} mb-0`}>{title}</h4>
      <h5 className={`${classes.color_black} ${classes.font_size_14}`}>
        {seeAll}
      </h5>
    </Link>
  );
};

export default PlaylistItemWithContent;
