import React from 'react';
import classes from './PlaylistDetails.module.css';
import PlaylistImg from './playlistImg/PlaylistImg';
import PlayListTitle from './playListTitle/PlayListTitle';

const PlaylistDetails = (props) => {
  const { singlePlayList, title, playlistId, navigateCartoonPage, id} = props;

  return (
    <div className={`${classes.playlist_item_container} h-100 w-100 justify-content-center d-flex `}>
      <div className={`${classes.width_360} ${classes.padding_20_0} ${classes.height_300} ${classes.playListCotainer} ${classes.data_container} d-flex flex-wrap`}>
        <PlaylistImg singlePlayList={singlePlayList} playlistId={playlistId} navigateCartoonPage={navigateCartoonPage} id={id} />
        <PlayListTitle title={title}/>
      </div>
    </div>
  );
};

export default PlaylistDetails;
