import React from 'react';
import classes from './SingleProductSlider.module.css';
import Titles from '../../../components/title/Titles';
import { LanguageContext } from '../../../context/LanguageContext';
import ProductsSlider from '../../../components/slider/productsSlider/ProductsSlider';

const SingleProductSlider = (props) => {
  const {similiar} = props
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
          {similiar.length > 0 && <div className={`${classes.products_container} d-flex w-100 justify-content-center flex-wrap`}>
          <Titles 
          title={{
            subtitle: ctx.data?.similar_books[ctx.language],
            title: ctx.data?.we_picked[ctx.language],
          }}/>
          <ProductsSlider popular={similiar}/>
        </div>}
        </>
        )
      }}
    </LanguageContext.Consumer>
 
  );
};

export default SingleProductSlider;
