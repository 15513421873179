import axios from 'axios';
import Cookies from 'js-cookie';
import { updatePlayListAction } from './UpdatePlaylistSlice';

const fetchUpdatePlayList = (playlistId, title, cartoonId, removeCartoonId) => {
  return async (dispatch) => {
    const body = {
      playlistId,
      title,
      cartoonId,
      removeCartoonId: removeCartoonId ? [removeCartoonId] : null,
    };
    const auth = `Bearer ${Cookies.get('auth_token')}`;
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist/update`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json', Authorization: auth },
      });
      return response;
    };
    try {
      const updatePlayList = await fetchData();
      dispatch(
        updatePlayListAction.updateCartoonPlayListHandler({
          updatePlayList,
        })
      );
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};

export default fetchUpdatePlayList;
