import Cookies from 'js-cookie';
import classes from './PageForReading.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ReactReader, ReactReaderStyle } from 'react-reader';
import { LanguageContext } from '../../../context/LanguageContext';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import ReadingPageSettings from './readingPageSettings/ReadingPageSettings';
import readedBooks from '../../../store/readBooks/readedBooks/ReadedBooks';
import fetchDownLoad from '../../../store/purchases/downLoad/FetchDownLoad';
import addToReadBooks from '../../../store/readBooks/addToReadBooks/AddToReadBooks';
import deleteReadBook from '../../../store/readBooks/deleteReadBook/DeleteReadBook';
import ConfirmationModal from '../../../components/modal/confirmationModal/ConfirmationModal';
import { pageForReading } from '../../../utils/helpers/pageForReading/pageForReading';

const PageForReading = () => {
  const tocRef = useRef(null);
  const productId = useParams();
  const dispatch = useDispatch();
  const locations = useLocation();
  const renditionRef = useRef(null);
  const [size, setSize] = useState(100);
  const token = Cookies.get('auth_token');
  const [location, setLocation] = useState();
  const [fontFamily, setFontFamily] = useState();
  const file = useSelector((state) => state.file.file);
  const data = JSON.parse(localStorage.getItem('readingPageLocation')) ?? [];
  const readingData = data?.find((item) => item.productId === productId.id);
  const [selections, setSelections] = useState([]); 
  const [textSettings, setTextSettings] = useState(false);
  const [isHighlights, setIsHighlights] = useState(false);
  const brightness = window.sessionStorage.getItem('brightness');
  const highlights = JSON.parse(localStorage.getItem('highlights'));
  const [brightnessMode, setBrightnessMode] = useState(brightness ?? 'default');
  const [bookToRead, setBookToRead] = useState({ book: null, status: 'pending'});
  const check = sessionStorage.getItem('checked');
  const [iframeisReady, setIframeisReady] = useState(false);
  const alreadyRead = JSON.parse(window.localStorage.getItem('readBooks'));
  const [markAsRead, setMarkAsRead] = useState(false);
  const spaceData = JSON.parse(localStorage.getItem('spaceingData'));
  const space = spaceData?.find((item) => item.productId === productId.id)?.data;
  const [deleteFromRead, setDeleteFromRead] = useState(false);
  const scroll = JSON.parse(sessionStorage.getItem('isScroll')) ?? {flow: 'scrolled', manager: 'continuous'};
  const [page, setPage] = useState({page: '', total:''})
  const [textSpacing, setTextSpacing] = useState(pageForReading.defoldData)//space ?? pageForReading.defoldData);
  const locationData = {renditionRef, tocRef, scroll, setPage, setLocation, setMarkAsRead};
  const themes = useMemo(() => {return {darck: { background: 'black', color: '#fff' }, sepia: { background: '#F4EEE0', color: 'black' }}}, []);

  window.scroll(0, 0);
  document.body.style.overflow = 'hidden';

  const changeSize = (newSize) => {
    setSize(newSize);
  };

  useEffect(() => {
    const deleteFromReadBooks = async () => {
      if (alreadyRead?.includes(productId.id) && !renditionRef?.current?.location?.atEnd) {
        const filteredData = alreadyRead.filter((item) => item !== productId.id);
        window.localStorage.setItem('readBooks', JSON.stringify(filteredData));
        dispatch(deleteReadBook(productId.id));
      }
    }
    deleteFromReadBooks()
  }, [alreadyRead, renditionRef, productId, dispatch]);

  useEffect(() => {
    if (locations && space) {
      const iframeItem = document.getElementsByTagName('iframe');
      const margin = document.getElementsByClassName('epub-container');
      const text = iframeItem[0]?.contentWindow.document.querySelectorAll('P');
      if (margin && text && margin[0]) {
        Object.keys(space)?.forEach((item, index) => {
          pageForReading.changeTextSpace(item, margin, space[item].value, iframeItem, text, space, 0, index);
        });
      }
    }
  }, [locations, space, location]);

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const pageDataAsyncHandler = useCallback(async () => {
    if (file) {
      await delay(300);
      return file.data;
    }
  }, [file]);

  const loadIframe = useCallback(async () => {
    const src = await pageDataAsyncHandler();
    if (src) {
      setIframeisReady(true);
    }
  }, [pageDataAsyncHandler]);

  useEffect(() => {
    loadIframe();
  }, [loadIframe]);

  useEffect(() => {
      document.body.style.background = themes[brightnessMode]?.background;
  }, [locations, brightnessMode, themes, iframeisReady]);

  useEffect(() => {
    if (token) {
      logEvent(firebaseAnalytics, 'Web_Book_reading');
      dispatch(readedBooks());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (renditionRef.current) renditionRef.current.themes.fontSize(`${size}%`);
  }, [size]);

  useEffect(() => {
    const fetchBookToRead = async () => {
      const book = await dispatch(fetchDownLoad(productId.id, 'book'));
      if (book.payload.file.status === 200) {
        setBookToRead({ book: book.payload.file.data, status: 'fullfieled' });
      }
    };
    fetchBookToRead();
  }, [dispatch, productId.id]);

  const fontFamilyHandler = (fontType) => {
    setFontFamily(fontType);
  };

  if (fontFamily) {
    const iframeItem = document.getElementsByTagName('iframe');
    if (iframeItem && iframeItem.length > 0) {
      const text = iframeItem[0].contentWindow.document.querySelectorAll('P');
      if (text || brightnessMode === 'darck') {
        for (const a of text) {
          a.style.fontFamily = fontFamily;
        }
      }
    }
  }

  useEffect(() => {
    pageForReading.changeHighlights({renditionRef, setSelections, selections, highlights, setIsHighlights})
  }, [setSelections, selections, highlights, isHighlights]);

  useEffect(() => {
    if (location && !renditionRef?.current?.location?.atEnd) {
      if (!readingData) {
        localStorage.setItem(
          'readingPageLocation',
          JSON.stringify([
            ...data,
            { productId: productId.id, location: location },
          ])
        );
      } else {
        const filteredData = data.filter(
          (item) => item.productId !== productId.id
        );
        localStorage.setItem(
          'readingPageLocation',
          JSON.stringify([
            ...filteredData,
            { productId: productId.id, location: location },
          ])
        );
      }
    } else if (location && renditionRef?.current?.location?.atEnd && deleteFromRead) {
      const filter = data?.filter((item) => item.productId !== productId.id);
      localStorage.setItem('readingPageLocation', JSON.stringify(filter));
    }
  }, [location, renditionRef, deleteFromRead, productId, readingData]);

  const addReadBooks = async () => {
    const response = await dispatch(addToReadBooks(productId.id));
    if (response.payload.status === 200) {
      setMarkAsRead(false);
      setDeleteFromRead(true);
      window.localStorage.setItem('readBooks', JSON.stringify([...alreadyRead, productId.id]));
    }
  };

  if (!iframeisReady) return;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div>
            {bookToRead.status === 'fullfieled' ? (
              <div>
                {textSettings ? (
                  <div
                    className={`${classes.transparent_container} w-100 bg-transparent position-absolute`}
                    onClick={() => setTextSettings(false)}
                  />
                ) : null}
                <div id='books' className={classes.read_book_container}>
                  <ReadingPageSettings
                    textSettings={textSettings}
                    setTextSettings={setTextSettings}
                    decreaseFontSize={() => changeSize(Math.max(80, size - 10))}
                    increaseFontSize={() => changeSize(Math.min(130, size + 10))}
                    fontFamilyHandler={fontFamilyHandler}
                    scrollHandler={pageForReading.scrollHandler}
                    isSwitch={check}
                    night={brightnessMode}
                    brightnessModeHandler={(type) => pageForReading.lightingModeHandler(type, setBrightnessMode)}
                    textSpacing={textSpacing}
                    setTextSpacing={setTextSpacing}
                    addSpacing={(title, type) => pageForReading.spaceingHandler(title, type, textSpacing, setTextSpacing, productId)}
                  />
                  <ReactReader
                    location={readingData?.location ?? 0}
                    url={file.data}
                    getRendition={(rendition) => pageForReading.getRendition(rendition, {renditionRef, size, setSelections})}
                    readerStyles={pageForReading.styles(ReactReaderStyle, check, brightnessMode)}
                    epubOptions={scroll}
                    tocChanged={(toc) => (tocRef.current = toc)}
                    locationChanged={(epubcifi) => pageForReading.lastPageHandler(epubcifi, locationData)
                    }
                  />
                </div>
                <div className={`${classes.page_container} d-flex justify-content-center align-items-center position-absolute text-center padding-0`}>
                  {page.page !== '' && page.total !== '' && `${ctx.data?.page[ctx.language].charAt(0).toUpperCase() + ctx.data?.page[ctx.language].slice(1) } ${page.page} ${ctx.data?.chapter[ctx.language]} ${page.total} `}
                </div>
              </div>
            ) : (
              <></>
            )}
            <ConfirmationModal
              show={markAsRead}
              onHide={() => setMarkAsRead(false)}
              logOutHandler={() => addReadBooks()}
              titles={{
                max_title: ctx?.data?.mark_as_read_message[ctx.language],
              }}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PageForReading;
