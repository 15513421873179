import axios from 'axios';
import Cookies from 'js-cookie';
import { recommendedCartoonsAction } from './RecommendedCartoonsSlice';

const fetchRecommendedCartoons = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/recommended?limit=${limit}&page=${page}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const recomendedCartoonsData = await fetchData();
      let recommendedCartoons = recomendedCartoonsData.data
      dispatch(recommendedCartoonsAction.recommendedCartoonsHandler({recommendedCartoons}));
    } catch (e) {
      return e;
    }
  };
};

export default fetchRecommendedCartoons;