import React from 'react';
import classes from './CategoryCard.module.css';
import { LanguageContext } from '../../../context/LanguageContext';

const CategoryCard = (props) => {
  const { data, onChange, onClick, className, categories } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`
            ${className}`}
            onClick={onClick}
          >
            <input
              type='checkbox'
              value={data?._id}
              id={data.name['en']}
              onChange={onChange}
              className={`${classes.box_shadow_none}`}
              checked={
                categories && categories?.includes(data._id) ? true : false
              }
              hidden
            />
            <label
              htmlFor={data.name['en']}
              className={`${classes.category_item} ${
                categories?.includes(data._id) && classes.active_category
              } w-100 d-flex justify-content-center align-items-center`}
            >
              <div className={`${classes.width_20}`}>
                <img
                  className={classes.width_20}
                  src={`${process.env.REACT_APP_DEV_URI}${data.image.imageURL}`}
                  alt=''
                />
              </div>
              <div>
                <span>{data.name[ctx.language]}</span>
              </div>
            </label>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CategoryCard;
