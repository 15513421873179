import React from 'react';
import PlaylistItemWithContent from './PlayListItemWithContent'
import PlaylistItemWithNoData from './PlaylistItemWithNoData';

const PlayListItem = (props) => {
  let { id, imageUrl, title, cartoonCount, seeAll, playlistId, video, index, marginRight, marginLeft} = props

  return (
    <>
      {imageUrl ? (
        <PlaylistItemWithContent
          playlistId={playlistId}
          marginRight={marginRight}
          marginLeft={marginLeft}
          id={id}
          imageUrl={imageUrl}
          index={index}
          seeAll={seeAll}
          cartoonCount={cartoonCount}
          video={video}
          title={title}
        />
      ) : (
        <PlaylistItemWithNoData
          marginRight={marginRight}
          marginLeft={marginLeft}
          title={title}
          seeAll={seeAll}
        />
      )}
    </>
  );
}

export default PlayListItem;