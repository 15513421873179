import React from 'react';
import { TextField } from '@mui/material';
import classes from './ChangeUserData.module.css';
import ChangeDataButtons from './changeDataButtons/ChangeDataButtons';

const ChangeUserData = (props) => {
  const {
    cancel,
    label,
    type,
    onChange,
    cancelId,
    name,
    defaultValue,
    placeholder,
    isShow,
    accept,
    onSubmit,
    error,
    onBlur
  } = props;

  return (
    <form className={`${classes.change_data_container} d-flex w-100 flex-wrap`} onSubmit={onSubmit}>
      <div className='w-100'>
        <TextField
          id='outlined-password-input'
          label={label}
          type={type}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete='off'
          autoFocus={true}
          accept={accept}
          name={name}
          onBlur={onBlur}
        />
        {error}
      </div>
      {!isShow ? (
        <ChangeDataButtons
          cancel={cancel}
          cancelId={cancelId}
        />
      ) : null}
    </form>
  );
};

export default ChangeUserData;
