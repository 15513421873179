export const requestotp = {
  async requestOTP(
    e,
    generateRecaptcha,
    recaptchaId,
    RecaptchaVerifier,
    auth,
    signInWithPhoneNumber,
    phoneNumber,
    setShowModal,
    setShowAuth,
    showAuth,
    setError,
    setShow,
    setLoading,
    disabled,
    setDisabled
  ) {
    if (showAuth) {
      setLoading(['loginByPhoneNumber'])
    }
    e.preventDefault();
    
    await generateRecaptcha(e,
      generateRecaptcha,
      recaptchaId,
      RecaptchaVerifier,
      auth,
      signInWithPhoneNumber,
      phoneNumber,
      setShowModal,
      setShowAuth,
      showAuth,
      setError,
      setShow,
      this.requestOTP,
      setLoading, 
      disabled,
      setDisabled
    );
    
    let appVerifier = window.recaptchaVerifier;
    const provider = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    const filtered = disabled?.filter((item) => item !== 'resend')
    try {
      window.confirmationResult = provider;
      if (provider) {
        setDisabled(filtered)
        setShowModal(['validated'])
        setShowAuth(showAuth);
      }
    } catch (error) {
      setDisabled(filtered)
      setError(true);
      setShowModal([])
      setShow(false);
    }
  }
}


