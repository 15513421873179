import axios from 'axios';
import Cookies from 'js-cookie';
import { allPlayListsAction } from './AllPlayListsSlice';

const fetchAllPlayLists = (page, limit) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist/all?limit=${limit}&page=${page}&type=web`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      let allPlayLists = data.data.playlist
      let playlistCount = data.data.playlistCount
     return dispatch(allPlayListsAction.allPlayListsHandler({'allPlayLists': { 'allPlayLists' : allPlayLists, 'playlistCount': playlistCount }}));
    } catch (e) { }
  };
};

export default fetchAllPlayLists;