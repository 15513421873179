import React from 'react';
import classes from './TipMessage.module.css';
import { LanguageContext } from '../../context/LanguageContext';

const TipMessage = (props) => {
  const { show } = props;
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={
              show
                ? `${classes.dropdown_container} ${classes.height_60} d-flex align-items-center position-absolute overflow-hidden`
                : `${classes.dropdown_container} d-flex align-items-center position-absolute overflow-hidden`
            }
          >
            <div>
              <span className='d-flex w-100 align-items-center'>{ctx.data?.fill_fields[ctx.language]}</span>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default TipMessage;
