import React from 'react';
import classes from './EditTitle.module.css';
import { LanguageContext } from '../../../../../../context/LanguageContext';

const EditTitle = (props) => {
  const { saveTitleHandler, cancelTitleHandler, defaultValue, titleHandler } =
    props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`d-flex flex-wrap w-100`}
          >
            <div className={`${classes.edit_title} w-100`}>
              <input
                defaultValue={defaultValue}
                className={'w-100 border-0'}
                onChange={titleHandler}
              />
            </div>
            <div
              className={`d-flex ${classes.gap_10} justify-content-end w-100`}
            >
              <span
                className={`${classes.text_confirm_edit} ${classes.cancel_text}`}
                onClick={cancelTitleHandler}
              >
                {  ctx.data?.cancel[ctx.language]}
              </span>
              <span
                className={`${classes.text_confirm_edit} ${classes.save_text}`}
                onClick={saveTitleHandler}
              >
               {ctx.data?.save[ctx.language]}
              </span>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default EditTitle;
