import TabbarMenu from './TabbarMenu';
import styles from './TabBar.module.css';
import { useLocation } from 'react-router-dom';
import DownloadApp from '../../downloadApp/DownloadApp';
import React, { useEffect, useMemo, useState } from 'react';
import {constantData} from '../../../utils/helpers/constantData'
import { LanguageContext } from '../../../context/LanguageContext';
import { operationsWithOs } from '../../../utils/helpers/operationsWithOs/operationsWithOs';

const TabBar = () => {
  const os = operationsWithOs.getOS() 
  const location = useLocation();
  const imageUri = '/assets/tabbar/';
  const [download, setDownload] = useState(false)
  const path = location.pathname.slice(location.pathname.indexOf('/') + 1);
  const [name, setName] = useState(sessionStorage.getItem('name') ?? null)
  const menu = useMemo(() => constantData.tabBarMenu, [])
  
  const tabBarParameters = (name, lang) => {
    let data = [];
    if (name && lang) {
      menu.forEach((item) => {
        data.push({
          id: item === 'vinni' ? '' : item,
          title: name[item][lang],
        });
      });
    }
    return data
  };

  const tabBarHandler = (type) => {
    window.scroll(0, 0)
    window.sessionStorage.setItem('name', type !== '' ? type : 'vinni')
    setName(type)
  }

  useEffect(() => {
    if(location.pathname === '/') {
      window.sessionStorage.setItem('name', 'vinni')
      setName('')
    }
  }, [location])

  useEffect(() => {
    if(path) {
      for(let i of menu) {
        if(path.includes(i)) {
          setName(i)
        }
      }
    }
  }, [menu, path])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const tabs = tabBarParameters(ctx.data, ctx.language)
        return (
          <div
            className={`${styles.tab_bar_container} ${styles.tab_bar_display} w-100 position-fixed bottom-0 start-0`}
            id='tabBar'
           >
            <div
              className={`${!download ?  ' align-items-center h-100': ''} d-flex align-items-center justify-content-around`}
            >
              {download ? tabs.map((tab) => {
                return (
                  <TabbarMenu
                    key={tab.id}
                    href={`/${tab.id}`}
                    onClick={() => tabBarHandler(tab.id)}
                    imageSrc={imageUri + `${tab.id !== '' ? tab.id : 'vinni'}${(name !== tab.id || name === 'vinni') ? '_outlined' : '_filled'}.svg`}
                    active={name === tab.id}
                    title={tab.title}
                  />
                );
              }) : <DownloadApp onClick={() => setDownload(true)} os={os}/> 
              }

            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default TabBar;
