import React from 'react';
import classes from './Advanced.module.css';
import { LanguageContext } from '../../../../../../context/LanguageContext';

const Advanced = (props) => {
    const {setAdvanced} = props
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.advenced_container} d-flex w-100 justify-content-center`} onClick={() => setAdvanced(true)}>
            <div className={`${classes.advenced_item_container} w-100 d-flex align-items-center justify-content-between`}>
              <span className={`${classes.advenced_item_text}`}>{ctx.data?.advanced[ctx.language]}</span>
              <img src='../../assets/products/sort/sortProductShowSmall.svg' alt='' className={classes.paddin_5}/>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Advanced;
