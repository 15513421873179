import { createSlice } from '@reduxjs/toolkit';

const initialState = {preferance:{}};

const preferanceSlice = createSlice({
  name: 'preferance',
  initialState,
  reducers: {
    preferanceHandler(state, actions) {
      state.preferance = actions.payload.preferance;
    },
  },
});

export const  preferanceAction = preferanceSlice.actions;
export default preferanceSlice;
