export const operationsWithKeys = {
    onKeyDown(event, selectedIndex, searchResult, setSelectedIndex, searchFrom, languageKey, searchCartoons) {
        let searchInput = document.getElementById('searchCartoon')
        let index = 0
        switch (event.keyCode) {
          case 38:
            selectedIndex > 0 ? index = selectedIndex - 1 : index = searchResult.length - 1
            break
          case 40:
            if (selectedIndex >= 0 && selectedIndex < searchResult.length - 1 && searchInput) {
              index = selectedIndex + 1
            }
            break
          default:
            return
        }
        setSelectedIndex(index)
        const value = searchFrom === 'books' ? searchResult[index].name[languageKey] : searchResult[index].title[languageKey]
        searchInput.value = value
        searchCartoons(event)
      },

     keyDownHandler(e, setCodeVerification, curInput, index, OTP, setOTP, id){
        setCodeVerification(true);
        if ((!curInput?.value || curInput?.value === '') && e.keyCode === 8) {
          OTP[index - 1] = '';
          setOTP(OTP);
          document.getElementById(`${id}_${index}`)?.focus();
        } else if (curInput?.value && curInput?.value !== '' && e.keyCode === 8) {
          OTP[index] = '';
          setOTP(OTP);
          document.getElementById(`${id}_${index + 1}`).focus();
        }
      },

      keyUpHandler(OTP, setOTP, curInput, nextInput) {
        if (OTP.length === 1 && OTP[0] === '') {
          setOTP([]);
        }
        if (
          curInput?.value &&
          curInput?.value !== '' &&
          nextInput &&
          !nextInput.value
        ) {
          nextInput.focus();
        }
      }

}