import { createSlice } from '@reduxjs/toolkit';

const initialState = {smiliarCartoons: []};

const similiarCartoonsSlice = createSlice({
  name: 'similiarCartoons',
  initialState,
  reducers: {
    smiliarCartoonsHandler(state, action) {
      state.smiliarCartoons = action.payload.smiliarCartoons;
    },
  },
});
export const smiliarCartoonsAction = similiarCartoonsSlice.actions;
export default similiarCartoonsSlice;