import React from 'react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import classes from './AuthorData.module.css';
import { LanguageContext } from '../../../context/LanguageContext';

const AuthorData = (props) => {
  const { data, books } = props;
  const navigate = useNavigate();

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
            <div className={`${classes.gap_40} ${classes.padding_10} d-flex flex-wrap justify-content-center w-100`}>
              <div className={`${classes.gap_15} ${classes.max_width_510} d-block justify-content-center flex-wrap`}>
                <div className='w-100'>
                  <span className={`${classes.author_title}`}>
                    {ctx.data?.author[ctx.language]}
                  </span>
                </div>
                <div>
                  <div className={`${classes.gap_10} d-block justify-content-center`}>
                    <img
                      className={`${classes.author_img_container} w-100 h-100`}
                      src={`${process.env.REACT_APP_DEV_URI}${data?.author?.image}`}
                      alt=''
                    />
                    <span
                      className={`${classes.author_name} w-100`}
                    >
                      {data?.author?.name[ctx.language]}
                    </span>
                    <span className={`${classes.author_data} d-block`}>
                      {data?.author?.bio[ctx.language]}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${classes.max_width_340} w-100`}>
                <div className={`${classes.similiar_books} w-100 d-flex justify-content-between`}>
                  <span 
                    className={classes.text}
                  >{ctx.data?.author_books[ctx.language]}</span>
                  <span
                    className={`${classes.cursor_pointer} ${classes.text}`}
                    onClick={() => navigate(`/authors/${data?.author._id}`)}
                  >
                    {ctx.data?.see_all[ctx.language]}
                  </span>
                </div>
                <div
                  className={`${classes.books_container} w-100 d-flex flex-wrap`}
                 >
                  {books?.map((item) => {
                    return (
                      <div className={`${classes.books_item} d-flex`} key={nanoid()} onClick={() => navigate(`/products/${item._id}`)}>
                        <div className={`${classes.item_data} d-flex flex-wrap`}>
                          <div
                            className={`${classes.max_height_147} justify-content-center d-flex w-100`}
                          >
                            <img
                              className={`${classes.max_width_100} w-100 h-100`}
                              src={`${process.env.REACT_APP_DEV_URI}${item.image.imageURL}`}
                              alt=''
                            />
                          </div>
                          <div
                            className={`d-flex justify-content-center w-100`}
                          >
                            <span className={`${classes.books_title} overflow-hidden w-100 text-center`}>
                              {item.name[ctx.language]}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};
export default AuthorData;
