import React from 'react';
import classes from './BackDrop.module.css';

const BackDrop = (props) => {
  const { onClick, className } = props;
  return (
    <div
      className={`${classes.z_index_1} ${className} position-fixed bg-transparent top-0 bottom-0 start-0 end-0`}
      onClick={onClick}
    />
  );
};

export default BackDrop;
