import axios from 'axios';
import Cookies from 'js-cookie';
import { readedBooksAction } from './ReadedBooksSlice';

const readedBooks = (limit, activePage) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/user/readBooks/all?type=web&limit=${limit ? limit : '*'}&page=${activePage ? activePage : '*'}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const readedBooks = await fetchData();
      return dispatch(readedBooksAction.readedBooksHandler({data: readedBooks.data.result, pageCount: readedBooks.data.pageCount, status: readedBooks.status }));
    } catch (e) {
      return e;
    }
  };
};

export default readedBooks;
