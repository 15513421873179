import axios from 'axios';
import Cookies from 'js-cookie';
import { allBlogsAction } from '././AllBlogsSlice';

const FetchAllBlogs = (page, limit) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/blog/all?limit=${limit}&page=${page}&type=web`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      dispatch(allBlogsAction.allBlogsHandler({'allBlogs': data.data}));
    } catch (e) { }
  };
};

export default FetchAllBlogs;