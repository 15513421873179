import Cookies from 'js-cookie';
import LogOut from '../logOut/LogOut';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './NavBar.module.css';
import BackDrop from '../backDrop/BackDrop';
import Profile from './navBarParametrs/profile/Profile';
import SignInSettings from '../modal/auth/SignInSettings';
import Language from './navBarParametrs/language/Language';
import { LanguageContext } from '../../context/LanguageContext';
import UploadCartoons from '../../pages/cartoon/uploadCartoons/UploadCartoons';

const NavBar = (props) => {
  const { token, country, setCountry } = props;
  const [show, setShow] = useState(false);
  const [status] = useState(false);
  const [search, setSearch] = useState(false);
  const [language, setLanguage] = useState(false);
  const [userToken, setUserToken] = useState(token);
  const [confirmLogOut, setConfirmLogOut] = useState(false);
  const [isUploadCartoon, setIsUploadCartoon] = useState(false);
  const [profileParametrsShow, setProfileParametrsShow] = useState(false);
  const nowReadingBook = JSON.parse(
    localStorage.getItem('readingPageLocation')
  );
  const authToken = Cookies.get('auth_token');

  const modalShowHandler = () => {
    if (status) {
      setShow(false);
    }
  };

  const setIsShow = (isShown) => {
    setShow(isShown);
  };

  const modalHideHandler = () => {
    setShow(false);
  };

  const tokenHandler = (userToken) => {
    !userToken && setUserToken(userToken);
  };

  const navBarParametrs = (nowReadingBook, authToken) => {
    let data = ['now_reading', 'books', 'authors', 'cartoons', 'about'];

    if (!nowReadingBook || nowReadingBook.length === 0 || !authToken) {
      data = data.filter((item) => item !== 'now_reading');
    }
    return data;
  };

  const navBarParametr = navBarParametrs(nowReadingBook, authToken);

  return (
    <>
      <LanguageContext.Consumer>
        {(ctx) => {
          return (
            <>
              <nav className={`w-100 h-100`} id='navBar'>
                <div
                  className={`${classes.nav_bar_container} position-relative d-flex justify-content-between`}
                >
                  <Link
                    to='/'
                    className={`${classes.nav_bar_logo_container} d-flex align-items-center`}
                  >
                    <img
                      className={`${classes.nav_bar_icon}`}
                      src='../../favicon.png'
                      alt=''
                    />
                  </Link>
                  <div
                    className={`${classes.parametrs_container} justify-content-center d-flex align-items-center h-100`}
                  >
                    {navBarParametr?.map((item) => {
                      return (
                        <Link
                          className='d-flex align-items-center text-center d-flex text-decoration-none'
                          to={`/${
                            item === 'books'
                              ? 'products'
                              : item === 'about'
                              ? 'about-us'
                              : item === 'now_reading'
                              ? `products/${
                                  nowReadingBook &&
                                  nowReadingBook[nowReadingBook?.length - 1]
                                    ?.productId
                                }`
                              : item
                          }`}
                          key={`navigation_bar_${item}`}
                        >
                          {ctx?.data &&
                            ctx?.data[item][ctx.language]?.toUpperCase()}
                        </Link>
                      );
                    })}
                  </div>
                  <div
                    className={`${classes.action_menues} d-flex  align-items-center justify-content-between`}
                  >
                    <Language
                      setProfileParametrsShow={setProfileParametrsShow}
                      showLanguages={language}
                      setShowLanguages={setLanguage}
                      setShow={setShow}
                    />
                    <Profile
                      authToken={userToken}
                      setShow={setShow}
                      setConfirmLogOut={setConfirmLogOut}
                      profileParametrsShow={profileParametrsShow}
                      setProfileParametrsShow={setProfileParametrsShow}
                    />
                  </div>
                </div>
              </nav>
              <SignInSettings
                country={country ?? localStorage.getItem('country')}
                setCountry={setCountry}
                showAuth={show}
                setShowAuth={setShow}
                onHide={modalHideHandler}
                onPress={modalShowHandler}
                setUserData={() => {
                  setShow(false);
                }}
                setShow={setIsShow}
                setCookiesToken={tokenHandler}
              />
              <LogOut
                show={confirmLogOut}
                setShow={setConfirmLogOut}
                setAuthToken={setUserToken}
              />
              {profileParametrsShow || language ? (
                <div
                  className={`${classes.transparentBackground} position-absolute start-0 w-100 h-100 top-0 bg-transparent`}
                  onClick={() => {
                    setProfileParametrsShow(false);
                    setLanguage(false);
                  }}
                />
              ) : null}
              {isUploadCartoon ? (
                <UploadCartoons
                  show={isUploadCartoon}
                  onHide={() => setIsUploadCartoon(false)}
                />
              ) : null}
              {search && <BackDrop onClick={() => setSearch(false)} />}
            </>
          );
        }}
      </LanguageContext.Consumer>
    </>
  );
};

export default NavBar;
