import { createSlice } from '@reduxjs/toolkit';

const initialState = { userData: { user: {}, status: null}};

const getUserDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    userDataHandler(state, action) {
      state.userData = action.payload.userData;
    },
  },
});
export const getUserDataAction = getUserDataSlice.actions;
export default getUserDataSlice;
