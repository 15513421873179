import axios from 'axios';
import Cookies from 'js-cookie';
import { loginAction } from './LoginSlice';

const login = (token) => {
  return async (dispatch) => {
    const body = {
      tokenId: token,
    };
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/loginphone/mobile`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    };
    try {
      const result = await fetchData();
      const expiresIn = (new Date(result.tokenExpire) - new Date()) / (1000 * 60 * 60 * 24);
      const refreshTokenExpiresIn = (new Date(result.refreshTokenExpireAt) - new Date()) / (1000 * 60 * 60 * 24)
      localStorage.setItem('sessionClose', new Date(result.tokenExpire))
      if (result && Object.keys(result).length > 0) {
        Cookies.set('auth_token', result.token.toString(), { expires: expiresIn });
        Cookies.set('refresh_token', result.refreshToken.toString(), { expires: refreshTokenExpiresIn });
        const tokenCredentials = {
          token,
          expairedAt: '',
        };
        dispatch(loginAction.loginHandler({ tokenCredentials }));
        return result;
      }
    } catch (e) {
     const response = dispatch(loginAction.loginHandler({ tokenCredentials: e.response}))
     return response;
    }
  };
};

export default login;
