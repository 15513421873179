import React from 'react';
import {constantData} from '../../../utils/helpers/constantData'
import classes from './ControlPanel.module.css'
import { videoPlayer } from '../../../utils/helpers/videoPlayer';

const ControlPanel = (props) => {
  let { prev, next, video, visibleControlMenu, setIsPlay, iconUrls, setUpdateData } = props
  const parametrs = constantData.parametrs(prev, video, videoPlayer, iconUrls, visibleControlMenu, setIsPlay, setUpdateData, next)

  return (
    <>
    {parametrs.map((item, index) => {
      return (
        <div className={classes.margin_right_10} key={`${item}_${index}`}>
        <img
          src={parametrs[index].src}
          alt='previus'
          className={`${classes.player_mini_icon} ${classes.cursor_pointer} ${classes.fill_white} ${classes.action_buttons} object-fit-cover`}
          id={parametrs[index].id}
          onClick={(event) => parametrs[index].onclick(event)}
        />
      </div>
      )
    })}
    </>
  );
};

export default ControlPanel;