export const verifyotp = {
  async verifyOTP(e, title, OTP, setDisabled, loading, editPhoneNumber,  confIsVisible, login,  data, lang, user) {
    let mutableUser = {...user }
    e.preventDefault();
    setDisabled([title]);
    if (confIsVisible && !login) loading(['validation']);

    if (OTP.length === 6) {
      const verificationCode = OTP.join('');
      let confirmationResult = window.confirmationResult;
      login && loading(['loginByPhoneNumber', 'validation']);
      editPhoneNumber(confirmationResult, verificationCode, data, lang, mutableUser,)
    }
  }
}