export const languages = {
  languageDataHandler(data, lang, textInputProps) {
    const arr = [];
    let key = {}
    let ids = {}
    const langData = process.env.REACT_APP_LANGUAGES.split(', ');
    const name = {
      am: 'armenian',
      en: 'english',
      ru: 'russian',
      fr: 'french',
    };

    langData.forEach((item) => {
      key[item] = textInputProps?.labelIds[item]
      ids[item] = textInputProps?.inputIds[item]
    })

    langData.forEach((item) => {
      if (data && lang) {
        arr.push({
          selectedLanguage: item,
          title: data[name[item]][lang],
          src: `../assets/icons/languageIcon/${name[item]}.svg`,
          htmlFor: key[item],
          id: ids[item],
        });
      }
    });
    return arr;
  }
}