import axios from 'axios';
import Cookies from 'js-cookie';
import { uploadCartooonAction } from './UploadCartoonSlice';

const uploadCartoon = (data, onUploadProgress, cancelFileUpload) => {
  const auth = `Bearer ${Cookies.get('auth_token')}`;
  return async (dispatch) => {
    const CancelToken = axios.CancelToken;
    const fetchData = async () => {
      let formData = new FormData();
      formData.append('titleEN', data.name.en);
      formData.append('titleRU', data.name.ru);
      formData.append('titleAM', data.name.am);
      formData.append('titleFR', data.name.fr);
      formData.append('descriptionEN', data.description.en);
      formData.append('descriptionRU', data.description.ru);
      formData.append('descriptionAM', data.description.am);
      formData.append('descriptionFR', data.description.fr);
      formData.append('authorEN', data.author.en);
      formData.append('authorRU', data.author.ru);
      formData.append('authorAM', data.author.am);
      formData.append('authorFR', data.author.fr);
      formData.append('ageGroup', data.ageGroup);
      formData.append('cartoon', data.video[0]);
      formData.append('language', data.language);
      formData.append('image', data.image[0]);
      formData.append('categories', data.category);
      formData.append('tag', data.tag);
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/new`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: auth,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress,
        cancelToken: new CancelToken(
          (cancel) => (cancelFileUpload.current = cancel)
        ),
      });
      return response;
    };
    try {
      const uploadCartoon = await fetchData();
      return dispatch(uploadCartooonAction.uploadCartooonHandler({ uploadCartoon }));
    } catch (e) {
      if (axios.isCancel(e)) {
      }
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      dispatch(uploadCartooonAction.uploadCartooonHandler({ uploadCartoon: e.response }));
      return e;
    }
  };
};

export default uploadCartoon;
