import Modals from '../Modals';
import { useParams } from 'react-router-dom';
import Checkbox from '../../checkbox/Checkbox';
import classes from './RatingProduct.module.css';
import Button from '../../buttons/authButton/Button';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../context/user/UserContext';
import { LanguageContext } from '../../../context/LanguageContext';
import { filterProducts } from '../../../utils/helpers/filterProducts';

const RatingProduct = (props) => {
  const productId = useParams();
  const [hoveredRating, setHoveredRating] = useState(null);
  const { user } = useContext(UserContext);
  const { show, rateProduct, setShow } = props;
  const dataRate = user?.ratings?.find(
    (item) => item.productId === productId.id
  );
  const [rating, setRating] = useState();

  const handleRatingChange = (e) => {
    setRating(+e.target.value);
  };

  const onMouseEnter = (id) => {
    setHoveredRating(id);
  };

  useEffect(() => {
    if (dataRate?.rating) setRating(dataRate?.rating);
  }, [dataRate]);

  const onHide = () => {
    setRating(dataRate?.rating);
    setShow(false);
  };

  const data = filterProducts.productsrating(true);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Modals show={show} onHide={onHide}>
            <div
              className={`${classes.rating_conteiner} d-flex justify-content-center align-items-center flex-wrap`}
            >
              <div
                className={`${classes.rating_img_container} d-flex justify-content-between align-items-center`}
              >
                {data.map((item) => {
                  return (
                    <Checkbox
                      type='radio'
                      hidden={true}
                      key={`star_${item.starId}`}
                      onMouseEnter={() => onMouseEnter(item.starId)}
                      onMouseLeave={() => onMouseEnter(0)}
                      name={'stars'}
                      img={item.imageUrl}
                      id={`star_${item.starId}`}
                      value={item.starId}
                      onChange={handleRatingChange}
                      divClassName={`${classes.pasive_rate} d-flex align-items-center justify-content-center position-relative`}
                      labelClassName={`${rating === item.starId || hoveredRating === item.starId ? `${classes.active_rate}` : `${classes.pasive_rate}`}`}
                    />
                  );
                })}
              </div>
              <Button
                divStyle={`d-flex w-100 align-items-center justify-content-center`}
                imgStyle='w-100'
                textStyle='fw-light'
                buttonStyle={`${classes.rate} ${classes.rate_button} text-center justify-content-center align-items-center border-0 w-100 justify-content-center d-flex`}
                onClick={() => rateProduct(rating)}
                text={ctx.data?.rate[ctx.language]}
              />
            </div>
          </Modals>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default RatingProduct;
