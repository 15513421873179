import axios from 'axios';
import Cookies from 'js-cookie';
import { updateCartoonAction } from './UpdateCartoonSlice';

const updateCartoon = (data, onUploadProgress, cancelFileUpload, cartoonId) => {
  const auth = `Bearer ${Cookies.get('auth_token')}`;
  return async (dispatch) => {
    const CancelToken = axios.CancelToken;
    const fetchData = async () => {
      let formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/update/${cartoonId}`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: auth,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress,
        cancelToken: new CancelToken(
          (cancel) => (cancelFileUpload.current = cancel)
        ),
      });
      return response;
    };
    try {
      const updateCartoon = await fetchData();
      return dispatch(updateCartoonAction.updateCartoonHandler({ updateCartoon }));
    } catch (e) {
      if (axios.isCancel(e)) {
      }
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};

export default updateCartoon;
