import { createSlice } from '@reduxjs/toolkit';

const initialState = {cartoonCategories: []};

const cartoonCategoriesSlice = createSlice({
  name: 'cartoonCategories',
  initialState,
  reducers: {
    cartoonCategoriesHandler(state, action) {
      state.cartoonCategories = action.payload.cartoonCategories;
    },
  },
});
export const cartoonCategoriesAction = cartoonCategoriesSlice.actions;
export default cartoonCategoriesSlice;
