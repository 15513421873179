import React from 'react';
import classes from './UploadCartoonTitle.module.css';
import { LanguageContext } from '../../../../../context/LanguageContext';
import TipMessage from '../../../../../components/tipMessage/TipMessage';

const UploadCartoonTitle = (props) => {
  const { title, show, onMouseEnter, onMouseLeave } = props;
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className='d-flex flex-wrap w-100'>
            <div
              className='w-100'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <span className={classes.title}>{title}</span>
            </div>
            {
              <TipMessage show={show}/>
            }
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonTitle;
