import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: { data: {}, status: null}};

const addToFavoriteProductsSlice = createSlice({
  name: 'addToFavoriteProducts',
  initialState,
  reducers: {
    addToFavoriteProductsHandler(state, action) {
      state.data = action.payload;
    },
  },
});
export const addToFavoriteProductsAction = addToFavoriteProductsSlice.actions;
export default addToFavoriteProductsSlice;
