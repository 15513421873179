import classes from './FilterData.module.css';
import React, { useEffect, useState } from 'react';
import SortByPopular from '../sorting/SortByPopular';
import { LanguageContext } from '../../../context/LanguageContext';
import CardBestsellers from '../../../components/card/cardBestsellers/CardBestsellers';

const FilterData = (props) => {
  const [data, setData] = useState();
  const [books, setBooks] = useState([]);
  const [sorted, setSorted] = useState(false);
  const { categories, products, activePage, pageCount, setLang, setSort} = props;

  useEffect(() => {
    if (products) {
      setData(products);
    }
  }, [products]);

  const handleSortedData = (book) => {
    setBooks(book);
    setSorted(true);
  };

  useEffect(() => {
    if (sorted) {
      setData(books);
      setSorted(false);
    }
  }, [sorted, books]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`position-relative w-100`}>
            <div className={`${classes.filter_by_popularity_container} d-flex w-100 flex-wrap`}>
              <div className='d-flex mb-4'>
              </div>
              <div className={`${classes.page_sort_container} d-flex justify-content-between w-100`}>
                <div className={`${classes.page_container} d-flex justify-content-between`}>
                  <span>
                    {`${ctx.data?.page[ctx.language]} ${activePage} ${ctx.data?.of[ctx.language]
                      } ${pageCount}`}
                  </span>
                </div>
                <SortByPopular
                  setLang={setLang}
                  setSort={setSort}
                  data={data}
                  handleSortedData={handleSortedData}
                />
              </div>
            </div>
            <div
              className={`${classes.filter_data_container} ${classes.padding_50} d-flex flex-wrap`}
            >
              <div className={`${classes.card_container} d-flex flex-wrap position-relative w-100`}>
                {(data)?.map((product) => {
                  return (
                    <CardBestsellers
                    titles={['name', 'author']}
                      id={product._id}
                      data={product}
                      img={product.image.imageURL}
                      categories={categories && categories}
                      key={product._id}
                      className={classes.bestsellers_card}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FilterData;
