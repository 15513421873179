import { createSlice } from '@reduxjs/toolkit';

const initialState = { data:{data: [], status:null}};

const deleteReadBookSlice = createSlice({
  name: 'deleteReadBook',
  initialState,
  reducers: {
    deleteReadBookHandler(state, action) {
      state.data = action.payload;
    },
  },
});
export const deleteReadBookAction = deleteReadBookSlice.actions;
export default deleteReadBookSlice;
