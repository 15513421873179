import React, { useState } from 'react';
import classes from './UploadCartoonsImage.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import TipMessage from '../../../../components/tipMessage/TipMessage';

const UploadCartoonsImage = (props) => {
  const { onChange, cartoonImage } = props;
  const [show, setShow] = useState(false);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.gap_10} w-100 position-relative d-flex flex-wrap`}
          >
            <label
              htmlFor='upload_image'
              className='w-100'
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div className={`${classes.choose_file_container} `}>
                <span className={classes.choose_file}>
                  {ctx.data?.choose_image_file[ctx.language]}
                </span>
              </div>
            </label>
            <input
              className={`${classes.hide_border} d-none w-100`}
              type='file'
              onChange={onChange}
              id='upload_image'
              name='avatar'
              accept='image/png, image/jpeg'
            />
            <TipMessage show={show} />
            {cartoonImage && (
              <div
                className={`${classes.cartoon_image_container} d-flex`}
              >
                <img className='w-100' src={`${cartoonImage}`} alt='' />
              </div>
            )}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonsImage;
