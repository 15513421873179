import React, { useEffect, useState } from 'react';
import Tab from '../../../../../components/tab/Tab';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { languages } from '../../../../../utils/languages';

const UploadCartoonsTab = (props) => {
  const [show, setShow] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { languageHandler, selectedLanguage, textInputProps } = props;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowSize > 470) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [windowSize]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const languageData = languages.languageDataHandler(
          ctx?.data,
          ctx?.language,
          textInputProps
        );
        return (
          <div
            className={`d-flex w-100`}
          >
            {languageData.map((item) => {
              return (
                <Tab
                  cartoons={true}
                  key={item.selectedLanguage}
                  isLanguage={true}
                  activeTab={selectedLanguage === item.selectedLanguage}
                  onClick={() => {
                    languageHandler(item.selectedLanguage);
                  }}
                  title={item.title}
                  src={item.src}
                  htmlFor={item.htmlFor}
                  id={item.id}
                  show={show}
                />
              );
            })}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonsTab;
