import { createSlice } from '@reduxjs/toolkit';

const initialState = { bestsellers: [] };

const bestsellersSlice = createSlice({
  name: 'bestsellers',
  initialState,
  reducers: {
    bestsellersHandler(state, action) {
      state.bestsellers = action.payload.bestsellers;
    },
  },
});

export const bestsellersAction = bestsellersSlice.actions;
export default bestsellersSlice;
