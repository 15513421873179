import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { useLocation } from 'react-router-dom';
import classes from './AboutCartoon.module.css';
import { useDispatch, useSelector } from 'react-redux';
import CartoonAction from '../cartoonAction/CartoonAction';
import { UserContext } from '../../context/user/UserContext';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { cartoonRates } from '../../utils/helpers/cartoonRates/cartoonRates';
import fetchCartoonRating from '../../store/cartoons/rating/FetchCartoonRating';

const AboutCartoon = (props) => {
  const dispatch = useDispatch();
  let curUser = useContext(UserContext);
  const token = Cookies.get('auth_token');
  const {
    cartoonData,
    setShowAddToPlaylist,
    showAddToPlayList,
    selectedCartoonId,
    checkedCartoonId,
    setMoved,
  } = props;

  const location = useLocation()
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [monthName, setMonthName] = useState('');
  const [cartoonRate, setCartoonRate] = useState()
  const [likesCount, setLikesCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const curCartoonRating = curUser?.user?.cartoonRating;  
  const [showErrorModal, setShowErrorModal] = useState(false);
  const categories = useSelector((state) => state.cartoonCategories.cartoonCategories);
  const rating = curCartoonRating?.find((item) => item.cartoonId === selectedCartoonId);
  const rate = rating?.like ? 'like' : rating?.like === undefined ? '' : 'dislike'

  useEffect(() => {
    setCartoonRate(rate)
  }, [rate])

  useEffect(() => {
    if (cartoonData && cartoonData.ratingsCount && location) {
      setLikesCount(cartoonData.ratingsCount.likes);
    }
  }, [cartoonData, location]);

  useEffect(() => {
    setShowMore(false);
  }, [selectedCartoonId]);
  
  useEffect(() => {
    if (cartoonData && Object.keys(cartoonData)?.length > 0) {
      let date = cartoonData.createdAt?.split('T')[0].split('-');
      if (date) {
        let newDate = new Date(date[0], date[1] - 1, date[2]);
        let monthName = newDate.toLocaleString('en-GB', { month: 'long' });
        setDay(date[2]);
        setMonthName(monthName.toLocaleLowerCase());
        setYear(date[0]);
      }
    }
  }, [cartoonData]);

  const changeDurationHandler = () => {
    setMoved(false);
  };

  const cartoonRateing = (type) => async () => {
    if (token) {
      const response = await dispatch(fetchCartoonRating(selectedCartoonId, type === 'like'));
      setLikesCount(response.payload.cartoonRating.data.likes)
      type === cartoonRate ? setCartoonRate('') :  setCartoonRate(type)
      cartoonRates.changeCartoonRate(type, curUser, cartoonRate, selectedCartoonId)
    } else setShowErrorModal(true);
  }
  
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className='w-100'
            onMouseEnter={changeDurationHandler}
            onMouseOverCapture={changeDurationHandler}
            onMouseOutCapture={changeDurationHandler}
          >
            {Object.keys(ctx.data)?.length > 0 ? (
              <>
                <div
                  className='justify-content-between d-flex col-12 flex-wrap'
                >
                  <div
                    className='align-items-center justify-content-between col-12 mx-0 d-flex mt-3 d-flex'
                  >
                    <h5 className={classes.author}>
                      {cartoonData?.title
                        ? cartoonData.title[ctx.language]
                        : 'Title'}
                    </h5>
                    <CartoonAction
                      showErrorModal={showErrorModal}
                      setShowErrorModal={setShowErrorModal}
                      selectedCartoonId={selectedCartoonId}
                      cartoon={cartoonData}
                      showAddToPlayList={showAddToPlayList}
                      setShowAddToPlaylist={setShowAddToPlaylist}
                      checkedCartoonId={checkedCartoonId}
                    />
                  </div>
                </div>
                <div
                  className='justify-content-between d-flex col-12 flex-wrap'
                >
                  <div
                    className='align-items-center justify-content-between col-12 mx-0 d-flex'
                  >
                    <h6 className={`${classes.about_video}`}>
                      @{cartoonData?.uploader?.username}
                    </h6>
                  </div>
                  <div className='align-items-center col-12 d-flex'>
                    <div className={`col-8 col-sm-9`}>
                      <p className={`${classes.about_video}`} id='view_count'>
                        {cartoonData?.viewsCount}{' '}
                        {ctx.data?.views[ctx.language]}
                      </p>
                      <span className={`${classes.about_video}`}>
                        {`${ctx.data?.added[ctx.language]} - `}
                        {day}{' '}
                        {ctx.data && (monthName !== '' || monthName) && ctx.data[monthName][ctx.language]}{' '}
                        {year}
                      </span>
                    </div>
                    <div
                      className='align-items-center col-4 col-sm-3 d-flex justify-content-end'
                     >
                       <p
                          className={`${classes.text_vinni} ${classes.like_count} mt5 mx-3`}
                        >
                          {likesCount}
                        </p>
                      {['like', 'dislike'].map((item) => {
                        return (
                          <div className='font-weight-bold d-flex ml-1' key={`${item}_${nanoid()}`}>
                            <img
                              src={
                                cartoonRate === item
                                  ? `../../assets/cartoon/video/${item}Pressed.svg`
                                  : `../../assets/cartoon/video/${item}.svg`
                              }
                              className={
                                 token
                                  ? `${classes.like_icon}`
                                  : `${classes.like_icon} pe-none`
                              }
                              alt='rate'
                              onClick={cartoonRateing(item)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={
                      !showMore ? 'd-block col-12 my-1' : 'd-none  col-12'
                    }
                  >
                    <button
                      className={`${classes.more} border-0`}
                      onClick={() => {
                        setShowMore(true);
                      }}
                    >
                      {ctx.data?.more[ctx.language]} ...
                    </button>
                  </div>
                </div>
                <div
                  className={
                    showMore ? `${classes.categoriesss} d-block mt-3` : `d-none`
                  }
                >
                  <p className={classes.views_count}>
                    {ctx.data?.author[ctx.language]} -
                    {cartoonData?.author
                      ? cartoonData?.author[ctx.language]
                      : null}
                  </p>
                  <div className='d-flex flex-wrap'>
                    {cartoonData?.categories?.map((id, index) => {
                      let categoryIndex =
                        categories && categories.length > 0
                          ? categories.findIndex((el) => el?._id === id)
                          : -1;
                      let isLast = index !== categories.length - 1;
                      let category = isLast
                        ? `${classes.categorie} d-flex mr-10`
                        : `${classes.categorie} d-flex`;

                      return (
                        <div className={category} key={`${id}__${index}`}>
                          <img
                            className={`${classes.category_image}`}
                            src={
                              categories[categoryIndex]
                                ? `${process.env.REACT_APP_DEV_URI}${categories[categoryIndex].image.imageURL}`
                                : ''
                            }
                            alt='cartoon category'
                          />
                          <p
                            className={`${classes.categories} ${classes.views_count}`}
                          >
                            {categories[categoryIndex]
                              ? categories[categoryIndex].name[ctx.language]
                              : ''}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <p className={classes.views_count}>
                    {cartoonData?.description
                      ? cartoonData?.description[ctx.language]
                      : null}
                  </p>
                  <button
                    className={`${classes.more} border-0`}
                    onClick={() => {
                      setShowMore(false);
                    }}
                  >
                    {ctx.data?.close[ctx.language]}
                  </button>
                </div>
              </>
            ) : null}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default AboutCartoon;
