import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import classes from './UserData.module.css';
import { auth } from '../../../firebase/FirebaseConfig';
import Loader from '../../../components/spinner/Loader';
import React, { useContext, useEffect, useState } from 'react';
import UserCredentials from './userCredentials/UserCredentials';
import { UserContext } from '../../../context/user/UserContext';
import { LanguageContext } from '../../../context/LanguageContext';
import ErrorModal from '../../../components/modal/error/ErrorModal';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import ModalTitles from '../../../components/modal/modalTitles/ModalTitles';
import editPhoneNumber from '../../../store/edit/editPhoneNumber/EditPhoneNumber';
import CodeVerification from '../../../components/modal/auth/codeVerification/CodeVerification';
import { verifyotp } from '../../../utils/helpers/verifyOTP/verifyOTP';
import Modals from '../../../components/modal/Modals';
import { operationsWithKeys } from '../../../utils/helpers/operationsWithKeys/operationsWithKeys';
import { operationsWithPhoneNumber } from '../../../utils/helpers/operationsWithPhoneNumber/operationsWithPhoneNumber';
import { requestotp } from '../../../utils/helpers/requestOTP/requestOTP';

const UserData = (props) => {
  const { setData, data } = props;
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState([]);
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(true);
  const [isError, setIsError] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [confIsVisible, setconfIsVisible] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState('recaptchaId');
  const [codeVerification, setCodeVerification] = useState(true);
  const { user, setUserData } = useContext(UserContext);
  const [disabled, setDisabled] = useState(['codeVerification']);
  const [errorMessage, setErrorMessage] = useState();
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const curInput = document.getElementById(`updatePhoneNumber_${index + 1}`);
  const nextInput = document.getElementById(`updatePhoneNumber_${index + 2}`);
  const [resend, setReasend] = useState(false);

  const clickHandler = (id) => {
    setIndex(id);
  };

  const request = (e) => {
    e.preventDefault();
    requestotp.requestOTP(
      e,
      operationsWithPhoneNumber.generateRecaptcha,
      recaptchaId,
      RecaptchaVerifier,
      auth,
      signInWithPhoneNumber,
      phoneNumber,
      setconfIsVisible,
      setSaveClick,
      false,
      setIsError,
      setCodeVerification,
      () => {},
      disabled,
      setDisabled
    );
  };

  const editPhoneNumbers = async (
    confirmationResult,
    verificationCode,
    data,
    mutableUser,
    lang
  ) => {
    try {
      const result = await confirmationResult.confirm(verificationCode);
      const response = await dispatch(editPhoneNumber(result.user.accessToken));

      if (response?.status === 200) {
        mutableUser.phoneNumber = response.data.phoneNumber;
        setStatus(true);
        setUserData(mutableUser);
        setData([]);
        window.location.reload();
      } else {
        setErrorMessage(data?.wrong_phone_number[lang]);
        setIsError(true);
        setconfIsVisible(false);
        throw new Error();
      }
    } catch (e) {
      setCodeVerification(false);
      setStatus(true);
    }
  };

  useEffect(() => {
    if (
      confIsVisible &&
      OTP &&
      !OTP.includes('') &&
      OTP.length === 6 &&
      !resend
    ) {
      setActiveButton(true);
      setDisabled([]);
    } else {
      resend
        ? setDisabled(['codeVerification', 'resend'])
        : OTP.length === 6 && !OTP.includes('')
        ? setDisabled([])
        : setDisabled(['codeVerification']);
      setActiveButton(false);
    }
  }, [OTP, confIsVisible, resend]);

  const phoneNumberChangeHandler = (phone) => {
    setPhoneNumber(`+${phone}`);
  };

  useEffect(() => {
    if (confIsVisible && !resend) {
      const time = setTimeout(() => {
        setErrorMessage(null);
        setconfIsVisible(false);
        setIsError(true);
        setStatus(true);
      }, 40000);
      return () => clearTimeout(time);
    }
  }, [confIsVisible, resend]);

  const hideVerificationModal = () => {
    setIsError(false);
    setconfIsVisible(false);

    setOTP([]);
    setCodeVerification(true);
    setDisabled(['codeVerification']);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <UserCredentials
              data={data}
              setData={setData}
              phoneNumber={phoneNumber}
              requestOTP={request}
              saveClick={saveClick}
              phoneNumberChangeHandler={phoneNumberChangeHandler}
              generateRecaptcha={operationsWithPhoneNumber.generateRecaptcha}
              recaptchaId={recaptchaId}
              RecaptchaVerifier={RecaptchaVerifier}
              auth={auth}
              signInWithPhoneNumber={signInWithPhoneNumber}
              setSaveClick={setSaveClick}
              setReasend={setReasend}
              setIsError={setIsError}
            />
            <div id={recaptchaId} />
            {confIsVisible ? (
              <Modals show={confIsVisible} onHide={hideVerificationModal}>
                <CodeVerification
                  type='updatePhoneNumber'
                  phoneNumber={phoneNumber}
                  onSubmit={(e) =>
                    verifyotp.verifyOTP(
                      e,
                      'submit',
                      OTP,
                      setDisabled,
                      setStatus,
                      editPhoneNumbers,
                      confIsVisible,
                      false,
                      ctx.data,
                      ctx.language,
                      user
                    )
                  }
                  title={
                    <ModalTitles
                      classIdentifier={'max_title'}
                      max_title={
                        ctx.data?.verify_your_phone_number[ctx.language]
                      }
                    />
                  }
                  onChange={(e, id) => operationsWithPhoneNumber.changeCode(e, id, OTP, index, setOTP)}
                  onFocus={clickHandler}
                  show={confIsVisible}
                  onKeyDown={(e) =>
                    operationsWithKeys.keyDownHandler(
                      e,
                      setCodeVerification,
                      curInput,
                      index,
                      OTP,
                      setOTP,
                      'updatePhoneNumber'
                    )
                  }
                  onKeyUp={() => operationsWithKeys.keyUpHandler(OTP, setOTP, curInput, nextInput)}
                  codeVerification={codeVerification}
                  activeButton={activeButton}
                  resendCode={(e) =>
                    operationsWithPhoneNumber.resendCode(
                      e,
                      setRecaptchaId,
                      recaptchaId,
                      ReactDOM,
                      request,
                      setDisabled,
                      disabled
                    )
                  }
                  onHide={hideVerificationModal}
                  index={index}
                  disabled={disabled}
                />
              </Modals>
            ) : null}
            <ErrorModal
              show={isError}
              onHide={() => {
                setIsError(false);
                setOTP([]);
                setCodeVerification(true);
                setDisabled(['codeVerification']);
              }}
              errorMessage={
                errorMessage ?? ctx.data?.your_session_expired[ctx.language]
              }
            />
            {!status && <Loader className={`${classes.loader_container} position-absolute top-0`} />}
            <div className='d-none' id='recaptchaId'></div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};
export default UserData;
