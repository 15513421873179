import React from 'react';
import classes from './UserAccountTitle.module.css';
import ComponentsTitle from './componentsTitle/ComponentsTitle';
import { LanguageContext } from '../../../context/LanguageContext';

const UserAccountTitle = (props) => {
  const { title, pageName } = props;
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.title_container} w-100 d-flex flex-wrap`}>
            <div
              className={`${classes.my_account_container} justify-content-start d-flex w-100`}
            >
              <span className={`${classes.my_account_title} ${classes.title}`}>
                {pageName}
              </span>
            </div>
            <ComponentsTitle title={title} />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UserAccountTitle;
