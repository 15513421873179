import { createSlice } from '@reduxjs/toolkit';

const initialState = {deletePlaylist:{}};

const deletePlaylistSlice = createSlice({
  name: 'deletePlaylist',
  initialState,
  reducers: {
    deletePlaylistHandler(state, actions) {
      state.deletePlaylist = actions.payload.deletePlaylist;
    },
  },
});

export const deletePlaylistAction = deletePlaylistSlice.actions;
export default deletePlaylistSlice;
