import classes from './NoData.module.css'
import React, { useEffect, useState } from 'react';
import { constantData } from '../../utils/helpers/constantData';

const NoData = (props) => {
  const { title } = props
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 820)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 820)
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`${classes.bg_no_data} d-flex justify-content-center align-items-center position-relative`}
      style={{ backgroundImage: `url('${constantData.noDataImageUrls[isSmallScreen ? 1 : 0]}')` }}
    >
      <h2 className={`${classes.no_data_text} text-center`}>{title}</h2>
    </div>
  )
}

export default NoData;