export const operationsWithPhoneNumber = {
  particularlyHideNumber(phoneNumber, isValidated) {
    let number = null;
    if (isValidated && phoneNumber) {
      const slice = phoneNumber.slice(
        phoneNumber.length - 1,
        phoneNumber.length + 1
      );
      number =
        phoneNumber.substring(0, 7) +
        '*'.repeat(phoneNumber.length - 8) +
        slice;
    }
    return number;
  },

  changeCode(e, id, OTP, index, setOTP) {
    if (e.target.value.length > 1)
      document.getElementById(id).value = e.target.value[0];
    let codesArray = [...OTP];
    if (
      !codesArray[index] &&
      codesArray[index] !== '' &&
      e.target.value &&
      e.target.value !== ''
    ) {
      codesArray.push(e.target.value);
    } else if (
      codesArray[index] ||
      (codesArray[index] === '' && e.target.value && e.target.value !== '')
    ) {
      codesArray[index] = e.target.value;
    }
    setOTP(codesArray);
  },

  changePhoneNumber(
    phone,
    country,
    setCheckedPhoneNumber,
    onPhoneChange,
    authValidation,
    phoneNumber,
    setIsValid,
    style,
    setDisabled
  ) {
    setCheckedPhoneNumber(false);
    onPhoneChange(phone);
    const stateStatus = authValidation.isValidHandler(
      phone ? phone : phoneNumber,
      country,
      true
    );
    window.sessionStorage.setItem('stateStatus', stateStatus);
    setIsValid(stateStatus);
    const next = document.getElementById('enter');
    if (stateStatus) {
      next &&
        next?.classList.replace(
          style.next_button_color,
          style.next_button_color_active
        );
      setDisabled([]);
    } else {
      next &&
        next.classList.replace(
          style.next_button_color_active,
          style.next_button_color
        );
      setDisabled(['signIn']);
    }
  },

  generateRecaptcha(
    e,
    generateRecaptcha,
    recaptchaId,
    RecaptchaVerifier,
    auth,
    signInWithPhoneNumber,
    phoneNumber,
    setShowAuth,
    showAuth,
    setError,
    setShow,
    requestOTP,
    setLoading,
    disabled,
    setDisabled
  ) {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          // auth,
          recaptchaId,
          {
            function() {},
            size: 'invisible',
            callback: () => {
              window.grecaptcha && window.grecaptcha.reset();
              requestOTP(
                e,
                generateRecaptcha,
                recaptchaId,
                RecaptchaVerifier,
                auth,
                signInWithPhoneNumber,
                phoneNumber,
                setShowAuth,
                showAuth,
                setError,
                setShow,
                setLoading,
                disabled,
                setDisabled
              );
            },
          },
          auth
        );
      }
    } catch (err) {}
  },

  resendCode(
    e,
    setRecaptchaId,
    recaptchaId,
    ReactDOM,
    requestOTP,
    setDisabled,
    disabled
  ) {
    setDisabled([...disabled, 'resend']);
    const newId = Date.now();
    setRecaptchaId(newId);
    const newRecaptcha = <div id={newId} />;
    ReactDOM.render(newRecaptcha, document.getElementById(recaptchaId));
    window.grecaptcha.reset();
    requestOTP(e);
  },
};
