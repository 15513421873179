import styles from './Components.module.css';
import React, { useEffect, useState } from 'react';

const Container = (props) => {
  const {children} = props
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`${styles.footer_container} w-100 position-absolute bottom-0 start-0`} id='footer'>
      <div
        className={`${styles.display_flex} w-100 align-items-start justify-content-center flex-wrap ${windowSize <= 730 ? styles.padding_bottom_100 : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
