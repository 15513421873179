import axios from 'axios';
import { productsAction } from './ProductsSlice';

const fetchProducts = (filters, pageination, searchText, limit, sort, lang) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = { ...filters, page: pageination, limit: limit ?? '*', searchText };
      const sorting = `&sort=${sort}&lang=${lang}`
      const url = `${process.env.REACT_APP_DEV_URI}/product/all?type=web${sort !== '' ? sorting : ''}`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response;
    };
    try {
      const result = await fetchData();
      if (result.status === 200) {
        const products = result.data;
        return dispatch(
          productsAction.productsHandler({
            products: products,
            status: 'fullfieled',
          })
        );
      }
    } catch (e) {
      return dispatch(
        productsAction.productsHandler({
          products: {},
          status: 'unsuccessful',
          error: e.message,
        })
      );
    }
  };
};

export default fetchProducts;
