import React from 'react';
import styles from './Components.module.css';
import { Link } from 'react-router-dom';
import DeveloperInformationContainer from './blocks/DeveloperInformationContainer';
import { LanguageContext } from '../../../context/LanguageContext';

const DeveloperInformation = (props) => {
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <DeveloperInformationContainer>
            <div className={`${styles.receved_text} ${styles.display_flex} w-100 align-items-start align-self-center`}>
              <div className={styles.dateContainer}>
              <span className={styles.font_size_14}>© {new Date().getFullYear()}</span>
              <a className='text-decoration-none' href={`${process.env.REACT_APP_DEV_URI}`}> vinni.am.</a>
              </div>
              <span className={styles.font_size_14}>{ctx.data?.copyright[ctx.language]}</span>
            </div>
            <div className={`${styles.link_item} ${styles.display_flex} w-100 align-items-start justify-content-center flex-wrap`}>
              <span className={`${styles.font_size_14} justify-content-center text-center ${styles.display_flex} w-100 align-items-start`}>
                {ctx.data?.developed_by[ctx.language]}
              </span>
              <Link to='https://dynamic.am/en/' className={styles.dynamic_logo_container}>
                <img className='w-100' src='/assets/dynamicLogo/dynamic_blue.png' alt='' />
              </Link>
            </div>
          </DeveloperInformationContainer>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default DeveloperInformation;
