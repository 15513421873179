import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import ConfirmationModal from '../modal/confirmationModal/ConfirmationModal';
import logOut from '../../store/logOut/LogOut';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const LogOut = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show, setShow, setAuthToken } = props;

  const logOutHandler = async () => {
    const response = await dispatch(logOut());
    if (response === 200) {
      setAuthToken(null);
      setShow(false);
      navigate('/');
      window.sessionStorage.clear();
      logEvent(firebaseAnalytics, 'Web_User_logout');
    }
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <ConfirmationModal
            logout={true}
            show={show}
            onHide={() => setShow(false)}
            titles={{
              max_title: ctx.data?.are_you_sure_logout[ctx.language],
              mid_title: ctx.data?.logout_text[ctx.language],
              min_title: ctx.data?.we_will_miss[ctx.language],
            }}
            logOutHandler={logOutHandler}
          />
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default LogOut;
