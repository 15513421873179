import axios from 'axios'
import { localisationAction } from './LocalizationSlice'

const fetchLocalization = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_DEV_URI}/localizations/mobile`
            const response = await axios.get(url)
            return response.data
        }
        try {
            const localization = await fetchData()
            dispatch(localisationAction.localisationHandler({localization}))
        } catch(e){}
    }
}

export default fetchLocalization