import React from 'react'
import classes from './ComponentsTitle.module.css'

const ComponentsTitle = (props) => {
    const {title} = props
    return (
        <div className={`d-flex w-100  justify-content-start`}>
        <span className={`${classes.personal_data_title} ${classes.title}`}>{title}</span>
      </div>
    )
}

export default ComponentsTitle