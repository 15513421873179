import axios from 'axios';
import Cookies from 'js-cookie';
import { deleteReadBookAction } from './DeleteReadBookSlice';

const deleteReadBook = (productId) => {
  return async (dispatch) => {
    const body = {
      productId,
    };

    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/user/readBooks/delete`;
      const auth = `Bearer ${Cookies.get('auth_token')}`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: auth,
      };
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers,
      });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(
        deleteReadBookAction.deleteReadBookHandler({
          data: data.data,
          status: data.status,
        })
      );
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};

export default deleteReadBook;
