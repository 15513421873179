import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './ProductsSlider.module.css';
import { LanguageContext } from '../../../context/LanguageContext';

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className ? className : ''} ${classes.buttons} d-flex align-items-center justify-content-center`} onClick={onClick}>
      <img src='../assets/slider/home/sliderNextButton.svg' alt='' />
    </div>
  );
}

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className ? className : ''} ${classes.buttons} d-flex align-items-center justify-content-center`} onClick={onClick}>
      <img src='../assets/slider/home/sliderPrevButton.svg' alt=''/>
    </div>
  );
}

export default class ProductsSlider extends Component {
  render() {
    const {popular} = this.props
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
       popular?.length > 4 ? 4 : popular?.length,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:  popular?.length > 3 ? 3 : popular?.length,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: popular?.length > 2 ? 2 : popular?.length,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const scrollToTop = () => {
      window.scroll(0, 0);
    };

    return (
      <LanguageContext.Consumer>
        {(ctx) => {
          return (
            <div className={classes.carousel_container}>
            {popular?.length > 0 &&
              <Slider {...settings}>
                  {popular?.map((data) => {
                    return (
                      <Link
                        to={`/products/${data._id}`}
                        onClick={scrollToTop}
                        className={`${classes.slider_item} position-relative align-items-center`}
                        key={data._id}
                      >
                        <img
                        className='w-100 h-100'
                          src={`${process.env.REACT_APP_DEV_URI}${data.image.imageURL}`}
                          alt=''
                        />
                        <div className='d-none'></div>
                        <div className={`${classes.gap_40} flex-wrap position-absolute`}>
                          <div className={'d-flex flex-wrap justify-content-center'}>
                            <span className={`${classes.text} w-100 d-flex justify-content-center text-center`}>{ data.name[ctx.language]}</span> <br />
                            <span className={`${classes.text} w-100 d-flex justify-content-center text-center`}>{ctx.data?.author[ctx.language]}</span>
                            <span className={`${classes.text} w-100 d-flex justify-content-center text-center`}> { data.author[ctx.language]}</span>
                          </div>
                          <div>
                            <button className={`${classes.explore_more} border-0 bg-transparent font-weight-bold`}>
                              {ctx.data?.explore_more[ctx.language]}
                            </button>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                )}
              </Slider>
            }
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}
