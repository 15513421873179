import React from 'react';
import { nanoid } from 'nanoid';
import classes from './PlayListNameActions.module.css';
import Button from '../../../../../../components/buttons/authButton/Button';

const PlayListNameActions = (props) => {
  const { deleteHandler, editHandler, title } = props;

  const dataHandler = (type) => {
    if (type === 'edit') {
      editHandler();
    } else {
      deleteHandler();
    }
  };

  return (
    <>
      <div className='d-flex'>
        <span className={classes.title}>{title}</span>
      </div>
      <div className={`d-flex ${classes.gap_20}`}>
        {['edit', 'delete'].map((item) => {
          return (
            <Button
              key={nanoid()}
              imgStyle={classes.edit_img}
              buttonStyle='border-0 bg-transparent'
              image={`../assets/${item}/${item}.svg`}
              onClick={() => dataHandler(item)}
            />
          );
        })}
      </div>
    </>
  );
};

export default PlayListNameActions;
