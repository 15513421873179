import { createSlice } from '@reduxjs/toolkit';

const initialState = {cartoonData: {data: {}, status: null}};

const cartoonDataSlice = createSlice({
  name: 'cartoonData',
  initialState,
  reducers: {
    cartoonDataHandler(state, action) {
      state.cartoonData = action.payload;

    },
  },
});
export const cartoonDataAction = cartoonDataSlice.actions;
export default cartoonDataSlice;