import axios from 'axios';
import Cookies from 'js-cookie';
import { cartoonDataAction } from './CartoonSlice';

const fetchCartoon = (id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/information/${id}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const cartoonData = await fetchData();
      return dispatch(cartoonDataAction.cartoonDataHandler({cartoonData: {data: cartoonData.data, status: cartoonData.status}}));
    } catch (e) {
      return e;
    }
  };
};

export default fetchCartoon;