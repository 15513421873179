import { createSlice } from '@reduxjs/toolkit';

const initialState = {rating:{data:{}, status:null}}

const productRatingSlice = createSlice({
    name:'rating',
    initialState,
    reducers: {
        ratingHandler(state, action) {
            state.rating = action.payload.rating
        }
    }
})

export const productRatingAction = productRatingSlice.actions
export default productRatingSlice