import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {books: [], pageCount: null, status: null} };

const readedBooksSlice = createSlice({
  name: 'readedBooks',
  initialState,
  reducers: {
    readedBooksHandler(state, action) {
      state.data = action.payload
    },
  },
});
export const readedBooksAction = readedBooksSlice.actions;
export default readedBooksSlice;
