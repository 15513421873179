export const inputErrorChecker = {
  checkDataContainsErrors(
    foundedIndex,
    checkingData,
    availableTypes
  ) {
    const errorLines = [];
    for (let i = foundedIndex - 1; i >= 0; i--) {
        if (
          ((typeof checkingData[availableTypes[i]] === 'object' &&
            !Array.isArray(checkingData[availableTypes[i]]) &&
            Object.keys(checkingData[availableTypes[i]]).length < 4) ||
            !Object.values(checkingData[availableTypes[i]]).every(
              (item) => item.length > 0
            ) ||
            (Array.isArray(checkingData[availableTypes[i]]) &&
              checkingData[availableTypes[i]].length === 0)) &&
          !errorLines.includes(availableTypes[i])
        ) {
          errorLines.push(availableTypes[i]);
        } else if (typeof checkingData[availableTypes[i]] === 'string') {
          errorLines.push('language');
        }
    }
    return errorLines;
  },
  
  inputsAreFilled(checkingData, lang) {
    let errorLines = [];
    const availableTypes = Object.keys(checkingData);
    const existingObjectKey = Object.values(checkingData).some((item) => {
  
      if (typeof item === 'object' && Object.keys(item).length > 0) {
        return true;
      } else if (Array.isArray(item) && item.length > 0) {
        return true;
      } else if (typeof item === 'string' && item !== '') {
        return true
      }else return false;
    });
  
    let objectKeysThatHasValues = [];
    for (let key of availableTypes) {
      if (
        (Array.isArray(checkingData[key]) && (checkingData[key].length > 0 && checkingData[key][0] !== '')) ||
        ((typeof checkingData[key] === 'object' &&  Object.keys(checkingData[key]).length > 0  && checkingData[key][lang] !== '' && checkingData[key][lang] !== undefined) || (typeof checkingData[key] === 'string' && checkingData['language'] !== ''))
      ) {
        objectKeysThatHasValues.push(key);
      } 
    }  

    const foundedIndex = availableTypes.findIndex((item) => item === objectKeysThatHasValues[objectKeysThatHasValues.length - 1]);
    if (existingObjectKey) {
     errorLines = this.checkDataContainsErrors(foundedIndex, checkingData, availableTypes)
    }
    return errorLines;
  },
}