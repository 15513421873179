import { createSlice } from '@reduxjs/toolkit';

const initialState = {localization:{}}

const localizationSlice = createSlice({
    name:'localization',
    initialState,
    reducers:{
        localisationHandler(state, action)  {
            state.localization = action.payload.localization;
        }
    }
})

export const localisationAction = localizationSlice.actions;
export default localizationSlice