import { useEffect } from 'react';
import React, { useState } from 'react';
import classes from './ReadingPageSettings.module.css';
import { Link, useParams } from 'react-router-dom';
import TextSettings from './textSettings/TextSettings';
import { LanguageContext } from '../../../../context/LanguageContext';

const ReadingPageSettings = (props) => {
  const productId = useParams();
  const [click, setClick] = useState(false);
  const [fontFamily, setFontFamily] = useState();

  const {
    defaultChecked,
    isSwitch,
    reduceSpacing,
    letterSpacing,
    reduceLetterSpacing,
    brightnessModeHandler,
    scrollHandler,
    decreaseFontSize,
    increaseFontSize,
    fontFamilyHandler,
    night,
    textSettings,
    setTextSettings,
    textSpacing,
    setTextSpacing,
    addSpacing,
    changeFontSize
  } = props;

  const clickHandler = (click) => {
    setClick(click);
  };

  const fontType = (fontType) => {
    setFontFamily(fontType);
  };
  useEffect(() => {
    fontFamilyHandler(fontFamily);
  }, [props, click, fontFamily]);

  const navigatePrev = () => {
    document.body.style.background = 'white';
  };
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
            <div className={`${classes.read_books_setings_container} d-flex w-100 justify-content-between align-items-center`}>
              <Link
                to={`/products/${productId.id}`}
                className={`${classes.prev_button_container} d-flex user-select-none`}
                onClick={navigatePrev}
              >
                <img
                  src={
                    night !== 'darck'
                      ? '../../assets/readBook/prev.svg'
                      : '../../assets/readBook/prevWhite.svg'
                  }
                  alt=''
                />
              </Link>
              <div className='d-flex flex-wrap'>
                <button
                  className={`${classes.text_settings_img_container} bg-transparent border-0 user-select-none`}
                  onClick={() => setTextSettings(!textSettings)}
                >
                  <img
                  className='w-100'
                    src={
                      night !== 'darck'
                        ? '../../assets/readBookSettings/text_settings.svg'
                        : '../../assets/readBookSettings/text_settingsWhite.svg'
                    }
                    alt=''
                  />
                </button>
                {textSettings && (
                  <div className={`${classes.read_book_settings} d-flex w-100 position-absolute flex-wrap user-select-none`}>
                    <TextSettings
                    changeFontSize={changeFontSize}
                      decreaseFontSize={decreaseFontSize}
                      increaseFontSize={increaseFontSize}
                      letterSpacing={letterSpacing}
                      reduceLetterSpacing={reduceLetterSpacing}
                      reduceSpacing={reduceSpacing}
                      fontType={fontType}
                      clickHandler={clickHandler}
                      scrollHandler={scrollHandler}
                      defaultChecked={defaultChecked}
                      isSwitch={isSwitch}
                      brightnessModeHandler={brightnessModeHandler}
                      textSpacing={textSpacing}
                      setTextSpacing={setTextSpacing}
                      addSpacing={addSpacing}
                    />
                  </div>
                )}
              </div>
            </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ReadingPageSettings;
