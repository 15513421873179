import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Blocks.module.css';

const Links = (props) => {
  const {className, link, children, target, rel} = props
  return (
    <Link to={link} className={`${styles.footer_menus_link} ${className} text-decoration w-100 font-weight-normal`} target={target} rel={rel}>
      {children}
    </Link>
  );
};

export default Links;
