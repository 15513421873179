import axios from 'axios';
import { updateTokenAction } from './UpdateTokenSlice';

const updateToken = (refreshToken) => {
    return async (dispatch) => {
      const body = {refreshToken};
      const headers = { 'Content-Type': 'application/json' }
      const fetchData = async () => {
     const url = `${process.env.REACT_APP_DEV_URI}/token/update`; 
        const response = await axios.post(url, body, {
          withCredentials: true,
          headers
        });
        return response;
      };
      try {
        const updatedToken = await fetchData()
          return dispatch(updateTokenAction.updateTokenHandler({ updatedToken }));
      } catch (e) {
        return e;
      }
    };
  };
  
  export default updateToken;