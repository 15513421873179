import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './BlogsCard.module.css';

const BlogsCard = (props) => {
  const navigate = useNavigate();
  const { index, data, languageKey, more, mx } = props;

  const replaceState = () => {
    window.scroll(0, 0);
    window.history.replaceState({ blogPage: `blog__${data?._id}` }, '');
    navigate(`/blogs/${data?._id}`);
  };

  return (
    <div
      className={
        index <= 0
          ? index === 0
            ? `${classes.w_65} ${classes.mb_25} ${classes.blog_main}`
            : `${classes.home} ${classes.blog_main}`
          : `w-100 ${classes.mb_25} ${classes.blog_main} ${mx}`
      }
      id={`blog__${data?._id}`}
      onClick={replaceState}
    >
      <img
        src={`${process.env.REACT_APP_DEV_URI}/${data?.image}`}
        alt='blog'
        className={
          index === 0
            ? `${classes.img_large} w-100`
            : `${classes.img_small} w-100`
        }
      />
      <p
        className={
          index === 0
            ? `${classes.title} ${classes.text_elipsis} ${classes.fs_25} mt-4 font-weight-bold overflow-hidden`
            : `${classes.title} ${classes.text_elipsis}  ${classes.fs_20} overflow-hidden mt-1 font-weight-bold`
        }
      >
        {data?.title[languageKey]}
      </p>
      <p
        className={
          index === 0
            ? `${classes.text_elipsis} ${classes.ddescription} ${classes.fs_20} mt-2 overflow-hidden`
            : `${classes.text_elipsis} ${classes.ddescription} ${classes.fs_15} overflow-hidden`
        }
      >
        {data?.description[languageKey]}
      </p>
      <div
        onClick={() => navigate(`/blogs/${data?._id}`)}
        className={`${classes.arrow} d-flex mt-2 align-items-center`}
      >
        <span className={`${classes.more}`}>{more}</span>
        <img src='../assets/latesArticles/next.svg' alt='' />
      </div>
    </div>
  );
};

export default BlogsCard;
