import { useMemo, useState } from 'react';
import React, { useEffect } from 'react';
import UploadCartoonsItem from '../UploadCartoonsItem';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { inputIdentifier } from '../../../../../utils/inputIdentifier';

const UploadCartoonDescription = (props) => {
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState('am');
  const { uploadDescriptionLanguage, description, containsError, cartoonData } =
    props;

    const changeLanguage = (type) => {
      setLanguage(type);
    };

    const languageData = useMemo(
      () => process.env.REACT_APP_LANGUAGES.split(', '),
      []
    );
    const textInputProps = inputIdentifier.inputIdentifierHandler(
      languageData,
      'description',
      'label'
    );

  useEffect(() => {
    languageData.forEach((item) => {
      const selectedLanguage = document.getElementById(
        textInputProps.inputIds[item]
      );
      if (selectedLanguage) {
        if (containsError) {
          if ((description?.item && description?.item !== '') || language === item) {
            selectedLanguage.style.color = '#09BCC1';
          } else if (description[item] && description?.item !== '' && language !== item) {
            selectedLanguage.style.color = 'black';
          } else if (
            language !== item &&
            (!description.item || description?.item === '')
          ) {
            selectedLanguage.style.color = '#D62626';
          }
        } else {
          if (
            (description?.item && description?.item !== '') ||
            language === item
          ) {
            selectedLanguage.style.color = '#09BCC1';
          } else {
            selectedLanguage.style.color = 'black';
          }
        }
      }
    });
  }, [containsError, language, description, textInputProps, languageData]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <UploadCartoonsItem
            keys='description'
            selectedLanguage={language}
            changeLanguage={changeLanguage}
            languageHandler={uploadDescriptionLanguage}
            name={description}
            setShow={setShow}
            show={show}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            placeholder={ctx.data?.description[ctx.language]}
            title={ctx.data?.description[ctx.language]}
            isDescription={true}
            textInputProps={textInputProps}
            cartoonData={cartoonData}
          />
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonDescription;
