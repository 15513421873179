import React from 'react';
import styles from './SlideImage.module.css';

const SlideImage = (props) => {
  const { imgClick, src, alt } = props

  return (
    <div className={`${styles.carousel_image_container} d-flex my-0 mx-auto overflow-hidden object-fit-contain`} onClick={imgClick}>
      <img src={src} alt={alt} className={`${styles.slide_image} w-100 h-100`} />
    </div>
  );
};

export default SlideImage;
