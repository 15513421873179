import axios from 'axios'
import { similiarAction } from './SimiliarSlice'

const fetchSimiliar = (productId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_DEV_URI}/product/similiar/${productId}`
            const response = await axios.get(url)
            return response.data
        }
        try {
            const similiar = await fetchData()
            dispatch(similiarAction.similiarHandler({similiar}))
        } catch(e){}
    }
}

export default fetchSimiliar