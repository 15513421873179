import axios from 'axios'
import { productAction } from './ProductSlice'

const product = (productId) => {
    return async (dispatch) => {
      const fetchdata = async () => {
        const url = `${process.env.REACT_APP_DEV_URI}/product/${productId}`;
        const response = await axios.get(url);
        return response.data;
      };
      try {
        const singleProduct = await fetchdata();
        dispatch(productAction.singleProductHandler({ singleProduct }));
      } catch (e) {}
    };
}

export default product