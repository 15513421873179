import { useDispatch } from 'react-redux';
import classes from './Episodes.module.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../../../../context/LanguageContext';
import fetchDownLoad from '../../../../store/purchases/downLoad/FetchDownLoad';

const Episodes = (props) => {
  const productId = useParams();
  const dispatch = useDispatch();
  const [chooseEpisode, setChooseEpisode] = useState(false);
  const [episodeIndex, setEpisodeIndex] = useState(0);
  const {
    data,
    activeEpisode,
    setAutoPlay,
    autoPlay,
    setEpisode,
    next,
    setNext,
    setDisabled,
    setActiveEpisode,
    chooseEpisodeHandler
  } = props;

  const episodesHandler = async (index) => {
    setAutoPlay(false);
    setDisabled(['prev', 'pause', 'next']);
    const audio = document.getElementById('audio');
    setChooseEpisode(true);
    setEpisodeIndex(index);
    setNext(false);
    setEpisode(`00${index + 1}`);
    setActiveEpisode(index);
    const response = await dispatch(
      fetchDownLoad(productId.id, 'audiobook', `00${index + 1}`)
    );
    if (audio.paused && response.payload.file.status === 200) {
      setAutoPlay(true);
      if (`00${index + 1}` === '001' && data.audioBook.length !== 1) {
        setDisabled(['prev']);
      } else if (index + 1 === data.audioBook.length && data.audioBook.length !== 1) {
        setDisabled(['next']);
      } else if(data.audioBook.length === 1) {
        setDisabled(['next', 'prev'])
      } else {
        setDisabled([]);
      }
    }
  };

  useEffect(() => {
    chooseEpisodeHandler(episodeIndex);
  }, [props, episodeIndex]);

  useEffect(() => {
    const playButton = document.getElementById('playImg');
    if (autoPlay) {
      playButton
        ? (playButton.src = '../../assets/audio/play.svg')
        : (playButton.src = '../../assets/audio/pause.svg');
    }
  }, [autoPlay]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.episodes_container} ${classes.gap_10} d-flex flex-wrap w-100`}>
            <span className={`${classes.font_weight_600} w-100`}>{ctx.data?.chapters_and_episodes[ctx.language]}</span>
            <div className={`${classes.episode_item} d-flex overflow-x-scroll overflow-y-hidden d-flex`}>
              {data.audioBook.map((audioBook, index) => {
                const second = Math.floor(audioBook.audioLength / 1000);
                const cartoonDurationArr = [
                  {
                    time: Math.floor(second / 3600),
                    type: ctx.data?.hours[ctx.language].slice(0, 1),
                    key: 'h',
                  },
                  {
                    time: Math.floor(second / 60),
                    type: ctx.data?.minute[ctx.language].slice(0, 1),
                    key: 'm',
                  },
                  {
                    time: `${second}`.slice(0, 2),
                    type: ctx.data?.seconds[ctx.language].slice(0, 1),
                    key: 's',
                  },
                ];
                return (
                  <div
                    className={`${classes.episod_container} position-relative`}
                    key={audioBook._id}
                    onClick={() => episodesHandler(index)}
                  >
                    <div className={`${classes.pading_5_10} ${classes.gap_5} d-flex flex-wrap`}>
                      <div className={`d-flex justify-content-between w-100`}>
                        <span className={`${classes.time_episode_container} ${classes.title}`}>
                          {audioBook.episode}
                        </span>
                        <div
                          className={`${classes.gap_5} d-flex`}
                        >
                          {cartoonDurationArr.map((item) => {
                            return (
                              item.time !== 0 && (
                                <span key={`episode_${item.type}`}
                                  className={classes.time_episode_container}
                                >
                                  {item.time}
                                  {item.type}
                                </span>
                              )
                            );
                          })}
                        </div>
                      </div>
                      <div className='d-flex justify-content-start'>
                        <span className={classes.font_weight_400}>
                          {ctx.data?.chapter[ctx.language]} {index + 1}
                        </span>
                      </div>
                    </div>
                    {(((next || !chooseEpisode) && activeEpisode === index) ||
                      (chooseEpisode && episodeIndex === index && !next)) && (
                      <div className={`${classes.active_item} d-flex w-100 position-absolute bottom-0 font-weight-normal`}>
                        <span className='text-center w-100'>{ctx.data?.now_playing[ctx.language]}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Episodes;
