import axios from 'axios';
import { popularAction } from './PopularSlice';

const fetchPopular = () => {
    return async (dispatch) => {
        const fetchData = async () => {
          const url = `${process.env.REACT_APP_DEV_URI}/product/popular`;
          const response = await axios.get(url);
          return response.data;
        };
        try {
          const popular = await fetchData();
         dispatch(popularAction.popularHandler({popular}));
        } catch (e) {
          return e;
        }
      };
}
export default fetchPopular