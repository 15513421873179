import React from 'react';
import classes from './ChangeDataButtons.module.css';
import Button from '../../../buttons/authButton/Button';
import { LanguageContext } from '../../../../context/LanguageContext';

const ChangeDataButtons = (props) => {
  const { cancel, cancelId } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.buttons_container} d-flex w-100 justify-content-end align-items-center`}>
            <Button buttonStyle={`${classes.cancel_button_container} border-0 bg-transparent justify-content-end align-items-center`} onClick={cancel} type='button' id={cancelId} text={ctx.data?.cancel[ctx.language]} divStyle={classes.wid}/>
            <Button buttonStyle={`${classes.save_button_container} border-0 bg-transparent justify-content-end align-items-center w-100`} type='submit' text={ctx.data?.save[ctx.language]} 
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ChangeDataButtons;
