
export const operationsWithOs = {
  getOS() {
    const userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      iosPlatforms = ['iPhone', 'iPad', 'iPod']

    let os = null;

if (iosPlatforms.includes(platform)) {
      os = 'iOS'
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    }
    return os;
  }
}