export const constantData = {
  paths: [
    { path: "/myBooks", savedItems: ["myBooksActiveButton", "myBooks"] },
    {
      path: "/products",
      savedItems: ["activeButton", "checkedData", "readBooks", "productdata"],
    },
    { path: "/search", savedItems: ["searchPage"] },
    { path: "/cartoons", savedItems: ["cartoonCheckedData"] },
    {
      path: "/read-book",
      savedItems: [
        "isScroll",
        "Night",
        "Sepia",
        "Default",
        "checked",
        "brightness",
        "appearance",
      ],
    },
  ],
  codeVerificationTitles: {
    max_title: "verify_your_phone_number",
    mid_title: "enter_your_otp_code_here",
    min_title: "code_sent_to",
  },

  providers: ["apple", "facebook", "google"],

  languageData: [
    {
      lang: "am",
      img: "../../assets/icons/languageIcon/armenian.svg",
    },
    {
      lang: "en",
      img: "../../assets/icons/languageIcon/english.svg",
    },
    {
      lang: "ru",
      img: "../../assets/icons/languageIcon/russian.svg",
    },
    {
      lang: "fr",
      img: "../../assets/icons/languageIcon/french.svg",
    },
  ],

  menus: ["my_account", "purchased", "my_playlists", "my_cartoons"],

  tabBarMenu: ["vinni", "products", "cartoons", "authors", "settings"],
  noDataImageUrls: [
    "../../../assets/noData/noData.svg",
    "../../../assets/noData/noDataSmallPage.svg",
  ],
  playback: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],

  parametrs(
    prev,
    video,
    videoPlayer,
    iconUrls,
    visibleControlMenu,
    setIsPlay,
    setUpdateData,
    next
  ) {
    const parametr = [
      {
        id: "prev",
        src: "../../assets/video/prev.svg",
        onclick: (event) => prev(event),
      },
      {
        id: "playOrPause",
        src:
          window.innerWidth < 800
            ? video?.paused
              ? "../../assets/video/play.svg"
              : "../../assets/video/pause.svg"
            : "../../assets/video/pause.svg",
        onclick: (event) =>
          videoPlayer.videoClicked(
            event,
            true,
            video,
            iconUrls,
            visibleControlMenu,
            setIsPlay,
            setUpdateData
          ),
      },
      {
        id: "next",
        src: "../../assets/video/next.svg",
        onclick: (event) => next(event),
      },
    ];
    return parametr;
  },

  iconsUrl: [
    "../../../assets/video/pause.svg",
    "../../../assets/video/play.svg",
    "../../../assets/video/prev10.svg",
    "../../../assets/video/next10.svg",
  ],

  iconUrls: [
    "../../assets/video/pause.svg",
    "../../assets/video/play.svg",
    "../../assets/video/normalScreen.svg",
    "../../assets/video/fullScreen.svg",
    "../../assets/video/volumeMax.svg",
    "../../assets/video/volumeMin.svg",
    "../../assets/video/playback.svg",
  ],

  necessaryKeys: [
    { title: "our_story", desc: "story_text" },
    { title: "magic", desc: "magic_text" },
    { title: "choose_us", desc: "choose_us_text" },
    { title: "vision", desc: "vision_text" },
  ],

  playButtons: ["../../assets/video/pause.svg", "../../assets/video/play.svg"],
 offset:[0, -8, -5, -4.8, -5, -3, 0],
 keys:['purchased', 'already_read', 'want_to_read'],
 sortingKeys:['a-z', 'z-a', 'rating'],
 icons:['../assets/video/privacy_policy.svg', '../assets/video/contact_us.svg', '../assets/video/log_out.svg', '../assets/tabbar/about_filled.svg'],
 productType:{
  book: ['read'],
  audioBook: ['listen'],
  both: ['read', 'listen'],
},

productLanguage: {
  hy: 'armenian',
  en: 'english',
  fr: 'french',
  ru: 'russian',
},
fontFamily:[ 'Original', 'PT Serif','Cursive','Source Sans Pro', 'Vollkorn', 'OpenDyslexic','AccessibleDfA-', 'IA Writer Duospace'],
userData:{
  displayName: 'name_surname',
  username: 'users_name',
  phoneNumber: 'phone_number',
  email: 'email',
},
userCartoons:['my_cartoons', 'watch_later'],
userDeleteReportData: ['username', 'phoneNumber', 'email']

};