export const operationWithVideoPlayer = {
  playHandler(setIsPlay) {
        const video = document.getElementById('cartoon_video');
        const playButton = document.getElementById('playButton');
        const timeline = document.getElementById('timeLine');
        if (video && video.currentTime >= 0 && !video.ended) {
          if (video.paused) {
            video && video.play();
            setIsPlay(true);
            playButton.src = '../assets/video/pause.svg';
          } else {
            setIsPlay(false);
            video && video.pause();
            playButton.src = '../assets/video/play.svg';
          }
        }
    
        if(video.currentTime ===  video?.duration) {
          playButton.src = '../assets/video/pause.svg';
          timeline.style.backgroundSize = `${0}% 100%`;
          timeline.value = 0
          video && video.play();
        } else if(!video.currentTime ===  video?.duration && video.paused) {
          playButton.src = '../assets/video/play.svg';
        }
    }
}

