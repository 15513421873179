import { createSlice } from '@reduxjs/toolkit';

const initialState = {newPurchases:{data:[], status:null}}

const newPurchasesSlice = createSlice({
    name:'newPurchases',
    initialState,
    reducers: {
        newPurchasesHandler(state, action) {
            state.newPurchases = action.payload.newPurchases
        }
    }
})

export const newPurchasesAction = newPurchasesSlice.actions
export default newPurchasesSlice