import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, error: null };

const watchLaterSlice = createSlice({
  name: 'watchLater',
  initialState,
  reducers: {
    watchLaterHandler(state, action) {
      state.data = action.payload.data
    },
  },
});
export const watchLaterAction = watchLaterSlice.actions;
export default watchLaterSlice;
