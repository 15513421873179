import classes from './FilterComponents.module.css';
import React, { useEffect, useState } from 'react';
import FilterItems from './filterItems/FilterItems';
import { useDispatch, useSelector } from 'react-redux';
import ageGroups from '../../../store/ageGroups/AgeGroups';
import allAuthors from '../../../store/authors/allAuthors';
import { LanguageContext } from '../../../context/LanguageContext';

const FilterComponents = (props) => {
  const size = 980;
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const {
    filter,
    categories,
    checkedData,
    filterProductsChange,
    authors,
    booksType,
    ratings,
    defaultCategoryToggled,
    filterShow,
    setFilterShow,
    clearFilter,
    cartoons
  } = props;

  const dispatch = useDispatch();
  const age = useSelector((state) => state.ageGroups.ageGroups.data);

  useEffect(() => {
    dispatch(allAuthors());
    dispatch(ageGroups());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  if (filterShow.includes('filter') && windowSize <= size) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  useEffect(() => {
    if (filterShow.includes('filter') && windowSize <= size) {
      document.body.style.overflow = 'hidden';
    } else if (windowSize <= size && !filterShow.includes('filter')) {
      setFilterShow([]);
      document.body.style.overflow = 'auto';
    } else {
      setFilterShow(['show']);
      document.body.style.overflow = 'auto';
    }
  }, [windowSize]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={
              filterShow.includes('show')
                ? `${classes.filter_container} overflow-auto d-flex flex-wrap align-items-start position-sticky`
                : !filterShow.includes('filter') &&
                  !filterShow.includes('show') &&
                  !filterShow.includes('bacDrop')
                ? `${classes.filter}`
                : filterShow.includes('bacDrop')
                ? `${classes.width_0} ${classes.filter}`
                : filterShow.includes('filter') &&
                  `${classes.w_250} ${classes.filter}`
            }
            id='filterComponents'
          >
            <div className='w-100'>
              <div
                className={`${classes.clear_container} d-flex w-100 justify-content-end`}
              >
                <button
                  className='bg-transparent border-0 '
                  onClick={clearFilter}
                >
                  {ctx.data?.clear_filters[ctx.language]}
                </button>
              </div>
              <div
                className={`${classes.data_container} 
               ${classes.productsContainer} flex-wrap d-flex align-items-start justify-content-start flex-wrap`}
              >
                    <FilterItems
                      defaultCategoryToggled={defaultCategoryToggled}
                      checkedData={checkedData}
                      onChange={filterProductsChange}
                      categories={categories}
                      authors={authors}
                      age={age}
                      booksType={booksType}
                      ratings={ratings}
                      filter={filter}
                      filterShow={filterShow}
                      cartoons={cartoons}
                    />
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FilterComponents;
