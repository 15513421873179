import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, error: null };

const uploadedCartoonsSlice = createSlice({
  name: 'uploadedCartoons',
  initialState,
  reducers: {
    uploadedCartoonsHandler(state, action) {
      state.data = action.payload.data
    },
  },
});
export const uploadedCartoonsAction = uploadedCartoonsSlice.actions;
export default uploadedCartoonsSlice;