import { createSlice } from '@reduxjs/toolkit';

const initialState = {editPhoneNumber:{}};

const editPhoneNumberSlice = createSlice({
  name: 'editPhoneNumber',
  initialState,
  reducers: {
    editPhoneNumberHandler(state, actions) {
      state.editPhoneNumber = actions.payload.editPhoneNumber;
    },
  },
});

export const  editPhoneNumberAction = editPhoneNumberSlice.actions;
export default editPhoneNumberSlice;
