import { createSlice } from '@reduxjs/toolkit';

const initialState = { suggestedProducts: [] };

const suggestedProductsSlice = createSlice({
  name: 'suggestedProducts',
  initialState,
  reducers: {
    suggestedProductsHandler(state, action) {
      state.suggestedProducts = action.payload.suggestedProducts;
    },
  },
});
export const suggestedProductsAction = suggestedProductsSlice.actions;
export default suggestedProductsSlice;