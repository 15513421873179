import axios from 'axios';
import Cookies from 'js-cookie';
import { allAuthorsAction } from './AllAuthorsSlice';

const allAuthors = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/product/author/all?type=web&limit=${limit ?? '*'}&page=${page ?? '*'}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const allAuthors = await fetchData();
      dispatch(allAuthorsAction.allAuthorsHandler({data:allAuthors.data, status:allAuthors.status}));
    } catch (e) {
      return e;
    }
  };
};

export default allAuthors;
