import React from 'react';
import classes from './ModalTitle.module.css';

const ModalTitles = (props) => {
  const { max_title, mid_title, min_title, errorMessage, classIdentifier, className} = props;

  return (
    <span className={`${classes[classIdentifier]} ${className}`}>
      {max_title ?? mid_title ?? min_title ?? errorMessage}
    </span>
  );
};

export default ModalTitles;
