import axios from 'axios';
import Cookies from 'js-cookie';
import { editPhoneNumberAction } from './EditPhoneNumberSlice';

const editPhoneNumber = (token) => {
    return async (dispatch) => {
      const body = {tokenId: token};
      const auth = `Bearer ${Cookies.get('auth_token')}`;
      const headers = {'Content-Type': 'application/json', Authorization: auth }
      const fetchData = async () => {
        const url = `${process.env.REACT_APP_DEV_URI}/changephonenumber/mobile`;
        const response = await axios.post(url, body, {
          withCredentials: true,
          headers
        });
        return response;
      };
      try {
        const result = await fetchData()
         return dispatch(editPhoneNumberAction.editPhoneNumberHandler({ editPhoneNumber: result }));
      } catch (e) {
        const response = dispatch(editPhoneNumberAction.editPhoneNumberHandler({ editPhoneNumber: e.response }));
        return response;
        ;
      }

    };
};

export default editPhoneNumber;
