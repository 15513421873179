import { createSlice } from '@reduxjs/toolkit';

const initialState = { watchCartoon: [] };

const watchCartoonSlice = createSlice({
  name: 'watchCartoon',
  initialState,
  reducers: {
    watchCartoonHandler(state, action) {
      state.watchCartoon = action.payload.watchCartoon;
    },
  },
});
export const watchCartoonAction = watchCartoonSlice.actions;
export default watchCartoonSlice;
