import axios from 'axios';
import Cookies from 'js-cookie';
import { ageGroupsAction } from './AgeGroupsSlice';

const ageGroups = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/ageGroups`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const ageGroups = await fetchData();
      dispatch(ageGroupsAction.ageGroupsHandler({ageGroups:{data:ageGroups.data, status:ageGroups.status}}));
    } catch (e) {
      return e;
    }
  };
};

export default ageGroups;
