import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TabBar.module.css';

const TabbarMenu = (props) => {
  const {title, href, onClick, imageSrc, active} = props
  return (
    <div className={'h-100'}>
      <Link
        to={href}
        onClick={onClick}
        className={`${styles.tab_bar_menues} text-decoration-none d-flex align-items-center justify-content-center flex-column`}
      >
        <img src={imageSrc} alt='' className={styles.tab_bar_icon} />
        {active && <span>{title}</span>}
      </Link>
    </div>
  );
};

export default TabbarMenu;
