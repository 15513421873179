import classes from './Blogs.module.css'
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LanguageContext } from '../../context/LanguageContext';
import React, { useCallback, useEffect, useState } from 'react';
import BlogsCard from '../../components/card/blogs/BlogsCard';
import fetchAllBlogs from '../../store/blog/fetchAllBlogs/FetchAllBlogs';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';
import NoDataAvailable from '../../components/noDataAvailable/NoDataAvailable';

const Blogs = () => {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.allBlogs.allBlogs);
  const [page, setPage] = useState(1)
  const [allBlogsData, setAllBlogsData] = useState([])
  const [updatePage, setUpdaePage] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [size, setSize] = useState('big')
  let elementToView = document.getElementById(window.history.state.blogPage)

  useEffect(() => {
    if (typeof window.history.state.blogPage === 'undefined') {
      window.scroll(0, 0)
    }
  }, [])

  useEffect(() => {
    if (elementToView) {
      setTimeout(() => {
        elementToView.scrollIntoView()
      }, 100);
    }
  }, [elementToView])

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Blogs_page')
    dispatch(fetchAllBlogs(page, process.env.REACT_APP_AUTHORS_BLOGS))
  }, [dispatch, page])

  useEffect(() => {
    if (blogs.blogs.length > 0) {
      const allData = [...allBlogsData, ...blogs.blogs]
      const filteredData = allData.filter((value, index, self) =>
        index === self.findIndex((item) => (item._id === value._id)))
      setAllBlogsData([...filteredData, filteredData])
      setUpdaePage(true)
      setHasMore(blogs.blogs.length >= process.env.REACT_APP_AUTHORS_BLOGS)
    } else {
      setHasMore(false)
    }
  }, [blogs]);

  const handleScroll = () => {
    if (updatePage) {
      setPage(page + 1)
      setUpdaePage(false)
      if (process.env.REACT_APP_AUTHORS_BLOGS === 10) process.env.REACT_APP_AUTHORS_BLOGS = 9
    }
  }

  const resizePlyer = useCallback(() => {
    if (window.innerWidth > 560 && window.innerWidth < 720) {
      setSize('middle')
    } else if (window.innerWidth < 560) {
      setSize('small')
    } else {
      setSize('big')
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizePlyer);
    return () => window.removeEventListener('resize', resizePlyer)
  }, [resizePlyer]);

  useEffect(() => {
    resizePlyer()
  }, [blogs, resizePlyer])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.gap_25} justify-content-center `}>
            <h1 className={`py-4 ${classes.bg} justify-content-center d-flex text-center text-white`}>{ctx.data?.our_blog[ctx.language]}</h1>
            {
              allBlogsData?.length > 0 ?
                <div className='col-10 col-md-10 col-lg-10 col-xl-8 col-xxl-7 mx-auto'>
                  <div className={allBlogsData.length !== 1 ? 'd-flex justify-content-between' : 'd-flex justify-content-center'}>
                    <InfiniteScroll
                      dataLength={allBlogsData.length}
                      next={handleScroll}
                      inverse={false}
                      hasMore={hasMore}
                      className='w-100'
                    >
                      <div className='d-flex flex-wrap justify-content-between mt-2'>
                        <BlogsCard
                          data={allBlogsData[0]}
                          languageKey={ctx.language}
                          index={size === 'small' ? 1 : 0}
                          more={ctx.data?.explore_more[ctx.language]}
                          mx={'mx-2'}
                        />
                        <div className={size === 'big' ? `${classes.width_30_percent} d-flex flex-column` : size === 'middle' ? 'd-flex w-100 justify-content-between mt-4 mx-2 flex-wrap' : 'd-flex w-100 gap_10 mt-4 mx-2 flex-wrap'}>
                          {
                            allBlogsData.slice(1, size === 'big' ? 3 : allBlogsData.length).map((item, index) => {
                              return (
                                <div className={size !== 'middle' ? size === 'big' ? 'w-100 d-flex' : 'd-flex w-100' : `${classes.max_width_48_percent} d-flex`}>
                                  <BlogsCard
                                    key={`blog_${item._id}`}
                                    data={item}
                                    languageKey={ctx.language}
                                    index={1}
                                    more={ctx.data?.explore_more[ctx.language]}
                                    mx={''}
                                  />
                                </div>
                              )
                            })}
                        </div>
                        <div className={size !== 'big' ? 'd-flex w-100 gap_10 mt-4 mx-2 flex-wrap' : `d-flex w-100 ${classes.gap_5} mt-4 mx-2 flex-wrap`}>
                          {
                            size === 'big' && allBlogsData.slice(3, allBlogsData.length).map((item, index) => {
                              return (
                                <div className={size !== 'big' ? `${classes.max_width_48_percent} d-flex` : `${classes.width_30_percent} d-flex`} >
                                  <BlogsCard
                                    key={`blog_${item._id}`}
                                    data={allBlogsData[index]}
                                    languageKey={ctx.language}
                                    index={1}
                                    more={ctx.data?.explore_more[ctx.language]}
                                    mx={''}
                                  />
                                </div>
                              )
                            })}
                        </div>

                      </div>
                    </InfiniteScroll>
                  </div>
                </div> :
                <NoDataAvailable />
            }
          </div>
        );
      }}
    </LanguageContext.Consumer>
  )
};

export default Blogs;