import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classes from './SearchCartoon.module.css'
import { useSearchParams } from 'react-router-dom';
import NoData from '../../../components/noData/NoData';
import React, { useEffect, useState, useCallback } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import searchCartoon from '../../../store/cartoons/search/SearchCartoon';
import CartoonSearch from '../../../components/cartoonSearch/CartoonSearch';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import { videoPlayer } from '../../../utils/helpers/videoPlayer';

const SearchCartoon = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [searchCartoons, setSearchCartoons] = useState([])
  const [searchText, setSearchText] = useState(false)
  const [autoPlay, setAutoPlay] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState()
  const [showSaerchResult, setShowSearchResult] = useState(false)

  const search = useCallback(async () => {
    const searchText = searchParams.get('search')
    setSearchText(searchText)
    if (searchText !== '') {
      let result = await dispatch(searchCartoon(
        searchText
      ))
      setSearchCartoons(result.payload.data?.data ? result.payload.data.data.cartoons : [])
    }
  }, [dispatch, searchParams])

  useEffect(() => {
    search()
    logEvent(firebaseAnalytics, 'Web_Carton_search')
  }, [dispatch, searchText, search])

  useEffect(() => {
    if (document.getElementById(`video_${selectedIndex}`)) {
      document.getElementById(`video_${selectedIndex}`).autoPlay = true
    }
  }, [autoPlay, selectedIndex])

  const onMouseEnter = (index) => {
    let img = document.getElementById(`img_${index}`)
    let video = document.getElementById(`video_${index}`)
    if (video && video.currentTime >= 0 && !video.ended) {
      if (video.currentTime === video.duration) video.currentTime = 0
      video.play();
    }
    if (img) img.style.display = 'none'
    setSelectedIndex(index)
    setAutoPlay(true)
  }

  const onMouseLeave = (index) => {
    let img = document.getElementById(`img_${index}`)
    let video = document.getElementById(`video_${index}`)
    if (video && video.currentTime >= 0 && !video.ended) {
      if (video.currentTime === video.duration && !video.paused) video.currentTime = 0
      video.pause();
    }
    if (img) img.style.display = 'flex'
    setSelectedIndex(index)
    setAutoPlay(false)
  }

  const formatDate = (date) => {
    if (date?.length > 0) {
      let newDate = new Date(date[0], date[1] - 1, date[2])
      let monthName = newDate.toLocaleString('default', { month: 'long' })
      return `${date[2]} ${monthName} ${date[0]}`
    }
  }

  const resizePlyer = useCallback(() => {
    if (searchCartoons && searchCartoons.length > 0) {
      for (let i in searchCartoons) {
        let video = document.getElementById(`video_${i}`)
        let img = document.getElementById(`img_${i}`)
        let search_video = document.getElementById(`search_video_${i}`)
        if (video && search_video && img) {
          search_video.style.height = `${video.offsetWidth * 0.55}px`
          video.style.height = `${video.offsetWidth * 0.52}px`
          img.style.height = `${video.offsetWidth * 0.55}px`
          search_video.style.overflow = 'hidden'
        }
      }
    }
  }, [searchCartoons])

  useEffect(() => {
    window.addEventListener('resize', resizePlyer);
    return () => window.removeEventListener('keydown', resizePlyer)
  }, [resizePlyer]);

  useEffect(() => {
    resizePlyer()
  }, [searchCartoons, resizePlyer])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.search_content} d-flex justify-content-center flex-wrap flex-column align-items-center`} onClick={() => setShowSearchResult(false)}>
            <div className='col-8'>
              <CartoonSearch
                showSaerchResult={showSaerchResult}
                setShowSearchResult={setShowSearchResult}
                languageKey={ctx.language}
                search={ctx.data?.search[ctx.language]}
                searchCartoon={search}
              />
            </div>
            {searchCartoons?.length > 0 && searchCartoons.map((item, index) => {
              return (
                <Link
                  className={`col-md-9 col-lg-8 col-xl-7 col-sm-10 col-8 d-flex flex-wrap justify-content-center ${classes.link}`}
                  key={`search_video_${index}`}
                  to={`/cartoons/${item._id}?search=${searchText}`}
                  state={{ 'play': true }}
                >
                  <div
                    className={`search_bg col-sm-5 col-md-5 col-12 col-lg-5 col-xl-4`}
                    id={`search_video_${index}`}
                    onMouseEnter={() => onMouseEnter(index)}
                    onMouseLeave={() => onMouseLeave(index)}
                  >
                    <div
                      className={`${classes.relative} ${classes.item_content} position-relative justify-content-center d-flex align-items-center`}
                      id={`search`} >
                      {item.videoUrl ?
                        <video
                          src={`${process.env.REACT_APP_DEV_URI}${item.videoUrl}`}
                          id={`video_${index}`}
                          muted
                        />
                        : null
                      }
                      <img id={`img_${index}`} src={`${process.env.REACT_APP_DEV_URI}${item.imageUrl}`} alt='bg' className={`${classes.bg_image} position-absolute top-0 start-0 w-100 h-100 object-fit-fill`} />
                      <p className={`${classes.time} ${classes.absolute} mt-auto ms-auto position-absolute bottom-0`}>
                        {videoPlayer.cartoonDuration(item)}
                      </p>
                    </div>
                  </div>
                  <div className={`${classes.about} col-sm-5 col-md-6 col-12 col-lg-6 xol-xl-5`}>
                    <h5 className={`${classes.video_title} justify-content-center justify-content-md-start mt-md-0 mt-2`}>{item.title[[ctx.language]]}</h5>
                    <div className='d-flex views justify-content-start'>
                      <h6>{item.viewsCount} {ctx.data?.views ? ctx.data?.views[ctx.language] : 'Views'} </h6>
                      <h6>{formatDate(item?.createdAt?.split('T')[0].split('-'))}</h6>
                    </div>
                  </div>
                </Link>
              )
            })}
            {
              searchCartoons && searchCartoons.length === 0 ?
                <NoData title={`${ctx.data?.no_search_result[ctx.language]}`} />
                : null
            }
          </div>
        );
      }}
    </LanguageContext.Consumer>)
};

export default SearchCartoon;