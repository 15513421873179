import { createSlice } from '@reduxjs/toolkit';

const initialState = { codeValidation: {} };

const codeValidationSlice = createSlice({
  name: 'codeValidation',
  initialState,
  reducers: {
    codeValidationHandler(state, action) {
      state.codeValidation = action.payload.codeValidation;
    },
  },
});
export const codeValidationAction = codeValidationSlice.actions;
export default codeValidationSlice;
