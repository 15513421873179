import React from 'react';

const Button = (props) => {
  const {
    onClick,
    id,
    disabled,
    image,
    text,
    divStyle,
    textStyle,
    type,
    buttonStyle,
    imgStyle,
    textClick,
    onSubmit,
    onDoubleClick
  } = props;

  return (
    <div className={divStyle}>
      <button
        onClick={onClick}
        className={buttonStyle}
        id={id}
        disabled={disabled}
        type={type}
        onSubmit={onSubmit}
        onDoubleClick={onDoubleClick}
      >
        {image && <img className={imgStyle} src={image} alt='' />}
        <span className={textStyle} onClick={textClick}>{text}</span>
      </button>
    </div>
  );
};

export default Button;
