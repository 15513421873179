import { createSlice } from '@reduxjs/toolkit';

const initialState = {ageGroups:{data:[], status:null}};

const ageGroupsSlice = createSlice({
  name: 'ageGroups',
  initialState,
  reducers: {
    ageGroupsHandler(state, action) {
      state.ageGroups = action.payload.ageGroups;
    },
  },
});
export const ageGroupsAction = ageGroupsSlice.actions;
export default ageGroupsSlice;
