import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {} };

const deleteCartoonSlice = createSlice({
  name: 'deleteCartoon',
  initialState,
  reducers: {
    deleteCartoonHandler(state, action) {
      state.data = action.payload.data;
    },
  },
});
export const deleteCartoonAction = deleteCartoonSlice.actions;
export default deleteCartoonSlice;
