import React from 'react';
import classes from './UploadCartoonInput.module.css';

const UploadCartoonInput = (props) => {
  const { onChange, placeholder, type, accept, name, id, isDescription, first, defaultValue, value} = props;

  return (
    <div className={`${isDescription ? classes.height_100 : ''} ${classes.description_input} d-flex justify-content-start w-100`}>
     {!isDescription ? <input
      className={`${classes.carttoon_info} border-0 shadow-none w-100`}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        defaultValue={defaultValue}
        accept={accept}
        name={name}
        id={id}
        autoFocus={first}
        value={value}
        autoComplete='off'
      /> :
      <textarea  
       className={`${classes.description_input} ${classes.carttoon_info} border-0 shadow-none w-100 h-100 border-0`}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        defaultValue={defaultValue}
        accept={accept}
        id={id}
        name={name}>
      </textarea>}
    </div>
  );
};

export default UploadCartoonInput;
