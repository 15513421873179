import React from 'react';
import { nanoid } from 'nanoid';
import classes from './SpacingItem.module.css';

const SpacingItem = (props) => {
  const {addSpacing, marginsData } = props;

  return (
    <>
      {marginsData.map((item) => {
        return (
          <div className={`${classes.spacing_container} d-flex justify-content-center align-items-center w-100`} key={nanoid()}>
            <div className={`${classes.spacing_item_container} d-flex w-100 justify-content-between`}>
              <div className={`${classes.spacing_item_text_container} d-flex align-items-center`}>
                <span
                  className={classes.spacing_item}
                  onClick={() => addSpacing(item.type, item.reduce)}
                >
                  -
                </span>
              </div>
              <div className={`${classes.spacing_item_text_container} d-flex align-items-center`}>
                <span className={classes.spacing_item_text}>
                  {item.spacingText}
                </span>
              </div>
              <div className={`${classes.spacing_item_text_container} d-flex align-items-center`}>
                <span
                  className={classes.spacing_item}
                  onClick={() => addSpacing(item.type, item.add)}
                >
                  +
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SpacingItem;
