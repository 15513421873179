import { createSlice } from '@reduxjs/toolkit';

const initialState = { addPlaylist: [] };

const updatePlaylistsSlice = createSlice({
  name: 'updatePlaylists',
  initialState,
  reducers: {
    updatePlaylistsHandler(state, action) {
      state.updatePlaylists = action.payload.updatePlaylists;
    },
  },
});
export const updatePlaylistsAction = updatePlaylistsSlice.actions;
export default updatePlaylistsSlice;
