import React from 'react';
import { Link } from 'react-router-dom';
import classes from './UploadCartoonButton.module.css';
import { LanguageContext } from '../../../context/LanguageContext';

const UploadCartoonButton = () => {
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`d-flex ${classes.width100}  justify-content-end`}
          >
            <Link to='/upload-cartoons' className={`${classes.upload_cartoon} border-0 bg-transparent text-decoration-none`}>
              {
                ctx.data?.upload_cartoon[ctx.language]}
            </Link>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};
export default UploadCartoonButton;
