import React from 'react';
import Title from './titles/Title';
import { Link } from 'react-router-dom';
import RatingCards from './rating/RatingCards';
import classes from './CardBestsellers.module.css';
import CategoryCards from './category/CategoryCards';
import ProgressiveImage from '../../progressiveImage/ProgressiveImage';

const CardBestsellers = (props) => {
  const {
    data,
    categories,
    img,
    bestsellers,
    myBook,
    className,
    style,
    height_unset,
    mx_auto,
    imageClasses,
    padding_unset,
    dataContainer,
    titles
  } = props;

  return (
    <Link
      to={`/products/${data?._id}`}
      className={`${classes.card_bestseller} ${className} d-flex justify-content-center text-decoration-none`}
    >
      <div
        className={style ? style : `${classes.card_item} ${classes.card_container} ${bestsellers ? classes.max_width_300 : ''} ${classes.ellipsis} ${dataContainer} d-flex justify-content-center text-decoration-none w-100 h-100 flex-wrap overflow-hidden text-nowrap`}>
        <div
          className={
            height_unset
              ? height_unset
              : `${classes.card_item_img_container} ${classes.card_container} d-flex justify-content-center text-decoration-none w-100 align-items-center`
          }
        >
          <div className={mx_auto ? mx_auto : classes.bestsellers}>
            <ProgressiveImage
              src={img}
              alt=''
              className={imageClasses}
            />
          </div>
        </div>
        <div
          className={`${classes.card_item_rating_container} d-flex justify-content-start align-items-center w-100 justify-content-center`}
        >
          <RatingCards data={data} />
        </div>
        <Title data={data} padding_unset={padding_unset} myBook={myBook} titles={titles}/>
        {categories && <CategoryCards
          data={data}
          categories={categories}
          padding_unset={padding_unset}
          className={classes.padding_0_20}
        />}
      </div>
    </Link>
  );
};

export default CardBestsellers;
