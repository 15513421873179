import Cookies from 'js-cookie';
import classes from './Cartoons.module.css';
import { useLocation } from 'react-router-dom';
import NoData from '../../components/noData/NoData';
import CartoonCard from './cartoonCard/CartoonCard';
import Loader from '../../components/spinner/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import BackDrop from '../../components/backDrop/BackDrop';
import CartoonSearch from './filter/search/CartoonSearch';
import PageTitle from '../../components/pageTitle/PageTitle';
import { LanguageContext } from '../../context/LanguageContext';
import Button from '../../components/buttons/authButton/Button';
import searchCartoon from '../../store/cartoons/search/SearchCartoon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import deleteCartoon from '../../store/cartoons/deleteCartoon/DeleteCartoon';
import FilterComponents from '../products/filterComponents/FilterComponents';
import UploadCartoonButton from './uploadCartoonButton/UploadCartoonButton';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';
import watchLater from '../../store/cartoons/watchLater/watchLater/WatchLater';
import uploadedCartoons from '../../store/cartoons/uploadedCartoons/UploadedCartoons';
import fetchCartoonCategories from '../../store/cartoons/categories/FetchCartoonCategories';

const Cartoons = () => {
  const limit = process.env.REACT_APP_CARTOONS_LIMIT;
  let timeoutID = null;
  const location = useLocation();
  const dispatch = useDispatch();
  const token = Cookies.get('auth_token');
  const [cartoonData, setCartoonData] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const categoriesCartoon = useSelector((state) => state.cartoonCategories.cartoonCategories);
  const searchCartoonData = useSelector((state) => state.searchCartoon.data);
  const [isData, setIsData] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterPage, setFilterPage] = useState(1);
  const [filterFetched, setfilterFetched] = useState(true);
  const [filterFetching, setFilterFetching] = useState(false);
  const [filterInitialLoded, setFilterInitialLoded] = useState(true);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const ref = useRef(null);
  const checkedData = JSON.parse(window.sessionStorage.getItem('cartoonCheckedData'));

  useEffect(() => {
    if(location) {
      window.scroll(0, 0)
    }
  }, [location])
    
  localStorage.setItem('firstLoadDone', 1)
  const [filteredData, setFilteredData] = useState({
    ageGroup: checkedData?.ageGroup ?? [],
    categories: checkedData?.categories ?? [],
  });
  const [showLoader, setShowLoader] = useState(true);
  const [filterShow, setFilterShow] = useState('show');
  const [data, setData] = useState({
    searchText: ref.current?.value.length > 0 ? ref.current?.value : '',
    ageGroup: checkedData?.ageGroup ?? [],
    categories: checkedData?.categories ?? [],
  });
  const uploadedCartoon = useSelector((state) => state.uploadedCartoons.data)
  const watchLaterData = useSelector((state) => state.watchLater?.data?.data?.watchLaterList)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if(token) {
      dispatch(uploadedCartoons())
    }
  }, [dispatch, token])

  useEffect(() => {
    if(token) {
      dispatch(watchLater(10, 1))
    }
  }, [dispatch, disabled, token])

  useEffect(() => {
    data['searchText'] = ref.current?.value;
  }, [data, ref.current?.value]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
      clearTimeout(timeoutID);
    }, 700);
  }, [searchCartoonData]);

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_Cartoons_page')
    dispatch(fetchCartoonCategories());
  }, [dispatch]);

  useEffect(() => {
    if (
      ref.current?.value?.length > 1 ||
      filteredData.ageGroup?.length > 0 ||
      filteredData.categories?.length > 0
    ) {
      setFiltered(true);
      setfilterFetched(true);
      setIsData(false);
    } else {
      setFiltered(true);
      setfilterFetched(false);
      setSearchData([]);
      setIsData(true);
    }
  }, [ref, filteredData]);

  useEffect(() => {
    if (filtered && searchData.length === 0) {
      setIsData(true);
    } else {
      setIsData(false);
      setCartoonData([]);
    }
  }, [filtered, searchData]);

  const searchCartoons = (e, clearText) => {
    if (
      (e !== null && (!e.target.value.length || e.target.value.length > 1)) ||
      typeof clearText !== 'undefined'
    ) {
      setFilterPage(1);
      setShowLoader(true)
      setSearchData([]);
      setIsData(false);
    }
  };

  const filterProductsChange = (e, type) => {
    setFilterPage(1);
    setfilterFetched(true);
    setIsData(false);
    setShowLoader(true)
    setSearchData([]);

    const mutableData = {...data}
    if (e.target.checked) {
      mutableData[type].push(e.target.value)
    } else {
      mutableData[type] = mutableData[type].filter(
        (item) => item !== e.target.value)
    }
    window.sessionStorage.setItem(
      'cartoonCheckedData',
      JSON.stringify(mutableData)
    );
    setData(mutableData)
  };

  const clearFilters = () => {
    setFilteredData({ ageGroup: [], categories: [] });
    window.sessionStorage.removeItem('cartoonCheckedData');
    setData({
      searchText: ref.current?.value.length > 0 ? ref.current?.value : '',
      ageGroup: [],
      categories: []
    })
    setFiltered(false);
    setFilterPage(1);
    setShowLoader(true)
    setSearchData([]);
  };

  const deleteCartoonHandler = async (cartoonId, index) => {
    const response = await dispatch(deleteCartoon(cartoonId));
    if(response.payload.data.status === 200) {
      let cartoons = [...searchData]
      if (index >= 0) {
        cartoons.splice(index, 1);
      }
      setSearchData(cartoons)
    }
  };

  const handleFilterData = useCallback(async () => {
    if (filterFetching) {
      return;
    }
    setFilterFetching(true);

    if (filtered && filterInitialLoded) {
      setShowLoader(true)
      dispatch(
        searchCartoon(
          data,
          limit,
          filterPage
        )
      );
    }
    setFilterPage(filterPage + 1);

    if (!filterInitialLoded) {
      setFilterInitialLoded(true);
    }
    setfilterFetched(true);
  }, [
    dispatch,
    limit,
    filtered,
    filterInitialLoded,
    filterPage,
    filterFetching,
    data
  ]);

  useEffect(() => {
    if (filterFetched) {
      setfilterFetched(false);
      if (filtered) {
        if (searchCartoonData?.data?.cartoons.length > 0) {
          setSearchData([...searchData, ...searchCartoonData.data.cartoons]);
          setHasMore(searchCartoonData?.data?.cartoons.length >= limit);
        } else {
          setSearchData(searchData);
          setHasMore(false);
        }
        setShowLoader(false)
        setFilterFetching(false);
      }
    }
  }, [searchCartoonData]);

  useEffect(() => {
    setCartoonData([]);
  }, [location]);

  useEffect(() => {
    if (filtered && filterInitialLoded && searchData.length === 0 && !isData) {
      handleFilterData();
    }
  }, [
    searchData,
    filterInitialLoded,
    filtered,
    handleFilterData,
    isData,
    filterPage,
  ]);

  const loadMore = () => {
    if (filtered) {
      handleFilterData();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const productsFilter = () => {
    setFilterShow(!filterShow.includes('filter') ? ['filter'] : [])
    window.scroll(0, 0)
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div
              className={`${classes.gap_50} d-flex flex-wrap`}
              onClick={() => setShowSearchResult(false)}
            >
              <PageTitle title={ctx.data?.cartoons[ctx.language]} />
              <div
                className={`${classes.cartoon_container} d-flex w-100 justify-content-center ${windowSize <= 9810 ? 'flex-wrap' : ''} ${
                  classes.gap_20
                } position-relative`}
              >
                <FilterComponents
                  categories={categoriesCartoon}
                  filterProductsChange={filterProductsChange}
                  clearFilter={clearFilters}
                  checkedData={data}
                  cartoons={true}
                  filterShow={filterShow}
                  setFilterShow={setFilterShow}
                />
                <div className={`${classes.cartoons_container} w-100`}>
                  <div
                    id='cartoonData'
                    className={`${classes.cartoons_item_container} ${classes.gap_20} flex-wrap d-flex justify-content-start w-100`}
                  >
                    <div
                      className={`d-flex w-100 align-items-center justify-content-between ${classes.filter_container}`}
                    >
                      <CartoonSearch
                        searchCartoons={searchCartoons}
                        inputRef={ref}
                        showSearchResult={showSearchResult}
                        setShowSearchResult={setShowSearchResult}
                        searchFrom='cartoon'
                        languageKey={ctx.language}
                        searchBookModal={`${classes.searchBookModal} position-absolute overflow-auto`}
                      />
                      <div className={`d-flex align-items-center justify-content-between ${!filterShow.includes('show') ? 'w-100' : ''}`}>
                       {!filterShow.includes('show') && (
                        <Button
                          divStyle='position-relative'
                          buttonStyle={`${classes.side_bar_icon} d-flex border-0 bg-transparent`}
                          text={
                            ctx.data?.filter[ctx.language]
                              .charAt(0)
                              .toUpperCase() +
                            ctx.data?.filter[ctx.language].slice(1)
                          }
                          onClick={productsFilter}
                        />
                      )}
                      {token && <UploadCartoonButton />}
                      </div>
                    </div>
                    <InfiniteScroll
                      pageStart={0}
                      className={`d-flex w-100 flex-wrap ${classes.infinite_scroll_container} ${classes.gap_20} 
                      ${classes.min_height_628} ${filtered && searchData.length === 0 ? 'justify-content-center' : ''}`}
                      loadMore={loadMore}
                      hasMore={hasMore}
                      initialLoad={false}
                    >
                      {!isData &&
                        (searchData.length > 0 ? searchData : cartoonData)?.map(
                          (cartoon, index) => {
                            return (
                              <CartoonCard
                                id={cartoon._id}
                                cartoon={cartoon}
                                key={cartoon._id}
                                categories={
                                  categoriesCartoon && categoriesCartoon
                                }
                                languageKey={ctx.language}
                                buttons={['cartoonMenu']}
                                userCartoons={searchCartoonData}
                                deleteCartoon={deleteCartoonHandler}
                                index={index}
                                uploadedCartoon={uploadedCartoon}
                                watchLaterData={watchLaterData}
                                setDisabled={setDisabled}
                              />
                            );
                          }
                        )}
                      {filtered && searchData.length === 0 && !showLoader && (
                        <NoData title={ctx.data?.no_data[ctx.language]} />
                      )}
                    </InfiniteScroll>
                  </div>
                  <div
                    className={
                      showLoader
                        ? `${classes.abs} position-fixed top-0 start-0 w-100 d-flex h-100`
                        : `${classes.abs} d-none position-fixed top-0 start-0 w-100 d-flex h-100`
                    }
                  >
                    <Loader />
                  </div>
                </div>
              </div>
            </div>
            {filterShow.includes('filter') && <BackDrop onClick={() => setFilterShow(['bacDrop'])} className={`${classes.z_index_3300} ${classes.back_drop_background} `}/>}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Cartoons;
