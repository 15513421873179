import axios from 'axios';
import Cookies from 'js-cookie';
import { deleteFavoriteProductAction } from './DeleteFavoriteProductSlice';

const deleteFavoriteProduct = (productId) => {
  const token = Cookies.get('auth_token')
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {productId};
      const url = `${process.env.REACT_APP_DEV_URI}/user/wantread/delete`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      });
      return response;
    };
    try {
      const data = await fetchData();
      if (data.status === 200) return  dispatch(deleteFavoriteProductAction.deleteFavoriteProductHandler({data:data.data, status: data.status }));
    } catch (e) {
      return e
    }
  };
};

export default deleteFavoriteProduct;