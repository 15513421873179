import React from 'react';
import classes from './Suggestions.module.css';
import Titles from '../../../components/title/Titles';
import { LanguageContext } from '../../../context/LanguageContext';
import SuggestionsItem from '../../../components/suggestionsItem/SuggestionsItem';

const Suggestions = () => {
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.suggestions_container} flex-wrap justify-content-center d-flex w-100 align-items-center`}
          >
            <Titles
              title={{
                subtitle: ctx.data?.suggest[ctx.language],
                title: ctx.data?.help_you[ctx.language],
              }}
            />
            <div
              className={`${classes.suggestions_img_container} justify-content-evenly d-flex w-100 align-items-center`}
            >
              {['book_selection', 'every_age_group', 'quick_support'].map(
                (item) => {
                  return (
                    <SuggestionsItem
                      key={item}
                      imgSuggestions={`./assets/latesArticles/${item}.png`}
                      textSuggestions={
                        ctx.data && ctx.data[item][ctx.language]?.toUpperCase()
                      }
                    />
                  );
                }
              )}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Suggestions;
