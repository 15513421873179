import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import LogOut from '../../components/logOut/LogOut';
import Cookies from 'js-cookie';
import classes from './Settings.module.css'
import SignInSettings from '../../components/modal/auth/SignInSettings';
import { changeTextShape } from '../../utils/helpers/changeTextShape';
import { constantData } from '../../utils/helpers/constantData';

const Settings = () => {
  const navigate = useNavigate();
  const authToken = Cookies.get('auth_token')
  const nowReadingBook = JSON.parse(localStorage.getItem('readingPageLocation'));
  const alreadyRead = JSON.parse(localStorage.getItem('readBooks'))
  const icons = constantData.icons
  const [confirmLogOutShow, setConfirmLogOutShow] = useState(false);
  const [confirmLogInShow, setConfirmLogInShow] = useState(false);
  const [token, setToken] = useState(authToken);
  const [status, setStatus] = useState(false);

  const modalChangeHandler = (state) => {
    setStatus(state);
  };

  const setIsShow = (isShown) => {
    setConfirmLogInShow(isShown);
  };

  const tokenHandler = (userToken) => {
    !token && setToken(userToken);
  };

  const modalHideHandler = () => {
    setConfirmLogInShow(false);
  };

  const modalShowHandler = () => {
    if (status) {
      setConfirmLogInShow(false);
    }
  };

  const navigateToCntacts = () => {
    navigate('/')
    setTimeout(() => {
      let item = document.getElementById('contact-us');
      if (item) {
        item?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 300);
    return () => clearTimeout();
  }

  const navBarParametrs = (nowReadingBook, alreadyRead, authToken) => {
    let data = [
      {
        path: 'my_account',
        src: '../assets/video/notifications.svg'
      },
      {
        path: 'purchased',
        src: '../assets/video/myBooks.svg'
      },
      {
        path: 'my_playlists',
        src: '../assets/video/myCartoons.svg'
      },
      {
        path: 'my_cartoons',
        src: '../assets/video/play.svg'
      },
      {
        path: 'now_reading',
        src: '../assets/video/now_reading.svg'
      }
    ];

    if(!nowReadingBook || nowReadingBook.length === 0  || !authToken) {
      data = data.filter((item) => !item.path.includes('now_reading'))
    }
    return data;
  };

  const menus = navBarParametrs(nowReadingBook, alreadyRead, authToken);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          ctx.data && Object.keys(ctx?.data).length > 0 &&
          <>
            {authToken ?
              <div className={`${classes.settings} d-flex flex-column justify-content-center`}>
                {menus.map((item, index) => {
                  return (
                    <Link
                      to={item.path === 'now_reading' ? `/products/${nowReadingBook && nowReadingBook[nowReadingBook?.length - 1]?.productId}` : `/${changeTextShape.toKebabcase(ctx.data[item.path], 'en')}`}
                      key={changeTextShape.toKebabcase(ctx.data[item.path], 'en')}
                      className={(index === 0 || index === 3) ? classes.padding_left_34 : ''}
                    >
                      <img src={item.src} alt='book' className={(index === 0 || index === 3 || index === 4) ? `${classes.other_img} object-fit-scale` : '' } />
                      <h6>{changeTextShape.fisrtCharackterToUpperCase(ctx.data[item.path], ctx.language)}</h6>
                    </Link>
                  )
                })}
                <a href={`${process.env.REACT_APP_DEV_URI}/privacy?lang=${ctx.language === 'am' ? 'hy' : ctx.language}`} className={classes.padding_left_34}>
                  <img src={icons[0]} alt='book' className={`${classes.other_img} object-fit-scale`}/>
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data.privacy_policy, ctx.language)}
                </a>
                <Link to={`/about-us`} className={classes.padding_left_34}>
                  <img src={icons[3]} alt='book' className={`${classes.other_img} object-fit-scale`} />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data.about, ctx.language)}
                </Link>
                <Link
                onClick={navigateToCntacts}
                  className={`${classes.contact_us} bg-transparent border-0 text-start`}
                >
                  <img src={icons[1]} alt='book' />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data.contact_us, ctx.language)}
                </Link>
                <button
                  className={`${classes.contact_us} bg-transparent border-0 text-start`}
                  onClick={() => { setConfirmLogOutShow(true) }}
                >
                  <img src={icons[2]} alt='book' className={`${classes.margin_right_10}`} />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data.log_out, ctx.language)}
                </button>
                {confirmLogOutShow ? (
                  <LogOut
                    show={confirmLogOutShow} setShow={setConfirmLogOutShow} setAuthToken={setToken}
                  />
                ) : null}
              </div> : <div className={`${classes.settings} d-flex flex-column justify-content-center`}>
                <div className={`d-flex bg-transparent border-0 text-start ${classes.contact_us} ${classes.login} ${classes.padding_left_34}`} onClick={() => setConfirmLogInShow(true)}>
                  <img src={menus[0].src} alt='book' className={`${classes.other_img} object-fit-scale`} />
                  <h6>{ctx.data?.login[ctx.language]}</h6>
                </div>
                <a href={`${process.env.REACT_APP_DEV_URI}/privacy?lang=${ctx.language === 'am' ? 'hy' : ctx.language}`} className={classes.padding_left_34}>
                  <img src={icons[0]} alt='book' className={`${classes.other_img} object-fit-scale`} />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data?.privacy_policy, ctx.language)}
                </a>
                <Link to={`/about-us`} className={classes.padding_left_34}>
                  <img src={icons[3]} alt='book' className={`${classes.other_img} object-fit-scale`} />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data.about, ctx.language)}
                </Link>
                <Link
                onClick={navigateToCntacts}
                className={`${classes.contact_us} bg-transparent border-0 text-start`}
                >
                  <img src={icons[1]} alt='book' />
                  {changeTextShape.fisrtCharackterToUpperCase(ctx.data?.contact_us, ctx.language)}
                </Link>

                <SignInSettings
                country={localStorage.getItem('country')}
                  showAuth={confirmLogInShow}
                  setShowAuth={setConfirmLogInShow}
                  onHide={modalHideHandler}
                  onPress={modalShowHandler}
                  changeModalContent={modalChangeHandler}
                  setUserData={() => {
                    setConfirmLogInShow(false);
                  }}
                  setShow={setIsShow}
                  setCookiesToken={tokenHandler}
                />
              </div>
            }
          </>
        )
      }}

    </LanguageContext.Consumer>
  );
};

export default Settings;