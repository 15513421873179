import axios from 'axios';
import Cookies from 'js-cookie';
import { addPlaylistAction } from './AddPlayListSlice';

const addPlayList = (title, cartoonId) => {
    const token = Cookies.get('auth_token')
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {title, cartoonId};
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      });
      return response;
    };
    try {
      const addPlaylist = await fetchData();
      return dispatch(addPlaylistAction.addPlaylistHandler({ addPlaylist }));
    } catch (e) {}
  };
};

export default addPlayList;
