import { createSlice } from '@reduxjs/toolkit';

const initialState = { filter: {} };

const filterSlice = createSlice({
  name: 'popular',
  initialState,
  redusers: {
    filterHandler(state, action) {
      state.filter = action.payload.filter;
    },
  },
});

export const filterAction = filterSlice.actions;
export default filterSlice;
