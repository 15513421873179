import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: { data: [], pageCount:0, status: null} };

const favoriteProductsSlice = createSlice({
  name: 'favoriteProducts',
  initialState,
  reducers: {
    favoriteProductsHandler(state, action) {
      state.data = action.payload;
    },
  },
});
export const favoriteProductsAction = favoriteProductsSlice.actions;
export default favoriteProductsSlice;
