export const productsRating = {
  checkImage(star) {
    let image = {};
    let keys = ['star', 'star1-2', 'star2-3', 'star3-4', 'star4-5'];
    if (!star) keys = keys.filter((menu) => !['star'].includes(menu));
    keys.forEach((item, index) => {
      image = { ...image, [item]: star ? index + 1 : index + 2 };
    });
    return image;
  },

  productRatings(data, setSrc, star) {
    const image = this.checkImage(star);
    Object.keys(image).forEach((item) => {
      if (data?.rating?.rate >= 5 || data?.rating?.rate === 0) {
        setSrc(`../assets/stars/star4-5.svg`);
      } else if (data.rating?.rate <= image[item]) {
        setSrc(`../assets/stars/${item}.svg`);
      }
    });
  },
};
