export const authValidation = {
  isValidHandler (inputNumber, country, valid) {
    if (
      inputNumber.length ===
      country.format?.slice(0, country.format.indexOf(' ')).length - 1
    ) {
      if (valid) {
        return false;
      } else return true;
    } else if (
      country.format?.replace(/[^.,a-zA-Z]/g, '').length ===
      inputNumber?.replace(/\s/g, '').length
    ) {
      return true;
    } else {
      return false;
    }
  },

  isValidCountry (inputNumber, country) {
    const status = this.isValidHandler(inputNumber, country, false);
    return status;
  },
};
