import React from 'react';
import styles from './Blocks.module.css';

const Titles = (props) => {
  const {children} = props
  
  return (
    <h2 className={`${styles.footer_menus_title} p-0 m-0`}>
      {children}
    </h2>
  );
};

export default Titles;
