import { createSlice } from '@reduxjs/toolkit';

const initialState = { latestProducts:[]};

const latestProductsSlice = createSlice({
  name: 'latestProducts',
  initialState,
  reducers: {
    latestProductsHandler(state, action) {
      state.latestProducts = action.payload.latestProducts;
    },
  },
});
export const latestProductsAction = latestProductsSlice.actions;
export default latestProductsSlice;
