import React from 'react';
import classes from './UploadCartoonApplyButton.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';

const UploadCartoonApplyButton = (props) => {
  const {
    applyUploadCartoon,
    cancelUploadCartoon,
    apply,
    filterCartoon,
    edit,
  } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.apply_button_container} justify-content-end w-100 d-flex`}
          >
            {!filterCartoon && (
              <button
                className={`${classes.disabled_button} bg-transparent border-0`}
                onClick={cancelUploadCartoon}
              >
                {ctx.data?.cancel[ctx.language]}
              </button>
            )}
            <button
              className={`${!apply ? classes.disabled_button : ''} d-flex justify-content-end bg-transparent border-0`}
              disabled={apply}
              onClick={applyUploadCartoon}
            >
              {edit
                ? ctx.data?.edit[ctx.language]
                : ctx.data?.add[ctx.language]}
            </button>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonApplyButton;
