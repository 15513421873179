import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {} };

const authorByidSlice = createSlice({
  name: 'authorById',
  initialState,
  reducers: {
    authorByIdHandler(state, action) {
      state.data = action.payload.data;
    },
  },
});
export const authorByIdAction = authorByidSlice.actions;
export default authorByidSlice;
