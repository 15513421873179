import axios from 'axios';
import Cookies from 'js-cookie';
import { watchCartoonAction } from './WatchCartoonSlice';

const watchCartoon = (params) => {
  const token = Cookies.get('auth_token')
  return async (dispatch) => {
    const fetchData = async () => {
      const body = params;
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/watched`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      });
      return response;
    };
    try {
      const watchCartoon = await fetchData();
      const watchCartoonData = watchCartoon.data
      dispatch(watchCartoonAction.watchCartoonHandler({ watchCartoonData }));
    } catch (e) {}
  };
};

export default watchCartoon;
