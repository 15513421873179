import { useParams } from 'react-router-dom';
import classes from './AudioPlayer.module.css'
import { useDispatch, useSelector } from 'react-redux';
import AudioButtons from '../audioButtons/AudioButtons';
import React, { useMemo, useEffect, useState, useRef } from 'react';
import fetchDownLoad from '../../../../../store/purchases/downLoad/FetchDownLoad';

const AudioPlayer = (props) => {
  const productId = useParams();
  const dispatch = useDispatch();
  const [blob, setBlob] = useState();
  const downLoad = useSelector((state) => state.file);
  const { data, setAutoPlay, autoPlay, episode, setEpisode, setDisabled, disabled, nextOnClick, prevOnClick} = props;
  const audioRef = useRef(null);

  useEffect(() => {
    if (episode === '001') {
      setDisabled([...disabled, 'prev']);
    } else if (parseInt(episode, 10) === data.audioBook.length) {
      setDisabled([...disabled, 'next']);
    } 
  }, [episode, data, setDisabled]);

  useEffect(() => {
    if(data.audioBook.length === 1) {
      setDisabled(['next','prev']);
    }  else if(data.audioBook.length === 0) {
      setDisabled(['next','prev', 'pause']);
    }
  }, [data, setDisabled])

  const audioHandler = async (type) => {
    const audio = document.getElementById('audio');
    const playButton = document.getElementById('playImg');
    if (type !== 'pause') {
      let response = null;
      setAutoPlay(false);
      setDisabled(['prev', 'pause', 'next']) 
      audio.pause();
      if (type === 'next') {
        if (episode < data.audioBook.length) {
          setEpisode(`00${parseInt(episode, 10) + 1}`);
          nextOnClick(`00${parseInt(episode, 10) + 1}`);
          response = await dispatch(fetchDownLoad(productId.id,'audiobook',`00${parseInt(episode, 10) + 1}`)
          );
        }
      } else if (type === 'prev') {
        if (episode !== '001') {
          setEpisode(`00${parseInt(episode, 10) - 1}`);
          prevOnClick(`00${parseInt(episode, 10) - 1}`);
          response = await dispatch(fetchDownLoad(productId.id, 'audiobook', `00${parseInt(episode, 10) - 1}`)
          );
        }
      }
      if (audio.paused && response.payload.file.status === 200) {
        setAutoPlay(true);
        if(`00${parseInt(episode, 10) - 1}` === '001' && type === 'prev') {
          setDisabled(['prev']);
        } else if (parseInt(episode, 10) + 1 === data.audioBook.length && type === 'next') {
          setDisabled(['next']);
        } else {
          setDisabled([])
        }
      } else {
        setDisabled([])
      }
    } else {
      if (audio.paused) {
        playButton.src = '../../assets/audio/play.svg';
        audio.play();
      } else {
        playButton.src = '../../assets/audio/pause.svg';
        audio.pause();
      }
    }
  };

  useEffect(() => {
    const playButton = document.getElementById('playImg');
    if (autoPlay) {
      playButton
        ? (playButton.src = '../../assets/audio/play.svg')
        : (playButton.src = '../../assets/audio/pause.svg');
    }
  }, [autoPlay]);

  useEffect(() => {
    if (downLoad.file.status === 200) setBlob(downLoad.file.data);
  }, [downLoad]);

  const useObjectUrl = (blob) => {
    const audioData = useMemo(
      () => new Blob([blob], { type: 'audio/mpeg' }),
      [blob]
    );
    const url = URL.createObjectURL(audioData);
    return url;
  };

  const changeSeek = (e) => {
    const playButton = document.getElementById('playImg');
    const audio = document.getElementById('audio');
    const time = parseFloat((e.target.value * audio.duration) / 100);
    if(audio) audio.currentTime = time;
    if(audio.currentTime === audio.duration) {
      playButton.src = '../../assets/audio/pause.svg'
    }
  };

  const changeTimelinePosition = (e) => {
    const audio = document.getElementById('audio');
    const timeline = document.getElementById('timeline');
    const playButton = document.getElementById('playImg');
    const currentTimeData = document.getElementById('currentTime');
    const minutes = Math.floor(audio.duration / 60);
    const seconds = (audio.duration % 60).toFixed(0);
    const currentMinute = Math.floor(audio.currentTime.toFixed(0) / 60);
    const currentSeconds = (audio.currentTime.toFixed(0) % 60).toFixed(0);
    if (currentSeconds < 10) {
      currentTimeData.innerHTML = `${currentMinute}:0${currentSeconds} / ${minutes}:${seconds}`;
    } else {
      currentTimeData.innerHTML = `${currentMinute}:${currentSeconds} / ${minutes}:${seconds}`;
    }
    const percentagePosition = (100 * audio.currentTime) / audio.duration;
    timeline.style.backgroundSize = `${percentagePosition}% 100%`;
    timeline.value = percentagePosition;
    if(audio.currentTime === audio.duration) {
      playButton.src = '../../assets/audio/pause.svg'
    }
  };

  const AudioFile = ({ blob }) => {
    const src = useObjectUrl(blob);
    return (
      <>
        <div className={`${classes.audio_player}`}>
          <audio
            ref={audioRef}
            onTimeUpdate={() => changeTimelinePosition()}
            id='audio'
            autoPlay={autoPlay ? true : false}
            className={classes.audio_container}
          >
            <source src={src} />
          </audio>
          <div className={`${classes.controls} d-flex flex-wrap flex-direction-row align-items-center w-100`}>
            <AudioButtons
              disabled={disabled}
              audioBookData={data}
              episode={episode}
              audioHandler={audioHandler}
              id='playerButton'
            />
            <input
              type='range'
              id='timeline'
              className={`${classes.timeline} d-flex justify-content-center w-100`}
              defaultValue={'0'}
              onChange={changeSeek}
            />
          </div>
          <div className={`${classes.current_time_container} d-flex w-100 justify-content-end`}>
            <span id={'currentTime'} className={classes.current_time} />
          </div>
        </div>
      </>
    );
  };

  return <>{blob && <AudioFile {...{ blob }} />}</>;
};

export default AudioPlayer;
