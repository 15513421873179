import { createSlice } from '@reduxjs/toolkit';

const initialState = {updatePlayList:{}};

const updatePlayListSlice = createSlice({
  name: 'updatePlayList',
  initialState,
  reducers: {
    updateCartoonPlayListHandler(state, actions) {
      state.updatePlayList = actions.payload.updatePlayList;
    },
  },
});

export const updatePlayListAction = updatePlayListSlice.actions;
export default updatePlayListSlice;
