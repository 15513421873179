import { Link } from 'react-router-dom';
import classes from './CartoonCard.module.css';
import CartoonTitle from './cardItems/title/CartoonTitle';
import React, { useEffect, useRef, useState } from 'react';
import CartoonVideo from './cardItems/video/CartoonVideo';
import BackDrop from '../../../components/backDrop/BackDrop';
import { LanguageContext } from '../../../context/LanguageContext';
import CartoonCategories from './cardItems/categories/CartoonCategories';
import PlaylistModal from '../../../components/modal/playlist/PlaylistModal';
import ConfirmationModal from '../../../components/modal/confirmationModal/ConfirmationModal';

const CartoonCard = (props) => {
  const {
    cartoon,
    categories,
    languageKey,
    deleteCartoon,
    index,
    uploadedCartoon,
    userCartoonsData,
    id,
    watchLaterData,
    setDisabled,
  } = props;

  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [cartoonId, setCartoonId] = useState();
  const [deleted, setDeleted] = useState(false);
  const data = (userCartoonsData ?? uploadedCartoon?.data?.cartoons)?.find((item) => item._id === cartoonId);
  const cartoonWatchLater = watchLaterData?.find((item) => item._id === cartoonId)
  const userCartoon = uploadedCartoon?.data?.cartoons?.find((item) => item._id === cartoonId)
  const [loaded, setLoaded] = useState(false)
  const [showPlaylist, setShowPlaylist] = useState(false)

  const handleLoadedMetadata = () => {
    setLoaded(true)
      const time = setTimeout(() => {
        setIsHovered(true)
      }, 350)
      return () => clearTimeout(time)
  };

  const onMouseEnter = () => {
    setLoaded(true)
    setIsHovered(false)
  };

  const onMouseLeave = () => {
    const time = setTimeout(() => {
    setLoaded(false)
    setIsHovered(false)
    vidRef.current?.pause();
    }, 100)
    return () => clearTimeout(time)
  }

  const linkClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  };

  const manuHandler = (type) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(!show);
    setCartoonId(type);
  };
  
  useEffect(() => {
    if(!vidRef.current?.paused && loaded) {
      setIsHovered(true)
      setLoaded(true)
    } 
  }, [loaded, isHovered])

  useEffect(() => {
    if(loaded && isHovered) {
        setIsHovered(true)
        setLoaded(true)
    }
  }, [isHovered, loaded])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <Link
              to={`/cartoons/${cartoon._id}`}
              state={{play: true }}
              className={`${classes.max_height_240}`}
            >
              <div
                className={`${classes.cartoon_cards} ${classes.border_radius_10} flex-wrap d-flex text-decoration-none justify-content-center`}
              >
                <CartoonVideo
                  id={`videoPlayers`}
                  src={cartoon?.videoUrl}
                  vidRef={vidRef}
                  poster={cartoon?.imageUrl}
                  handleLoadedMetadata={handleLoadedMetadata}
                  autoPlay={loaded}
                  cartoon={cartoon}
                  loaded={loaded}
                  isHovered={isHovered}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
                <div
                  className={`${classes.padding_0_15} flex-wrap w-100 d-flex`}
                >
                  <CartoonTitle
                    titleText={cartoon?.title[languageKey]}
                    title={cartoon?.title[languageKey]}
                    cartoonId={cartoon._id}
                    show={show}
                    setShow={setShow}
                    onClick={manuHandler(cartoon._id)}
                    cartoonData={data}
                    setDeleted={setDeleted}
                    id={id}
                    userCartoon={userCartoon}
                    cartoonWatchLater={cartoonWatchLater}
                    setDisabled={setDisabled}
                    setShowPlaylist={setShowPlaylist}
                  />
                  <CartoonCategories
                    cartoonCategory={cartoon?.categories}
                    categories={categories}
                  />
                </div>
              </div>

              {show && <BackDrop onClick={linkClickHandler} />}
            </Link>
            <ConfirmationModal
              logout={true}
              show={deleted}
              onHide={() => setDeleted(false)}
              titles={{
                max_title: ctx?.data?.delete_cartoon_alert_title[ctx.language],
              }}
              logOutHandler={() => {
                setDeleted(false);
                deleteCartoon(cartoonId, index);
              }}
            />
             <PlaylistModal
              show={showPlaylist}
              onHide={() => setShowPlaylist(false)}
              id={cartoonId}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonCard;