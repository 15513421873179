import React from 'react';
import styles from './ConfirmationButtons.module.css';

const ConfirmationButtons = (props) => {
  const { title, confirmationHandler } = props;

  return (
    <div className={`${styles.confirm_logOut_container} ${styles.button_container} d-flex justify-content-center w-100 flex-wrap`}>
      {Object.keys(title).map((item, index) => {
        return (
          <button
            className={`${styles[item]} ${index === 0 ? styles.cancelLogout : ''} border-0`}
            onClick={() => confirmationHandler(index === 1)}
            key={`button_${index}`}
          >
            {title[item]}
          </button>
        );
      })}
    </div>
  );
};

export default ConfirmationButtons;
