import { createSlice } from '@reduxjs/toolkit';

const initialState = {deleteUser:{}};

const deleteUserSlice = createSlice({
  name: 'deleteUser',
  initialState,
  reducers: {
    deleteUserHandler(state, actions) {
      state.deleteUser = actions.payload.deleteUser;
    },
  },
});

export const deleteUserAction = deleteUserSlice.actions;
export default deleteUserSlice;
