import React, { useEffect } from 'react';
import classes from './UserCategories.module.css';
import { useDispatch, useSelector } from 'react-redux';
import CategoryCard from '../../../components/card/categoryCard/CategoryCard';
import productCategories from '../../../store/category/ProductCategories';

const UserCategories = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const { editCategoryClick, onChangeaCategory, checkedData, hidden} = props;

  useEffect(() => {
    dispatch(productCategories());
  }, [dispatch]);

  return (
    <div className={`d-flex justify-content-center w-100 flex-wrap`}>
      <div className={`${classes.category_item_container} d-flex justify-content-start flex-wrap`}>
        {categories.map((category) => {
          return (
            <CategoryCard
              data={category}
              onChange={onChangeaCategory}
              hidden={hidden}
              onClick={editCategoryClick}
              key={category._id}
              className={`${classes.edit_category_container} w-100`}
              categories={checkedData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UserCategories;
