import React from 'react';
import styles from './Slide.module.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/spinner/Loader'
import SlideTitle from '../../../carouselComponents/slideTitle/SlideTitle';
import SlideImage from '../../../carouselComponents/slideImage/SlideImage';
import { LanguageContext } from '../../../../../context/LanguageContext';

const Slide = ({
  data: { url, title, bookTitle, bookAuthor, description },
  animation,
  id,
}) => {
  const navigate = useNavigate();

  if (!url) {
    return <Loader />;
  }
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            onClick={() => navigate(`/products/${id}`)}
            id='slideAnimation'
            className={`${styles.slide} ${animation ? styles.fade_in_animation : ''}`}
          >
            <SlideImage src={url ? url : ''} alt={title} />
            <SlideTitle
              title={ctx?.data?.last_added[ctx.language]}
              bookTitle={bookTitle[ctx.language]}
              bookAuthor={bookAuthor[ctx.language]}
              description={description[ctx.language]}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Slide;
