import React from 'react';
import classes from './Buttons.module.css';
import { LanguageContext } from '../../../context/LanguageContext';
import LoginByProvider from '../../modal/auth/loginByProvider/LoginByProvider';

const Buttons = (props) => {
  const { setShow, disabled, setDisabled, error, setError, isValid } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div className={`${classes.line_container} d-flex justify-content-center align-items-center w-100`}>
              <span className={`${classes.line_container_border} ${classes.font_size_20} d-flex align-items-center text-center`}/>
              <span className={`${classes.font_size_20} d-flex align-items-center text-center`} >{ctx.data?.or[ctx.language]}</span>
              <span className={`${classes.line_container_border} ${classes.font_size_20} d-flex align-items-center text-center`}/>
            </div>
            <div className={`${classes.gap_25} d-flex flex-wrap w-100 justify-content-center`}>
              <div
                className={`${classes.gap_25} d-flex justify-content-center flex-wrap`}
              >
                <LoginByProvider
                  isValid={isValid}
                  setShow={setShow}
                  error={error}
                  setError={setError}
                  disabled={disabled}
                  setDisabled={setDisabled}
                />
              </div>
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Buttons;
