import axios from 'axios';
import Cookies from 'js-cookie';
import { blogAction } from './BlogSlice';

const fetchBlog = (id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/blog/${id}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      dispatch(blogAction.blogHandler({'blog': data.data}));
    } catch (e) { }
  };
};

export default fetchBlog;