import { createSlice } from '@reduxjs/toolkit';

const initialState = {editEmail:{}};

const editEmailSlice = createSlice({
  name: 'editEmail',
  initialState,
  reducers: {
    editEmailHandler(state, actions) {
      state.editEmail = actions.payload.editEmail;
    },
  },
});

export const  editEmailAction = editEmailSlice.actions;
export default editEmailSlice;
