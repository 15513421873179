import axios from 'axios';
import Cookies from 'js-cookie';
import { watchLaterAction } from './WatchLaterSlice';

const watchLater = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/user/watchLater/all?limit=${limit ?? '*'}&page=${page ?? '*'}&type=web`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(watchLaterAction.watchLaterHandler({data}));
    } catch (e) {
      return e;
    }
  };
};

export default watchLater;
