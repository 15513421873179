import Cookies from 'js-cookie';
import UserData from './userData/UserData';
import { useDispatch } from 'react-redux';
import classes from './UserAccount.module.css';
import React, { useEffect, useState } from 'react';
import UserAgeGroup from './userAgeGroup/UserAgeGroup';
import DeleteUser from './userData/deleteUser/DeleteUser';
import { LanguageContext } from '../../context/LanguageContext';
import UserAccountTitle from './userAccountTitle/UserAccountTitle';
import EditCategory from './userCategories/editCategory/EditCategory';
import editUserData from '../../store/edit/editUserData/EditUserData';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const UserAccount = () => {
  const dispatch = useDispatch();
  const token = Cookies.get('auth_token');
  const [ageData, setAgeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [showPhone, setShowPhone] = useState(true);
  const [data, setData] = useState('')

  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_User_account')
    if (token) {
      dispatch(editUserData());
    }
  }, [dispatch, token]);

  const ageGroupData = (age) => {
    setAgeData(age);
  };
  
  const categoriesData = (category) => {
    setCategoryData(category);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.padding_50} d-flex w-100 justify-content-center flex-wrap`}
          >
            <div
              className={`${classes.gap_20} ${classes.user_data_item_width} d-flex justify-content-center flex-wrap`}
            >
              <UserAccountTitle
                title={ctx.data?.personal_data[ctx.language]}
                pageName={ctx.data?.my_account[ctx.language]}
              />
              <UserData
                showPhone={showPhone}
                setShowPhone={setShowPhone}
                setData={setData}
                data={data}
              />
              <UserAgeGroup
                userAgeGroup={ageGroupData}
                categoryData={categoryData}
                hideChangePhone={setShowPhone}
                setData={setData}
                data={data}
              />
              <EditCategory
                data={data}
                ageData={ageData}
                categoriesData={categoriesData}
                setData={setData}
                hideChangePhone={setShowPhone}
              />
              <DeleteUser />
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};
export default UserAccount;
