import { createSlice } from '@reduxjs/toolkit';

const initialState = { result: {userData: {},  token: '', error: null }};

const userExistenceSlice = createSlice({
  name: 'userExistence',
  initialState,
  reducers: {
    userExistenceHandler(state, actions) {
      state.result = actions.payload.result;
    },
  },
});

export const userExistenceAction = userExistenceSlice.actions;
export default userExistenceSlice;
