import axios from 'axios';
import { getUserDataAction } from './GetUserDataSlice';
import Cookies from 'js-cookie';

const getUserData = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/user/me`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const userData = await fetchData();
      return dispatch(
        getUserDataAction.userDataHandler({
          userData: { user: userData.data, status: userData.status },
        })
      );
    } catch (e) {
      if (e?.response?.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
      }
      return dispatch(
        getUserDataAction.userDataHandler({
          userData: { user: {}, status: e.response.status },
        })
      );
    }
  };
};

export default getUserData;
