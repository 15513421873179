import axios from 'axios';
import { bestsellersAction } from './BestsellersSlice';

const bestsellers = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/product/bestseller?limit=${limit}&page=${page}`;
      const response = await axios.get(url);
      return response.data;
    };
    try {
      const bestsellers = await fetchData();
      dispatch(bestsellersAction.bestsellersHandler({ bestsellers }));
    } catch (e) {
      return e;
    }
  };
};

export default bestsellers;
