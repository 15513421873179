import React from 'react';
import classes from './Spacing.module.css';
import SpacingItem from './spacingItem/SpacingItem.js';
import { LanguageContext } from '../../../../../../context/LanguageContext';

const Spacing = (props) => {
  const { prevToSettings, textSpacing, addSpacing} = props;

  const marginData = (names, lang) => {
    let arr = []
    if(names && lang) {
      for(let item in textSpacing) {
        arr.push({
          type: item,
          add: 'add',
          reduce: 'subtract',
          spacingText: names[item] && `${names[item][lang]?.charAt(0).toUpperCase()}${names[item][lang]?.slice(1)}`,
        })
      }    
    }
    return arr
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const data = marginData(ctx.data, ctx.language)
        return (
          <div>
          <div className={`${classes.advanced_container} d-flex align-items-center justify-content-center w-100`}>
            <div className={classes.spaceing_img_container} onClick={prevToSettings}>
              <img src='../../assets/readBook/prev.svg' alt='' />
            </div>
            <div>
              <span>{ctx.data?.advanced[ctx.language]}</span>
            </div>
          </div>
          <div className='d-flex justify-content-center w-100 flex-wrap'>
            <SpacingItem
              marginsData={data}
              addSpacing={addSpacing}
          />
          </div>
        </div>
        )
      }}
    </LanguageContext.Consumer>
  );
};

export default Spacing;
