import { createSlice } from '@reduxjs/toolkit';

const initialState = { tokenCredentials: { token: '', expairedAt: null, error: null }};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginHandler(state, actions) {
      state.tokenCredentials = actions.payload.tokenCredentials;
    },
  },
});

export const loginAction = loginSlice.actions;
export default loginSlice;
