import { textSpaces } from './textSpaces'
import { operationsWithValues } from '../operationsWithValues/operationsWithValues'

const actions = [ {type: 'add', func: operationsWithValues.addValue}, {type: 'subtract', func: operationsWithValues.subtractValue}]

const numbersActions = (title, type, textSpacing) => {
    return actions.find((item) => item.type === type)?.func(textSpacing[title]?.value, textSpacing[title]?.size, textSpacing[title]?.limit) ?? null
}

export const pageForReading = {
    changeTextSpace(title, margin, space, iframeItem, text, data, value, key) {
        if (key === value) {
            margin[0].style.paddingLeft = `${space}px`;
            margin[0].style.paddingRight = `${space}px`;
          } else if (iframeItem && iframeItem.length > 0) {
            for (const a of text) {
              a.style[data[title].key] = `${space}px`;
            }
          }
    },

    getRendition(rendition, data) {
        const {renditionRef, size, highlights, setSelections} = data
        renditionRef.current = rendition;
        renditionRef.current.themes.fontSize(`${size}%`);
        renditionRef.current.themes.default({'::selection': { background: '#cff3f4'}});
        highlights?.length > 0 && highlights.forEach((highlights) => {
            renditionRef.current.annotations.add(
              'highlight',
              highlights.cfiRange,
              {},
              null,
              'hl',
              {
                fill: '#09BCC1',
                'fill-opacity': '0.5',
                'mix-blend-mode': 'multiply',
              }
            );
          });
          setSelections(highlights ? highlights : []);
          rendition.hooks.content.register((contents) => {
          const body = contents.window.document.querySelector('body')
          if (body && window.sessionStorage.getItem('brightness') === 'darck') {
            const spans = body.getElementsByTagName('span')
            const bdis =  body.getElementsByTagName('bdi')
            const paragraphs =  body.getElementsByTagName('p')
            const arr = Array.from(spans).length > 0 ? spans : Array.from(bdis).length > 0 ? bdis : Array.from(paragraphs).length > 0 ? paragraphs : []
            if (Array.from(arr).length > 0) {
              for (let item of arr) { 
                item.classList.remove('c1')
                item.style.color = '#fff'
              }
            }
          }
        })
      },

      changeHighlights(data) {
        const  {renditionRef, setSelections, selections, highlights, setIsHighlights} = data
        if (renditionRef?.current) {
            const setRenderSelection = (cfiRange, contents) => {
              const renditionRefText = renditionRef.current.getRange(cfiRange)?.toString();
              setSelections(selections.concat({ text: renditionRefText, cfiRange }));
              if (highlights && highlights.length > 0) {
                const highlightsText = highlights.filter(
                  (item) => item.text === renditionRefText
                );
                if (highlightsText.length > 0) {
                  const filteredHighlights = highlights.filter(
                    (item) => item.text !== renditionRefText
                  );
                  localStorage.setItem(
                    'highlights',
                    JSON.stringify(filteredHighlights)
                  );
                  window.location.reload();
                } else {
                  setIsHighlights(false);
                  localStorage.setItem(
                    'highlights',
                    JSON.stringify(
                      selections.concat({ text: renditionRefText, cfiRange })
                    )
                  );
                }
              } else {
                localStorage.setItem(
                  'highlights',
                  JSON.stringify(
                    selections.concat({ text: renditionRefText, cfiRange })
                  )
                );
              }
              renditionRef.current.annotations.add(
                'highlight',
                cfiRange,
                {},
                null,
                'hl',
                {
                  fill: '#09BCC1',
                  'fill-opacity': '0.5',
                  'mix-blend-mode': 'multiply',
                }
              );
              contents.window.getSelection().removeAllRanges();
            };
      
            renditionRef.current.on('selected', setRenderSelection);
            return () => {
              renditionRef.current.off('selected', setRenderSelection);
            };
          }
        },

        async lastPageHandler(epubcifi, data) {
            const {renditionRef, tocRef,  scroll, filteredData, setPage, setLocation, setMarkAsRead} = data
            const location = renditionRef.current.location?.start.displayed
            const epubContainer = document.getElementsByClassName('epub-container')
            const bottom = epubContainer[0]?.scrollHeight - epubContainer[0]?.scrollTop === epubContainer[0]?.clientHeight
            const end = renditionRef.current.location.atEnd
          
             if (renditionRef.current && tocRef.current && epubContainer && epubContainer[0]) {
                if (((scroll.flow === 'scrolled' && bottom && end) || (scroll.flow !== 'scrolled' && end)) && !filteredData ) {
                  setMarkAsRead(true)
                } else {
                  setMarkAsRead(false)
             }
              setPage({page: location.page, total:location.total})
            }
            setLocation(epubcifi);
          },

          lightingModeHandler(type, setBrightnessMode) {
    let background = null;
    let textColor = null;
    setBrightnessMode(type);
    document.body.style.background = background;
    window.sessionStorage.setItem('brightness', type);
    const iframeItem = document.getElementsByTagName('iframe');
    if (iframeItem && iframeItem.length > 0) {
      const pharagraphs =
        iframeItem[0].contentWindow.document.querySelectorAll('P');
      const spans =
        iframeItem[0].contentWindow.document.querySelectorAll('span');
      if (type === 'sepia') {
        background = '#F4EEE0';
        textColor = 'black';
        document.body.style.background = '#F4EEE0';
      } else if (type === 'darck') {
        background = '#0000';
        textColor = '#ffff';
        document.body.style.background = 'black';
      } else {
        background = '#ffff';
        textColor = 'black';
        document.body.style.background = 'ffff';
      }
      if (pharagraphs?.length > 0) {
        for (const pharagraph of pharagraphs) {
          pharagraph.style.background = background;
          pharagraph.style.color = textColor;
        }
      }
      if (spans?.length > 0) {
        for (const span of spans) {
          span.style.background = background;
          span.style.color = textColor;
        }
      }
    }
  },

  scrollHandler(e) {
    sessionStorage.setItem('checked', e.target.checked);
    if (e.target.checked) {
      sessionStorage.setItem(
        'isScroll',
        JSON.stringify({ flow: 'scrolled', manager: 'continuous' })
      );
    } else {
      sessionStorage.setItem(
        'isScroll',
        JSON.stringify({ flow: 'auto', manager: 'continuous' })
      );
    }
    window.location.reload();
  },

   defoldData: {
    page_margins: {
      value: 0,
      size: 8,
      limit: { max: 80, min: 0 },
      key: 'pageMargins',
    },
    word_spacing: {
      value: 5,
      size: 2,
      limit: { max: 31, min: 5 },
      key: 'wordSpacing',
    },
    letter_spacing: {
      value: 0,
      size: 0.5,
      limit: { max: 10, min: 0 },
      key: 'letterSpacing',
    },
    line_height: {
      value: 20,
      size: 0.5,
      limit: { max: 30, min: 20 },
      key: 'lineHeight',
    },
  },

  spaceingHandler(title, type, textSpacing, setTextSpacing, productId) {
    const mutableData = { ...textSpacing };
    const iframeItem = document.getElementsByTagName('iframe');
    const margin = document.getElementsByClassName('epub-container');
    const text = iframeItem[0]?.contentWindow.document.querySelectorAll('P');
    const space = numbersActions(title, type, textSpacing)
    // let data = JSON.parse(localStorage.getItem('spaceingData')) ?? []
    if (margin && text) {
      textSpaces.changeTextSpace(title, margin, space, iframeItem, text, textSpacing, 'page_margins', title)
      mutableData[title].value = space ? space : textSpacing[title].value && textSpacing[title].value;
      // const filteredData = data.filter((item) => item.productId !== productId.id)
      // localStorage.setItem('spaceingData', JSON.stringify([...filteredData, {productId:productId.id,  data:mutableData}]))
      setTextSpacing(mutableData);
    }
},

styles(ReactReaderStyle, check, brightnessMode) {
  let ownStyles = {
    ...ReactReaderStyle,
    tocAreaButton: {
      ...ReactReaderStyle.tocAreaButton,
      color: '#030728',
    },
    prev: {
      ...ReactReaderStyle.prev,
      padding: '0 0 0 30px',
      display: check === 'true' || check === null ? 'none' : 'block',
    },
    next: {
      ...ReactReaderStyle.next,
      padding: '0 30px 0 0',
      display: check === 'true' || check === null ? 'none' : 'block',
    },
    readerArea: {
      ...ReactReaderStyle.readerArea,
      background:
        brightnessMode === 'sepia'
          ? '#F4EEE0'
          : brightnessMode === 'darck'
          ? 'black'
          : '#ffffff',
      color: '#ffffff',
    },
  };

  return ownStyles;
}
}