import React from 'react';
import Container from './components/Container';
import DeveloperInformation from './components/DeveloperInformation';
import FooterItems from './components/FooterItems';

const Footer = () => {
  return (
    <Container>
      <FooterItems />
      <DeveloperInformation />
    </Container>
  );
};

export default Footer;
