import React from 'react';
import classes from './SuggestionsItem.module.css';

const SuggestionsItem = (props) => {
  const { imgSuggestions, textSuggestions } = props;

  return (
    <div
      className={`${classes.suggestions_data_container} d-flex justify-content-center flex-wrap`}
    >
      <div
        className={`${classes.vinni_img_container} ${classes.suggestionContainer} flex-wrap`}
      >
        <img src={imgSuggestions} alt='' />
      </div>
      <div className='text-center'>
        <span>{textSuggestions}</span>
      </div>
    </div>
  );
};

export default SuggestionsItem;
