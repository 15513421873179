import axios from 'axios';
import Cookies from 'js-cookie';
import { deleteCartoonAction } from './DeleteCartoonSlice';

const deleteCartoon = (cartoonId) => {
    const token = Cookies.get('auth_token')
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {cartoonId};
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/delete`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(deleteCartoonAction.deleteCartoonHandler({ data }));
    } catch (e) {}
  };
};

export default deleteCartoon;
