import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, error: null };

const searchCartoonSlice = createSlice({
  name: 'searchCartoon',
  initialState,
  reducers: {
    searchCartoonHandler(state, action) {
      state.data = action.payload.data;
    },
  },
});
export const searchCartoonAction = searchCartoonSlice.actions;
export default searchCartoonSlice;
