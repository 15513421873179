import { useLocation, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './PlaylistModal.module.css';
import React, { useEffect, useState } from 'react';
import Button from '../../buttons/authButton/Button';
import Modals from '../../../components/modal/Modals';
import { useDispatch, useSelector } from 'react-redux';
import AddNewPlayList from './addNewPlayList/AddNewPlayList';
import { LanguageContext } from '../../../context/LanguageContext';
import fetchAddPlayList from '../../../store/cartoons/addPlayList/AddPlayList';
import fetchAllPlayLists from '../../../store/cartoons/allPlaylist/FetchAllPlayLists';
import updatePlaylists from '../../../store/cartoons/updatePlaylists/UpdatePlaylists';
import Cookies from 'js-cookie';

const PlaylistModal = (props) => {
  const page = 1
  
  const dispatch = useDispatch();
  const cartoonId = useParams();
  const location = useLocation()
  const {onHide, show, id} = props;
  const [title, setTitle] = useState('');
  const token = Cookies.get('auth_token')
  const [update, setUpdate] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [createNewPlaylist, setCreateNewPlaylist] = useState(false);
  const allPlayLists = useSelector((state) => state.allPlayLists.allPlayLists);
  const [limit, setLimit] = useState(process.env.REACT_APP_PLAYLIST_MODAL_LIMIT);
  const [playListId, setPlayListId] = useState({removeCartoonId:[], playlistId:[]})

  useEffect(() => {
    if (update && token) dispatch(fetchAllPlayLists(page, limit));
    setUpdate(false);
  }, [dispatch, page, limit, update, location, token]);

  const addNewPlaylist = async () => {
    await dispatch(fetchAddPlayList(title, [cartoonId.id ?? id]));
    setCreateNewPlaylist(false);
    setUpdate(true);
  };

  const save = async () => {
    setDisableSaveButton(true);
    onHide();
    if (playListId.removeCartoonId.length > 0) {
      await dispatch(updatePlaylists({playlistId: playListId.removeCartoonId, removeCartoonId: [cartoonId.id ?? id]}));
    }
    if (playListId.playlistId.length > 0) {
      await dispatch(updatePlaylists({playlistId: playListId.playlistId, cartoonId: [cartoonId.id ?? id]}));
    }
  };

  const handleCancelClick = () => {
    if (createNewPlaylist && playlists.length === 0) {
      onHide();
      setPlayListId({removeCartoonId:[], playlistId:[]})
    } else {
      setCreateNewPlaylist(false);
    }
  };

  const selectIsChecked = (cartoons, cartoonsId) => {
    return (
      (cartoons?.includes(cartoonId.id ?? id) && (playListId.removeCartoonId.length === 0 || !playListId.removeCartoonId.includes(cartoonsId))) ||
      playListId.playlistId?.includes(cartoonsId)
    );
  };

  const updateExsistingPlaylists = (index) => event => {
    const mutablePlaylists = [...playlists];
    const playlist = {...playlists[index]};
    let cartoons = [...playlists[index].cartoons];
    let removed = playListId.removeCartoonId?.filter((item) => item !== playlist._id);
    let added = playListId.playlistId?.filter((item) => item !== playlist._id);

    if (!event.target.checked) {
      cartoons = cartoons.filter((element) => element !== cartoonId.id ?? id);
      const playlistsToRemove = [...removed, playlist._id];
      setPlayListId({removeCartoonId:playlistsToRemove, playlistId:added})
      setDisableSaveButton(playlistsToRemove.length === 0 && added.length === 0);
    } else if (event.target.checked) {
      const playlistsToAdd = [...added, playlist._id];
      let uniqueChars = [...new Set(playlistsToAdd)];
      setPlayListId({removeCartoonId:removed, playlistId:uniqueChars})
      setDisableSaveButton(removed.length === 0 && uniqueChars.length === 0);
    } 
    playlist.cartoons = cartoons;
    mutablePlaylists[index] = playlist;
  };

  useEffect(() => {
    if(allPlayLists.allPlayLists.length > 0) {
      setPlaylists(allPlayLists.allPlayLists)
    }
  }, [allPlayLists])

  const playlistsAction = (type) => {
    if(type === 'cancel') {handleCancelClick()} else addNewPlaylist()
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Modals
            show={show}
            onHide={onHide}
            playlist={`${createNewPlaylist}`}
            text={ctx.data?.new[ctx.language]}
            addToPlaylist={() => setCreateNewPlaylist(true)}
          >
            {createNewPlaylist || playlists.length === 0 ? (
              <div className={`${classes.add_new} d-flex flex-column`}>
                <input
                  type='text'
                  onChange={(e) => {setTitle(e.target.value);  e.preventDefault(); e.stopPropagation();}}
                  id='playlistName'
                  className={`${classes.playlist_name_input} ${classes.add_to_playlist} form-control m-auto`}
                  aria-describedby='emailHelp'
                  placeholder={
                    ctx.data?.enter_playlist_name
                      ? ctx.data?.enter_playlist_name[ctx.language]
                      : 'Enter playlist name'
                  }
                />
                <div className={`d-flex justify-content-end ${classes.gap_10}`}>
                  {['cancel', 'save'].map((item, index) => {
                    return (
                      <Button
                      key={`new-playlist_${item}`}
                        onClick={() => playlistsAction(item)}
                        buttonStyle={`${classes[item]} ${
                          index === 0 ? 'bg-transparent' : ''
                        } ${item === 'save' ? classes.save_button : ''} border-0`}
                        text={ctx.data && ctx.data[item][ctx.language]}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <AddNewPlayList
                playlists={playlists}
                updateExsistingPlaylists={updateExsistingPlaylists}
                selectIsChecked={selectIsChecked}
                allPlayLists={allPlayLists}
                setLimit={setLimit}
                limit={limit}
                defaultLimit={process.env.REACT_APP_PLAYLIST_MODAL}
                setUpdate={setUpdate}
                onHide={onHide}
                save={save}
                disableSaveButton={disableSaveButton}
              />
            )}
          </Modals>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlaylistModal;
