import React from 'react';
import classes from './BooksInformation.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import RatingSingleProduct from '../ratingProduct/RatingSingleProduct';
import { constantData } from '../../../../utils/helpers/constantData';

const BooksInformation = (props) => {
  const { data, id, styles } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.data_container} ${styles} flex-wrap justify-content-center`}
            id={id}
          >
            <div
              className={`${classes.book_parametrs} d-flex flex-wrap justify-content-center`}
            >
              <RatingSingleProduct data={data} />
              <div className='d-flex flex-wrap justify-content-center w-100'>
                <span
                  className={`${classes.title_container} ${classes.font_size_20} w-100 text-center`}
                >
                  {ctx.data?.published[ctx.language]}
                </span>
                {data && (
                  <span className={classes.font_size_20}>{data.released}</span>
                )}
              </div>
              <div className='d-flex flex-wrap justify-content-center w-100'>
                <span
                  className={`${classes.title_container} ${classes.font_size_20} w-100 text-center`}
                >
                  {ctx.data?.language[ctx.language]}
                </span>
                <span className={classes.font_size_20}>
                  {ctx.data &&
                    ctx.data[constantData.productLanguage[data.language]][ctx.language]}
                </span>
              </div>
              <div className='d-flex flex-wrap justify-content-center w-100'>
                <span
                  className={`${classes.title_container} ${classes.font_size_20} w-100 text-center`}
                >
                  {ctx.data?.age[ctx.language]}
                </span>
                <span className={classes.font_size_20}>
                  {data?.ageGroup?.map((age) => age)}
                </span>
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default BooksInformation;
