import React, { useEffect } from 'react';
import { signInWithPopup } from 'firebase/auth';
import login from '../../../../store/login/Login';
import classes from './LoginByProvider.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../buttons/authButton/Button';
import {constantData} from '../../../../utils/helpers/constantData';
import { LanguageContext } from '../../../../context/LanguageContext';
import { auth, providerHandler } from '../../../../firebase/FirebaseConfig';

const LoginByProvider = (props) => {
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.login.tokenCredentials);
  const { setShow, error, setError, disabled, setDisabled, isValid} = props;

  const signInHandler = async (type) => {
    setDisabled(['provider']);
    const provider = providerHandler(type);
    if (provider) {
      setTimeout(() => {
        setDisabled(isValid ? [] : ['signIn']);
      }, 3000);
    }
    const result = await signInWithPopup(auth, provider);
    try {
      const response = await dispatch(login(result.user.accessToken));
      if (response && response.token?.length > 0) {
        window.location.reload();
      } else {
        throw new Error();
      }
    } catch (error) {
      setShow(false);
      setError({ status: true });
    }
    return () => clearTimeout();
  };

  const providersHandler = (names, lang) => {
    let data = [];
    if (names && lang) {
      constantData.providers.forEach((item) => {
        data.push({
          title: names[`signup_${item}`][lang],
          image: `../../assets/icons/webSite/${
            item === 'google' && disabled ? 'disabledGoogle' : item
          }.svg`,
          id: item,
        });
      });
    }
    return data;
  };

  useEffect(() => {
    if (error?.status && loginResponse) {
      setError({ status: true, message: loginResponse.data.Error });
    }
  }, [loginResponse, error, setError]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const data = providersHandler(ctx.data, ctx.language);
        return (
          <>
            {data.map((item) => {
              return (
                <Button
                  key={item.id}
                  onClick={() => signInHandler(item.id)}
                  buttonStyle={`${classes[item.id]} ${classes.button_style} ${classes.padding_20} d-flex align-items-center w-100 justify-content-center overflow-hidden border-0`}
                  textStyle={`${disabled ? classes.color_white : ''} w-100 text-center font-weight-normal`}
                  divStyle={`${classes.button_style} d-flex align-items-center w-100 justify-content-center overflow-hidden border-0`}
                  text={item.title}
                  image={item.image}
                  disabled={disabled}
                  imgStyle={`${classes.provider_images} d-flex`}
                  id={item.id}
                />
              );
            })}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default LoginByProvider;
