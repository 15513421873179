export const footerItemData = {
  links() {
    const links = {
      say_hello: ['tel:+37455701078', `mailto:${process.env.REACT_APP_EMAIL}`],
      socials: [
        'https://www.facebook.com/profile.php?id=100083345526730',
        'https://www.instagram.com/vinni__app/',
        'https://apps.apple.com/am/app/vinni/id1569213680',
        'https://play.google.com/store/apps/details?id=am.dynamic.vinni',
      ],
      menus: ['/products', '/authors', '/cartoons', '/blogs'],
    };
    return links;
  },

  values(name, lang, className) {
    const values = name && {
      address: `${name['address_company'][lang]}`,
      say_hello: ['+374 55 701 078', `${process.env.REACT_APP_EMAIL}`],
      socials: [
        'Facebook',
        'Instagram',
        <img className={className} src='../assets/footer/app.png' alt='' />,
        <img className={className} src='../assets/footer/google.png' alt='' />,
      ],
      menus: [
        name['books'][lang],
        name['authors'][lang],
        name['cartoons'][lang],
        name['blog'][lang],
      ],
    };
    return values;
  },
};
