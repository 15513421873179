import React, { Fragment, useEffect } from 'react';

import styles from './AboutUs.module.css';
import PageTitle from '../../components/pageTitle/PageTitle';
import AboutCompanyPart from './AboutCompanyPart';
import { LanguageContext } from '../../context/LanguageContext';
import WelcomeText from './WelcomeText';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';
import { constantData } from '../../utils/helpers/constantData';

const AboutUs = () => {
  useEffect(() => {
    logEvent(firebaseAnalytics, 'Web_About_Vinni')
  }, [])
  const aboutDataHandler = (data) => {
    const baseUrl = '/assets/about/';
    let dataArray = [];
    constantData.necessaryKeys.forEach((item) => {
      dataArray.push({
        title: data[item.title],
        description: data[item.desc],
        imageUrl: `${baseUrl}${item.title}.png`,
        className: item.title,
      });
    });
    return dataArray;
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        if (ctx?.data && Object.keys(ctx?.data).length > 0) {
          const pageData = aboutDataHandler(ctx.data);
          return (
            <Fragment>
              <PageTitle title={ctx.data['who_we_are'][ctx.language]} />
              <div className={`${styles.max_width_1200} m-auto`}>
                <WelcomeText
                  title={ctx.data['welcome_to_vinni'][ctx.language]}
                  description={ctx.data['about_vinni'][ctx.language]}
                />
                <div
                  className={`${styles.about_company_container} ${styles.max_width_1200} m-auto d-flex flex-wrap justify-content-center px-5 mt-5`}
                >
                  {pageData.map((item) => {
                    return (
                      <AboutCompanyPart
                        key={item.imageUrl}
                        imageUrl={item.imageUrl}
                        title={item.title[ctx.language]}
                        description={item.description[ctx.language]}
                        className={item.className}
                      />
                    );
                  })}
                </div>
                <div className='mt-3 d-flex flex-wrap'>
                  <WelcomeText
                    title={ctx.data['join_us'][ctx.language]}
                    description={ctx.data['join_us_text'][ctx.language]}
                  />
                  <WelcomeText
                    title={ctx.data['contact_us'][ctx.language]}
                    description={ctx.data['contact_text'][ctx.language]}
                  >
                    <p className='h6 dark-color'>
                      {ctx.data['thanks_text'][ctx.language]}
                    </p>
                    <p className='h6 dark-color'>
                      {ctx.data['happy_time'][ctx.language]}
                    </p>
                  </WelcomeText>
                </div>
              </div>
            </Fragment>
          );
        }
      }}
    </LanguageContext.Consumer>
  );
};

export default AboutUs;
