import React from 'react';
import classes from './Title.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import { nanoid } from 'nanoid';

const Title = (props) => {
  const { titles, data, myBook } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.cards_container} ${
              !myBook ? classes.padding_20 : ''
            } w-100 flex-wrap d-block overflow-hidden w-100`}
          >
            {titles.map((item, index) => {
              return (
                <React.Fragment key={`${item}_${nanoid()}`}>
                  <span className={`${classes.card_item_rating_text} w-100`}>
                    {data[item][ctx.language]}
                  </span>{' '}
                  {index === 0 && titles.length > 1 && <br />}
                </React.Fragment>
              );
            })}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Title;
