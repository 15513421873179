import classes from './ReadMore.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const ReadMore = (props) => {
  const { data, className, description, read_more_container, buttonStyle} = props;
  const [isDescription, setIsDescription] = useState(false);
  const descriptionRef = useRef(null)
  const [isMore, setIsMore] = useState();

  const readMoreHandler = () => {
    const description = document.getElementById('description');
    if (
      description.scrollHeight >= description.clientHeight &&
      !isDescription && (isMore || isMore === undefined)
    ) {
      setIsDescription(true);
      setIsMore(false);
    } else if (
      description.scrollHeight > description.clientHeight &&
      isDescription && !isMore
    ) {
      setIsDescription(false);
      setIsMore(true);
    }  else if (
      description.scrollHeight === description.clientHeight &&
      isDescription && !isMore
    ) {
      setIsDescription(false);
      setIsMore(true);
    } 
  };

  const updateDimensions = useCallback(() => {
    if (descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight) {
      console.log('if')
      setIsDescription(false);
      setIsMore(true);
    } else {
      console.log('else')
      setIsDescription(false);
      setIsMore(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    if (descriptionRef.current) {
      updateDimensions()
    }

  }, [updateDimensions])
  
  console.log(descriptionRef.current?.scrollHeight, descriptionRef.current?.clientHeight)
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div>
            <span
            ref={descriptionRef}
              className={`${!isDescription ? `${description} w-100 overflow-hidden` : `${read_more_container} w-100 overflow-hidden`}`}
              onClick={readMoreHandler}
              id='description'
            >
              {data[ctx.language]}
            </span>
            <div className={buttonStyle}>
            {(isMore !== undefined ? isMore : descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight) ? (
              <button className={`${classes.read_more} ${className} border-0 bg-transparent`} onClick={readMoreHandler}>
                {ctx.data?.more[ctx.language]}
              </button>
            ) : null}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ReadMore;
