import React from 'react';
import classes from './BlogCard.module.css';

const Blog = (props) => {
  const { description, imgUrl, needReverse } = props;
  const blog = description?.replaceAll('\n', '\n\n');
  return (
    <div
      className={
        needReverse
          ? `${classes.content} ${classes.reverse} d-flex flex-wrap justify-content-between w-100 align-items-center`
          : `${classes.content} d-flex flex-wrap justify-content-between w-100 align-items-center`
      }
    >
      <h3 className='w-100'> A Letter From Abraham Lincoln To His Son's Teacher </h3>
      <p className={`${classes.description} w-100 mw-100 ml4`}>
        My son starts school today. It is all going to be strange and new to him
        for a while and I wish you would treat him gently. It is an adventure
        that might take him across continents. All adventures that probably
        include wars, tragedy and sorrow. To live this life will require faith,
        love and courage.
      </p>
      <div className='w-100 d-flex'>
        <img
          className='mw-100 w-100'
          src={`${process.env.REACT_APP_DEV_URI}/${imgUrl}`}
          alt='blog'
        />
      </div>
      <p className={`${classes.description} w-100 mw-100 ml4`}>{blog}</p>
    </div>
  );
};

export default Blog;
