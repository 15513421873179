import axios from 'axios';
import Cookies from 'js-cookie';
import { deletePlaylistAction } from './DeletePlaylistSlice';

const deletePlayList = (
  playlistId
) => {
  return async (dispatch) => {
    const body = { playlistId };
    const auth = `Bearer ${Cookies.get('auth_token')}`;
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist/delete`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json', Authorization: auth },
      });
      return response;
    };
    try {
      const deletePlaylist = await fetchData();
      dispatch(
        deletePlaylistAction.deletePlaylistHandler({
          deletePlaylist,
        })
      );
    } catch (e) {
      return e;
    }
  };
};

export default deletePlayList;
