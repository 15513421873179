import axios from 'axios';
import Cookies from 'js-cookie';
import { searchCartoonAction } from './SearchCartoonSlice';

const searchCartoon = (data, limit, page ) => {
  const token = Cookies.get('auth_token');
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        searchText: typeof data === 'object' ? data.searchText :  data ? data : '',
        page:page ?? '*',
        limit:limit ?? '*',
        ageGroup: data.ageGroup?.length > 0 ? data?.ageGroup : null,
        categories: data.categories?.length > 0 ? data?.categories : null,
        userId: data._id,
      };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/search`;
      const response = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(searchCartoonAction.searchCartoonHandler( {data} ));
    } catch (e) {
      return dispatch(
        searchCartoonAction.searchCartoonHandler({ error: e.response?.status })
      );
    }
  };
};

export default searchCartoon;
