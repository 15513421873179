import React from 'react';

const Checkbox = (props) => {
  const {labelClassName, name,img, hidden, value, id, onChange, checkedData, title, divClassName, onMouseEnter, onMouseLeave, type} = props
  
  return (
    <div className={`${!divClassName ? 'form-check' : ''} ${divClassName ? divClassName : ''} checkboxContainer`} onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}> 
        <input
          className='form-check-input boxShedow_none checkboxContainer'
          type={type}
          value={value}
          id={id}
          name={name}
          hidden={hidden}
          onChange={onChange}
          checked={checkedData}
        />
        <label htmlFor={id} className={`form-check-label checkboxContainer ${labelClassName ?? ''}`}>
          {title}
          {img && <img src={img} alt='' />}
        </label>
    </div>
  );
};

export default Checkbox;
