import React from 'react';
import classes from './Playback.module.css';
import { constantData } from '../../../utils/helpers/constantData';

const Playback = (props) => {
  const { showPlayBackMenu, playBack, changePlayBack, normal } = props;

  return (
    <div id='playBack' className={`${classes.play_back_container} position-absolute overflow-auto`}>
      <div
        className={`d-flex align-items-center ${classes.play_back}`}
        onClick={() => (event) => {
          showPlayBackMenu(event, false);
        }}
      >
        <img
          src='../../../assets/video/back.svg'
          alt='back'
          className={`${classes.fill_white} ${classes.back} object-fit-cover`}
          id='back'
        />
        <p className={classes.cursor_pointer}>{playBack}</p>
      </div>
      {constantData.playback.map((item, index) => {
        return (
          <p
            key={`playback_${index}`}
            className={classes.cursor_pointer}
            onClick={(event) => {
              changePlayBack(event, index);
            }}
          >
            {index !== 3 ? item : normal}
          </p>
        );
      })}
    </div>
  );
};

export default Playback;
