import React from 'react';
import classes from './CategoriesItem.module.css';
import { Link } from 'react-router-dom';

const CategoriesItem = (props) => {
  const { categories, categoryName, categoriesItemContainer, categoriesDataContainer, categoryImg, categoriesItem, children } = props;

  const categoryhandler = () => {
    if (categories) {
      const category = categories.find((category) => category.name.en === categoryName);
      let existingFilters = {}
      existingFilters.categories = [category._id];
      return {'checkedData': JSON.stringify(existingFilters)}
    }
    return ''
  }

  return (
    <Link
      to={`/products`}
      state={categoryhandler()}
      className={`${categoriesItemContainer} ${categoriesDataContainer}`}
    >
      <img src={categoryImg} className={`${classes.category_image} w-100 h-100`} alt='' />
      <div className={`${categoriesItem} ${classes.position_container} d-flex`}>
        {children}
      </div>
    </Link>
  );
};

export default CategoriesItem;
