import classes from './Blog.module.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/spinner/Loader';
import fetchBlog from '../../../store/blog/blog/FetchBlog';
import { LanguageContext } from '../../../context/LanguageContext';
import BlogCard from '../../../components/card/blogs/blog/BlogCard';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';

const Blog = () => {
  const dispatch = useDispatch();
  const id = useParams();
  const blog = useSelector((state) => state.blog.blog);
  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    if (id.id) {
      logEvent(firebaseAnalytics, 'Web_Blog_page');
      dispatch(fetchBlog(id.id));
    }
  }, [dispatch, id.id]);

  useEffect(() => {
    setBlogData(blog);
  }, [blog]);

  if (!blogData || !blogData.title) return <Loader />;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={classes.blog}>
            <p
              className={`${classes.title} ${classes.bg} d-flex justify-content-center font-weight-bold mt-0`}
            >
              {ctx.data['blog'][ctx.language]}{' '}
            </p>
            <div className='col-12 col-md-10 col-lg-8 px-3 mx-auto'>
              <div className=' d-flex justify-content-center'>
                <BlogCard
                  description={blogData.description[ctx.language]}
                  imgUrl={blogData.image}
                />
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Blog;
