import { createSlice } from '@reduxjs/toolkit';

const initialState = {cartoonRating: {data: {}, status: null}};

const cartoonRatingSlice = createSlice({
  name: 'cartoonRating',
  initialState,
  reducers: {
    cartoonRatingHandler(state, action) {
      state.cartoonRating = action.payload.cartoonRating;
    },
  },
});
export const cartoonRatingAction = cartoonRatingSlice.actions;
export default cartoonRatingSlice;