import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './MyBooksCard.module.css';
import Title from '../cardBestsellers/titles/Title';
import Button from '../../buttons/authButton/Button';
import RatingCards from '../cardBestsellers/rating/RatingCards';
import { LanguageContext } from '../../../context/LanguageContext';
import CategoryCards from '../cardBestsellers/category/CategoryCards';
import ConfirmationModal from '../../modal/confirmationModal/ConfirmationModal';

const MyBooksCard = (props) => {
  const navigate = useNavigate();
  const { data, categories, deleteBook} = props;
  const [removeData, setRemoveData] = useState(false);

  const deleteDataHandler = (e) => {
    setRemoveData(true);
    e.stopPropagation();
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div
              className={`${classes.my_books_card_item_container} ${removeData ? `${classes.background} bg-transparent` : ''} d-flex w-100 text-decoration-none`}
              onClick={() => !removeData && navigate(`/products/${data?._id}`)}
            >
              <div className={`${classes.my_books_data_container} d-flex align-items-center`}>
                <div className={classes.img_data_container}>
                  {data.image && data.image.imageURL && (
                    <img
                      className={'w-100 h-100'}
                      src={`${process.env.REACT_APP_DEV_URI}${data.image.imageURL}`}
                      alt=''
                    />
                  )}
                </div>
              </div>
              <div
                className={`${classes.max_width_220} justify-content-center align-items-center d-flex flex-wrap`}
              >
                <div className={`${classes.card_data_container} ${classes.gap_10} d-flex flex-wrap align-items-center justify-content-center w-100`}>
                  <Title  myBook={true} data={data} titles={['name', 'author']}/>
                  <RatingCards data={data && data} />
                  <CategoryCards
                    data={data && data}
                    categories={categories}
                    category={true}
                  />
                </div>
                <Button divStyle={`${classes.delete_container} d-flex w-100 justify-content-end`} buttonStyle={`border-0 justify-content-end`} text={ctx?.data?.popup_delete_button[ctx.language]} textClick={deleteDataHandler}/>
              </div>
            </div>
            <ConfirmationModal
              show={removeData}
              onHide={() => setRemoveData(false)}
              logOutHandler={deleteBook}
              titles={{
                max_title:ctx?.data?.delete_product_text[ctx.language],
              }}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default MyBooksCard;
