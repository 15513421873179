import React from 'react';
import styles from './AboutUs.module.css';

const WelcomeText = (props) => {
  const { title, description, children } = props;
  return (
    <div
      className={`${styles.max_width_800} d-flex flex-wrap px-5 mt-5 align-content-start flex-column ${styles.flex_grow} justify-content-between`}
    >
      <div>
        <h2 className={`${styles.font_waight_600}`}>{title}</h2>
      </div>
      <div>
        <p className={`h6 dark-text`}>{description}</p>
      </div>
      <div>{children}</div>
      <div className={`${styles.text_container} w-100`} />
    </div>
  );
};

export default WelcomeText;
