import classes from './PlayListItem.module.css';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../../../context/LanguageContext';
import fetchCartoonCategories from '../../../../../store/cartoons/categories/FetchCartoonCategories';
import ConfirmationModal from '../../../../../components/modal/confirmationModal/ConfirmationModal';
import CategoryCards from '../../../../../components/card/cardBestsellers/category/CategoryCards';

const PlayListItem = (props) => {
  const params = useParams();
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false);
  const { authorName, src, title, data, deleteCartoonHandler } = props;
  const categories = useSelector((state) => state.cartoonCategories.cartoonCategories);

  useEffect(() => {
    dispatch(fetchCartoonCategories());
  }, [dispatch]);

  const cartoonDeleteHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShow(true)
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <Link
              to={`/cartoons/${params.id}/${data._id}`}
              state={{ play: true }}
              className={`${classes.max_height_100} ${classes.max_width_700} ${classes.gap_20} ${classes.playlist_item_container} ${classes.padding_20} ${classes.curs_pointer} d-flex align-items-center w-100`}
            >
              <div
                className={`${classes.max_height_80} ${classes.img_container} d-flex align-items-center`}
              >
                <img
                  src={`${process.env.REACT_APP_DEV_URI}${src}`}
                  alt=''
                  className={`${classes.height_80} ${classes.width_140}`}
                />
              </div>
              <div
                className={`${classes.max_height_100} ${classes.playlit_item_data}`}
              >
                <div className={'d-flex justify-content-between'}>
                  <span className={`${classes.title} overflow-hidden`}>
                    {title}
                  </span>
                  <img
                    src='../assets/delete/delete.svg'
                    alt=''
                    className={`${classes.delete_button}`}
                    onClick={cartoonDeleteHandler}
                  />
                </div>
                <div>
                  <span className={`${classes.title} overflow-hidden`}>
                    {authorName}
                  </span>
                </div>
                <CategoryCards
                  data={data}
                  categories={categories}
                  category={true}
                  className={classes.width_80}
                />
              </div>
            </Link>
            <ConfirmationModal
              show={isShow}
              cartoonPlaylist={true}
              onHide={() => setIsShow(false)}
              logOutHandler={deleteCartoonHandler}
              titles={{
                max_title: ctx.data?.are_you_delete_playlist[ctx.language],
              }}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlayListItem;