import Modals from '../../Modals';
import classes from './SignInErrorModal.module.css'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../../../../context/LanguageContext';
import SignInSettings from '../../auth/SignInSettings';

const SignInErrorModal = (props) => {
  const authToken = Cookies.get('auth_token');
  const {showErrorModal, onHide, setShowErrorModal} = props;
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(authToken);
  const [status, setStatus] = useState(false);

  const setIsShow = (isShown) => {
    setShow(isShown);
  };

  const tokenHandler = (userToken) => {
    !token && setToken(userToken);
  };

  const modalHideHandler = () => {
    onHide();
    if(show) {
      setShow(false)
    } 
  };

  const modalShowHandler = () => {
    if (status) {
      setShow(false);
    }
  };

  useEffect(() => {
    if(show) setShowErrorModal(false)
  }, [show, setShowErrorModal])
  
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <Modals show={showErrorModal} onHide={() => modalHideHandler()}>
              <div className='px-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src='../../assets/icons/error/signInError.svg'
                    alt='error'
                    className={classes.width_40_percent}
                  />
                </div>
                <div className={`mt-4 text-center ${classes.font_size_25} ${classes.color_black} ${classes.font_weight_600} ${classes.sign_in} text-center`}>
                  <span>{ctx.data?.please[ctx.language]}</span>
                  <span
                    className={`${classes.sign_in_span}`}
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    {ctx.data?.login[ctx.language]}
                  </span>
                  <span>{ctx.data?.get_access[ctx.language]}</span>
                </div>
              </div>
            </Modals>
            <SignInSettings
              country={localStorage.getItem('country')}
              showAuth={show}
              setShowAuth={setShow}
              onHide={modalHideHandler}
              onPress={modalShowHandler}
              setUserData={() => {
                setShow(false);
              }}
              setShow={setIsShow}
              setCookiesToken={tokenHandler}
            />

          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SignInErrorModal;
