import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 0,
};

const logOutSlice = createSlice({
  name: 'logOut',
  initialState,
  reducers: {
    logout(state, action) {
      state.status = action.payload.status;
    },
  },
});

export const logOutActions = logOutSlice.actions;
export default logOutSlice;
