export const editEmailFunctions = {
  isValidEmail(email) {
    const isValid = String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return isValid !== null;
  },

  clickHandler(id, setIndex) {
    setIndex(id);
  },

  checkOTPCode(confIsVisible, OTP, setActiveButton) {
    if (confIsVisible && OTP && !OTP.includes('') && OTP.length === 4) {
        setActiveButton(true);
      } else {
        setActiveButton(false);
      }
  },
  async verifyOTP(e, user, codeVerification, confIsVisible, setStatus, OTP, dispatch, codeValidation, setconfIsVisible, setCodeVerification, changeData, setUserData) {
    e.preventDefault()
    let mutableUser = { ...user };
    if (codeVerification && confIsVisible) {
      setStatus(false)
    }
    const verificationCode = +OTP.join('');
    const response = await dispatch(codeValidation(verificationCode));

   if(response.payload?.confirmValidation?.status === 200) {
     setconfIsVisible(false);
     setCodeVerification(true);
     setStatus(true)
     mutableUser.email = changeData['email'];
     setUserData(mutableUser);
   } else {
    setCodeVerification(false);
    setStatus(true)
   }
  }
};