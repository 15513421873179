import React from 'react';
import classes from './PhoneNumber.module.css'
import PhoneInput from 'react-phone-input-2';
import ErrorModal from '../../../../components/modal/error/ErrorModal';
import { LanguageContext } from '../../../../context/LanguageContext';
import { authValidation } from '../../../../utils/helpers/authValidation';
import ChangeDataButtons from '../../../../components/user/editData/changeDataButtons/ChangeDataButtons';

const PhoneNumber = (props) => {
  const {
    handleSubmit,
    user,
    setPhoneNumber,
    setInvalidPhoneNumber,
    cancel,
    invalidPhoneNumber,
    addId,
    onPhoneChange,
    phoneNumber
  } = props;

  const changePhoneNumber = (phone, country) => {
    onPhoneChange(phone);
    setPhoneNumber(phone);
    setInvalidPhoneNumber(false);
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className='phoneInputContainer'
              id={addId && '#changeInputHeight'}
            >
              <PhoneInput
              dropdownClass={classes.drop_down}
                className={`${classes.phone_input} d-flex`}
                buttonClass={classes.height_56}
                required
                country={!phoneNumber ? localStorage.getItem('country') : ''}
                onEnterKeyPress={(e) => handleSubmit(e)}
                value={user.phoneNumber}
                onChange={(phone, country) => changePhoneNumber(phone, country)}
                type='tel'
                aria-describedby='emailHelp'
                isValid={(inputNumber, country) =>
                  authValidation.isValidCountry(inputNumber, country)
                }
              />
              <ChangeDataButtons cancel={cancel} />
            </form>
            <ErrorModal
              img={'../assets/icons/vinni.svg'}
              show={invalidPhoneNumber}
              onHide={() => setInvalidPhoneNumber(false)}
              errorMessage={ctx.data?.invalid_current_phone[ctx.language]}
            />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PhoneNumber;
