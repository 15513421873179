import { createSlice } from '@reduxjs/toolkit';
const initialState = { allBlogs: {blogs:[], pageCount: 0}};

const allBlogsSlice = createSlice({
  name: 'allBlogs',
  initialState,
  reducers: {
    allBlogsHandler(state, action) {
      state.allBlogs = action.payload.allBlogs;
    },
  },
});
export const allBlogsAction = allBlogsSlice.actions;
export default allBlogsSlice;