import axios from 'axios';
import Cookies from 'js-cookie';
import { userExistenceAction } from './userExistenceSlice';

const userExistence = (token) => {
  return async (dispatch) => {
    const body = {
      tokenId: token,
    };
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/check/user/existence`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      });

      return response.data;
    };
    const result = await fetchData();
    try {
      if (result && Object.keys(result).length > 0) {
        Cookies.set('token', result.token.toString(), {maxAge: 'session'});
     };
      return dispatch(userExistenceAction.userExistenceHandler({ result }));

    } catch (e) {
        return dispatch(userExistenceAction.userExistenceHandler({ result }));
    }
  };
};

export default userExistence;
