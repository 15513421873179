import Cookies from 'js-cookie';
import classes from './PlayCartoon.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../../components/spinner/Loader';
import playlist from '../../../store/cartoons/playList/Playlist';
import { LanguageContext } from '../../../context/LanguageContext';
import VideoPlayer from '../../../components/videoPlayer/VideoPlayer';
import fetchCartoon from '../../../store/cartoons/cartoon/FetchCartoon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';
import CartoonPlayer from '../../../components/cartoonPlayer/CartoonPlayer';
import AboutCartoon from '../../../components/aboutCartoon/AboutCartoon';
import CartoonSearch from '../../../components/cartoonSearch/CartoonSearch';
import updatePlaylists from '../../../store/cartoons/updatePlaylists/UpdatePlaylists';
import fetchCartoonCategories from '../../../store/cartoons/categories/FetchCartoonCategories'
import SignInErrorModal from '../../../components/modal/error/signInErrorModal/SignInErrorModal';
import fetchRecommendedCartoons from '../../../store/cartoons/recommendedCartoons/FetchRecommendedCartoons';
import { operatinosWithPlaylist } from '../../../utils/helpers/removeFromPlaylist/removeFromPlaylist';
import { videoPlayer } from '../../../utils/helpers/videoPlayer';
import { constantData } from '../../../utils/helpers/constantData';

const PlayCartoon = () => {
  const limit = process.env.REACT_APP_PLAY_CARTOON_LIMIT;
  const location = useLocation()
  const dispatch = useDispatch();
  const cartoonId = useParams();
  const plylistDefaultHeight = 360
  const token = Cookies.get('auth_token');
  const cartoon = useSelector((state) => state.cartoonData.cartoonData);
  const singlePlayList = useSelector((state) => state.playList.playList);
  const recommendedCartoons = useSelector((state) => state.recommendedCartoons.recommendedCartoons);
  const [cartoonData, setCartoonData] = useState(cartoon.data);
  const [playList, setPlayList] = useState([]);
  const [page, setPage] = useState(1);
  const [recommendedPage, setRecommendedPage] = useState(1);
  const [updatePage, setUpdatePage] = useState(true);
  const [updateRecommendedPage, setUpdateRecommendedPage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCartoonId, setselecteCartoonId] = useState(cartoonId.id);
  const [showPlaylist, setShowPlaylist] = useState(cartoonId.playlistId ? true : false);
  const [recommendedCartoonsData, setRecommentedCartoonsData] = useState([]);
  const [recommended, setRecommented] = useState([]);
  const [showAddToPlayList, setShowAddToPlaylist] = useState(false);
  const [updatePlaylist, setUpdatePlaylist] = useState([]);
  const [nextCalled, setNextCalled] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState();
  const [isNormalScreen, setIsNormalScreen] = useState(true);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [showSaerchResult, setShowSearchResult] = useState(false);
  const [checkedCartoonId, setCheckedCartoonId] = useState('');
  const [moved, setMoved] = useState(false)
  const [volumeMoved, setVolumeMouved] = useState(false)
  const [hasPrev, setHasPrev] = useState(0)
  const [playlisIsColsed, setPlaylistIsClosed] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(
    typeof token === 'undefined'
  );
  const icons = useMemo(() => constantData.playButtons, [])

  useEffect(() => {
    if (cartoonId.playlistId && showPlaylist && !showErrorModal) {
      logEvent(firebaseAnalytics, 'Carton_playlist_watching')
      dispatch(playlist(cartoonId.playlistId, limit, page));
    }
    dispatch(fetchRecommendedCartoons(limit, recommendedPage));
  }, [
    dispatch,
    limit,
    recommendedPage,
    showPlaylist,
    page,
    cartoonId.playlistId,
    showErrorModal,
  ]);

  useEffect(() => {
    const fetchData = async (id) => {
      logEvent(firebaseAnalytics, 'Web_Carton_watching')
      if (id) {
        const catroonInformation = await dispatch(fetchCartoon(id));
        if (catroonInformation.payload?.cartoonData.status === 200)
          setCartoonData(catroonInformation.payload.cartoonData.data);
      }
    };
    fetchData(selectedCartoonId);
  }, [dispatch, selectedCartoonId, location]);

  useEffect(() => {
    const video = document.getElementById('video')
    const playOrPause = document.getElementById('playOrPause')
    if (moved && video && playOrPause) {
      video.pause()
      playOrPause.src = icons[1]
  } else if (video && playOrPause) {
      video.play()
      playOrPause.src = icons[0]
    }
  }, [moved, icons])

  useEffect(() => {
    setPage(1);
    setRecommendedPage(1);
    setUpdatePage(true);
    setUpdateRecommendedPage(true);
  }, [cartoonId, selectedCartoonId]);

  useEffect(() => {
    dispatch(fetchCartoonCategories())
  }, [dispatch])

  useEffect(() => {
    if (
      updateRecommendedPage &&
      singlePlayList?.data?.length > 0 &&
      (playList.length === 0 || playList[0]._id !== singlePlayList.data[0]._id)
    ) {
      let cartoons = [];
      let allPlaylist = playList;
      if (recommendedPage === 1) {
        allPlaylist = [];
      }

      if (cartoons.length === 0 && cartoonId.playlistId) {
        setShowPlaylist(true);
        window.history.replaceState(
          { page: 0, isPlaylist: true, id: singlePlayList.data[0]._id },
          ''
        );
      }

      for (let i = 0; i < singlePlayList.data.length; ++i) {
        let index = playList.findIndex(
          (item) => item._id === singlePlayList.data[i]._id
        );
        if (index === -1) {
          cartoons.push(singlePlayList.data[i]);
        }
      }

      let newData = allPlaylist.concat(cartoons);
      setPlayList(newData);
      setUpdateRecommendedPage(true);
    } else if (updatePlaylist.length > 0 && singlePlayList?.data?.length > 0) {
      setPlayList(singlePlayList.data);
      setUpdateRecommendedPage(true);
    }
  }, [
    singlePlayList,
    cartoonId.playlistId,
    playList,
    recommendedPage,
    updateRecommendedPage,
    updatePlaylist.length,
  ]);

  const updateCartoon = useCallback((event) => {
    if (event.state) {
      setShowPlaylist(event.state.isPlaylist);
      setSelectedIndex(event.state.page);
      setselecteCartoonId(event.state.id);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', updateCartoon);
  }, [updateCartoon]);

  useEffect(() => {
    if (recommendedCartoonsData.length === 0 && recommendedCartoons.length !== 0 && !showPlaylist) {
      window.history.replaceState({page: 0, isPlaylist: false, id: recommendedCartoons[0]._id }, '');
    }

    let allData = [...recommended, ...recommendedCartoons];
    let filteredData = allData.filter((value, index, self) => index === self.findIndex((t) => t._id === value._id));
    let index = filteredData.findIndex((item) => item._id === selectedCartoonId);
    if (index !== -1) {
      let removedCartoon = filteredData.splice(index, 1);
      setRecommentedCartoonsData(filteredData);
      setRecommented([...filteredData, ...removedCartoon]);
    } else {
      setRecommented(filteredData);
      setRecommentedCartoonsData(
        filteredData.filter((item) => item._id !== selectedCartoonId)
      );
    }
    setUpdateRecommendedPage(true);
  }, [recommendedCartoons, showPlaylist, selectedCartoonId]);

  useEffect(() => {
    if (nextCalled) {
      let index = playList.findIndex((item) => item._id === selectedCartoonId);
      if (index !== -1 && index < playList.length - 1 && showPlaylist) {
        let index = playList.findIndex(
          (item) => item._id === selectedCartoonId
        );
        let cartoon = playList[index + 1];
        if (cartoon._id !== selectedCartoonId) {
          setSelectedIndex(selectedIndex + 1);
          setselecteCartoonId(cartoon._id);
          setShowPlaylist(true);
          window.history.pushState(
            { page: selectedIndex + 1, isPlaylist: true, id: cartoon._id },
            '',
            `/cartoons/${cartoonId.playlistId}/${cartoon._id}`
          );
        }
      } else {
        let index = 0;
        let selectedCartoon = recommended.splice(0, 1);
        setRecommentedCartoonsData(recommended);
        setRecommented(recommended.concat([...selectedCartoon]));
        setSelectedIndex(index);
        setselecteCartoonId(selectedCartoon[0]._id);
        setShowPlaylist(false);
        window.history.pushState(
          { page: index, isPlaylist: false, id: selectedCartoon[0]._id },
          '',
          `/cartoons/${selectedCartoon[0]._id}`
        );
      }
      setHasPrev(hasPrev + 1)
      setNextCalled(false);
    }
  }, [
    nextCalled,
    cartoonId,
    playList,
    recommended,
    selectedCartoonId,
    selectedIndex,
    showPlaylist,
    recommendedCartoonsData,
  ]);

  const resizePlyer = useCallback(() => {
    let video = document.getElementById('video');
    let title = document.getElementById('player_title');
    let height = offsetHeight;
    if (
      video &&
      playList.length > 0 &&
      document.getElementById('player') &&
      document.getElementById('players') &&
      title
    ) {
      if (isNormalScreen && window.innerWidth >= 735) {
        setOffsetHeight(video.offsetWidth);
        height = video.offsetWidth;
        document.getElementById('player').style.maxHeight = `${video.offsetWidth * 0.56
          }px`;
        document.getElementById('smilarPlaylist').style.maxHeight = `${playlisIsColsed ? 360 + video.offsetWidth * 0.56 : 360}px`;
        document.getElementById('players').style.maxHeight = `${video.offsetWidth * 0.56 - title.offsetHeight
          }px`;
        document.getElementById('player').style.visibility = 'visible';
      }
    } else if (isNormalScreen && video) {
      setOffsetHeight(video.offsetWidth);
      height = video.offsetWidth;
    }

    if (isNormalScreen && video && height && window.innerWidth >= 735) {
      video.style.height = `${height * 0.55}px`;
    } else if (isNormalScreen && video && window.innerWidth >= 735) {
      video.style.height = `${video.offsetWidth * 0.55}px`;
    } else if (isNormalScreen && video) {
      video.style.height = `${video.offsetWidth * 0.55}px`;
    }

        if (
      window.innerWidth < 735 &&
      document.getElementById('tabBar') &&
      isNormalScreen
    ) {
      document.getElementById('tabBar').classList.replace('d-none', 'd-block')
    } else if (document.getElementById('tabBar')) {
      document.getElementById('tabBar').classList.add('d-none');
    }
  }, [playList, isNormalScreen, offsetHeight]);

  useEffect(() => {
    window.addEventListener('resize', resizePlyer);
    return () => window.removeEventListener('resize', resizePlyer);
  }, [resizePlyer]);

  useEffect(() => {
    resizePlyer();
  }, [cartoonData, resizePlyer]);

  const changePage = () => {
    setPage(page + 1);
    setUpdatePage(false);
  };

  const changeRecommendedPage = () => {
    setRecommendedPage(recommendedPage + 1);
    setUpdateRecommendedPage(false);
  };

  let smiliar = document.getElementById('player_smiliar');
  if (smiliar) {
    smiliar.style.visibility = 'visible';
    smiliar.style.border = 'none';
  }

  const prev = () => {
    if (showPlaylist) {
      const index = playList.findIndex(
        (item) => item._id === selectedCartoonId
      );
      if (index > 0 && index <= playList.length) {
        setSelectedIndex(index - 1);
        const cartoon = playList[index - 1];
        setSelectedIndex(index - 1);
        setselecteCartoonId(cartoon._id);
        window.history.pushState(
          { page: index - 1, isPlaylist: true, id: playList[index - 1]._id },
          '',
          `/cartoons/${cartoonId.playlistId}/${cartoon._id}`
        );
      } else {
        const video = document.getElementById('video');
        if (video) video.play();
      }
    } else if (hasPrev > 0) {
      setHasPrev(hasPrev - 1)
      let prev = recommended.splice(recommended.length - 1, 1);
      let selectedCartoon = recommended.splice(recommended.length - 1, 1);
      let index = recommended.length - 1;
      let allCartoons = prev.concat([...recommended])
      setRecommentedCartoonsData(recommended);
      setRecommented(selectedCartoon.concat([...allCartoons]));
      setSelectedIndex(index);
      setselecteCartoonId(selectedCartoon[0]._id);
      setShowPlaylist(false);
      window.history.pushState(
        { page: index, isPlaylist: false, id: selectedCartoon[0]._id },
        '',
        `/cartoons/${selectedCartoon[0]._id}`
      );
    } else {
      const video = document.getElementById('video');
      if (video) video.play();
    }
  };

  const changeSelectedCartoonIndex = (index, isPlaylist) => {
    const play = document.getElementById('playOrPause')
    setShowPlaylist(isPlaylist);
    setSelectedIndex(index);
    if (isPlaylist && playList.length > 0) {
      window.history.replaceState(
        { page: index, isPlaylist: isPlaylist, id: playList[index]._id },
        ''
      );
      setselecteCartoonId(playList[index]._id);
    } else if (!isPlaylist) {
      setHasPrev(hasPrev + 1)
      window.history.replaceState(
        {
          page: index,
          isPlaylist: isPlaylist,
          id: recommendedCartoonsData[index]._id,
        },
        ''
      );
      setselecteCartoonId(recommendedCartoonsData[index]._id);
      setRecommentedCartoonsData(
        recommended.filter(
          (item) => item._id !== recommendedCartoonsData[index]._id
        )
      );
    }
    if (play) {
      play.src = '../../../assets/video/pause.svg'
    }
  };

  if (cartoon.cartoonData?.status !== 200) {
    return <Loader />;
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          ctx.data &&
          Object.keys(ctx?.data).length > 0 && (
            <div
              onClick={() => {
                setInputIsFocused(false);
                setShowSearchResult(false);
              }}
            >
              {!showErrorModal ||
                location.pathname === `/cartoons/${cartoonId.id}` ? (
                <div
                  className={`${classes.main} d-flex flex-wrap w-100 m-auto`}
                  onClick={() => {
                    setInputIsFocused(false);
                    setShowSearchResult(false);
                  }}
                >
                  <div className='col-12 py-3'>
                    <CartoonSearch
                      setInputIsFocused={setInputIsFocused}
                      setShowAddToPlaylist={setShowAddToPlaylist}
                      showSaerchResult={showSaerchResult}
                      setShowSearchResult={setShowSearchResult}
                      languageKey={ctx.language}
                      search={ctx.data?.search[ctx.language]}
                    />
                  </div>
                  <div
                    className='col-md-12 col-lg-8 px-md-5 px-sm-0 py-0 d-flex flex-wrap'
                    id='videoPlayer'
                  >
                    <VideoPlayer
                      moved={moved}
                      setMoved={setMoved}
                      cartoonData={cartoonData}
                      setNextCalled={setNextCalled}
                      prevCall={prev}
                      showPrev={showPlaylist}
                      selectedCartoonId={selectedCartoonId}
                      playBack={ctx.data?.playback_speed[ctx.language]}
                      hasNext={videoPlayer.hasNext(
                        cartoonId.playListId,
                        selectedCartoonId,
                        playList,
                        recommendedCartoonsData.length,
                        selectedIndex
                      )}
                      addListener={
                        showAddToPlayList || inputIsFocused
                      }
                      inputIsFocused={inputIsFocused}
                      isNormalScreen={isNormalScreen}
                      setIsNormalScreen={setIsNormalScreen}
                      offsetHeight={offsetHeight}
                      normal={ctx.data?.normal[ctx.language]}
                      volumeMoved={volumeMoved}
                      setVolumeMouved={setVolumeMouved}
                    />
                    <AboutCartoon
                      cartoonData={cartoonData}
                      setShowAddToPlaylist={setShowAddToPlaylist}
                      showAddToPlayList={showAddToPlayList && !showErrorModal}
                      selectedCartoonId={selectedCartoonId}
                      checkedCartoonId={checkedCartoonId}
                      moved={moved}
                      setMoved={setMoved}
                      onMouseoMove={videoPlayer.onMouseoMove}
                    />
                  </div>
                  <div className={`col-md-12 col-lg-4 ${classes.playlist_cartoon} ${window.innerWidth < 600 ? 'w-100 p-0' : ''}`}>
                    {cartoonId.playlistId &&
                      playList.length > 0 &&
                      showPlaylist && (
                        <CartoonPlayer
                          plylistDefaultHeight={plylistDefaultHeight}
                          playlisIsColsed={playlisIsColsed}
                          setPlaylistIsClosed={setPlaylistIsClosed}
                          data={playList}
                          title={ctx.data?.cartoons[ctx.language]}
                          language={ctx.language}
                          updatePage={updatePage}
                          changePage={changePage}
                          id=''
                          cartoonId={selectedCartoonId}
                          isPlaylist={true}
                          changeSelectedIndex={(index, isPlaylist) =>
                            changeSelectedCartoonIndex(
                              index,
                              isPlaylist,
                              playList,
                              recommended,
                              recommendedCartoonsData,
                              setShowPlaylist,
                              setSelectedIndex,
                              setselecteCartoonId,
                              setRecommentedCartoonsData
                            )
                          }
                          selectedIndex={selectedIndex}
                          playlistId={cartoonId.playlistId}
                          selectedCartoon={playList[selectedIndex]}
                          show={showPlaylist}
                          setUpdatePlaylist={setUpdatePlaylist}
                          removeFromPlaylist={(id) => operatinosWithPlaylist.removeFromPlaylist(id, playList, selectedCartoonId, dispatch, updatePlaylists, cartoonId, setPlayList, setSelectedIndex, setselecteCartoonId)}
                          setShowAddToPlaylist={setShowAddToPlaylist}
                          selectedCartoonId={selectedCartoonId}
                          setCheckedCartoonId={setCheckedCartoonId}
                        />
                      )}
                    <div id='smilarPlaylist' className={`${classes.smilar_max_height} overflow-auto`}>
                      <CartoonPlayer
                        plylistDefaultHeight={plylistDefaultHeight}
                        playlisIsColsed={playlisIsColsed}
                        setPlaylistIsClosed={setPlaylistIsClosed}
                        data={recommendedCartoonsData}
                        title={null}
                        language={ctx.language}
                        updatePage={updateRecommendedPage}
                        changePage={changeRecommendedPage}
                        id='_smiliar'
                        isPlaylist={false}
                        changeSelectedIndex={(index, isPlaylist) =>
                          changeSelectedCartoonIndex(index, isPlaylist)
                        }
                        playlistId=''
                        selectedIndex={showPlaylist ? -1 : selectedIndex}
                        selectedCartoon={recommendedCartoons[selectedIndex]}
                        show={showPlaylist}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <SignInErrorModal
                  showErrorModal={showErrorModal}
                  setShowErrorModal={setShowErrorModal}
                  onHide={() => {
                    setShowErrorModal(false);
                  }}
                />
              )}
            </div>
          )
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlayCartoon;
