import React from 'react';
import classes from './AgeGroup.module.css';

const AgeGroup = (props) => {
  const { ageGroup, onClick, onChangeaAge, data } = props;

  return (
        <div className={`${classes.age_group_container} ${classes.ageGroup} d-flex justify-content-between align-items-center`}>
          {ageGroup?.map((age) => {
            return (
              <div onClick={onClick} key={age}>
                <input
                  className={`${classes.title} ${classes.title_edit} font-weight-normal border-0`}
                  onChange={onChangeaAge}
                  type='checkbox'
                  hidden
                  name={age}
                  value={age}
                  id={`age_group${age}`}
                  checked={data?.includes(age)}
                />
                <label className={classes.age_title} htmlFor={`age_group${age}`}>
                  <span
                    id={`age_group${age}`}
                    className={`${ageGroup && classes.item} ${
                      data?.includes(age) ? `${classes.active_age} align-items-center d-flex justify-content-center` : ''
                    }`}
                  >
                    {age}
                  </span>
                </label>
              </div>
            );
          })
          }
        </div>
  );
};

export default AgeGroup;
