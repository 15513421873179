import { createSlice } from '@reduxjs/toolkit';

const initialState = { file: { data: null, status: null } };

const downLoadSlice = createSlice({
  name: 'downLoad',
  initialState,
  reducers: {
    downLoadHandler(state, action) {
      state.file = action.payload.file;
    },
  },
});
export const downLoadAction = downLoadSlice.actions;
export default downLoadSlice;
