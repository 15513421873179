import React from 'react';

import styles from './SlideTitle.module.css';

const SlideTitle = ({ title, bookTitle, bookAuthor, description }) => {
  return (
    <div className={`${styles.slide_title} text-center flex-column d-flex end-0`}>
      <h1 className='m-0 p-0 overflow-hidden'>{title}</h1>
      <h3 className='m-0 p-0 d-flex'>{bookTitle}</h3>
      <h3 className='m-0 p-0 d-flex'>{bookAuthor}</h3>
      <p className='m-0 p-0 overflow-hidden'>{description}</p>
    </div>
  );
};

export default SlideTitle;
