import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

const deleteFromWatchLaterSlice = createSlice({
  name: 'deletedWatchLater',
  initialState,
  reducers: {
    deleteFromWatchLaterHandler(state, action) {
      state.data = action.payload.data;
    },
  },
});
export const deleteFromWatchLaterAction = deleteFromWatchLaterSlice.actions;
export default deleteFromWatchLaterSlice;
