import { createSlice } from '@reduxjs/toolkit';

const initialState = {playList: [], status:null};

const playListSlice = createSlice({
  name: 'playList',
  initialState,
  reducers: {
    playListHandler(state, action) {
      state.playList = action.payload.playList;
    },
  },
});
export const playListAction = playListSlice.actions;
export default playListSlice;