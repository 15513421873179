import React from 'react'
import classes from './ListenTitle.module.css'

const ListenTitle = (props) => {
    const {authorName, booksName} = props
    return (
        <div className='d-flex flex-wrap'>
        <div className={`${classes.title_item_container} w-100 d-flex flex-wrap`}>
        {authorName && <span className={`${classes.title_item} w-100`}>{authorName}</span>}
        <span className={`${classes.title_item} w-100`}>{booksName}</span>
        </div>
    </div>
    )
}

export default ListenTitle