import classes from './Contacts.module.css';
import Titles from '../../../components/title/Titles';
import { LanguageContext } from '../../../context/LanguageContext';
import ContactsCard from '../../../components/card/contactsCard/ContactsCard';

const Contacts = () => {
  return (
    <LanguageContext.Consumer>
    {(ctx) => {
      return (
        <div
        id='contact-us'
        className={
          `${classes.contacts_data_container} d-flex justify-content-center flex-wrap align-items-center w-100`
        }
      >
        <Titles
          title={{subtitle:ctx.data?.help[ctx.language], title:ctx.data?.questions[ctx.language], information:ctx.data?.contact_us_for_more[ctx.language]}}/>
        <ContactsCard
          className={classes.contacts_card_email}
          contactsImg='../assets/contacts/contactsEmail.svg'
          link={`mailto:${process.env.REACT_APP_EMAIL}`}
          data={process.env.REACT_APP_EMAIL}
          title={ctx.data?.email[ctx.language]}
        />
        <ContactsCard
          className={classes.contacts_card_phone}
          contactsImg='../assets/contacts/contactsPhone.svg'
          link={`tel:+374${process.env.REACT_APP_PHONE_NUMBER.replace(/\s/g, '')}`}
          data={`+374 ${process.env.REACT_APP_PHONE_NUMBER}`}
          title={ctx.data?.phone_number[ctx.language]}
        />
      </div>
      )}}
      </LanguageContext.Consumer>
   
  );
};
export default Contacts;
