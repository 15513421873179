import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './CartoonTitle.module.css';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../../../../context/LanguageContext';
import addPlayList from '../../../../../store/cartoons/addPlayList/AddPlayList';
import fetchWatchLater from '../../../../../store/cartoons/watchLater/FetchWatchLater';

const CartoonTitle = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    titleText,
    title,
    cartoonId,
    show,
    onClick,
    cartoonData,
    setDeleted,
    id,
    cartoonWatchLater,
    setDisabled,
    userCartoon,
    setShow,
    setShowPlaylist
  } = props;

  const dropdownMenusHandler = (filter, key) => {
    let menus = [
      'edit',
      'save_to_playlist',
      'watch_later',
      'popup_delete_button',
    ];
    if ((!filter && !key) || (!filter && key)) {
      menus = menus.filter((menu) =>
        ['save_to_playlist', 'watch_later'].includes(menu)
      );
    } else if (+key === 1 && !userCartoon) {
      menus = menus.filter((menu) =>
        ['save_to_playlist', 'popup_delete_button'].includes(menu)
      );
    } else if (+key === 1 && userCartoon) {
      menus = menus.filter((menu) =>
        ['edit', 'save_to_playlist', 'popup_delete_button'].includes(menu)
      );
    }
    return menus;
  };

  const data = dropdownMenusHandler(!!cartoonData, id);

  const cartoonAction = (type, id) => async( e )=> {
    e.preventDefault();
    e.stopPropagation();
    const requests = [addPlayList, fetchWatchLater];

    if(type === 'edit') {
      navigate(`/upload-cartoons?cartoonId=${cartoonId}`);
    } else if(type === 'save_to_playlist') {
      setShowPlaylist(true)
    } else if (type === 'popup_delete_button') {
      setDeleted(true);
    } else if(type === 'watch_later') {
      setDisabled(true);
      id = data.length === 2 ? id : id - 1
      const response = await dispatch(id === 0 ? requests[id](title, [cartoonId]) : requests[id](cartoonId))
      if(response.payload.addWatchLater.status === 200) setDisabled(false);
    }
    setShow(false);
  };


  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.max_height_64} align-items-center w-100 justify-content-center d-flex`}
          >
            <div
              className={`${classes.title_container}  overflow-hidden w-100`}
            >
              <span className={classes.title_text}>{titleText}</span>
            </div>
            <div className={`position-relative d-flex`}>
              <div
                onClick={onClick}
                className={`${classes.menue_img_container} d-flex justify-content-center align-items-center`}
              >
                <img
                  className={`${classes.menue_img} w-100`}
                  src={`../assets/cartoon/card/cartoonMenu.svg`}
                  alt=""
                />
              </div>
              <div
                className={`position-absolute end-0 overflow-hidden d-flex flex-wrap ${
                  show
                    ? `${classes.addPlay_list_container}  ${
                        classes.padding_10
                      } ${
                        data?.length !== 2
                          ? classes.height_150
                          : classes.height_75
                      }`
                    : `${classes.addPlay_list_container}`
                }`}
              >
                {data?.map((item, index) => {
                  return (
                    <div
                      className={`${classes.gap_10} border-0 bg-transparent w-100 d-flex`}
                      onClick={cartoonAction(item, index)}
                      key={`${item}${index}`}
                    >
                      <div className={`${classes.add_playList_img_container}`}>
                        <img
                          className={
                            item === "watch_later" && cartoonWatchLater
                              ? `${classes.item_img} w-100 h-100`
                              : "w-100 h-100"
                          }
                          src={`../assets/cartoon/card/${item}.svg`}
                          alt=""
                        />
                      </div>
                      <div className={classes.add_playList_text_container}>
                        <button
                          disabled={
                            item === "watch_later" && cartoonWatchLater
                              ? true
                              : false
                          }
                          className={`${classes.text_container} border-0 bg-transparent`}
                        >
                          {ctx.data && ctx.data[item][ctx.language]}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonTitle;
