export const operationsWithValues = {
  addValue(num1, num2, limit) {
    if (num1 < limit.max) {
      return num1 + num2;
    } else return;
  },

  subtractValue(num1, num2, limit){
    if(num1 > limit.min) {
      return num1 - num2
    } else return
  }
};