import { createSlice } from '@reduxjs/toolkit';

const initialState = { updateCartoon: {} };

const updateCartoonSlice = createSlice({
  name: 'updateCartoon',
  initialState,
  reducers: {
    updateCartoonHandler(state, action) {
      state.updateCartoon = action.payload.updateCartoon;
    },
  },
});
export const updateCartoonAction = updateCartoonSlice.actions;
export default updateCartoonSlice;
