import React from 'react';
import Cookies from 'js-cookie';
import ReadMore from '../readMore/ReadMore';
import classes from './BookDetail.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import Button from '../../../../components/buttons/authButton/Button';
import { constantData } from '../../../../utils/helpers/constantData';

const BookDetail = (props) => {
  const { data, purchasesData, productsHandler, read, id} = props;

  const token = Cookies.get('auth_token');
  const productAction =
    purchasesData?.includes(id) && token
      ? constantData.productType[data.productType]
      : ['download'];

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.gap20} flex-wrap d-flex`}>
            <div className={`${classes.borderBottom} w-100`}></div>
            <div className={classes.padding}>
              <ReadMore
                data={data?.description}
                description={classes.description}
                read_more_container={classes.read_more_container}
              />
            </div>
            <div
              className={`${classes.singleProductPageContainer} ${classes.padding} ${classes.flexWrap} d-flex justify-content-center w-100 align-items-start text-decoration-none`}
            >
              {productAction.map((item) => {
                return (
                  <Button
                    key={item}
                    textStyle='w-100'
                    divStyle={`${classes.button_container} d-flex w-100 align-items-center justify-content-center justify-content-center`}
                    buttonStyle={`${classes.actions_for_book} border-0 text-center align-items-center d-flex`}
                    onClick={() => productsHandler(item)}
                    image={
                      item !== 'download' &&
                      `../assets/singleProduct/booksData/${item}.svg`
                    }
                    text={
                      ctx.data &&
                      ctx.data[read && item === 'read' ? 'continue' : item][
                        ctx.language
                      ]
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default BookDetail;
