import { createSlice } from '@reduxjs/toolkit';

const initialState = { userPurchases: { data: [], status: null } };

const userPurchasesSlice = createSlice({
  name: 'userPurchases',
  initialState,
  reducers: {
    userPurchasesHandler(state, action) {
      state.userPurchases = action.payload.userPurchases;
    },
  },
});

export const userPurchasesAction = userPurchasesSlice.actions;
export default userPurchasesSlice;
