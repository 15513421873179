import { createSlice } from '@reduxjs/toolkit';

const initialState = { addPlaylist: {} };

const addPlaylistSlice = createSlice({
  name: 'addPlaylist',
  initialState,
  reducers: {
    addPlaylistHandler(state, action) {
      state.addPlaylist = action.payload.addPlaylist;
    },
  },
});
export const addPlaylistAction = addPlaylistSlice.actions;
export default addPlaylistSlice;
