export const cartoonRates = {
  changeCartoonRate(type, curUser, rate, selectedCartoonId) {
    let user = { ...curUser.user };
    let cartoonRating = [...curUser.user.cartoonRating];
    const item = cartoonRating.find((item) => item.cartoonId === selectedCartoonId)
    const filteredData = cartoonRating.filter((item) => item.cartoonId !== selectedCartoonId)
    
    if(item) {
      type === rate ? cartoonRating = filteredData : cartoonRating = [...filteredData, { _id: '', cartoonId: selectedCartoonId, like: type === 'like'}]
    } else {
      const data = [...cartoonRating, { _id: '', cartoonId: selectedCartoonId, like: type === 'like'}]
      cartoonRating = data
    }
    user.cartoonRating = cartoonRating;
    curUser.setUserData(user);
}
}