import { createSlice } from '@reduxjs/toolkit';

const initialState = { products: {}, status: 'not fullfieled', error: '' };

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productsHandler(state, action) {
      state.products = action.payload;
    },
  },
});
export const productsAction = productsSlice.actions;
export default productsSlice;
