import React from 'react';
import classes from './CategoryImg.module.css';

const CategoryImg = (props) => {
  const { imgUrl } = props;
  return (
    <div className={`${classes.item_img_container} d-flex align-items-center`}>
      <img className='w-100' src={imgUrl} alt='' />
    </div>
  );
};

export default CategoryImg;
