import React from 'react';
import classes from './ErrorMessage.module.css';

const ErrorMessage = (props) => {
  const { text, className} = props;
  return (
    <span className={`${classes.font_size_12} ${classes.color_red} ${className} d-flex mt-0 w-100 font-weight-normal`}>{text}</span>
  );
};

export default ErrorMessage;
