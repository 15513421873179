import Cookies from 'js-cookie';
import { useState } from 'react';
import classes from './DeleteUser.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../../context/LanguageContext';
import ConfirmationModal from '../../../../components/modal/confirmationModal/ConfirmationModal';
import UserAccountComponent from '../../../../components/user/userAccountComponent/UserAccountComponent';
import UserAccountComponentTitle from '../../../../components/user/userAccountComponent/title/UserAccountComponentTitle';
import deleteUser from '../../../../store/user/deleteUser/DeleteUser';

const DeleteUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const token = Cookies.get('auth_token');
  const userMeId = useSelector((state) => state.userData.userData.user._id);

  const deleteAccount = async () => {
    const response = await dispatch(deleteUser(userMeId, token));
    if (response.payload.deleteUser.status === 200) {
      Cookies.remove('auth_token');
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <UserAccountComponent
              action={ctx.data?.popup_delete_button[ctx.language]}
            >
              <UserAccountComponentTitle
                title={ctx.data?.delete_your_account[ctx.language]}
              />
              {
                <div onClick={() => setIsShow(true)}>
                  <span
                    className={`${classes.title_delete} ${classes.title_edit}`}
                  >
                    {ctx.data?.popup_delete_button[ctx.language]}
                  </span>
                </div>
              }
            </UserAccountComponent>
            {isShow && (
              <ConfirmationModal
                show={isShow}
                onHide={() => setIsShow(false)}
                logOutHandler={deleteAccount}
                titles={{
                  max_title: ctx.data?.delete_account_text[ctx.language],
                  mid_title: ctx.data?.delete_account_text_second[ctx.language],
                }}
              />
            )}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default DeleteUser;
