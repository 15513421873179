import axios from 'axios';
import { productCategoriesAction } from './ProductCategoriesSlice';

const productCategories = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/category/all`;
      const response = await axios.get(url);
      return response.data;
    };
    try {
      const categories = await fetchData();
      if (categories && categories.length > 0)
        dispatch(productCategoriesAction.categoriesHandler({ categories }));
    } catch (e) {
      return e;
    }
  };
};

export default productCategories;
