import { createSlice } from '@reduxjs/toolkit';
const initialState = { blog: {}};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    blogHandler(state, action) {
      state.blog = action.payload.blog;
    },
  },
});
export const blogAction = blogSlice.actions;
export default blogSlice;