import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/storage'
import 'firebase/analytics'
import { FacebookAuthProvider, OAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCf7v4LcD-bveqJLKmc8UsoZc9qESLmFY0',
  authDomain: 'vinni-90d4b.firebaseapp.com',
  projectId: 'vinni-90d4b',
  storageBucket: 'vinni-90d4b.appspot.com',
  messagingSenderId: '857482068129',
  appId: '1:857482068129:web:8583594a085cd63d1670e0',
  measurementId: 'G-GY47KQQDDL',
};
const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app)
const providerHandler = (type) => {
  let provider = type === 'facebook' ? new FacebookAuthProvider() :
  type === 'apple' ? new OAuthProvider('apple.com') :
  new GoogleAuthProvider();
  return provider
} 
const firebaseAnalytics = getAnalytics(app)

export { auth, providerHandler, firebaseAnalytics, logEvent };