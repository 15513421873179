import axios from 'axios'
import Cookies from 'js-cookie';
import { codeValidationAction } from './CodeValidationSlice';

const codeValidation = (verificationCode) => {
  const auth = `Bearer ${Cookies.get('auth_token')}`;
    return async (dispatch) => {
      const fetchData = async () => {
        const body = { verificationCode};
        const url = `${process.env.REACT_APP_DEV_URI}/user/me/confirmValidationCode`;
        const headers = { 'Content-Type': 'application/json',Authorization: auth}
        const response = await axios.post(url, body, {headers});
        return response;
      };
      try {
        const confirmValidation = await fetchData();
          return dispatch(codeValidationAction.codeValidationHandler({ confirmValidation }));
      } catch (e) {
        const response = dispatch(codeValidationAction.codeValidationHandler({ codeValidation: e.response }));
        return response
      }
    };
  };
  
  export default codeValidation;
  