import React from 'react';
import 'react-phone-input-2/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './CodeVerification.module.css';
import ModalTitles from '../../modalTitles/ModalTitles';
import Button from '../../../buttons/authButton/Button';
import {constantData} from '../../../../utils/helpers/constantData'
import { LanguageContext } from '../../../../context/LanguageContext';

const CodeVerification = (props) => {
  const {
    codeVerification,
    email,
    type,
    OTP,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    resendCode,
    onSubmit,
    disabled,
    buttonType,
    phoneNumber,
    onDoubleClick,
    onDoubleClickSubmit,
  } = props;
  let contexts = [];
  const codeLength = email ? 4 : 6;
  const titles = constantData.codeVerificationTitles

  const checkInputValidity = (data, lang) => e =>{
    e.target.setCustomValidity(data['fill_fields'][lang]);
  };

  const inputBlocks = (data, lang) => {
    for (let count = 0; count < codeLength; count++) {
      contexts.push(
        <form
          key={`${type}_${count + 1}`}
          className={
            codeVerification
              ? `${classes.input_verification_code} d-flex justify-content-center align-items-center`
              : `${classes.input_error_code} d-flex justify-content-center align-items-center`
          }
          onSubmit={onSubmit}
        >
          <input
            onInvalid={checkInputValidity(data, lang)}
            id={`${type}_${count + 1}`}
            required
            maxLength={'1'}
            value={OTP}
            onChange={(event) => onChange(event, `${type}_${count + 1}`)}
            onFocus={() => onFocus(count)}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            autoFocus={count === 0 && true}
            type='number'
            className='d-flex align-items-center justify-content-center h-100 w-100 text-center'
          />
        </form>
      );
    }
    return contexts;
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const data = inputBlocks(ctx.data, ctx.language);
        return (
          <div className={`${classes.code_verification_container} ${classes.code_verification_data_container} d-flex  justify-content-center w-100  align-items-center flex-wrap`}>
            <div className={`${classes.code_verification} ${classes.code_verification_data_container} d-flex  justify-content-center w-100  align-items-center flex-wrap`}>
              {Object.keys(titles).map((item, index) => {
                return (
                  <div className={`${classes.code_verification_container} ${classes.code_verification_data_container} d-flex  justify-content-center w-100  align-items-center flex-wrap`} >
                    <ModalTitles {...{[item]: `${ctx.data && titles[item] && ctx.data[titles[item]][ctx.language]} ${index === 2 ? phoneNumber : ''}`, classIdentifier: item}}/>
                  </div>
                );
              })}
            </div>
            <div className={`${classes.verification_code_container} ${classes.code_verification_data_container} d-flex  justify-content-center w-100  align-items-center flex-wrap`}>
              <div className={`${classes.input_code_container} ${classes.code_verification_data_container} d-flex  justify-content-center w-100  align-items-center`} onChange={onChange}>
                {data.map((context) => {
                  return context;
                })}
              </div>
              {!codeVerification ? (
                <div
                  className={`${classes.error_message_container} d-flex justify-content-center   align-items-center`}
                >
                  <img src='../assets/icons/error/error.svg' alt='' />
                  <ModalTitles
                    className={`d-flex align-items-center text-center`}
                    classIdentifier={'error_text'}
                    errorMessage={ctx.data?.session_expired[ctx.language]}
                  />
                </div>
              ) : null}
            </div>
            <div className={`${classes.resend_container} d-flex justify-content-center align-items-center flex-wrap w-100`}>
              <div className={`justify-content-center flex-wrap d-flex`}>
                <ModalTitles
                  classIdentifier={'mid_title'}
                  mid_title={ctx.data?.did_not_get_the_code[ctx.language]}
                />
                <Button
                  onDoubleClick={onDoubleClick}
                  text={ctx.data?.resend[ctx.language]}
                  disabled={disabled.length > 0 && disabled?.includes('resend')}
                  onClick={resendCode}
                  className={`${classes.resend_button} border-0`}
                  buttonStyle={`${classes.resend_button} border-0 bg-transparent`}
                />
              </div>
              <Button
                textStyle={
                  'd-flex justify-content-center align-items-center w-100 text-center'
                }
                divStyle={`${classes.max_width_330} w-100`}
                onClick={onSubmit}
                buttonStyle={`${classes.button_color} border-0 justify-content-center w-100`}
                text={ctx.data?.submit[ctx.language]}
                id='codeverification'
                disabled={disabled.includes('codeVerification')}
                type={buttonType}
                onDoubleClick={onDoubleClickSubmit}
              />
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CodeVerification;
