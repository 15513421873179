import PropTypes from 'prop-types';
import Loader from '../spinner/Loader';
import styles from './Carousel.module.css';
import Arrows from './controls/arrows/Arrows';
import { useDispatch, useSelector } from 'react-redux';
import SlidesList from './controls/slideList/SlideList';
import React, { useEffect, useState, createContext, useCallback } from 'react';
import fetchLatestProducts from '../../store/latestProducts/FetchLatestProducts';

export const CarouselContext = createContext();

const Carousel = ({ width, height, autoPlay, autoPlayTime }) => {
  let items = [];
  const page = 1
  const dispatch = useDispatch();
  const [slide, setSlide] = useState(0);
  const [animation, setAnimation] = useState(true);
  const [touchPosition, setTouchPosition] = useState(null);
  const latestProducts = useSelector(
    (state) => state.latestProducts.latestProducts
  );

  useEffect(() => {
    dispatch(fetchLatestProducts(process.env.REACT_APP_CAROUSEL_LIMIT, page));
  }, [dispatch, page]);


  latestProducts.data?.forEach((item) => {
    const slide = {
      url: `${process.env.REACT_APP_DEV_URI}/${item.image.imageURL}`,
      bookTitle: item.name,
      bookAuthor: item.author,
      description: item.description,
      id:item._id
    };
    items.push(slide);
  });

  const changeSlide = useCallback(
    (direction = 1) => {
      setAnimation(false);
      let slideNumber = 0;
      if (slide + direction < 0) {
        slideNumber = items.length - 1;
      } else {
        slideNumber = (slide + direction) % items.length;
      }

      setSlide(slideNumber);

      const timeout = setTimeout(() => {
        setAnimation(true);
      }, 1);

      return () => {
        clearTimeout(timeout);
      };
    },
    [items?.length, slide]
  );

  const goToSlide = (number) => {
    setAnimation(false);
    setSlide(number % items.length);

    const timeout = setTimeout(() => {
      setAnimation(true);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleTouchStart = (e) => {
    setAnimation(false);

    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    if (touchPosition === null) {
      return;
    }

    const currentPosition = e.touches[0].clientX;
    const direction = touchPosition - currentPosition;

    if (direction > 1) {
      changeSlide(1);
    }

    if (direction < -1) {
      changeSlide(-1);
    }
    const timeout = setTimeout(() => {
      setAnimation(true);
    }, 0);

    setTouchPosition(null);

    return () => {
      clearTimeout(timeout);
    };
  };

  const keyboardAction = (e) => {
    if (e.keyCode === 39) {
      changeSlide(1);
    } else if (e.keyCode === 37) {
      changeSlide(-1);
    }
  };
  document.onkeydown = keyboardAction;

  useEffect(() => {
    if (!autoPlay) return;
    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);

    return () => {
      clearInterval(interval);
    };
  }, [items?.length, slide, autoPlay, autoPlayTime, changeSlide]); 

  if (
    !latestProducts ||
    latestProducts.length === 0 ||
    !items ||
    items?.length === 0
  )

  if(items.length === 0) {
    return <Loader/>
  }

  return (
    <div
      style={{ width, height }}
      className={`${styles.slider} w-100 d-flex align-items-center justify-content-around position-relative`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <CarouselContext.Provider
        value={{
          goToSlide,
          changeSlide,
          slidesCount: items.length,
          slideNumber: slide,
          items,
        }}
      >
        <div className={`${styles.slider_side_image}`}>
          <img
            src={
              slide - 1 < 0
                ? items[items.length - 1]?.url
                : items[slide - 1]?.url
            }
            alt=''
            className={animation ? `${styles.slider_side_image_animation}` : ''}
          />
        </div>
        <div className={`${styles.carousel_container} w-100 d-flex justify-content-between position-relative`}>
          <Arrows />
          <SlidesList animation={animation} />
        </div>
        <div className={styles.slider_side_image}>
          <img
            src={slide + 1 > items?.length - 1 ? items[0]?.url : items[slide + 1]?.url}
            alt=''
            className={animation ? styles.slider_side_image_animation : ''}
          />
        </div>
      </CarouselContext.Provider>
    </div>
  );
};

Carousel.propTypes = {
  autoPlay: PropTypes.bool,
  autoPlayTime: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};

Carousel.defaultProps = {
  autoPlay: true,
  autoPlayTime: 5000,
  width: '100%',
  height: '100%',
};

export default Carousel;
