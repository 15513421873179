import React from 'react';
import classes from './Category.module.css';
import Titles from '../../../components/title/Titles';
import { LanguageContext } from '../../../context/LanguageContext';
import CategoriesItem from '../../../components/categoriesItem/CategoriesItem';

const Category = (props) => {
  const { categories } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <Titles
              title={{
                subtitle: ctx.data?.explore[ctx.language],
                title: ctx.data?.categories[ctx.language],
              }}
            />
            <div
              className={`${classes.categories_container} ${classes.categories_data_container} d-flex justify-content-center align-items-center w-100`}
            >
              <CategoriesItem
                categoryImg='../assets/categoryImg/storiesCategory.webp'
                categoriesItemContainer={`${classes.stories_container} d-flex position-relative`}
                categoriesItem={classes.category_name_story}
                categoriesDataContainer={`${classes.category_image} h-100 object-fit-cover`}
                categories={categories}
                categoryName={'Stories'}
              >
                {categories &&
                  categories.map((category) => {
                    return (
                      <div
                        key={category._id}
                        className={classes.category_container}
                      >
                        {category.name.en === 'Stories' && (
                          <span
                            className={`${classes.category_name} text-decoration-none`}
                          >
                            {category.name[ctx.language]}
                          </span>
                        )}
                      </div>
                    );
                  })}
              </CategoriesItem>

              <div className={classes.categories_item_container}>
                <div
                  className={`${classes.categories} ${classes.categories_data_container} h-100 flex-column d-flex justify-content-center align-items-center w-100`}
                >
                  <CategoriesItem
                    categoryImg='../assets/categoryImg/fairyTaleCategory.webp'
                    categoriesItemContainer={`${classes.top_container} position-relative`}
                    categoriesItem={classes.category_fairy_tale}
                    categoriesDataContainer={`${classes.categories_data_container} d-flex justify-content-center align-items-center w-100`}
                    categories={categories}
                    categoryName={'Tale'}
                  >
                    {categories &&
                      categories.map((category) => {
                        return (
                          <div
                            key={category._id}
                            className={classes.category_container}
                          >
                            {category.name.en === 'Tale' && (
                              <span
                                className={`${classes.category_name} text-decoration-none`}
                              >
                                {category.name[ctx.language]}
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </CategoriesItem>
                  <div
                    className={`${classes.bottom_container} w-100 d-flex justify-content-between h-100`}
                  >
                    <CategoriesItem
                      categoryImg='../assets/categoryImg/detectiveCategory.jpg'
                      categoriesItemContainer={`${classes.bottom_container_item} h-100 position-relative`}
                      categoriesItem={`${classes.category_detective} ${classes.category_name} text-decoration-none`}
                      categories={categories}
                      categoryName={'Detective'}
                    >
                      {categories &&
                        categories.map((category) => {
                          return (
                            <div
                              key={category._id}
                              id={category._id}
                              className={classes.category_container}
                            >
                              {category.name.en === 'Detective' && (
                                <span>{category.name[ctx.language]}</span>
                              )}
                            </div>
                          );
                        })}
                    </CategoriesItem>

                    <CategoriesItem
                      categoryImg='../assets/categoryImg/adventureCategory.webp'
                      categoriesItemContainer={`${classes.bottom_container_item} h-100 position-relative`}
                      categoriesItem={`${classes.category_adventure} ${classes.category_name} text-decoration-none`}
                      categories={categories}
                      categoryName={'Adventures'}
                    >
                      {categories &&
                        categories.map((category) => {
                          return (
                            <div
                              key={category._id}
                              className={classes.category_container}
                            >
                              {category.name.en === 'Adventures' && (
                                <span>{category.name[ctx.language]}</span>
                              )}
                            </div>
                          );
                        })}
                    </CategoriesItem>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Category;
