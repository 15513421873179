import axios from 'axios';
import Cookies from 'js-cookie';
import { logOutActions } from './LogOutSlice';

const logOut = () => {
  return async (dispatch) => {
    const auth = `Bearer ${Cookies.get('auth_token')}`;
    const logoutHandler = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/logout`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: { Authorization: auth },
      });
      return response;
    };
    try {
      const logOut = await logoutHandler();
      if (logOut.status !== 400) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        dispatch(logOutActions.logout({ status: logOut.status }));
      }
      return logOut.status;
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};
export default logOut;
