import axios from 'axios';
import Cookies from 'js-cookie';
import { updatePlaylistsAction } from './UpdatePlaylistsSlice';

const updatePlaylists = (params) => {
  const token = Cookies.get('auth_token')
  return async (dispatch) => {
    const fetchData = async () => {
      const body = params;
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist/update`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      });
      return response;
    };
    try {
      const updatePlaylistsData = await fetchData();
      let updatePlaylists = updatePlaylistsData.data
     return dispatch(updatePlaylistsAction.updatePlaylistsHandler({ updatePlaylists }));
    } catch (e) {}
  };
};

export default updatePlaylists;