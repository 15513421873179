import { createSlice } from '@reduxjs/toolkit';

const initialState = { uploadCartoon: {} };

const uploadCartoonSlice = createSlice({
  name: 'uploadCartoon',
  initialState,
  reducers: {
    uploadCartooonHandler(state, action) {
      state.uploadCartoon = action.payload.uploadCartoon;
    },
  },
});
export const uploadCartooonAction = uploadCartoonSlice.actions;
export default uploadCartoonSlice;
