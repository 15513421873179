import React from 'react';
import classes from './DurationProgressBar.module.css'
import { videoPlayer } from '../../../../utils/helpers/videoPlayer';

const DurationProgressBar = (props) => {
  let { video, moved, setMoved, setCurtime, setDuration, setShowControlmentu} = props
  
  const handleMapMove = (e) => {
    videoPlayer.onMouseoMove(e, true, video)
    videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
    setShowControlmentu(true)
};

  return (
    <div
      className={`d-flex mx-0 my-auto flex-column invisibile ${classes.cursor_pointer} ${classes.hide} ${classes.dur_progress}`}
      id='durProgress'
      onClick={(event) => {videoPlayer.durationClick(event, video); videoPlayer.curentTimeHandler(video, setCurtime, setDuration)}}
      onMouseMove={(event) => videoPlayer.onMouseoMove(event, moved, video)}
      onMouseUp={() => setMoved(false)}
      onMouseOver={videoPlayer.onMouseOver}
      onMouseOut={(event) => videoPlayer.onMouseOut(event, moved)}
      onTouchMove={(event) => handleMapMove(event)}
    >

      <div className='progress' id='duration'>
        <div className={`progress-bar ${classes.progress_line}`} role='progressbar' id='progress' aria-valuemin='0' aria-valuemax='100'></div>
      </div>
      <div
        className='thumb'
        id='thumb'
        onMouseDown={() => setMoved(true)}
        onMouseUp={() => setMoved(false)}
        onMouseMove={videoPlayer.onMouseoMove}
      />
    </div>
  );
};

export default DurationProgressBar;