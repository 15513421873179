import React, { useEffect, useState } from 'react';
import classes from './UploadCartoonLanguage.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import ErrorMessage from '../../../../components/error/ErrorMessage';
import UploadCartoonTitle from '../uploadCartoonsItem/uploadCartoonTitle/UploadCartoonTitle';

const UploadCartoonLanguage = (props) => {
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    languageDataHandler,
    languageText,
    containsError
  } = props;

  useEffect(() => {
    if (containsError) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [containsError]);

  const languageHandler = (data, lang) => {
    let arr = [];
    const langData = ['hy', 'en', 'ru', 'fr'];
    let name = {
      hy: 'armenian',
      en: 'english',
      ru: 'russian',
      fr: 'french',
    };

    langData.forEach((item) => {
      if (data && lang) {
        arr.push({
          value: item,
          title: data[name[item]][lang],
        });
      }
    });
    return arr;
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const languageData = languageHandler(ctx?.data, ctx?.language);

        return (
          <div
            className={`${classes.gap_5} position-relative d-flex w-100 flex-wrap`}
          >
            <UploadCartoonTitle
              title={
                
                ctx.data?.language[ctx.language]
              }
              setShow={setShow}
              show={show}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            />
            <div
              className={`align-items-center flex-wrap d-flex position-relative w-100`}
            >
              <div
                className={`${classes.gap_5} d-flex w-100 flex-wrap`}
              >
                <div
                  className={`${classes.padding_10} ${classes.gap_20} ${classes.language_items_container} ${classes.language_container} d-flex w-100`}
                >
                  {languageData.map((language) => {
                    return (
                      <div
                        key={language.value}
                        className={`${classes.align_items_center}
                         ${classes.height36} 
                         ${classes.font_size_14} d-flex align-items-center`}
                      >
                        <input
                          onChange={languageDataHandler}
                          type={'radio'}
                          value={language.value}
                          id={`age_group_cartoon${language.value}`}
                          name='age_group'
                          hidden
                        />
                        <label
                          htmlFor={`age_group_cartoon${language.value}`}
                          className={`${classes.minWidth60} ${classes.textAlignCenter}`}
                        >
                          <span
                            className={`${
                              languageText.includes(language.value)
                                ? `${classes.active_language} align-items-center d-flex justify-content-center`
                                : ''
                            } ${classes.item} justify-content-center ${classes.fontSize}`}
                            id={`age_group_cartoon${language.value}`}
                          >
                            {language.title}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {isError && (
              <ErrorMessage
                text={
                  
                  ctx.data?.error_meesage[ctx.language]
                }
              />
            )}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonLanguage;
