import { createSlice } from '@reduxjs/toolkit';

const initialState = {recommendedCartoons: []};

const recommendedCartoonsSlice = createSlice({
  name: 'recommendedCartoons',
  initialState,
  reducers: {
    recommendedCartoonsHandler(state, action) {
      state.recommendedCartoons = action.payload.recommendedCartoons;
    },
  },
});

export const recommendedCartoonsAction = recommendedCartoonsSlice.actions;
export default recommendedCartoonsSlice;