import React from 'react';
import classes from './CartoonCategories.module.css';
import { LanguageContext } from '../../../../../context/LanguageContext';

const CartoonCategories = (props) => {
  const { categories, cartoonCategory } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <span
            className={`${classes.gap_20} ${classes.category_container} w-100 overflow-hidden d-block text-nowrap`}
          >
            {categories && 
              cartoonCategory?.map((item) => {
                return (
                  <span key={`${item}`}>
                    {`${categories?.find((categoryId) => categoryId._id === item)?.name[ctx.language]}  `}
                  </span>  
                );
              })}
          </span>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonCategories;
