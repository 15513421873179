import Cookies from 'js-cookie';
import Home from './pages/home/Home';
import RootLayout from './pages/RootLayout';
import Cartoons from './pages/cartoon/Cartoons';
import Products from './pages/products/Products';
import PlayCartoon from './pages/cartoon/playCartoon/PlayCartoon';
import MyBooks from './pages/myLibrary/myBooks/MyBooks';
import UserAccount from './pages/userAccount/UserAccount';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SingleProductPage from './pages/singleProductPage/SingleProductPage';
import Settings from './pages/settings/Settings';
import UploadCartoons from './pages/cartoon/uploadCartoons/UploadCartoons';
import SearchCartoon from './pages/cartoon/searchCartoon/SearchCartoon';
import PlayList from './pages/cartoon/playList/PlayList';
import SinglePlayList from './pages/cartoon/singlePlayList/SinglePlayList';
import Authors from './pages/authors/Authors';
import SingleAuthor from './pages/authors/singleAuthor/SingleAuthor';
import Blogs from './pages/blog/Blogs';
import Blog from './pages/blog/blog/Blog';
import PageForReading from './pages/singleProductPage/pageForReading/PageForReading';
import AboutUs from './pages/aboutUs/AboutUs';
import UserCartoons from './pages/userCartoons/UserCartoons';
import './App.css'
import UserDeleteReport from './pages/userDeleteReport/UserDeleteReport';

const token = Cookies.get('auth_token')

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <Home/>,
      },
      {
        path: `/my-books`,
        element: token && token !== '' ? <MyBooks /> : <Home />,
      },
      {
        path: `/my-account`,
        element: token && token !== '' ? <UserAccount /> :<Home />,

      },
      {
        path: '/products',
        element: <Products />,
      },
      {
        path: `/products/:id`,
        element: <SingleProductPage />,
      },
      {
        path: `/products/read-book/:id`,
        element: <PageForReading />,
      },
      {
        path: `/cartoons`,
        element: <Cartoons />,
      },
      {
        path: `/cartoons/:playlistId/:id`,
        element: <PlayCartoon />,
      },
      {
        path: `/cartoons/:id`,
        element: <PlayCartoon />,
      },
      {
        path: `/settings`,
        element: <Settings />,
      },
      {
        path: `/blogs`,
        element: <Blogs />
      },
      {
        path: `/blogs/:id`,
        element: <Blog />
      },
      {
        path: `/my-playlists`,
        element: <PlayList />,
      },
      {
        path: `/upload-cartoons`,
        element: <UploadCartoons />,
      },
      {
        path: `/cartoon`,
        element: <SearchCartoon />,
      },
      {
        path: `/single-playlist/:id`,
        element: <SinglePlayList />,
      },
      {
        path: `/authors`,
        element: <Authors />,
      },
      {
        path: `/authors/:id`,
        element: <SingleAuthor />,
      },
      {
        path: '/about-us',
        element: <AboutUs />,
      },
      {
        path: '/my-cartoons',
        element: <UserCartoons />,
      },
      {
        path: '/user-deletion-request',
        element: <UserDeleteReport />,
      }
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
}
export default App;
