import React, { useState } from 'react';
import classes from './MyBooksTab.module.css';
import Tab from '../../../../components/tab/Tab';
import { LanguageContext } from '../../../../context/LanguageContext';

const MyBooksTab = (props) => {
  const { onClick, setActiveTab, keys, pageName, defaultClasses, fs} = props;
  const [isActive, setIsActive] = useState(
    sessionStorage.getItem(pageName) ?? 0
  );
  const userBooksSectionsHandler = (data) => {
    let tabs = [];
    keys.forEach((key) => {
      if (data && data[key]) {
        tabs.push(data[key]);
      }
    });
    return tabs;
  };

  const tabsClickHandler = (id) => {
    onClick(id, 1);
    setActiveTab(id)
    setIsActive(id);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const data = userBooksSectionsHandler(ctx?.data);
        return (
          <div className={`d-flex justify-content-center w-100`}>
            <div className={`${classes.my_books_tab_item_container} d-flex w-100 justify-content-start ${defaultClasses ? defaultClasses : ''}`}>
              {data?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    activeTab={+isActive === index}
                    onClick={() => tabsClickHandler(index)}
                    title={item[ctx.language]}
                    fs={fs}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default MyBooksTab;
