import axios from 'axios';
import Cookies from 'js-cookie';
import { suggestedCartoonsAction } from './SuggestedCartoonsSlice';

const suggestedCartoons = (searchText) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const body = {searchText}
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/search/suggestion`;
      const response = await axios.post(url, body, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      let suggestedCartoons = data.data.cartoons
      dispatch(suggestedCartoonsAction.suggestedCartoonsHandler({ suggestedCartoons}));
    } catch (e) { }
  };
};

export default suggestedCartoons;