import axios from 'axios';
import Cookies from 'js-cookie';
import { preferanceAction } from './PreferenceSlice';

const fetchPreferance = (ageGroup, categories) => {
  return async (dispatch) => {
    const body = { ageGroup, categories };
    const auth = `Bearer ${Cookies.get('auth_token')}`;
    const headers = { 'Content-Type': 'application/json', Authorization: auth };
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/user/preference`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers,
      });
      return response;
    };
    try {
      const preferance = await fetchData();
      return dispatch(preferanceAction.preferanceHandler({ preferance }));
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};

export default fetchPreferance;
