import React from 'react';
import classes from './FilterToolbar.module.css';

const FilterToolbar = (props) => {
  const {onClick, title, id, img, className} = props
  
  return (
    <div className={` ${classes.products_container} d-flex align-items-center w-100 flex-nowrap`} onClick={onClick}>
      <div className={`${classes.title_container_filter} d-flex align-items-center w-100 flex-nowrap`}>
        <div className={`d-flex align-items-center w-100 flex-nowrap`}>
          <span className={`${classes.title} text-nowrap`}>{title}</span>
        </div>
        <div
          className={`${classes.show_product} d-flex align-items-center w-100 flex-nowrap`}
          id={id}
        >
          <img src={img} className={className} alt=''/>
        </div>
      </div>
    </div>
  );
};

export default FilterToolbar;
