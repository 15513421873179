import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {data:[], pageCount:0, status: null}};

const purchasedProductsSlice = createSlice({
  name: 'purchasedProducts',
  initialState,
  reducers: {
    purchasesAllHandler(state, action) {
      state.data = action.payload;
    },
  },
});
export const purchasesAllAction = purchasedProductsSlice.actions;
export default purchasedProductsSlice;
