import { operationWithVideoPlayer } from '../../../../../utils/helpers/operationWithVideoPlayer/operationWithVideoPlayer';
import classes from './Video.module.css';
import React, { useEffect, useState } from 'react';

const Video = (props) => {
  const { vidRef, src, id, setIsPlay, className } = props;
  const [volume, setVolume] = useState('');
  const [isVolume, setIsVolume] = useState(false);

  const ontimeupdate = () => {
    const video = document.getElementById('cartoon_video');
    const timeline = document.getElementById('timeLine');
    const playButton = document.getElementById('playButton');
    const percentagePosition = (100 * video.currentTime) / video.duration;
    if (percentagePosition) {
      timeline.style.backgroundSize = `${percentagePosition}% 100%`;
      timeline.value = percentagePosition;
    } else {
      timeline.value = 0;
      timeline.style.backgroundSize = `${0}% 100%`;
    }

    if (video.currentTime === video?.duration) {
      playButton.src = '../assets/video/play.svg';
    }
  };

  const volumeHandler = () => {
    const video = document.getElementById('cartoon_video');
    const volumeTimeLine = document.getElementById('volumeTimeLine');
    const volumeButton = document.getElementById('volumeButton');
    video.muted = !video.muted;
    if (video.muted) {
      volumeTimeLine.value = '0';
      const time = volumeTimeLine.value / 100;
      video.volume = time;
      volumeTimeLine.style.backgroundSize = `0% 100%`;
      volumeButton.src = '../assets/video/volumeMin.svg';
    } else if (+volume >= 50) {
      volumeTimeLine.value = `${volume}`;
      const time = volumeTimeLine.value / 100;
      video.volume = time;
      volumeTimeLine.style.backgroundSize = `${volume}% 100%`;
      volumeButton.src = '../assets/video/volumeMax.svg';
    } else if (+volume !== 0) {
      volumeTimeLine.value = `${volume}`;
      const time = volumeTimeLine.value / 100;
      video.volume = time;
      volumeTimeLine.style.backgroundSize = `${volume}% 100%`;
      volumeButton.src = '../assets/video/volumeMid.svg';
    } else {
      volumeTimeLine.value = `${50}`;
      const time = volumeTimeLine.value / 100;
      video.volume = time;
      volumeTimeLine.style.backgroundSize = `${50}% 100%`;
      volumeButton.src = '../assets/video/volumeMid.svg';
    }
  };

  const changeTimeline = (e) => {
    const video = document.getElementById('cartoon_video');
    const timeLine = document.getElementById('timeLine');
    const time = (e.target.value * video.duration) / 100;
    video.currentTime = time;
    const percentagePosition = e.target.value;
    timeLine.style.backgroundSize = `${percentagePosition}%`;
  };

  const changeValume = (e) => {
    setVolume(e.target.value);
    const video = document.getElementById('cartoon_video');
    const volumeButton = document.getElementById('volumeButton');
    const volumeTimeLine = document.getElementById('volumeTimeLine');
    const time = e.target.value / 100;
    video.volume = time;
    const percentagePosition = e.target.value;
    volumeTimeLine.style.backgroundSize = `${percentagePosition}%`;
    if (+e.target.value === 0) {
      video.muted = true;
    }
    if (+e.target.value === 0) {
      volumeButton.src = '../assets/video/volumeMin.svg';
    } else if (+e.target.value !== 0 && video.muted) {
      video.muted = false;
    } else if (+e.target.value <= 50) {
      volumeButton.src = '../assets/video/volumeMid.svg';
    } else {
      volumeButton.src = '../assets/video/volumeMax.svg';
    }
  };

  useEffect(() => {
    const volumeTimeLine = document.getElementById('volumeTimeLine');
    const volumeButton = document.getElementById('volumeButton');
    if (volume === '') {
      volumeTimeLine.style.backgroundSize = `50% 100%`;
      volumeButton.src = '../assets/video/volumeMid.svg';
    }
  }, [volume]);

  return (
    <div className='position-relative w-100'>
      <div>
        <video
          ref={vidRef}
          onTimeUpdate={() => ontimeupdate()}
          id={id}
          src={src}
          className={`${classes.iframe_container} ${className ? className : ''}
        ${classes.border_radius_10}
        ${classes.video_container} position-relative`}
        />
      </div>
      <div
        className={`${classes.controls} ${classes.video_items_container} position-absolute`}
      >
        <div className={`${classes.timeline_change}`}>
          <div
            onClick={() => operationWithVideoPlayer.playHandler(setIsPlay)}
            className={`${classes.img_container} ${classes.display_flex}`}
          >
            <img id='playButton' src='../assets/video/play.svg' alt='' />
          </div>
          <div
            className={`${classes.display_flex} ${classes.gap_5} ${classes.right_10}`}
            onMouseEnter={() => setIsVolume(true)}
            onMouseLeave={() => setIsVolume(false)}
          >
            <div onClick={volumeHandler}>
              <img
                id='volumeButton'
                src='../assets/video/volumeMin.svg'
                alt=''
              />
            </div>
            <div
              className={isVolume ? `${classes.volume_container} d-flex align-items-center` :'d-none'}>
              <input
                id='volumeTimeLine'
                type='range'
                min='0'
                max='100'
                className={`${classes.timeline}`}
                defaultValue={'50'}
                onChange={changeValume}
              />
            </div>
          </div>
          <input
            type='range'
            min='0'
            max='100'
            className={`${classes.timeline} ${classes.max_width_none} w-100 justify-content-center w-100`}
            defaultValue={'0'}
            id='timeLine'
            onChange={changeTimeline}
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
