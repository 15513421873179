import React, { useState } from 'react';
import classes from './SortByPopular.module.css';
import BackDrop from '../../../components/backDrop/BackDrop';
import { LanguageContext } from '../../../context/LanguageContext';
import { constantData } from '../../../utils/helpers/constantData';

const SortByPopular = (props) => {
  const { setLang, setSort } = props;
  const [showData, setShowData] = useState(false);
  const [sortTitle, setSortTitle] = useState({ type: '', increase: '' });

  const sorting = () => {
    setShowData(!showData);
  };

  const sortingHandler = (type, increase, language) => {
    setLang(language)
    if (type === 'rating') {
      setSort(constantData.sortingKeys[2])
    } else if (increase === 'increase') {
      setSort(constantData.sortingKeys[0])
    } else {
      setSort(constantData.sortingKeys[1])
    }
    setSortTitle({ type: type, increase: increase });
  };

  const sortingDataHandler = (data, lang) => {
    const arr = [];
    const type = ['name_a_z', 'name_z_a', 'rating'];

    type.forEach((item, index) => {
      if (data && lang) {
        arr.push({
          title: data[item][lang],
          increase: item.includes('_a_z') ? 'increase' : '',
          name: item.includes('name') ? 'name'  : 'rating'
        });
      }
    });
    return arr
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        const sortingData = sortingDataHandler(ctx.data, ctx.language);

        return (
          <>
            <div
              className={`${classes.data_sort_container} ${
                showData ? classes.animation_container : classes.animation_hidden
              } d-flex flex-wrap justify-content-evenly position-absolute end-0`}
             >
              <div className={`${classes.sorting_title} d-flex justify-content-between w-100`} onClick={sorting}>
                <div className={`${classes.sort_item_data} overflow-hidden`}>
                  <span>
                    {
                      (sortTitle.type === 'name' &&
                      sortTitle.increase === 'increase'
                        ? ctx.data?.name_a_z[ctx.language]
                        : sortTitle.type === 'name' && sortTitle.increase === ''
                        ? ctx.data?.name_z_a[ctx.language]
                        : sortTitle?.type === 'rating'
                        ? ctx.data?.rating[ctx.language]
                        : ctx.data?.sort_by[ctx.language])}
                  </span>
                </div>
                <div className={`d-flex align-items-center`}>
                  <img
                    src={
                      showData
                        ? '../assets/products/sort/sortProductHidden.svg'
                        : '../assets/products/sort/sortProductShow.svg'
                    }
                    alt=''
                  />
                </div>
              </div>
              {showData && (
                <div className={`${classes.sort_data} ${classes.animation} d-flex flex-wrap align-items-start`}>
                  {sortingData.map((item) => {
                    return (
                      <span
                      className='w-100'
                        key={`sort_by_${item.title}`}
                        onClick={() => {
                        sortingHandler(item.name, item.increase, ctx.language);
                        setShowData(false);
                        }}
                      >
                        {item.title}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
            {showData && <BackDrop onClick={() => setShowData(false)} />}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default SortByPopular;