import { createSlice } from '@reduxjs/toolkit';

const initialState = {allCartoons:{data:[], status:null}};

const allCartoonsSlice = createSlice({
  name: 'allCartoons',
  initialState,
  reducers: {
    allCartoonHandler(state, action) {
      state.allCartoons = action.payload.allCartoons;
    },
  },
});
export const allCartoonsAction = allCartoonsSlice.actions;
export default allCartoonsSlice;
