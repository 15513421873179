import React from 'react';
import { Spinner } from 'react-bootstrap';
import classes from './Loader.module.css';

const Loader = (props) => {
  const {audio, auth, className, height} = props
  return (
    <div className={`${classes.spinner_container} ${audio ? `${classes.background_transparent} position-absolute` : ''} ${!auth ? height ? classes.height_50 : classes.height_100 : ''} ${className} d-flex w-100 justify-content-center align-items-center`}>
      <Spinner animation='border' role='status' />
    </div>
  );
};

export default Loader;
