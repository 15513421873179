import React, { useState } from 'react';
import classes from './Listen.module.css';
import Episodes from './episodes/Episodes';
import ListenTitle from './title/ListenTitle.js';
import Modals from '../../../components/modal/Modals';
import ProductImage from './productImage/ProductImage';
import AudioPlayer from './audio/audioPlayer/AudioPlayer';
import { LanguageContext } from '../../../context/LanguageContext';

const Listen = (props) => {
  const { listen, data, onHide} = props;
  const [next, setNext] = useState(false);
  const [disabled, setDisabled] = useState([]);
  const [autoPlay, setAutoPlay] = useState(false);
  const [chooseEpisode, setChooseEpisode] = useState(0);
  const [activeEpisode, setActiveEpisode] = useState(0);
  const [episode, setEpisode] = useState(data?.audioBook[0]?.episode);

  const NextHandler = () => {
    setNext(true);
    if (activeEpisode <= data.audioBook.length) {
      setActiveEpisode(
        chooseEpisode !== 0 ? chooseEpisode + 1 : activeEpisode + 1
      );
    } else {
      setActiveEpisode(activeEpisode);
    }
  };

  const PrevHandler = () => {
    setNext(true);
    if (activeEpisode !== 0) {
      setActiveEpisode(activeEpisode - 1);
    } else {
      setActiveEpisode(0);
    }
  };

  const chooseEpisodeHandler = (episode) => {
    setChooseEpisode(episode);
  };
  
  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <Modals show={listen} onHide={onHide}>
            <div
              className={`${classes.product_container} d-flex flex-wrap justify-content-center`}
            >
              <div className={`${classes.data_container} d-flex`}>
                <ProductImage
                  imgUrl={`${process.env.REACT_APP_DEV_URI}${data.image.imageURL}`}
                />
                <div
                  className={`${classes.product_data_container} d-flex flex-wrap`}
                >
                  <ListenTitle booksName={data.name[ctx.language]} />
                  <div>
                    <span className={`w-100 overflow-hidden ${classes.description}`}>{data.description[ctx.language]}</span>
                  </div>
                  <AudioPlayer
                    setEpisode={setEpisode}
                    episode={episode}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    data={data}
                    prevOnClick={PrevHandler}
                    nextOnClick={NextHandler}
                    listen={listen}
                    chooseEpisode={chooseEpisode}
                    autoPlay={autoPlay}
                    setAutoPlay={setAutoPlay}
                    episodeData={chooseEpisode}
                  />
                </div>
              </div>
              <Episodes
                episode={episode}
                setActiveEpisode={setActiveEpisode}
                setEpisode={setEpisode}
                setDisabled={setDisabled}
                disabled={disabled}
                data={data}
                activeEpisode={activeEpisode}
                setEpisodeOnClick={setActiveEpisode}
                chooseEpisodeHandler={chooseEpisodeHandler}
                next={next}
                setNext={setNext}
                autoPlay={autoPlay}
                setAutoPlay={setAutoPlay}
              />
            </div>
          </Modals>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Listen;
