import axios from 'axios';
import Cookies from 'js-cookie';
import { productRatingAction } from './ProductRatingSlice';

const productRating = (productId, rating) => {
  const token = Cookies.get('auth_token');
  return async (dispatch) => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/user/rateproduct`;
      const body = { productId, rating };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(url, body, {headers});
      return response;
    };
    try {
        const rating = await fetchData()
        if(rating.status === 200) {
            return (
                dispatch(productRatingAction.ratingHandler({rating:{data: rating.data, status:rating.status}}))
            )
        }
    } catch(e) {
        return e
    }
  };
};

export default productRating;
