import { createSlice } from '@reduxjs/toolkit';
const initialState = { suggestedCartoons: []};

const suggestedCartoonsSlice = createSlice({
  name: 'suggestedCartoons',
  initialState,
  reducers: {
    suggestedCartoonsHandler(state, action) {
      state.suggestedCartoons = action.payload.suggestedCartoons;
    },
  },
});
export const suggestedCartoonsAction = suggestedCartoonsSlice.actions;
export default suggestedCartoonsSlice;