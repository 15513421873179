import React from 'react';
import classes from './UserAccountComponentTitle.module.css';

const UserAccountComponentTitle = (props) => {
  const { title, email, user} = props;
  return (
    title && (
      <div className={`${email ? classes.title_data_container  : ''} d-flex align-items-center`}>
        <span className={`${user ? classes.title : classes.light_grey_title } ${classes.title_data}`}>{title}</span>
      </div>
    )
  );
};

export default UserAccountComponentTitle;
