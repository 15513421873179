import UserCategories from '../UserCategories';
import classes from './EditCategory.module.css';
import { useDispatch } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/user/UserContext';
import { LanguageContext } from '../../../../context/LanguageContext';
import fetchPreferance from '../../../../store/edit/preference/FetchPreference';
import ComponentsTitle from '../../userAccountTitle/componentsTitle/ComponentsTitle';
import ChangeDataButtons from '../../../../components/user/editData/changeDataButtons/ChangeDataButtons';

const EditCategory = (props) => {
  const {setData, data, categoriesData,ageData} = props
  const dispatch = useDispatch();
  const [categoryData, setCategoryData] = useState([]);
  const { user, setUserData } = useContext(UserContext);
  const [editCategory, setEditCategory] = useState(false);

  const cancel = (e) => {
    e.preventDefault();
    setEditCategory(false);
    setCategoryData(user.preference.categories);
  };

  useEffect(() => {
    setEditCategory(data.includes('categories'))
  }, [data])

  useEffect(() => {
    if(data !== 'categories') {
      setCategoryData(user.preference?.categories)
    }
  }, [data, user])


  useEffect(() => {
    if (user.preference) setCategoryData(user.preference?.categories);
  }, [user]);

  const onChangeaCategory = (e) => {
    if (e.target.checked && !categoryData.includes(e.target.value)) {
      setCategoryData([...categoryData, e.target.value]);
    } else {
      const deleteCategory = categoryData.filter(
        (data) => data !== e.target.value
      );
      setCategoryData(deleteCategory);
    }
  };

  const categoryHandler = async (e) => {
    e.preventDefault();
    let mutableUser = { ...user };
    const response = await dispatch(
      fetchPreferance(ageData, categoryData)
    );
    if (response.payload?.preferance.status === 200) {
      let userPreferences = JSON.parse(JSON.stringify(mutableUser.preference));
      userPreferences.categories = categoryData;
      mutableUser['preference'] = userPreferences;
      setUserData(mutableUser);
      setEditCategory(false);
    }
  };

  useEffect(() => {
    categoriesData(categoryData);
  }, [props, categoryData, categoriesData]);

  const showdata = () => {
    setEditCategory(true);
    setData('categories')
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <ComponentsTitle
              title={
                
                ctx.data?.categories[ctx.language]
              }
            />
            <form
              onSubmit={categoryHandler}
              className={`${classes.gap_20} w-100 flex-wrap d-flex`}
            >
              <UserCategories
                checkedData={categoryData}
                editCategoryClick={showdata}
                onChangeaCategory={onChangeaCategory}
                hidden={true}
              />
              {editCategory  && (
                <ChangeDataButtons cancel={cancel} />
              )}
            </form>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default EditCategory;
