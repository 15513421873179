import { createSlice } from '@reduxjs/toolkit';

const initialState = {allAuthors:{data:{}, status:null}};

const allAuthorsSlice = createSlice({
  name: 'allAuthors',
  initialState,
  reducers: {
    allAuthorsHandler(state, action) {
      state.allAuthors = action.payload;
    },
  },
});
export const allAuthorsAction = allAuthorsSlice.actions;
export default allAuthorsSlice;
