import React from 'react';
import styles from './Blocks.module.css';

const DeveloperInformationContainer = (props) => {
  const { children } = props
  return (
    <div className={`${styles.company_information} justify-content-between d-flex w-100 align-items-start`}>{children}</div>
  );
};

export default DeveloperInformationContainer