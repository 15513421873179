import React from 'react';
import classes from './ProductImage.module.css'

const ProductImage = (props) => {
  const {imgUrl} = props
  
  return (
    <div className={classes.product_img_container}>
      <img className='w-100 h-100' src={imgUrl} alt='' />
    </div>
  );
};
export default ProductImage;
