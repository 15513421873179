import React from 'react';
import classes from './PrevButton.module.css';
import Button from '../../buttons/authButton/Button';

const PrevButton = (props) => {
  const {prevButtonClick, image } = props
  return (
    <div >
      {image && (
        <Button id='prevButton' imgStyle='h-100' onClick={prevButtonClick} image={image} buttonStyle={`${classes.code_verification_prev} border-0 d-flex justify-content-center align-items-center p-0`}/>
      )}
    </div>
  );
};

export default PrevButton;