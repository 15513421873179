import { useEffect } from 'react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './UploadCartoonCategory.module.css';
import UploadCartoonTitle from '../uploadCartoonTitle/UploadCartoonTitle';
import { LanguageContext } from '../../../../../context/LanguageContext';
import ErrorMessage from '../../../../../components/error/ErrorMessage';
import fetchCartoonCategories from '../../../../../store/cartoons/categories/FetchCartoonCategories';
import CategoryCard from '../../../../../components/card/categoryCard/CategoryCard';

const UploadCartoonCategory = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const { onChange, categoryData, uploadCartoon, containsError } = props;
  const categories = useSelector(
    (state) => state.cartoonCategories.cartoonCategories
  );

  useEffect(() => {
    dispatch(fetchCartoonCategories());
  }, [dispatch]);

  useEffect(() => {
    if (containsError) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [containsError]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.gap_5} flex-wrap position-relative d-flex`}
          >
            <UploadCartoonTitle
              setShow={setShow}
              show={show}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              title={ctx.data?.categories[ctx.language]}
            />
            <div
              className={`${classes.categories_item_container} d-flex flex-wrap w-100 justify-content-start w-100`}
            >
              {categories.map((item) => {
                return (
                  <CategoryCard
                    key={item._id}
                    data={item}
                    onChange={onChange}
                    hidden={uploadCartoon}
                    categories={categoryData}
                    className={`${classes.category_card} w-100`}
                  />
                );
              })}
            </div>
            {isError && (
              <ErrorMessage text={ctx.data?.error_meesage[ctx.language]} />
            )}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonCategory;
