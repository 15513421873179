export const filterProducts = {
  productsTypes(name, lang) {
    let data = [];
    if (name && lang) {
      ['book', 'audiobook'].forEach((item) => {
        data.push({
          name: name[item][lang],
          _id: item,
        });
      });
    }
    return data;
  },

  productsrating(filter) {
    let rating = [];
    const rate = filter ? ['', '1-2', '2-3', '3-4', '4-5'] : ['1-2', '2-3', '3-4', '4-5'];
    for (let i in rate) {
      rating = [
        ...rating,
        {
          starId: filter ? +i + 1 : +i + 2,
          imageUrl: `../assets/stars/star${rate[i]}.svg`,
          _id: filter ? +i + 1 : +i + 2,
        },
      ];
    }
    return rating;
  },
};
