import { createSlice } from '@reduxjs/toolkit';
const initialState = { allPlayLists: {allPlayLists:[], playlistCount: 0}};

const allPlayListsSlice = createSlice({
  name: 'allPlayLists',
  initialState,
  reducers: {
    allPlayListsHandler(state, action) {
      state.allPlayLists = action.payload.allPlayLists;
    },
    resetPlaylist: () => initialState
  },
});
export const allPlayListsAction = allPlayListsSlice.actions;
export default allPlayListsSlice;