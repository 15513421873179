import axios from 'axios';
import Cookies from 'js-cookie';
import { uploadedCartoonsAction } from './UploadedCartoonsSlice';

const uploadedCartoons = (limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/uploaded/cartoons?limit=${limit ?? '*'}&page=${page ?? '*'}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(uploadedCartoonsAction.uploadedCartoonsHandler({data}));
    } catch (e) {
      return e;
    }
  };
};

export default uploadedCartoons;