import React from 'react';
import classes from './DownloadApp.module.css';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const DownloadApp = (props) => {
  const { os, onClick } = props;

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`d-flex w-100 ${classes.align_items} ${classes.gap_5} ${classes.justify_content}`}
          >
            <div
              className={`d-flex w-100 justify-content-center h-100 ${classes.height_100} ${classes.data_container} ${classes.max_height_50}`}
            >
              <div
                className={`d-flex ${classes.gap_20} ${classes.flex_wrap} w-100 justify-content-center`}
              >
                <span
                  className={`${classes.font_weight_500} ${classes.centered} ${classes.text_size} ${classes.text_align_center} ${classes.text_wrap}`}
                >
                  {ctx?.data?.please_download[ctx.language]}{' '}
                </span>
                <Link
                  className={`${classes.main_color} ${classes.font_weight_500} ${classes.max_width_100} ${classes.centered} ${classes.margin_right_20} d-flex align-items-center`}
                  to={
                    os === 'iOS'
                      ? 'https://apps.apple.com/am/app/vinni/id1569213680'
                      : 'https://play.google.com/store/apps/details?id=am.dynamic.vinni'
                  }
                >
                  <img
                    src={`../assets/footer/${os === 'iOS' ? 'app' : 'google'}.png`}
                    alt=''
                    className='w-100'
                  />
                </Link>
              </div>
            </div>
            <div
              className={`d-flex justify-content-end align-items-center flex-wrap ${classes.padding_top_5}`}
            >
              <img
                src='../assets/icons/cancel/cancelModal.svg'
                alt=''
                className={`${classes.max_width_20} ${classes.img_cancel}`}
                onClick={onClick}
              />
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default DownloadApp;
