import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Bestsellers.module.css';
import Titles from '../../../components/title/Titles';
import { LanguageContext } from '../../../context/LanguageContext';
import CardBestsellers from '../../../components/card/cardBestsellers/CardBestsellers';
import Button from '../../../components/buttons/authButton/Button';

const Bestsellers = (props) => {
  const navigate = useNavigate();
  const { bestsellers, categories, lang} = props;

  const navigateToBooks = () => {
    navigate('/products');
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`${classes.data_container} d-flex w-100 align-items-center justify-content-center`}
          >
            <Titles
              title={{
                subtitle: ctx.data?.read_with_us[ctx.language],
                title: ctx.data?.bestsellers[ctx.language],
              }}
            />
            <div
              className={`${classes.bestsellers_container} d-flex w-100 align-items-center flex-wrap justify-content-center`}
            >
              <div
                className={`${classes.cards_container} ${classes.bestsellers_container} w-100 d-flex w-100 align-items-center flex-wrap`}
              >
                {bestsellers?.map((bestseller) => (
                  <CardBestsellers
                  titles={['name', 'author']}
                    bestsellers={true}
                    data={bestseller}
                    img={bestseller.image.imageURL}
                    categories={categories && categories}
                    key={`${bestseller._id}`}
                    lang={lang}
                    className={classes.bestsellersCard}
                  />
                ))}
              </div>
            </div>
            <Button
              divStyle={`d-flex w-100 align-items-center justify-content-center`}
              buttonStyle={`${classes.more_books_container} border-0 text-center align-items-center d-flex justify-content-center`}
              onClick={navigateToBooks}
              text={ctx.data?.view_more[ctx.language]}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default Bestsellers;
