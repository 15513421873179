import axios from 'axios';
import Cookies from 'js-cookie';
import { addToFavoriteProductsAction } from './AddToFavoriteProductsSlice';

const addToFavoriteProducts = (productId) => {
  const token = Cookies.get('auth_token');

  return async (dispatch) => {
    const fetchData = async () => {
      const body = { productId };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const url = `${process.env.REACT_APP_DEV_URI}/user/wantread`;
      const response = await axios.post(url, body, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(addToFavoriteProductsAction.addToFavoriteProductsHandler({data: data.data, status: data.status}));
    } catch (e) {
      const response = dispatch(addToFavoriteProductsAction.addToFavoriteProductsHandler({ status: e.response.status}));  
      return response;
    }
  };
};

export default addToFavoriteProducts;
