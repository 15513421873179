import loginSlice from './login/LoginSlice';
import logOutSlice from './logOut/LogOutSlice';
import filterSlice from './filter/FilterSlice';
import { configureStore } from '@reduxjs/toolkit';
import productsSlice from './products/ProductsSlice';
import bestsellersSlice from './bestsellers/BestsellersSlice';
import popularSlice from './filter/PopularSlice';
import localizationSlice from './localization/LocalizationSlice';
import similiarSlice from './similiar/SimiliarSlice';
import userPurchasesSlice from './purchases/userPurchases/UserPurchasesSlice';
import newPurchasesSlice from './purchases/newPurchases/NewPurchasesSlice';
import downLoadSlice from './purchases/downLoad/DownLoadSlice';
import editPhoneNumberSlice from './edit/editPhoneNumber/EditPhoneNumberSlice';
import preferanceSlice from './edit/preference/PreferenceSlice';
import deletePurchasesSlice from './purchases/deletePurchases/DeletePurchasesSlice';
import cartoonDataSlice from './cartoons/cartoon/CartoonSlice';
import cartoonSlice from './cartoons/cartoon/CartoonSlice';
import uploadCartoonSlice from './cartoons/uploadCartoon/UploadCartoonSlice';
import ageGroupsSlice from './ageGroups/AgeGroupsSlice';
import addPlaylistSlice from './cartoons/addPlayList/AddPlayListSlice';
import addWatchLaterSlice from './cartoons/watchLater/WatchLaterSlice';
import cartoonCategoriesSlice from './cartoons/categories/CartoonCategoriesSlice';
import searchCartoonSlice from './cartoons/search/SearchCartoonSlice';
import cartoonRatingSlice from './cartoons/rating/CartoonRatingSlice';
import playListSlice from './cartoons/playList/PlayListSlice';
import allPlayListslice from './cartoons/allPlaylist/AllPlayListsSlice';
import blogSlice from './blog/blog/BlogSlice';
import watchCartoonSlice from './cartoons/watchCartoon/WatchCartoonSlice';
import recommendedCartoonsSlice from './cartoons/recommendedCartoons/RecommendedCartoonsSlice';
import suggestedCartoonsSlice from './cartoons/suggestedCartoons/SuggestedCartoonsSlice';
import suggestedProductsSlice from './products/suggestedProducts/SuggestedProductsSlice';
import updatePlaylistsSlice from './cartoons/updatePlaylists/UpdatePlaylistsSlice';
import allBlogsSlice from './blog/fetchAllBlogs/AllBlogsSlice';
import updatePlayListSlice from './cartoons/updateCartoonPlaylist/UpdatePlaylistSlice';
import deletePlaylistSlice from './cartoons/deletePlaylist/DeletePlaylistSlice';
import latestProductsSlice from './latestProducts/LatestProductsSlice';
import allAuthorsSlice from './authors/AllAuthorsSlice';
import authorByidSlice from './authors/authorById/AuthorByIdSlice';
import allCartoonsSlice from './cartoons/allCartoons/AllCartoonsSlice';
import favoriteProductsSlice from './favoriteProducts/allFavoriteProducts/favoriteProductsSlice';
import deleteFavoriteProductSlice from './favoriteProducts/deleteFavoriteProduct/DeleteFavoriteProductSlice';
import addToFavoriteProductsSlice from './favoriteProducts/addToFavoriteProducts/AddToFavoriteProductsSlice';
import productCategoriesSlice from './category/ProductCategoriesSlice';
import deleteUserSlice from './user/deleteUser/DeleteUserSlice';
import getUserDataSlice from './user/GetUserDataSlice';
import deleteReadBookSlice from './readBooks/deleteReadBook/DeleteReadBookSlice';
import readedBooksSlice from './readBooks/readedBooks/ReadedBooksSlice';
import addToReadBooksSlice from './readBooks/addToReadBooks/AddToReadBooksSlice';
import similiarCartoonsSlice from './cartoons/similiarCartoons/SimiliarCartoonsSlice';
import purchasedProductsSlice from './purchases/purchasedProducts/PurchasedProductsSlice';
import uploadedCartoonsSlice from './cartoons/uploadedCartoons/UploadedCartoonsSlice';
import editEmailSlice from './edit/editEmail/EditEmailSlice';
import editUserDataSlice from './edit/editUserData/EditUserDataSlice';
import productSlice from './singlePage/ProductSlice';
import codeValidationSlice from './edit/codeValidation/CodeValidationSlice';
import updateTokenSlice from './updateToken/UpdateTokenSlice';
import productRatingSlice from './productRating/ProductRatingSlice';
import watchLaterSlice from './cartoons/watchLater/watchLater/WatchLaterSlice';
import deleteCartoonSlice from './cartoons/deleteCartoon/DeleteCartoonSlice';
import updateCartoonSlice from './cartoons/updateCartoon/UpdateCartoonSlice';
import deleteFromWatchLaterSlice from './cartoons/watchLater/deleteWatchLater/DeleteFromWatchLaterSlice';
import userExistenceSlice from './userExistence/userExistenceSlice';

export const store = configureStore({
    reducer:{
        logOut:logOutSlice.reducer,
        categories:productCategoriesSlice.reducer,
        bestsellers:bestsellersSlice.reducer,
        filter:filterSlice.reducer,
        products:productsSlice.reducer,
        login:loginSlice.reducer,
        popular:popularSlice.reducer,
        localization:localizationSlice.reducer,
        similiar:similiarSlice.reducer,
        singleProduct:productSlice.reducer,
        addToFavoriteProducts:addToFavoriteProductsSlice.reducer,
        favoriteProducts:favoriteProductsSlice.reducer,
        userData:getUserDataSlice.reducer,
        rating:productRatingSlice.reducer,
        uploadedCartoons: uploadedCartoonsSlice.reducer,
        userPurchases: userPurchasesSlice.reducer,
        newPurchases:newPurchasesSlice.reducer,
        file:downLoadSlice.reducer,
        editUserData:editUserDataSlice.reducer,
        editPhoneNumber:editPhoneNumberSlice.reducer,
        deleteUserMe:deleteUserSlice.reducer,
        editEmail:editEmailSlice.reducer,
        codeValidation:codeValidationSlice.reducer,
        purchasedProducts:purchasedProductsSlice.reducer,
        watchCartoon:watchCartoonSlice.reducer,
        preferance:preferanceSlice.reducer,
        deletePurchases:deletePurchasesSlice.reducer,
        cartoonData: cartoonDataSlice.reducer,
        addToReadBooks:addToReadBooksSlice.reducer,
        deleteReadBook:deleteReadBookSlice.reducer,
        readedBooks:readedBooksSlice.reducer,
        allCartoons:allCartoonsSlice.reducer,
        cartoon:cartoonSlice.reducer,
        blog: blogSlice.reducer,
        playList: playListSlice.reducer,
        similiarCartoons:similiarCartoonsSlice.reducer,
        allPlayLists: allPlayListslice.reducer,
        cartoonRating: cartoonRatingSlice.reducer,
        uploadCartoon:uploadCartoonSlice.reducer,
        ageGroups:ageGroupsSlice.reducer,
        allAuthors:allAuthorsSlice.reducer,
        recommendedCartoons:recommendedCartoonsSlice.reducer,
        updatePlaylists:updatePlaylistsSlice.reducer,
        addPlaylist:addPlaylistSlice.reducer,
        addWatchLater:addWatchLaterSlice.reducer,
        cartoonCategories:cartoonCategoriesSlice.reducer,
        suggestedProducts:suggestedProductsSlice.reducer,
        suggestedCartoons:suggestedCartoonsSlice.reducer,
        searchCartoon:searchCartoonSlice.reducer,
        updatePlayList:updatePlayListSlice.reducer,
        deletePlaylist:deletePlaylistSlice.reducer,
        allBlogs:allBlogsSlice.reducer,
        latestProducts:latestProductsSlice.reducer,
        authorById:authorByidSlice.reducer,
        updatedToken:updateTokenSlice.reducer,
        deleteFavoriteProduct:deleteFavoriteProductSlice.reducer,
        watchLater:watchLaterSlice.reducer,
        deleteCartoon:deleteCartoonSlice.reducer,
        updateCartoon:updateCartoonSlice.reducer,
        deletedWatchLater:deleteFromWatchLaterSlice.reducer,
        userExistence:userExistenceSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>

    getDefaultMiddleware({
      thunk: {
        extraArgument: downLoadSlice.reducer,
      },
      serializableCheck: false,
    }),
});
