import React from 'react';
import classes from './CartoonVideo.module.css';
import CartoonCardImage from '../image/CartoonCardImage';

const CartoonVideo = (props) => {
  const {
    onMouseEnter,
    onMouseLeave,
    vidRef,
    id,
    autoPlay,
    handleLoadedMetadata,
    src,
    loaded,
    isHovered,
    cartoon,
  } = props;

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`w-100 position-relative object-fit-cover mw-100 ${
        classes.video_container
      } ${classes.height_160} ${classes.border_radius_10} ${classes.z_index}
       ${autoPlay ? classes.animation : ''} ${classes.height_160}`}
    >
      <div
        className={`w-100 mw-100 object-fit-cover ${classes.border_radius_10} ${classes.video_container}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {(!loaded || !isHovered) && (
          <CartoonCardImage
            cartoonImage={cartoon?.imageUrl}
            ageGroup={cartoon?.ageGroup[0]}
            duration={cartoon?.duration}
            autoPlay={loaded}
          />
        )}
        {(loaded || isHovered) && (
          <video
            ref={vidRef}
            id={id}
            muted={true}
            autoPlay={autoPlay}
            preload='none'
            src={`${process.env.REACT_APP_DEV_URI}${src}`}
            onLoadedMetadata={handleLoadedMetadata}
            className={`w-100 mw-100 object-fit-cover h-100 ${classes.iframe_container} ${classes.video_container} ${classes.border_radius_10} ${classes.position_relative} ${classes.height_160}`}
          />
        )}
      </div>
    </div>
  );
};

export default CartoonVideo;
