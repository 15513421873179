import { createSlice } from '@reduxjs/toolkit';

const initialState = {similiar:[]}
const similiarSlice = createSlice({
    name:'similiar',
    initialState,
    reducers:{
        similiarHandler (state, action) {
            state.similiar = action.payload.similiar
        }
    }
})

export const similiarAction = similiarSlice.actions
export default similiarSlice