import Cookies from 'js-cookie';
import {useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import NavBar from '../components/navBar/NavBar';
import getUserData from '../store/user/GetUserData';
import { useDispatch, useSelector } from 'react-redux';
import TabBar from '../components/navBar/tabBar/TabBar';
import { UserContext } from '../context/user/UserContext';
import updateToken from '../store/updateToken/UpdateToken';
import { LanguageContext } from '../context/LanguageContext';
import { constantData } from '../utils/helpers/constantData';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCountry } from '../utils/helpers/getCountry/getCountry';
import fetchLocalization from '../store/localization/FetchLocalization';
import style from '../components/footer/components/Components.module.css';

const RootLayout = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [country, setCountry] = useState()
  const token = Cookies.get('auth_token');
  const existenceToken = Cookies.get('token')
  const navBar = document.getElementById('navBar');
  const footer = document.getElementById('footer');
  const tabBar = document.getElementById('tabBar');
  const refreshToken = Cookies.get('refresh_token');
  const user = useSelector((state) => state.userData.userData);
  const [userData, setUserData] = useState(user ?? {});
  const preferedLanguage = Cookies.get('preferedLanguage');
  const keys = {'read-book': 'add', 'user-deletion-request':'add', remove: 'remove' };
  const localization = useSelector((state) => state.localization.localization);
  let prefLang = process.env.REACT_APP_LANGUAGES.split(',').find((item) => item === preferedLanguage) ?? 'en';
  const key = location?.pathname.split('/').find((item) => (item === 'read-book' || item === 'user-deletion-request')) ?? 'remove';
  const [language, setLanguage] = useState(prefLang);

  useEffect(() => {
    dispatch(fetchLocalization());
    if (token) {
      dispatch(getUserData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (localization && Object.keys(localization).length > 0) {
      setData(localization);
    }
    setUserData(user.user);
  }, [localization, user]);

  constantData.paths.forEach((item) => {
    if (!location.pathname.includes(item.path)) {
      item.savedItems.map((savedItem) =>
        {window.sessionStorage.removeItem(savedItem)
        window.localStorage.removeItem(savedItem)}
      );
    }
  });

    [navBar, footer, tabBar].forEach((item) => {
      item?.classList[keys[key]](style.display_none);
     });

   if(location.pathname === '/user-deletion-request')  {
    document.getElementById('root').style.padding = '0px'
  } else  {
    document.getElementById('root').style.padding =  'padding: 0 0 300px 0'
  }

    ['single-playlist', 'read-book'].forEach((item) => {
      if (!location.pathname.includes(item)) {
        document.body.style.overflowY = 'auto';
      }
    });

    if(!location.pathname.includes('read-book')) {
      document.body.style.background = '#ffff'
    }


  useEffect(() => {
      window.addEventListener('resize', () => {
        if (window.innerWidth > 850 && window.location.pathname.includes('/settings')) {
          navigate('/');
        }
      });
  }, [navigate, window.location.pathname]);

  useEffect(() => {
    const date = new Date(localStorage.getItem('sessionClose'));
    const refreshUntill = (date - new Date()) / (24 * 60 * 1000);

    const generateAuthToken = async (tokenString) => {
      if (!token && refreshToken) {
        Cookies.remove('auth_token');
        const response = await dispatch(updateToken(tokenString));
        if (response.payload?.updatedToken.status === 200) {
          const expiresIn =
            (new Date(response.payload?.updatedToken.data.expireAt) -
              new Date()) /
            (1000 * 60 * 60 * 24);
          Cookies.set('auth_token', response.payload?.updatedToken.data.token, {
            expires: expiresIn,
          });
          window.location.reload();
        } else {
          Cookies.remove('auth_token');
          Cookies.remove('refresh_token');
        }
      } else if ((!token && !refreshToken)) {
        localStorage.clear();
        sessionStorage.clear();
      }
    };

    if (refreshUntill <= 12) {
      generateAuthToken(refreshToken);
    }
  }, [dispatch, refreshToken, token, location, existenceToken]);

  useEffect(() => {
   getCountry.userCountry(setCountry)
  }, [])


  return (
    <LanguageContext.Provider value={{ language, data, setLanguage }}>
      <UserContext.Provider value={{ user: userData, setUserData }}>
        <NavBar token={token} country={country}/>
        <Outlet />
        <TabBar />
        <Footer /> 
      </UserContext.Provider>
    </LanguageContext.Provider>
  );
};

export default RootLayout;
