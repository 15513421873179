import React, { useEffect } from 'react';
import { Switch } from '@mui/material';
import classes from './ScrollMode.module.css';
import { LanguageContext } from '../../../../../../context/LanguageContext';
import { useLocation } from 'react-router-dom';

const ScrollMode = (props) => {
  const location = useLocation()
  const { isSwitch, scrollHandler } = props;
  const scroll = JSON.parse(sessionStorage.getItem('isScroll'))
  const switchItem = document.getElementsByClassName('MuiSwitch-track')
  const switchThumb = document.getElementsByClassName('MuiSwitch-thumb')

  useEffect(() => {
    if (location && switchItem && switchItem[0]) {
       if((scroll && scroll?.flow === 'scrolled') || !scroll) {
          switchItem[0].style.backgroundColor = '#09BCC1'
          switchThumb[0].style.color = '#09BCC1'
        } else {
          switchItem[0].style.backgroundColor = '#000'
          switchThumb[0].style.color = 'rgb(255, 255, 255)'
        }
    }
  }, [location, switchItem, scroll, switchThumb])

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.switch_container} d-flex justify-content-between align-items-center w-100`}>
            <button className='bg-transparent border-0'>{ctx.data?.scroll_mode[ctx.language]}</button>
            <Switch
              defaultChecked={
                isSwitch === 'true' || isSwitch === null ? true : false
              }
              onChange={scrollHandler}
            />
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default ScrollMode;
