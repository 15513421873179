import { createSlice } from '@reduxjs/toolkit';

const initialState = { addReadBooks: {data:[], status:null}};

const addToReadBooksSlice = createSlice({
  name: 'addToReadBooks',
  initialState,
  reducers: {
    addToReadBooksHandler(state, action) {
      state.addReadBooks = action.payload;
    },
  },
});
export const addToReadBooksAction = addToReadBooksSlice.actions;
export default addToReadBooksSlice;
