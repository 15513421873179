import React from 'react';
import CodeVerification from '../../../../components/modal/auth/codeVerification/CodeVerification';
import ErrorModal from '../../../../components/modal/error/ErrorModal';
import Loader from '../../../../components/spinner/Loader';
import { LanguageContext } from '../../../../context/LanguageContext';
import Modals from '../../../../components/modal/Modals';

const EditUserEmailModals = (props) => {
  const {
    confIsVisible,
    codeDisabled,
    email,
    clickHandler,
    verifyOTP,
    changeCode,
    keyDownHandler,
    keyUpHandler,
    codeVerification,
    activeButton,
    resendCode,
    index,
    error,
    invalidEmail,
    setError,
    setInvalidEmail,
    errorMessage,
    status,
    codeVerificationOnHide,
  } = props;

  const onHide = () => {
    setError(false);
    setInvalidEmail(false);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            {confIsVisible ? (
              <Modals
                login={true}
                show={confIsVisible}
                onHide={codeVerificationOnHide}
              >
                <CodeVerification
                  buttonType={'button'}
                  type='email'
                  disabled={codeDisabled}
                  phoneNumber={email}
                  onFocus={clickHandler}
                  onSubmit={verifyOTP}
                  email={true}
                  onChange={changeCode}
                  show={confIsVisible}
                  onKeyDown={keyDownHandler}
                  onKeyUp={keyUpHandler}
                  codeVerification={codeVerification}
                  activeButton={activeButton}
                  resendCode={resendCode}
                  onHide={codeVerificationOnHide}
                  index={index}
                />
              </Modals>
            ) : null}
            <ErrorModal
              show={error || invalidEmail}
              onHide={onHide}
              errorMessage={
                invalidEmail
                  ? ctx.data.invalid_current_email[ctx.language]
                  : errorMessage ?? ctx.data?.your_session_expired[ctx.language]
              }
            />
            {!status && <Loader />}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default EditUserEmailModals;
