import { useState } from 'react';
import React, { useEffect, useMemo } from 'react';
import { inputIdentifier } from '../../../../utils/inputIdentifier';
import { LanguageContext } from '../../../../context/LanguageContext';
import UploadCartoonsItem from '../uploadCartoonsItem/UploadCartoonsItem';

const UploadCartoonAuthor = (props) => {
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState('am');
  const { uploadAuthorLanguage, author, containsError, cartoonData } = props;

  const changeLanguage = (type) => {
    setLanguage(type);
  };

  const languageData = useMemo(
    () => process.env.REACT_APP_LANGUAGES.split(', '),
    []
  );
  const textInputProps = inputIdentifier.inputIdentifierHandler(
    languageData,
    'author',
    'label'
  );

  useEffect(() => {
    languageData.forEach((item) => {
      const selectedLanguage = document.getElementById(
        textInputProps.inputIds[item]
      );
      if (selectedLanguage) {
        if (containsError) {
          if ((author?.item && author?.item !== '') || language === item) {
            selectedLanguage.style.color = '#09BCC1';
          } else if (author[item] && author?.item !== '' && language !== item) {
            selectedLanguage.style.color = 'black';
          } else if (
            language !== item &&
            (!author.item || author?.item === '')
          ) {
            selectedLanguage.style.color = '#D62626';
          }
        } else {
          if ((author?.item && author?.item !== '') || language === item) {
            selectedLanguage.style.color = '#09BCC1';
          } else {
            selectedLanguage.style.color = 'black';
          }
        }
      }
    });
  }, [containsError, language, author, languageData, textInputProps]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <UploadCartoonsItem
            keys='author'
            cartoonData={cartoonData}
            changeLanguage={changeLanguage}
            selectedLanguage={language}
            languageHandler={uploadAuthorLanguage}
            name={author}
            setShow={setShow}
            show={show}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            placeholder={ctx.data?.author[ctx.language]}
            title={ctx.data?.author[ctx.language]}
            textInputProps={textInputProps}
          />
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default UploadCartoonAuthor;
