import axios from 'axios';
import { suggestedProductsAction } from './SuggestedProductsSlice';

const suggestedProducts = (searchText, type='book', page, ageGroup, authorsId, categories, rating ) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {searchText, type, page, ageGroup, authorsId, categories, rating};
      const url = `${process.env.REACT_APP_DEV_URI}/product/search`;
      const response = await axios.post(url, body, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response;
    };
    try {
      const result = await fetchData();
      const suggestedProducts = result.data.products;
      dispatch(suggestedProductsAction.suggestedProductsHandler({ suggestedProducts }));
    } catch (e) {}
  };
};

export default suggestedProducts;