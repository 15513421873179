import React from 'react';
import classes from './UserAccountComponentData.module.css';
import CategoryImg from '../categoryImg/CategoryImg';
import UserAccountComponentTitle from '../title/UserAccountComponentTitle';

const UserAccountComponentData = (props) => {
  const { title, user, imgUrl, email, edit } = props;
  return (
    <div className={`${classes.gap_20} d-flex`}>
      <CategoryImg imgUrl={imgUrl} />
      <UserAccountComponentTitle
        title={title}
        user={user}
        email={email}
        edit={edit}
      />
    </div>
  );
};

export default UserAccountComponentData;
