import { useEffect } from 'react';
import React, { useState } from 'react';
import classes from './FontFamilyConfiguration.module.css';
import { LanguageContext } from '../../../../../../context/LanguageContext';
import { constantData } from '../../../../../../utils/helpers/constantData';

const FontFamilyConfiguration = (props) => {
  const {fontFamilyHandler} = props
  const [value, setValue] = useState();

  const selectChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    fontFamilyHandler(value);
  }, [props, value]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.font_family_container} d-flex w-100 align-items-center justify-content-between`}>
            <span>{ctx.data?.font[ctx.language]}</span>
            <select
              id='fontFamily'
              className={`${classes.font_family_select}`}
              onChange={selectChange}
            >
              {constantData.fontFamily.map((item) => {
                return (
                  <option value={item} id='option' key={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default FontFamilyConfiguration;
