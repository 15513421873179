import React from 'react';
import classes from './CartoonAgeGroup.module.css';

const CartoonAgeGroup = (props) => {
    const {ageGroup} = props
  return (
    <div className={`${classes.age_group_container} d-flex position-absolute`}>
      <div className={`${classes.age_group_item_container} text-center d-flex align-items-center justify-content-center`}>
        <span className={`${classes.age_group_item} text-center`}>{ageGroup}</span>
      </div>
    </div>
  );
};

export default CartoonAgeGroup;
