export const inputIdentifier = {
  inputIdentifierHandler(languageData, type, part) {
    let textInputProps = {
      inputIds: {},
      labelIds: {},
    };
    languageData.forEach((language) => {
      textInputProps.inputIds[language] = `${type}${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;
      textInputProps.labelIds[language] = `${part}${
        type.charAt(0).toUpperCase() + type.slice(1)
      }${language.charAt(0).toUpperCase() + language.slice(1)}`;
    });
    return textInputProps;
  }
}