import classes from './PlayListItem.module.css'

const PlaylistItemWithNoData = (props) => {
    const {marginRight, marginLeft, title, seeAll} = props

return (
    <div
    className={`${classes.min} col-sm-6 col-md-4 col-lg-3 col-12 col-xl-2 ${classes.list_item}`}
    style={{
      marginRight: `${marginRight}px`,
      marginLeft: `${marginLeft}px`,
    }}
  >
    <div
      style={{ backgroundImage: `url(../assets/video/noVideo.jpeg)` }}
      className={`${classes.main_div} d-flex align-items-end ${classes.no_video} overflow-hidden position-relative`}
    ></div>
    <h4 className={classes.no_video_title}>{title}</h4>
    <h5>{seeAll}</h5>
  </div>
)
}

export default PlaylistItemWithNoData