import React from 'react';
import classes from './AddNewPlayList.module.css'
import Button from '../../../buttons/authButton/Button';
import { LanguageContext } from '../../../../context/LanguageContext';

const AddNewPlayList = (props) => {
 const {playlists, updateExsistingPlaylists, selectIsChecked, allPlayLists, setLimit, limit, defaultLimit, setUpdate, onHide, save, disableSaveButton} = props

 const playListAction = (type) => {
  if(type === 'save') {save()} else onHide()
 }

 return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div
              className={`${classes.playlists} overflow-auto`}
              id='playlists'
             >
              {playlists.map((item, index) => {
                return (
                  <div
                    className={`d-flex align-items-center`}
                    key={`allPlayLists_${item}_${index}`}
                  >
                    <input
                      value={item._id}
                      type='checkbox'
                      className={`${classes.checkbox} form-check-input boxShedow_none`}
                      id={`checkBox_${index}`}
                      onChange={updateExsistingPlaylists(index)}
                      checked={selectIsChecked(item.cartoons, item._id)}
                    />
                    <label
                      htmlFor={`checkBox_${index}`}
                      className={`cursor-pointer ${classes.playlist_name}`}
                    >
                      {item.title}
                    </label>
                  </div>
                );
              })}
              {playlists?.length < allPlayLists.playlistCount ? (
                <Button 
                 divStyle={`w-100 d-flex justify-content-center`}
                 buttonStyle={`${classes.more} mb-0 mt-3 border-0 justify-content-center`}
                 text={`${ctx.data?.more[ctx.language]} ...`}
                 onClick={() => {setLimit(limit + defaultLimit); setUpdate(true)}}
                />
              ) : null}
            </div>
            <div className='d-flex justify-content-end'>
              {['cancel', 'save'].map((item, index) => {
                return (
                  <Button
                  disabled={disableSaveButton}
                  key={`button_${item}`}
                  onClick={() => playListAction(item)}
                    buttonStyle={`${classes[item]} ${
                      index === 0 ? 'bg-transparent' : ''
                    } border-0`}
                    text={ctx.data && ctx.data[item][ctx.language]}
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default AddNewPlayList;
