import { createSlice } from '@reduxjs/toolkit';

const initialState = {data: {status: null}}

const deleteFavoriteProductSlice = createSlice({
    name:'deleteFavoriteProduct',
    initialState,
    reducers: {
        deleteFavoriteProductHandler(state, action) {
            state.data = action.payload;
          },
    }
})

export const deleteFavoriteProductAction = deleteFavoriteProductSlice.actions
export default deleteFavoriteProductSlice