import React, { useEffect, useState } from 'react';
import classes from './RatingCards.module.css';
import { productsRating } from '../../../../utils/helpers/productRating/productRatings';

const RatingCards = (props) => {
  const { data } = props;
  const [src, setSrc] = useState('')

  useEffect(() => {
    productsRating.productRatings(data, setSrc, false)
  }, [data])

  return (
    <div className={`${classes.card_item_img_container} d-flex justify-content-start align-items-center w-100 align-items-start`}>
      <div>
        <img src={src} alt=''/>
      </div>
      <div>
        <span className={`${classes.card_item_rating_text} overflow-hidden`}>
          {`${data.rating?.rate.toFixed(1)}(${
            data.rating?.count
          })`}
        </span>
      </div>
    </div>
  );
};

export default RatingCards;
