import axios from 'axios';
import Cookies from 'js-cookie';
import { playListAction } from './PlayListSlice';

const playlist = (id, limit, page) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/playlist/${id}?limit=${limit}&page=${page}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const playList = await fetchData();
      dispatch(playListAction.playListHandler({playList: {data: playList.data, status: playList.status}}));
    } catch (e) {
      return e;
    }
  };
};

export default playlist;