import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ContactsCard.module.css';

const ContactsCard = (props) => {
  const { className, contactsImg, title, link, data } = props;
  
  return (
    <div
      className={`${classes.contacts_card} ${className} d-flex align-items-center w-100 justify-content-center flex-wrap`}
    >
      <div
        className={`${classes.card_item_data_container} d-flex justify-content-start w-100 flex-wrap`}
      >
        <div
          className={`d-flex align-items-center w-100 justify-content-start`}
        >
          <img className={classes.width_60} src={contactsImg} alt='' />
        </div>
        <div
          className={`${classes.card_item_data} d-flex align-items-center w-100 flex-wrap`}
        >
          <span className={`${classes.adress_text} w-100`}>{title}</span>
          <Link
            className={`${classes.adress_text_data} w-100 text-decoration-none`}
            to={link}
          >
            {data}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ContactsCard;
