import axios from 'axios';
import Cookies from 'js-cookie';
import { editEmailAction } from './EditEmailSlice';

const editEmail = (email) => {
    return async (dispatch) => {
      const body = {email};
      const auth = `Bearer ${Cookies.get('auth_token')}`;
      const headers = {'Content-Type': 'application/json', Authorization: auth }
      const fetchData = async () => {
     const url = `${process.env.REACT_APP_DEV_URI}/user/me/editEmail`; 
        const response = await axios.post(url, body, {
          withCredentials: true,
          headers
        });
        return response;
      };
      try {
        const editEmail = await fetchData()
          return dispatch(editEmailAction.editEmailHandler({ editEmail }));
      } catch (e) {
        const response = dispatch(editEmailAction.editEmailHandler({ editEmail: e.response }))
        return response;
      }
    };
  };
  
  export default editEmail;