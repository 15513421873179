import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './AuthorBooksCard.module.css';
import { LanguageContext } from '../../../context/LanguageContext';

const AuthorBooksCard = (props) => {
  const navigate = useNavigate();
  const { id, img, data, authorName } = props;

  const navigateAuthorPage = () => {
    navigate(`/authors/${id}`);
    window.scroll(0, 0);
  };

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            onClick={navigateAuthorPage}
            className={`${classes.card_author_container} ${classes.padding_10} ${classes.border_radius_12} ${classes.gap_10} w-100 flex-wrap d-flex justify-content-between`}
          >
            <div
              className={`w-100 d-flex justify-content-between`}
            >
              <span className={`${classes.author_name} w-100 text-nowrap`}>
                {authorName}
              </span>
              <div
                className={`${classes.books_text} w-100 font-weight-normal justify-content-end d-flex`}
              >
                <span>{ctx.data?.see_all[ctx.language]}</span>
              </div>
            </div>
            <div
              className={`${classes.gap_20} w-100 d-flex justify-content-between`}
            >
              <div
                className={`${classes.author_img_container} flex-wrap d-flex`}
              >
                <div className={classes.img_container}>
                  <img
                    src={`${process.env.REACT_APP_DEV_URI}${img}`}
                    alt=''
                    className={`${classes.border_radius_12} w-100 h-100`}
                  />
                </div>
              </div>
              <div
                className={`${classes.author_books_container} flex-wrap d-flex justify-content-between w-100 align-items-start`}
              >
                <div
                  className={`${classes.author_books_container} d-flex flex-wrap w-100 align-items-start ${classes.gap_10} ${
                    data.books.length > 1
                      ? 'justify-content-center'
                      : 'justify-content-start'
                  }`}
                >
                  {data.books.map((book) => {
                    return (
                      <div
                        className={classes.books_img_container}
                        key={book?._id}
                      >
                        <img
                          src={`${process.env.REACT_APP_DEV_URI}${book.image.imageURL}`}
                          alt=''
                          className={`${classes.border_radius_12} w-100 h-100`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default AuthorBooksCard;
