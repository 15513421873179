import React from 'react';
import classes from './CartoonDuration.module.css'

const CartoonDuration = (props) => {
    const {duration} = props

    return (
        <div className={`${classes.duration_container} position-absolute`}>
            <div className={`${classes.duration_item_container} d-flex align-items-center justify-content-center`}>
                <span className={classes.duration_item}>
                    {duration ?? ''}
                </span>
            </div>
        </div>
    )
}

export default CartoonDuration