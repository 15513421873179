export const operatinosWithPlaylist = {
  async removeFromPlaylist(id, playList, selectedCartoonId, dispatch, updatePlaylists, cartoonId, setPlayList, setSelectedIndex, setselecteCartoonId) {
    let index = playList.findIndex((item) => item._id === id);
    let selected = playList.findIndex((item) => item._id === selectedCartoonId);

    if (playList.length === 1 || selected === playList.length - 1) {
      await dispatch(
        updatePlaylists({
          playlistId: [cartoonId.playlistId],
          removeCartoonId: [id],
        })
      );
      window.location.href = `/my-playlists`;
    } else {
      let list = [...playList];
      list.splice(index, 1);
      setPlayList(list);

      if (index < selected) {
        setSelectedIndex(selected - 1);
      } else if (index === selected) {
        setselecteCartoonId(list[selected]._id);
        window.history.replaceState(
          { page: selected, isPlaylist: true, id: list[selected]._id },
          ''
        );
      }
    }
    dispatch(
      updatePlaylists({
        playlistId: [cartoonId.playlistId],
        removeCartoonId: [id],
      })
    );
  }
}