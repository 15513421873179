import axios from 'axios';
import Cookies from 'js-cookie';
import { deleteUserAction } from './DeleteUserSlice';

const deleteUser = (_id, token) => {
  return async (dispatch) => {
    const body = { _id};
    const auth = `Bearer ${token}`;
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/user/me/delete`;
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json', Authorization: auth },
      });
      return response;
    };
    try {
      const deleteUser = await fetchData();
      return dispatch(deleteUserAction.deleteUserHandler({ deleteUser }));
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token');
        Cookies.remove('refresh_token');
        window.location.pathname = '/';
        window.history.pushState(null, document.title, window.location.href);
      }
      return e;
    }
  };
};

export default deleteUser;
