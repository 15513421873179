import { useLocation } from 'react-router-dom';
import classes from './VideoControl.module.css'
import ControlPanel from '../controlPanel/ControlPanel';
import React, { useEffect, useState, useCallback } from 'react';
import { videoPlayer } from '../../../utils/helpers/videoPlayer';
import VolumeProgressBar from './volumeProgressBar/VolumeProgressBar';
import DurationProgressBar from './durationProgressBar/DurationProgressBar';
import { firebaseAnalytics, logEvent } from '../../../firebase/FirebaseConfig';

const VideoControl = (props) => {
  let {
    video,
    showPrev,
    prev,
    next,
    showPlayBack,
    showPlayBackMenu,
    setUpdateData,
    isPlay,
    setIsPlay,
    updateData,
    playbackValue,
    visibleControlMenu,
    inputIsFocused,
    iconUrls,
    isNormalScreen,
    isMute,
    setIsMute,
    setMoved,
    moved,
    volumeMoved,
    setVolumeMouved,
    offsetHeight,
    setIsNormalScreen,
    selectedCartoonId,
    addListener,
    setShowControlmentu
  } = props

  const location = useLocation();
  let hour = null
  let min = null
  let second = null
  const controlls = [
    {
      id: 'playback',
      src: iconUrls[6],
      onClick: (event) => showPlayBackMenu(event, !showPlayBack),
      className: `${classes.player_icon} ${classes.cursor_pointer} ${classes.fill_white}`
    },
    {
      id: 'normalScreen',
      src: iconUrls[3],
      onClick: (event) => videoPlayer.changeScreenMode(event, video, iconUrls, isNormalScreen, offsetHeight, setUpdateData, setIsNormalScreen),
      className: `${classes.player_icon} ${classes.cursor_pointer} ${classes.fill_white}`
    }
  ]

  const [volume, setVolume] = useState(50)
  const [isFirst, setisFirst] = useState(true)
  const [curTime, setCurtime] = useState({})
  const [duration, setDuration] = useState({})
  const modal = document.getElementById('defaultModal')

  useEffect(() => {
    videoPlayer.update(updateData, iconUrls[0], setDuration, setCurtime, setVolume, setIsPlay)
  }, [updateData, setIsPlay, iconUrls])

  if (video) {
    let curDuration = video.duration
    hour = parseInt(curDuration / 3600)
    min = parseInt((curDuration - (hour * 3600)) / 60)
    second = parseInt(curDuration - (hour * 3600) - min * 60)
  }

  useEffect(() => {
    if (hour >= 0 && min >= 0 && second >= 0) {
      setDuration({ hour, min, second })
      document.getElementById('durationTime').style.visibility = 'visible'
    }
  }, [hour, min, second, selectedCartoonId])

  useEffect(() => {
    var timer = window.setInterval(() => {
      if (video && video.currentTime >= video.duration - 0.2) {
        next()
      }
      if (video != null && !video.paused) {
        videoPlayer.changeDurationHandler(video)
        videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
      }
    }, 1000 / playbackValue);
    return () => {
      window.clearInterval(timer);
    };
  }, [isPlay, isMute, volume, moved, volumeMoved, playbackValue, props, video, next]);

  useEffect(() => {
    if(video && moved) {
      const timer = window.setInterval(() => {
        videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
      }, 100);
      return () => {
        window.clearInterval(timer);
      };
    }
  }, [moved, video])

  const keydownHandler = useCallback((event) => {
    event.preventDefault()
    switch (event.keyCode) {
      case 32:
      case 75:
        videoPlayer.videoClicked(event, false, video, iconUrls, visibleControlMenu, setIsPlay, setUpdateData)
        break
      case 40:
        videoPlayer.changeVolume(-0.1, video, iconUrls, isFirst, setisFirst, setIsMute, setVolume)
        break
      case 38:
        videoPlayer.changeVolume(0.1, video, iconUrls, isFirst, setisFirst, setIsMute, setVolume)
        break
      case 39:
      case 76:
        videoPlayer.hodeOrShowNext10()
        if (video.currentTime - 10 < video.duration) {
          video.currentTime = video.currentTime + 10
        } else {
          video.currentTime = video.duration
        }
        videoPlayer.changeDurationHandler(video)
        videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
        logEvent(firebaseAnalytics, 'Video_Forwarded')
        break;
      case 37:
      case 74:
        videoPlayer.hodeOrShowPrev10()
        if (video.currentTime > 10) {
          video.currentTime = video.currentTime - 10
        } else {
          video.currentTime = 0
        }
        videoPlayer.changeDurationHandler(video)
        videoPlayer.curentTimeHandler(video, setCurtime, setDuration)
        logEvent(firebaseAnalytics, 'Video_Backwarded')
        break;
      case 77:
        videoPlayer.muteOrUnmute(event, video, iconUrls, isMute, volume, setVolume, setIsMute)
        break
      case 27:
        break
      case 70:
        videoPlayer.changeScreenMode(event, video, iconUrls, isNormalScreen, offsetHeight, setUpdateData, setIsNormalScreen)
        break
      default:
        break;
    }
  }, [isMute, video, iconUrls, isFirst, props, isNormalScreen, setIsPlay, setUpdateData, volume, visibleControlMenu])

  useEffect(() => {
    if (updateData) {
      if (addListener) {
        window.removeEventListener('keydown', keydownHandler)
      } else if (modal === null) {
        window.addEventListener('keydown', keydownHandler)
        videoPlayer.play()
        logEvent(firebaseAnalytics, 'Video_Played')
      } else {
        window.addEventListener('keydown', keydownHandler)
        videoPlayer.pause()
        logEvent(firebaseAnalytics, 'Video_Paused')
      }
    }
  }, [addListener, keydownHandler, isPlay, isFirst, updateData, video, inputIsFocused, modal])

  useEffect(() => {
    if (addListener || modal) {
      window.removeEventListener('keydown', keydownHandler)
    } else {
      window.addEventListener('keydown', keydownHandler);
    }
    return () => window.removeEventListener('keydown', keydownHandler)
  }, [keydownHandler, addListener, modal])

  const setMuteOrUnmute = (event) => {
    videoPlayer.muteOrUnmute(event, video, iconUrls, isMute, volume, setVolume, setIsMute)
  }

  const changeVolumeOnClick = (event) => {
    videoPlayer.volumeClick(event, video, iconUrls, setIsMute, setVolume)
  }

  const volumeElement = document.getElementById('volume')
  useEffect(() => {
    if (location.state?.play && video && volume) {
      video.muted = false
      video.autoPlay = true
      volumeElement.src = '../../assets/video/volumeMax.svg'
      videoPlayer.muteOrUnmute(null, video, iconUrls, true, volume, setVolume, setIsMute)
    } else if (video && volume) {
      localStorage.setItem('firstLoadDone', 0)
      video.muted = true
      video.autoPlay = true
      volumeElement.src = '../../assets/video/volumeMin.svg'
      videoPlayer.muteOrUnmute(null, video, iconUrls, false, volume, setVolume, setIsMute)
    }
  }, [video, volumeElement])

  const onMouseOut = () => {
    if (!volumeMoved) {
      videoPlayer.volumeOverOut(volumeMoved)
    }
  }

  const onMouseUp = () => {
    setVolumeMouved(false)
    videoPlayer.volumeOverOut(false)
  }

  return (
    <div
      className={`${classes['w-98']} ${classes.menu} position-absolute bottom-0 start-0 end-0 d-block user-select-none`}
      id='menu'
      onClick={(event) => {event.preventDefault(); event.stopPropagation(); setMoved(false)}}>
      <DurationProgressBar
        setCurtime={setCurtime}
        setDuration={setDuration}
        video={video}
        moved={moved}
        setMoved={setMoved}
        setShowControlmentu={setShowControlmentu}
      />
      <div
        className={`${classes.hide} d-flex justify-content-between m-auto overflow-hidden ${classes.player_bg} ${classes.py_3}`}
        onMouseOut={onMouseOut}
        onMouseUp={onMouseUp}
        id='controll'
      >
        <div className={`left d-flex my-auto `}>
          <div
           className={`${classes.controll_menu}`}
            id='controllMenu'>
            <ControlPanel
              prev={prev}
              next={next}
              video={video}
              visibleControlMenu={visibleControlMenu}
              setIsPlay={setIsPlay}
              showPrev={showPrev}
              iconUrls={iconUrls}
              setUpdateData={setUpdateData}
            />
          </div>
          <div className={`d-flex ${classes.padding_right_20} ${classes.margin_bottom_3} ${classes.height_25}`}>
            <VolumeProgressBar
              iconUrl={iconUrls[5]}
              volumeMove={volumeMoved}
              setMuteOrUnmute={setMuteOrUnmute}
              changeVolumeOnClick={changeVolumeOnClick}
              setVolumeMouved={setVolumeMouved}
              // onVolumeMouseoMove={videoPlayer.onVolumeMouseoMove}
              volumeMoved={volumeMoved}
              video={video}
              iconUrls={iconUrls}
              setIsMute={setIsMute}
              setVolume={setVolume}
              setCurtime={setCurtime}
              setDuration={setDuration}
            />
            <div className={`d-flex align-items-center ${classes.white} ${classes.time}`} id='time'>
              <p className={`${classes.time_width} m-0 d-block ${classes.width_20} d-block text-end ${classes.hour}`} id='hour'>{curTime.hour > 0 ? `${curTime.hour}:` : ''}</p>
              <p className={`${classes.width_20} d-block ${classes.min}`} id='min'>{curTime.min >= 0 ? `${curTime.min}:` : ''}</p>
              <p className={`${classes.width_20} d-block text-start`}>{curTime.second > 9 ? curTime.second : curTime.second >= 0 ? `0${curTime.second}` : ''} </p>
              <div id='durationTime' className='d-flex'>
                <p>{duration.second > 0 ? '-' : ''}</p>
                <p className={`${classes.time_width} m-0`} >
                  {videoPlayer.handleDuration(duration)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {controlls?.map(item => {
            return <img key={`controlls_${item.id}`} src={item.src} alt={item.id} className={`${item.className} ${item.id === 'playback' ? `${classes.margin_right_10} ${classes.play_back}` : classes.normal_screen}`} id={item.id} onClick={(event) => { item.onClick(event) }} />
          })}
        </div>
      </div>
    </div>
  );
};

export default VideoControl;