import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import Playback from './playback/Playback';
import classes from './VideoPlayer.module.css';
import VideoControl from './videoControl/VideoControl';
import ControlPanel from './controlPanel/ControlPanel';
import { videoPlayer } from '../../utils/helpers/videoPlayer';
import { constantData } from '../../utils/helpers/constantData';
import watchCartoon from '../../store/cartoons/watchCartoon/WatchCartoon';
import { firebaseAnalytics, logEvent } from '../../firebase/FirebaseConfig';

const VideoPlayer = (props) => {
  let playbackValues = useMemo(() => [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],[]);
  const iconUrls = useMemo(() => constantData.iconUrls, []);

  const dispatch = useDispatch();
  const {
    moved,
    setMoved,
    volumeMoved,
    setVolumeMouved,
    cartoonData,
    offsetHeight,
    setIsNormalScreen,
    isNormalScreen,
    selectedCartoonId,
    normal,
    showPrev,
    addListener,
    inputIsFocused,
    setNextCalled,
    prevCall,
  } = props;
  
  const [duration, setDuration] = useState({});
  const [curTime, setCurtime] = useState({});
  const data = cartoonData;
  const vieoRef = useRef(null);
  const [isPlay, setIsPlay] = useState(true);
  const [showPlayBack, setShowPlayBack] = useState(false);
  const [playBack, setPlayBack] = useState(3);
  const [updateData, setUpdateData] = useState(true);
  const [time, setTime] = useState(0);
  const [muted, setMuted] = useState(true);
  const [showControlMenu, setShowControlmentu] = useState(true);
  const timeForMarsAsWatched = 30;

  useEffect(() => {
    if (updateData) {
      setShowPlayBack(false);
      setIsPlay(true);
    }
  }, [props, updateData]);

  useEffect(() => {
    setMuted(false);
  }, [window.location]);

  const fullscreenchange = useCallback(
    (event) => {
      if (
        !document.fullscreenElement &&
        !document.webkitRequestFullscreen &&
        !document.msRequestFullscreen &&
        vieoRef.current
      ) {
        videoPlayer.changeScreenMode(
          event,
          vieoRef.current,
          iconUrls,
          false,
          offsetHeight,
          setUpdateData,
          setIsNormalScreen,
          false
        );
      }
    },
    [vieoRef.current]
  );

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullscreenchange);
    if (window.innerWidth < 820 && showControlMenu) {
      visibleControlMenu();
      videoPlayer.volumeOver();
    }
  }, [showControlMenu, fullscreenchange]);

  const next = (event) => {
    const playOrPause = document.getElementById('playOrPause')
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      vieoRef.current &&
      vieoRef.current.currentTime > 0 &&
      !vieoRef.current.ended &&
      vieoRef.current.readyState > vieoRef.current.HAVE_CURRENT_DATA
    ) {
      vieoRef.current.pause();
    }
    setNextCalled(true);
    playOrPause.src = '../../assets/video/pause.svg'
  };

  const prev = (event) => {
    const playOrPause = document.getElementById('playOrPause')
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      vieoRef.current &&
      vieoRef.current.currentTime > 0 &&
      !vieoRef.current.ended &&
      vieoRef.current.readyState > vieoRef.current.HAVE_CURRENT_DATA
    ) {
      vieoRef.current.pause();
    }
    prevCall();
    playOrPause.src = '../../assets/video/pause.svg'
  };

  const changePlayBack = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    setPlayBack(index);
    vieoRef.current.playbackRate = playbackValues[index];
    setShowPlayBack(false);
  };

  const visibleControlMenu = () => {
    let menu = document.getElementById('controll');
    let duration = document.getElementById('durProgress');
    menu?.classList.remove('hide');
    menu?.classList.add('visible');
    duration?.classList.remove('hide');
    duration?.classList.add('visible');
    if (window.innerWidth < 768) {
      let centralMenu = document.getElementById('center_menu');
      centralMenu.classList.remove('hide');
      centralMenu.classList.add('visible');
      if (document.getElementById('controllMenu')) {
        document.getElementById('controllMenu').style.display = 'none';
      }
    } else if (document.getElementById('controllMenu')) {
      document.getElementById('controllMenu').style.display = 'flex';
    }
  };

  const hideControlMenu = useCallback(() => {
    let menu = document.getElementById('controll');
    let duration = document.getElementById('durProgress');
    if (!showPlayBack && menu && duration) {
      menu.classList.remove('visible');
      menu.classList.add('hide');
      duration.classList.remove('visible');
      duration.classList.add('hide');
      if (window.innerWidth < 768) {
        let centralMenu = document.getElementById('center_menu');
        centralMenu.classList.remove('visible');
        centralMenu.classList.add('hide');
      }
      setShowControlmentu(false);
    }
  }, [showPlayBack]);

  const resizePlyer = useCallback(() => {
    setShowPlayBack(false);
    hideControlMenu();
  }, [hideControlMenu]);

  useEffect(() => {
    window.addEventListener('resize', resizePlyer);
  }, [showPlayBack, resizePlyer]);

  const timer = useRef(null);
  const create = () => {
    timer.current = setTimeout(() => {
      hideControlMenu();
      clearTimeout(timer);
    }, 2500);
  };

  useEffect(() => {
    if (window.innerWidth < 820 && showControlMenu) {
      visibleControlMenu();
      videoPlayer.onMouseOver();
      timer.current = setTimeout(() => {
        hideControlMenu();
        clearTimeout(timer);
      }, 2500);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [showControlMenu]);

  const showPlayBackMenu = (event, show) => {
    event.preventDefault();
    event.stopPropagation();
    setShowPlayBack(show);
  };

  const videoClicked = (event) => {
    if (event.detail === 2) {
      videoPlayer.changeScreenMode(
        event,
        vieoRef.current,
        iconUrls,
        isNormalScreen,
        offsetHeight,
        setUpdateData,
        setIsNormalScreen
      );
    }
    setTime(0);
    if (
      vieoRef.current &&
      vieoRef.current.currentTime > 0 &&
      !vieoRef.current.ended &&
      vieoRef.current.readyState > vieoRef.current.HAVE_CURRENT_DATA &&
      window.innerWidth >= 768 &&
      !moved
    ) {
      if (vieoRef.current.paused) {
        document.getElementById('playOrPause').src = constantData.iconsUrl[0];
        vieoRef.current.play();
      } else {
        document.getElementById('playOrPause').src = constantData.iconsUrl[1];
        vieoRef.current.pause();
      }
      setIsPlay(!isPlay);
    }
    setMoved(false);
  };

  useEffect(() => {
    var timer = window.setInterval(() => {
      if (
        vieoRef &&
        vieoRef.current.currentTime >= vieoRef.current.duration - 0.2
      ) {
        next();
      }
      if (vieoRef.current != null && !vieoRef.current.paused) {
        if (time + 1 === timeForMarsAsWatched) {
          dispatch(
            watchCartoon({
              cartoonId: selectedCartoonId,
              status: 'watched',
            })
          );
          logEvent(firebaseAnalytics, 'Video_Watched');
        }
        setTime(time + 1);
        videoPlayer.changeDurationHandler(vieoRef.current);
        videoPlayer.curentTimeHandler(vieoRef.current, setCurtime, setDuration);
      } else if (time < 10) {
        setTime(0);
      }
    }, 1000 / playbackValues[playBack]);
    return () => {
      window.clearInterval(timer);
    };
  }, [isPlay, playBack, props, vieoRef, next]);

  const onMouseOutCapture = (event) => {
    if (moved) {
      let x = event.clientX;
      let rec = vieoRef.current.getBoundingClientRect();
      let curLeft = x - rec.left;
      let curRight = x - rec.right;
      if (curLeft < 0) {
        videoPlayer.onMouseoMove(event, true, vieoRef.current);
        setMoved(false);
      } else if (curRight > 0) {
        videoPlayer.onMouseoMove(event, true, vieoRef.current);
        setMoved(false);
      }
    }
  };

  const onMouseLeave = () => {
    if (volumeMoved) {
      setVolumeMouved(false);
      videoPlayer.volumeOverOut(false);
    }
  };

  const removeTimer = () => {
    if (timer.current) {
      clearTimeout(timer);
    }
  };

  return (
    <div
      className={`${classes.video_container} ${classes.modal} user-select-none d-flex align-items-center justify-content-center position-relative w-100`}
      id='modal'
      onMouseLeave={onMouseLeave}
      onMouseOver={visibleControlMenu}
      onMouseOut={hideControlMenu}
      onMouseOutCapture={onMouseOutCapture}
      onMouseMove={(event) => videoPlayer.onMouseoMove(event, moved, vieoRef.current)}
      onTouchMove={removeTimer}
      onTouchEnd={create}
      onClick={(event) => {
        videoClicked(event);
        setShowControlmentu(!showControlMenu);
      }}
    >
      {data?.videoUrl ? (
        <video
          src={`${process.env.REACT_APP_DEV_URI}/${data.videoUrl}`}
          id='video'
          className={`${classes.video} h-100 w-100 padding-0`}
          ref={vieoRef}
          autoPlay={true}
          muted={muted}
          playsInline
        />
      ) : null}
      {showPlayBack ? (
        <Playback
          showPlayBackMenu={showPlayBackMenu}
          changePlayBack={changePlayBack}
          normal={normal}
        />
      ) : null}
      <VideoControl
        iconUrls={iconUrls}
        video={vieoRef.current}
        showPrev={showPrev}
        prev={prev}
        next={next}
        showPlayBack={showPlayBack}
        isPlay={isPlay}
        setIsPlay={setIsPlay}
        showPlayBackMenu={showPlayBackMenu}
        updateData={updateData}
        setUpdateData={setUpdateData}
        playbackValue={playbackValues[playBack]}
        setIsNormalScreen={setIsNormalScreen}
        visibleControlMenu={visibleControlMenu}
        addListener={addListener}
        inputIsFocused={inputIsFocused}
        curTime={curTime}
        setCurtime={setCurtime}
        duration={duration}
        setDuration={setDuration}
        setTime={setTime}
        isNormalScreen={isNormalScreen}
        isMute={muted}
        setIsMute={setMuted}
        setMoved={setMoved}
        moved={moved}
        volumeMoved={volumeMoved}
        setVolumeMouved={setVolumeMouved}
        setShowControlmentu={setShowControlmentu}
      />
      <div
        className={`${classes.hide} ${classes.center_menu_container} d-flex position-absolute`}
        id='center_menu'
      >
        <ControlPanel
          prev={prev}
          next={next}
          video={vieoRef.current}
          visibleControlMenu={visibleControlMenu}
          setIsPlay={setIsPlay}
          showPrev={showPrev}
          iconUrls={iconUrls}
          setUpdateData={setUpdateData}
        />
      </div>
      <div
        className={`${classes.next_or_prev} d-flex justify-content-between position-absolute`}
        id='nextOrPrev'
      >
        <div
          id='prev10'
          className={`${classes.margin_right_auto} ${classes.hide}`}
        >
          <img src={constantData.iconsUrl[2]} alt='prev' />
        </div>
        <div
          id='next10'
          className={`${classes.margin_right_auto} ${classes.hide}`}
        >
          <img src={constantData.iconsUrl[3]} alt='next' />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
