import axios from 'axios';
import Cookies from 'js-cookie';
import { purchasesAllAction } from './PurchasedProductsSlice';

const purchasedProducts = (limit, activePage) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/user/purchases?type=web&limit=${limit}&page=${activePage}`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const data = await fetchData();
      return dispatch(purchasesAllAction.purchasesAllHandler({data: data.data.purchasesList, pageCount: data.data.pageCount, status: data.status }));
    } catch (e) {
      return e;
    }
  };
};

export default purchasedProducts;
