import React from 'react';
import styles from './Blocks.module.css';

const FooterContainer = (props) => {
  const {children} = props
  
  return (
    <div className={`${styles.block_background} d-flex w-100 align-items-start justify-content-center`}>
      <div className={`${styles.data_container} d-flex w-100 align-items-start justify-content-between m-auto`}>
        <div
          className={`flex-wrap justify-content-between d-flex w-100 align-items-start m-auto ${styles.main_content} ${styles.gap_20}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default FooterContainer;
