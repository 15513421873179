import axios from 'axios';
import Cookies from 'js-cookie';
import { addToReadBooksAction } from './AddToReadBooksSlice';

const addToReadBooks = (productId) => {
    const token = Cookies.get('auth_token');
    return async (dispatch) => {
      const fetchData = async () => {
        const body = { productId };
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
        const url = `${process.env.REACT_APP_DEV_URI}/user/readBooks`;
        const response = await axios.post(url, body, { headers });
        return response;
      };
      try {
        const addReadBooks = await fetchData();
        return dispatch(addToReadBooksAction.addToReadBooksHandler({data:addReadBooks.data, status:addReadBooks.status}));
      } catch (e) {
        const response = dispatch(addToReadBooksAction.addToReadBooksHandler({status: e.response.status}));
        return response;
    };
  };  
}

export default addToReadBooks