export const videoPlayer = {
durationClick(event, video) {
  let x = event?.touches && event?.touches?.length > 0 ? event?.touches[0]?.clientX : event.clientX
  let thumb = document.getElementById('thumb')
  let duration = document.getElementById('duration')
  if (duration != null) {
    let rec = duration.getBoundingClientRect()
    let allWidth = rec.right - rec.left
    let curX = x - rec.left
    let durWidth = (curX * 100) / allWidth
    let progress = document.getElementById('progress')
    progress.style.transition = 'left 0s ease'
    progress.style.width = `${durWidth}%`;
    let curTime = (durWidth * video.duration) / 100
    video.currentTime = curTime
    if (durWidth > 98) {
      thumb.style.left = `98%`
    } else if (durWidth > 0.5) {
      thumb.style.left = `${durWidth - 0.5}%`
    } else {
      thumb.style.left = `0px`
    }
  }
},

onMouseoMove(event, moved, video){
  if (moved) {
    this.durationClick(event, video)
  }
},

volumeOver() {
  document.getElementById('volume_progress_main').style.visibility = 'visible';
  document.getElementById('volume_progress_main').style.width = '60px'
  document.getElementById('volume_progress_main').style.marginRight = '15px'
  document.getElementById('volume_thumb').style.visibility = 'visible'
},

pause(video, iconUrls) {
  if (video && video.currentTime >= 0 && !video.ended) {
    video.pause()
    document.getElementById('playOrPause').src = iconUrls[1]
  }
},

play(video, iconUrls, setUpdateData) {
  if (video && video.currentTime >= 0 && !video.ended) {
    setUpdateData(true)
    video.play()
    document.getElementById('playOrPause').src = iconUrls[0]
  }
},

volumeClick(event, video, iconUrls, setIsMute, setVolume) {
  event.preventDefault()
  event.stopPropagation();
  let x = event?.touches && event?.touches?.length > 0 ? event?.touches[0]?.clientX : event.clientX
  let thumb = document.getElementById('volume_thumb')
  let duration = document.getElementById('volume_duration')
  if (duration != null && video) {
    let rec = duration.getBoundingClientRect()
    let allWidth = 60
    video.muted = false
    setIsMute(false)
    document.getElementById('volume').src = iconUrls[4]
    let curX = x - rec.left - 5
    curX = curX > 0 ? curX : 0
    let volumeWidth = (curX * 100) / allWidth
    let progress = document.getElementById('volume_progresss')
    progress.style.transition = 'left 0s ease'
    progress.style.width = `${volumeWidth + 3}%`;
    let curVolume = (volumeWidth) / 100
    if (curVolume >= 0 && curVolume <= 1) {
      video.volume = curVolume
    }
    if (volumeWidth + 10 <= 100) {
      thumb.style.left = `${volumeWidth}%`;
      setVolume(volumeWidth)
      if (volumeWidth < 0.05) {
        thumb.style.left = '0';
        setVolume(0)
        video.muted = true
        setIsMute(true)
        document.getElementById('volume').src = iconUrls[5]
      }
    }
    this.volumeOver()
  }
},

hodeOrShowNext10() {
  let prev = document.getElementById('prev10')
  let next = document.getElementById('next10')
  prev.classList.add('hide')
  prev.classList.remove('visible')
  next.classList.remove('hide')
  next.classList.add('visible')
  setTimeout(() => {
    next.classList.add('hide')
    next.classList.remove('visible')
  }, 500);
},

hodeOrShowPrev10() {
  let prev = document.getElementById('prev10')
  let next = document.getElementById('next10')
  next.classList.add('hide')
  next.classList.remove('visible')
  prev.classList.remove('hide')
  prev.classList.add('visible')
  setTimeout(() => {
    prev.classList.add('hide')
    prev.classList.remove('visible')
  }, 500);
},

update(updateData, icon, setDuration, setCurtime, setVolume, setIsPlay) {
  if (updateData) {
    setDuration({})
    setCurtime({})
    setVolume(50)
    document.getElementById('playOrPause').src = icon
    setIsPlay(true)
  }
},

onVolumeMouseoMove(event, volumeMoved, video, iconUrls, setIsMute, setVolume) {
  event.preventDefault()
  event.stopPropagation();
  if (volumeMoved === true) {
    this.volumeClick(event, video, iconUrls, setIsMute, setVolume)
  }
},

changeDurationHandler(video) {
  let progressWidth = (100 / video.duration) * video.currentTime
  document.getElementById('progress').style.transition = 'left 0s ease'
  document.getElementById('progress').style.width = `${progressWidth}%`;
  document.getElementById('thumb').style.left = `${progressWidth - 0.65}%`;
},

 curentTimeHandler(video, setCurtime, setDuration) {
  if (video) {
    let curDuration = video.currentTime
    let hour = parseInt(curDuration / 3600)
    let min = parseInt((curDuration - (hour * 3600)) / 60)
    let second = parseInt(curDuration - (hour * 3600) - min * 60)
    if (min > 9) {
      document.getElementById('min').style.minWidth = '22px'
    } else {
      document.getElementById('min').style.minWidth = '14px'
    }
    if (hour > 9) {
      document.getElementById('hour').style.minWidthwidth = '22px'
    } else if (hour > 0 && hour < 10) {
      document.getElementById('hour').style.minWidthwidth = '14px'
    } else {
      document.getElementById('hour').style.minWidthwidth = '0px'
    }
    setCurtime({ hour, min, second })
    curDuration = video.duration
    hour = parseInt(curDuration / 3600)
    min = parseInt((curDuration - (hour * 3600)) / 60)
    second = parseInt(curDuration - (hour * 3600) - min * 60)
    setDuration({ hour, min, second })
  }
},
videoClicked(event, fromButtons, video, iconUrls, visibleControlMenu, setIsPlay, setUpdateData) {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  if ((window.innerWidth >= 768 || fromButtons) && video) {
    visibleControlMenu()
    !video.paused ? this.pause(video, iconUrls) : this.play(video, iconUrls, setUpdateData)
    setIsPlay(!video.paused)
  }
},

changeVolume(upOrDown, video, iconUrls, isFirst, setisFirst, setIsMute, setVolume) {
  let volume = video.volume * 100
  if (isFirst) {
    volume = 50
    setisFirst(false)
  }

  if ((volume > 0 && volume < 100) || ((volume === 0 && upOrDown > 0) || (volume === 100 && upOrDown < 0))) {
    let newVolume = volume + upOrDown * 100
    if (newVolume <= 0) {
      newVolume = 0
      document.getElementById('volume').src = iconUrls[5]
    } else if (newVolume > 100) {
      newVolume = 100
      document.getElementById('volume').src = iconUrls[4]
    } else {
      document.getElementById('volume').src = iconUrls[4]
    }
    video.volume = newVolume / 100
    video.muted = newVolume === 0
    setIsMute(newVolume === 0)
    setVolume(newVolume)
    let volumeWidth = newVolume
    document.getElementById('volume_progresss').style.transition = 'left 0s ease'
    document.getElementById('volume_thumb').style.left = `${volumeWidth - 10}%`
    document.getElementById('volume_progresss').style.width = `${volumeWidth}%`;
  }
},

muteOrUnmute(event, video, iconUrls, isMute, volume, setVolume, setIsMute) {
  if (event) {
    event.preventDefault()
    event.stopPropagation();
  }
  if (video) {
    this.volumeOver()
    video.muted = !isMute
    let volumeImg = document.getElementById('volume')
    isMute ? volumeImg.src = iconUrls[4] : volumeImg.src = iconUrls[5]
    document.getElementById('volume_progresss').style.transition = 'left 0s ease'
    if (!isMute) {
      document.getElementById('volume_thumb').style.left = '0'
      document.getElementById('volume_progresss').style.width = '0';
    } else {
      let curVolume = volume === 0 ? 100 : volume
      video.volume = curVolume / 100
      document.getElementById('volume_thumb').style.left = `${curVolume === 100 ? curVolume - 20 : curVolume - 10}%`
      document.getElementById('volume_progresss').style.width = `${curVolume}%`;
      setVolume(curVolume)
    }
    setIsMute(!isMute)
  }
},

changeScreenMode(event, video, iconUrls, isNormalScreen, offsetHeight, setUpdateData, setIsNormalScreen, needChange = true) {
  if (event) {
    event.preventDefault()
    event.stopPropagation();
  }
  const modal = document.getElementById('modal')

  setUpdateData(false)
  if (isNormalScreen === true && video) {
    video.style.maxHeight = `${100}vh`
    document.getElementById('modal').classList.add('modall');
    document.getElementById('normalScreen').src = iconUrls[2]
    document.getElementById('menu').style.width = '50% !important'
    document.body.style.overflowY = 'hidden' 
  } else if (video) {
    video.style.height = `${offsetHeight}px`
    document.getElementById('modal').classList.remove('modall');
    document.getElementById('normalScreen').src = iconUrls[3]
    document.getElementById('menu').style.width = '70% !important'
    document.body.style.overflowY = 'auto' 
  }
  if (modal && isNormalScreen === true) {
    if (modal.requestFullscreen) {
      modal.requestFullscreen();
    } else if (modal.webkitRequestFullscreen) {
      modal.webkitRequestFullscreen();
    } else if (modal.msRequestFullscreen) {
      modal.msRequestFullscreen();
    } else {
      document.getElementById('smilarPlaylist').style.display = 'none' 
      document.getElementById('footer').style.display = 'none' 
    }
    setTimeout(() => {
      if (window.innerWidth >= 768) document.getElementById('center_menu').classList.add('d-none')
    }, 100);

    modal.style.borderRadius = '0'
  } else if (needChange && !isNormalScreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else {
      document.getElementById('smilarPlaylist').style.display = 'block' 
      document.getElementById('footer').style.display = 'flex' 
    }
    modal.style.borderRadius = '8px'
    setTimeout(() => {
      if (window.innerWidth < 768) document.getElementById('center_menu').classList.remove('d-none')
    }, 100);
  }
  setIsNormalScreen(!isNormalScreen)
},

onMouseOver(event) {
  document.getElementById('duration').style.height = '5px'
  document.getElementById('thumb').style.visibility = 'visible'
},

onMouseOut(event, moved) {
  if (!moved) {
    document.getElementById('thumb').style.visibility = 'hidden'
    document.getElementById('duration').style.height = '3px'
  }
},

volumeOverOut(volumeClicked) {
  if (!volumeClicked) {
    document.getElementById('volume_progress_main').style.width = '0px'
    document.getElementById('volume_progress_main').style.marginRight = '0px'
    document.getElementById('volume_thumb').style.visibility = 'hidden'
  }
},

handleDuration(duration) {
  let hour = duration.hour > 0 ? `${duration.hour}:` : ''
  let min = duration.min >= 0 && typeof duration.min !== 'undefined' && duration.min !== null ? `${duration.min}:` : ''
  let second = duration.second > 9 ? duration.second : duration.second >= 0 && typeof duration.second !== 'undefined' && duration.second !== null ? `0${duration.second}` : ''
  return `${hour}${min}${second}`
},

hasNext(playListId, selectedCartoonId, playList, length, selectedIndex) {
  if (playListId && playList.length > 0) {
    return playList[playList.length - 1]._id !== selectedCartoonId ? true : false
  } else if (length > 0) {
    return length - 1 !== selectedIndex
  }
},

cartoonDuration(item) {
  const duration = item.duration / 1000
  const hour = parseInt(duration / 3600)
  const min = parseInt((duration - hour * 60) / 60)
  const second = parseInt(duration - hour * 60 - min * 60)

  let curHour = hour > 0 ? `${hour < 10 ? `0${hour}` : hour}:` : ''
  let curMin = min > 0 ? `${min < 10 ? `0${min}` : min}:` : '00:'
  let curSecond = second > 0 ? `${second < 10 ? `0${second}` : second}` : '00'
  return `${curHour}${curMin}${curSecond}`
}
}