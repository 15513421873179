import Cookies from 'js-cookie';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { auth } from '../../firebase/FirebaseConfig';
import React, { useEffect, useState } from 'react';
import classes from './UserDeleteReport.module.css';
import Loader from '../../components/spinner/Loader';
import SignIn from '../../components/modal/auth/signIn/SignIn';
import Button from '../../components/buttons/authButton/Button';
import { LanguageContext } from '../../context/LanguageContext';
import deleteUser from '../../store/user/deleteUser/DeleteUser';
import ErrorModal from '../../components/modal/error/ErrorModal';
import userExistence from '../../store/userExistence/userExistence';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import ModalTitles from '../../components/modal/modalTitles/ModalTitles';
import ConfirmationModal from '../../components/modal/confirmationModal/ConfirmationModal';
import CodeVerification from '../../components/modal/auth/codeVerification/CodeVerification';
import UserAccountComponent from '../../components/user/userAccountComponent/UserAccountComponent';
import UserAccountComponentData from '../../components/user/userAccountComponent/userAccountComponentData/UserAccountComponentData';
import { operationsWithKeys } from '../../utils/helpers/operationsWithKeys/operationsWithKeys';
import { operationsWithPhoneNumber } from '../../utils/helpers/operationsWithPhoneNumber/operationsWithPhoneNumber';
import { requestotp } from '../../utils/helpers/requestOTP/requestOTP';
import { verifyotp } from '../../utils/helpers/verifyOTP/verifyOTP';
import { constantData } from '../../utils/helpers/constantData';

const UserDeleteReport = () => {
  const dispatch = useDispatch();
  const token = Cookies.get('token');
  const [OTP, setOTP] = useState([]);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [context, setContext] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const data = constantData.userDeleteReportData
  const [checkUserDelete, setCheckUserDelete] = useState(false);
  const [recaptchaId, setRecaptchaId] = useState('recaptchaId');
  const [codeVerification, setCodeVerification] = useState(true);
  const curInput = document.getElementById(`auth_${index + 1}`);
  const nextInput = document.getElementById(`auth_${index + 2}`);
  const [disabled, setDisabled] = useState(['signIn', 'codeVerification']);
  const number = operationsWithPhoneNumber.particularlyHideNumber( phoneNumber, context.includes('validated'));
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('userData')));
  const [providerError, setProviderError] = useState({status: false, message: ''});
  const [errorMessage, seterrorMessage] = useState();

  const phoneNumberChangeHandler = (phone) => {
    setPhoneNumber(`+${phone}`);
  };

  const request = (e) => {
    e.preventDefault();
    requestotp.requestOTP(
      e,
      operationsWithPhoneNumber.generateRecaptcha,
      recaptchaId,
      RecaptchaVerifier,
      auth,
      signInWithPhoneNumber,
      phoneNumber,
      setContext,
      () => {},
      true,
      setError,
      setCodeVerification,
      setIsLoading,
      disabled,
      setDisabled
    );
  };

  const clickHandler = (id) => {
    setIndex(id);
  };

  const loginByPhoneNumber = async (
    confirmationResult,
    verificationCode,
    name,
    lang
  ) => {
    try {
      const result = await confirmationResult.confirm(verificationCode);
      const response = await dispatch(userExistence(result.user.accessToken));
      setUserData(response.payload.result.user);
      window.sessionStorage.setItem(
        'userData',
        JSON.stringify(response.payload.result.user)
      );
      if (response && response.token.length > 0) {
        setCodeVerification(true);
        setContext([]);
        setIsLoading([]);
      }
    } catch (e) {
      if (e.response?.status === 404 && e.response.data === 'no_user') {
        setError(true);
        seterrorMessage(name['does_not_user'][lang]);
      }
      setIsLoading([]);
      setCodeVerification(false);
      setDisabled([]);
    }
  };

  useEffect(() => {
    if (OTP.length === 6 && !OTP.includes('')) {
      const filteredData = disabled.filter(
        (item) => item !== 'codeVerification'
      );
      setDisabled(filteredData);
    } else if (!disabled.includes('codeVerification')) {
      const test = [...disabled, 'codeVerification'];
      setDisabled(test);
    }
  }, [OTP]);

  const deleted = async () => {
    const response = await dispatch(deleteUser(userData._id, token));
    if (response.payload.deleteUser.status === 200) {
      Cookies.remove('token');
      window.sessionStorage.removeItem('userData');
      setCheckUserDelete(false);
      setContext([]);
      setCodeVerification(true);
    }
  };

  const hideErrorModal = () => {
    setError(false);
    setIsLoading([]);
    setDisabled(disabled);
    setProviderError({ status: false });
  };

  useEffect(() => {
    if (token) {
      sessionStorage.removeItem('stateStatus');
    }
  }, [token]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        if (ctx?.data) {
          let firstPoint = { ...ctx.data?.found_user };
          if (userData) {
            ['am', 'ru', 'en', 'fr'].forEach(
              (key) =>
                (firstPoint[key] = firstPoint[key].replace(
                  'USERNAME',
                  userData.phoneNumber ?? userData.email
                ))
            );
          }
          return (
            <>
              <div id={recaptchaId} />
              <div
                className={`${classes.full_height} ${classes.padding_40} w-100 justify-content-center align-items-center d-flex flex-wrap`}
              >
                <div
                  className={`${classes.gap_20} ${classes.max_width_1000} w-100 d-flex flex-wrap justify-content-center`}
                >
                  <div
                    className={`${classes.max_width_1000} ${classes.haight_450} ${classes.border_radus_12} ${classes.box_shedow} ${classes.flex_wrap} d-flex w-100`}
                  >
                    <div
                      className={`${classes.description_container} w-50 d-flex justify-content-center`}
                    >
                      <div
                        className={`${classes.text_container} align-items-center d-flex justify-content-center `}
                      >
                        {!token ? (
                          <span
                            className={`${classes.color_white} text-center fw-bold`}
                          >
                            {ctx.data?.authenticate_expplore[ctx.language]}
                          </span>
                        ) : (
                          <ol>
                            <li
                              className={`${classes.color_white} ${classes.font_size_18} ${classes.font_weight_600} mb-3`}
                            >
                              {firstPoint[ctx.language]}
                            </li>
                            <li
                              className={`${classes.color_white} ${classes.font_size_18} ${classes.font_weight_600} mt-3`}
                            >
                              {ctx.data?.delete_account_info[ctx.language]}
                            </li>
                          </ol>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${classes.max_width_50} ${classes.padding_20} ${classes.gap_20} d-flex justify-content-center w-100 flex-wrap align-items-center`}
                    >
                      <div
                        className={`${classes.user_data_item} ${
                          token ? classes.haight_data_item : ''
                        } w-100 justify-content-center align-items-center d-flex flex-wrap`}
                      >
                        {token && userData ? (
                          data.map((item) => {
                            return (
                              <>
                                {userData[item] && (
                                  <UserAccountComponent
                                    className={classes.user_data_container}
                                  >
                                    <UserAccountComponentData
                                      title={userData[item]}
                                      user={item}
                                      imgUrl={`../assets/userAccount/${item}.svg`}
                                    />
                                  </UserAccountComponent>
                                )}
                              </>
                            );
                          })
                        ) : !context.includes('validated') ? (
                          <SignIn
                            country={localStorage.getItem('country')}
                            setDisabled={setDisabled}
                            disabled={disabled}
                            requestOTP={request}
                            phoneNumber={phoneNumber}
                            onPress={() => {}}
                            onPhoneChange={(phone) =>
                              phoneNumberChangeHandler(phone)
                            }
                            recaptchaId='recaptchaId'
                            setPhoneNumber={setPhoneNumber}
                            isLoading={isLoading}
                            error={providerError}
                            setError={setProviderError}
                          />
                        ) : (
                          <CodeVerification
                            resendCode={(e) =>
                              operationsWithPhoneNumber.resendCode(
                                e,
                                setRecaptchaId,
                                recaptchaId,
                                ReactDOM,
                                request,
                                setDisabled,
                                disabled
                              )
                            }
                            login={true}
                            type='auth'
                            disabled={disabled}
                            phoneNumber={number}
                            onFocus={clickHandler}
                            codeVerification={codeVerification}
                            onDoubleClick={() => setDisabled(['resend'])}
                            onChange={(e, id) =>
                              operationsWithPhoneNumber.changeCode(e, id, OTP, index, setOTP)
                            }
                            onKeyUp={() =>
                              operationsWithKeys.keyUpHandler(OTP, setOTP, curInput, nextInput)
                            }
                            onKeyDown={(e) =>
                              operationsWithKeys.keyDownHandler(
                                e,
                                setCodeVerification,
                                curInput,
                                index,
                                OTP,
                                setOTP,
                                'auth'
                              )
                            }
                            title={
                              <ModalTitles
                                classIdentifier={'max_title'}
                                max_title={
                                  ctx.data?.verify_your_phone_number[
                                    ctx.language
                                  ]
                                }
                              />
                            }
                            onSubmit={(e) =>
                              verifyotp.verifyOTP(
                                e,
                                'codeVerification',
                                OTP,
                                setDisabled,
                                setIsLoading,
                                loginByPhoneNumber,
                                false,
                                true,
                                ctx.data,
                                ctx.language
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {token && (
                    <div
                      className={`${classes.gap_10} d-flex w-100 justify-content-between align-items-center flex-wrap`}
                    >
                      <div>
                        <span className={classes.font_weight_600}>
                          {ctx.data?.guaranteed_delete[ctx.language]}
                        </span>
                      </div>
                      <div
                        className={`${classes.delete_container} ${classes.padding_10} d-flex w-100 justify-content-end align-items-center`}
                      >
                        <Button
                          text={ctx.data?.delete_account[ctx.language]}
                          textStyle={`${classes.color_red} ${classes.font_size_18} ${classes.font_weight_400}`}
                          buttonStyle='border-0 bg-transparent'
                          onClick={() => setCheckUserDelete(true)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ConfirmationModal
                show={checkUserDelete}
                onHide={() => setCheckUserDelete(false)}
                logOutHandler={deleted}
                titles={{
                  max_title: ctx?.data?.delete_product_text[ctx.language],
                }}
              />
              <ErrorModal
                show={providerError.status ? providerError.status : error}
                onHide={hideErrorModal}
                errorMessage={
                  errorMessage
                    ? errorMessage
                    : providerError.message !== ''
                    ? providerError.message
                    : ctx.data?.your_session_expired[ctx.language]
                }
              />

              {isLoading.includes('validation') && (
                <div
                  className={`position-fixed d-flex align-items-center justify-content-center ${classes.wait_while_is_authorizing}`}
                >
                  <Loader />
                </div>
              )}
            </>
          );
        }
      }}
    </LanguageContext.Consumer>
  );
};

export default UserDeleteReport;
