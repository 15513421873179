import React, { useRef } from 'react';
import classes from './AuthorData.module.css';
import { LanguageContext } from '../../../context/LanguageContext';
import ReadMore from '../../singleProductPage/booksData/readMore/ReadMore';

const AuthorData = (props) => {
  const bio = useRef(null);
  const {author} = props

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={`w-100 ${classes.bg_vinni} d-flex justify-content-center`}
            id='bg_vinni'
          >
            <div className={`d-flex align-items-center ${classes.author_main}`}>
              <div
                className={`${classes.author_info} relative w-100 justify-content-around d-flex align-items-center`}
                ref={bio}
                id='author_info'
              >
                <div className={`${classes.empty_div}`}></div>
                <div className={`${classes.about_content}`}>
                  <h5>{author?.name[ctx.language]}</h5>
                  <ReadMore
                    data={author.bio}
                    className={`${classes.more_button} border-0`}
                    description={classes.description}
                    read_more_container={classes.read_more_container}
                    buttonStyle={'d-flex justify-content-end'}
                  />
                </div>
                <div
                  className={`${classes.author_img} position-absolute w-100 d-flex justify-content-center`}
                  id='authorImage'
                >
                  <img
                    className={`w-100 object-fit-cover`}
                    src={
                      author?.image
                        ? `${process.env.REACT_APP_DEV_URI}${author.image}`
                        : ''
                    }
                    alt='author'
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default AuthorData;
