import React from 'react';
import style from './Titles.module.css';
import { nanoid } from 'nanoid';

const Titles = (props) => {
  const { title } = props;
  return (
    <div className={`${style.title_container} d-flex w-100 align-items-center justify-content-center flex-wrap`}>
      {Object.keys(title)?.map((item) => {
       return <span className={`${style[item]} text-center w-100`} key={`page_tittles_${nanoid()}`}>{title[item]?.toUpperCase()}</span>;
      })}
    </div>
  );
};

export default Titles;
