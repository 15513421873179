import axios from 'axios';
import Cookies from 'js-cookie';
import { favoriteProductsAction } from './favoriteProductsSlice';

const favoriteProducts = (limit, page) => {
  const token = Cookies.get('auth_token');
  return async (dispatch) => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_DEV_URI}/user/wantread/all?type=web&limit=${limit ?? '*'}&page=${page ?? '*'}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    };
    try {
      const wantreadAll = await fetchData();
      if (wantreadAll.status === 200)
        return dispatch(
          favoriteProductsAction.favoriteProductsHandler({data: wantreadAll.data.userWantReadSort, pageCount: wantreadAll.data.pageCount, status: wantreadAll.status})
        );
    } catch (e) {
      return e;
    }
  };
};

export default favoriteProducts;
