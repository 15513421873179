import React from 'react';
import classes from './FilterItem.module.css';
import FilterToolbar from '../filterTitle/FilterToolbar';

const FilterItem = (props) => {
  const {onClick, title, showData, children, showFilter} = props

  return (
    <>
      <FilterToolbar
        title={title}
        img={showData  ? '../assets/products/filter/filterProductHidden.svg' : '../assets/products/filter/filterProductShow.svg'}
        onClick={onClick}
      />
      <div 
      className={`${showData && showFilter ? `${classes.transition} ${classes.animation}` :  
      `${classes.animation_none}` } ${classes.data_container} d-flex flex-wrap`}>
       {children}
      </div>
    </>
  );
};

export default FilterItem;
