import React from 'react';
import classes from './UserAccountComponent.module.css';

const UserAccountComponent = (props) => {
  const { onClick, titleAge, children, className} = props;

  return (
    <div className={`${classes.user_data_item_container} ${className} d-flex w-100 justify-content-between align-items-center`} onClick={onClick}>
      <div
        className={`${classes.data_container} ${titleAge && classes.title_age} d-flex justify-content-between w-100 overflow-hidden`}
      >
        {children}
      </div>
    </div>
  );
};

export default UserAccountComponent;
