import style from '../Home.module.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titles from '../../../components/title/Titles';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../context/LanguageContext';
import BlogsCard from '../../../components/card/blogs/BlogsCard';
import Button from '../../../components/buttons/authButton/Button';
import fetchAllBlogs from '../../../store/blog/fetchAllBlogs/FetchAllBlogs';

const LatestArticles = () => {
  const dispatch = useDispatch();
  const page = 1
  const limit = process.env.REACT_APP_LATEST_ARTICLES_LIMIT
  const navigate = useNavigate()
  const blogs = useSelector((state) => state.allBlogs.allBlogs);
  const [allBlogsData, setAllBlogsData] = useState([])

  useEffect(() => {
    dispatch(fetchAllBlogs(page, limit))
  }, [dispatch, limit])

  useEffect(() => {
    if (blogs?.blogs?.length > 0) {
      setAllBlogsData(blogs.blogs)
    }
  }, [blogs]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            {allBlogsData.length > 0 && (
              <div className={`${style.data_container} ${style.homeContainer} d-flex flex-wrap justify-content-center`}>
                <Titles
                  title={{
                    subtitle: ctx.data?.our_blog[ctx.language],
                    title: ctx.data?.latest_articles[ctx.language],
                  }}
                />
                <div
                  className={`d-flex flex-wrap ${style.gap_20} justify-content-center `}
                >
                  {allBlogsData?.map((item) => {
                    return (
                      <BlogsCard
                        key={`home_blog_${item._id}`}
                        data={item}
                        languageKey={ctx.language}
                        index={-1}
                        more={ctx.data?.explore_more[ctx.language]}
                      />
                    );
                  })}
                </div>
                <Button
                  divStyle={`d-flex w-100 align-items-center justify-content-center`}
                  buttonStyle={`${style.more_books_container} border-0 align-items-center text-center d-flex justify-content-center`}
                  onClick={() => {
                    navigate('/blogs');
                  }}
                  text={ctx.data?.explore_more[ctx.language]}
                />
              </div>
            )}
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default LatestArticles;
