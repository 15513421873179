import React from 'react';
import classes from './CategoryCards.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import { nanoid } from 'nanoid';

const CategoryCards = (props) => {
  const { data, categories,  className, padding_unset, image} = props;
  const filteredData = categories?.filter((item) => (data?.categories ?? data)?.includes(item?._id ?? item))

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={`${classes.data_container} ${className} overflow-hidden w-100`}>
            {filteredData?.map((item) => {
              return (
                <React.Fragment key={`${item}_${nanoid()}`}>
                {image && <img
                    src={`${process.env.REACT_APP_DEV_URI}${item.image.imageURL}`}
                    alt='img'
                    className={classes.category_image}
                  />}
                  <span className={padding_unset ? padding_unset : `${classes.card_item_container_genre}`}>
                      {item?.name && item?.name[ctx.language]} 
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CategoryCards;
