import axios from 'axios';
import Cookies from 'js-cookie';
import { cartoonCategoriesAction } from './CartoonCategoriesSlice';

const fetchCartoonCategories = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const token = Cookies.get('auth_token');
      const headers = { Authorization: `Bearer ${token}` };
      const url = `${process.env.REACT_APP_DEV_URI}/cartoon/category`;
      const response = await axios.get(url, { headers });
      return response;
    };
    try {
      const cartoonCategories = await fetchData();
      dispatch(cartoonCategoriesAction.cartoonCategoriesHandler({cartoonCategories: cartoonCategories.data}));
    } catch (e) {
      return e;
    }
  };
};

export default fetchCartoonCategories;
