import { createSlice } from '@reduxjs/toolkit';

const initialState = { data:{status:null}};

const deletePurchasesSlice = createSlice({
  name: 'deletePurchases',
  initialState,
  reducers: {
    deletePurchasesHandler(state, action) {
      state.data = action.payload;
    },
  },
});
export const deletePurchasesAction = deletePurchasesSlice.actions;
export default deletePurchasesSlice;
