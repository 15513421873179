import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './CartoonSearch.module.css';
import { LanguageContext } from '../../../../context/LanguageContext';
import suggestedProducts from '../../../../store/products/suggestedProducts/SuggestedProducts';
import suggestedCartoons from '../../../../store/cartoons/suggestedCartoons/SuggestedCartoons';
import { operationsWithKeys } from '../../../../utils/helpers/operationsWithKeys/operationsWithKeys';

const CartoonSearch = (props) => {
  const dispatch = useDispatch();
  const suggestedProductsData = useSelector((state) => state.suggestedProducts.suggestedProducts);
  const suggestedCartoonsData = useSelector((state) => state.suggestedCartoons.suggestedCartoons);
  const { searchCartoons, inputRef, showSearchResult, setShowSearchResult, searchFrom, languageKey } = props;
  const [showClearImg, setShowClearImg] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    if (searchFrom === 'books') {
      setSearchResult(suggestedProductsData)
    } else {
      setSearchResult(suggestedCartoonsData)
    }
  }, [suggestedProductsData, suggestedCartoonsData])

  const clearText = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      searchCartoons(null, '')
      setShowClearImg(false)
      setSearchResult([])
    }
  }

  const onChange = (event) => {
    if (event.target) {
      setSelectedIndex(-1)
      searchCartoons(event)
      setShowClearImg(event.target.value !== '')
      setShowSearchResult(event.target.value !== '')
      search(event.target.value)
    }
  }

  const search = (value) => {
    if (searchFrom === 'books') {
      dispatch(suggestedProducts(value))
    } else {
      dispatch(suggestedCartoons(value))
    }
  }

  const suggestionClick = (title, index) => {
    setShowClearImg(title !== 0)
    if (inputRef.current) {
      inputRef.current.value = title
      searchCartoons(null, title)
      setShowClearImg(true)
      setShowSearchResult(false)
      dispatch(suggestedProducts(title))
      search(title)
      setSelectedIndex(index)
    }
  }

  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation();
    setSelectedIndex(-1)
    setShowSearchResult(searchResult.length !== 0 && event.target.value.length > 0)
  }

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <div className={` ${classes.align_items_center} ${searchFrom === 'cartoon' ? classes.cartoon_search_container : ''} ${classes.full_width} ${classes.productsSearchContainer} position-relative`}>
            <div className={`${classes.animation} ${classes.input_container} ${classes.align_items_center} d-flex justify-content-center`}>
              <input
                ref={inputRef}
                placeholder={ctx.data?.search[ctx.language]}
                id='searchCartoon'
                onChange={onChange}
                onClick={onClick}
                autoComplete='off'
                onKeyDown={(event) => {operationsWithKeys.onKeyDown(event, selectedIndex, searchResult, setSelectedIndex, searchFrom, languageKey, searchCartoons) }}
              />
              {showClearImg ?
                <img
                  src='../../assets/video/blackClose.png'
                  alt='clear'
                  className={classes.clear}
                  onClick={clearText}
                /> : null
              }
            </div>
            {showSearchResult ?
              <div
                className={`d-flex flex-column position-absolute overflow-auto padding-0 border-top-0 ${searchResult?.length > 0 ? ` ${classes.padding_y_10} ${classes.search_book_modal} ${classes.max_width_400}` : `${classes.search_book_modal} ${classes.max_width_400}`}`} 
              >
                {
                  searchResult?.map((item, index) => {
                    return (
                      <div id='searchBookModalContent' key={item._id} className={classes.search_book_modal_content} >
                        <h6
                          className={index === selectedIndex ? `${classes.selected_search_book_modal}` : ''}
                          onClick={() => suggestionClick(searchFrom === 'books' ? item.name[ctx.language] : item.title[ctx.language], index)}
                          onMouseEnter={() => setSelectedIndex(index)}
                          onMouseLeave={() => setSelectedIndex(-1)}
                        >
                          {searchFrom === 'books' ? item.name[ctx.language] : item.title[ctx.language]}
                        </h6>
                      </div>
                    )
                  })
                }
              </div> : null
            }
          </div>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default CartoonSearch;
