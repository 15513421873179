import axios from 'axios';
import Cookies from 'js-cookie';
import { cartoonRatingAction } from './CartoonRatingSlice';

const fetchCartoonRating = (cartoonId, isLiked) => {
    const token = Cookies.get('auth_token');
    return async (dispatch) => {
      const fetchData = async () => {
        const body = {
          'cartoonId': cartoonId,
          'isLiked': isLiked
        };
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
        const url = `${process.env.REACT_APP_DEV_URI}/cartoon/rating`;
        const response = await axios.post(url, body, { headers });
        return response;
      };
      try {
        const cartoonRating = await fetchData();
       return dispatch(cartoonRatingAction.cartoonRatingHandler({cartoonRating: {data: cartoonRating.data, status: cartoonRating.status}}));
      } catch (e) {
        return e;
      }
    };
  };
  
  export default fetchCartoonRating;
