import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classes from './RatingSingleProduct.module.css';
import product from '../../../../store/singlePage/Product';
import { UserContext } from '../../../../context/user/UserContext';
import React, { useContext, useEffect, useState } from 'react';
import productRating from '../../../../store/productRating/productRating';
import RatingProduct from '../../../../components/modal/rating/RateingProduct';
import { productsRating } from '../../../../utils/helpers/productRating/productRatings';
import SignInErrorModal from '../../../../components/modal/error/signInErrorModal/SignInErrorModal';

const RatingSingleProduct = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const productId = useParams();
  const token = Cookies.get('auth_token');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user, setUserData } = useContext(UserContext);
  const [src, setSrc] = useState('');

  const rateProduct = async (rating, productId) => {
    const mutableUser = { ...user };
    const changingRatings = [...mutableUser.ratings];
    if (rating) {
      const response = await dispatch(productRating(productId, rating));
      if (response?.payload?.rating?.status === 200) {
        const rate = response.payload.rating.data.rating;
        for (let i in changingRatings) {
          if (changingRatings[i].productId === productId) {
            changingRatings[i] = JSON.parse(JSON.stringify(changingRatings[i]));
            changingRatings[i].rating = rate;
          }
        }
        mutableUser.ratings = changingRatings;
        setUserData(mutableUser);
        dispatch(product(productId));
        setShow(false);
      }
    }
  };

  const productRatingHandler = () => {
    token ? setShow(true) : setIsAuthorized(true);
  };

  useEffect(() => {
    productsRating.productRatings(data, setSrc, true);
  }, [data]);

  return (
    <>
      <div className={`${classes.book_parametr_item} d-flex flex-wrap justify-content-center w-100`}>
        <div
          onClick={productRatingHandler}
          className={classes.rating_container}
        >
          <img src={src} alt='' />
        </div>
        <span className={`${classes.rate_text} w-100 d-flex justify-content-center text-center`}>{`${data.rating.rate.toFixed(1)}(${data.rating.count})`}</span>
      </div>
      <RatingProduct
        show={show}
        user={user}
        rateProduct={(rating) => rateProduct(rating, productId.id)}
        setShow={setShow}
      />
      <SignInErrorModal
      setShowErrorModal={setIsAuthorized}
        showErrorModal={isAuthorized}
        onHide={() => {
          setIsAuthorized(false);
        }}
      />
    </>
  );
};

export default RatingSingleProduct;
