import React, { useEffect, useState } from 'react';
import styles from './ProgressiveImage.module.css';

const ProgressiveImage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentSrc, setCurrentSrc] = useState('');
  const {src, className, alt} = props

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = `${process.env.REACT_APP_DEV_URI}${src}`;
    imageToLoad.onload = () => {
      setIsLoaded(true);
    };
    isLoaded && setCurrentSrc(`${process.env.REACT_APP_DEV_URI}${src}`);
  }, [isLoaded, src]);

  return (
    <>
      {!isLoaded ? (
        <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
          <span className={`${styles.loader} position-relative`} />
        </div>
      ) : (
        <img
          src={currentSrc}
          className={`${styles.progressive_image} ${className} w-100 h-100 object-fit-cover d-flex align-items-end`}
          alt={alt}
        />
      )}
    </>
  );
};

export default ProgressiveImage;
