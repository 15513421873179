import { createSlice } from '@reduxjs/toolkit';

const initialState = { popular: [] };

const popularSlice = createSlice({
  name: 'popular',
  initialState,
  reducers: {
    popularHandler(state, action) {
      state.popular = action.payload.popular;
    },
  },
});

export const popularAction = popularSlice.actions;
export default popularSlice;
