import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './CartoonPlayer.module.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import CartoonListItem from '../cartoonListItem/CartoonListItem';

const CartoonPlayer = (props) => {
  const categories = useSelector((state) => state.cartoonCategories.cartoonCategories);

  let {
    id,
    data,
    title,
    language,
    changePage,
    updatePage,
    isPlaylist,
    setUpdatePlaylist,
    removeFromPlaylist,
    setShowAddToPlaylist,
    selectedCartoonId,
    setCheckedCartoonId,
    playlisIsColsed,
    setPlaylistIsClosed,
    plylistDefaultHeight,
    changeSelectedIndex,
    playlistId
  } = props
  const [showId, setShowId] = useState(null)

  const calculateVideoDuraion = (durationn) => {
    let seconds = durationn / 1000
    let hour = parseInt(seconds / 3600)
    let min = parseInt((seconds - (hour * 3600)) / 60)
    let second = parseInt(seconds - (hour * 3600) - min * 60)
    let duration = ''
    if (hour > 0) {
      duration = `${hour}:`
    }
    if (min > 9) {
      duration = `${duration}${min}:`
    } else {
      duration = `${duration}0${min}:`
    }
    if (second > 9) {
      duration = `${duration}${second}`
    } else {
      duration = `${duration}0${second}`
    }
    return duration
  }

  const handleScroll = () => {
    if (updatePage) {
      changePage()
    }
  }

  const closeOrOpenPlaylist = () => {
    setPlaylistIsClosed(!playlisIsColsed)
    const smilarPlaylist = document.getElementById('smilarPlaylist')
    const player = document.getElementById('player')
    if (playlisIsColsed && smilarPlaylist) {
      smilarPlaylist.style.maxHeight = `${plylistDefaultHeight}px`
    } else if (smilarPlaylist && player) {
      smilarPlaylist.style.maxHeight = `${plylistDefaultHeight + player.offsetHeight}px`
    }
  }

  return (
    <div className={isPlaylist ? playlisIsColsed ? `${classes.player} ${classes.max_height_32} ${classes.player_border}` : `${classes.player} ${classes.player_border}` : `${classes.player} ${classes.player_border}`} id={`player${id}`} >
      {title ?
        <div className={`${classes.title_bg} d-flex justify-content-between align-items-center`} onClick={closeOrOpenPlaylist}>
          <h2 id='player_title'>{title}</h2>
          <img
            src={!playlisIsColsed ? '../../assets/video/close.svg' : '../../assets/video/arrowDown.png'}
            alt='close'
            className='ml-auto'
          />
        </div>
        :
        null}
      <div className={classes.players} id='players' >
        <InfiniteScroll
          dataLength={data.length}
          next={handleScroll}
          inverse={false}
          hasMore={updatePage}
        >
          {data?.map((item, index) => {
            let isSelected = item._id === selectedCartoonId
            let key = isPlaylist ? 'playlist' : 'similiar'
            let selectedIndex = data.findIndex((item) => item._id === showId)

            return (
              <div onClick={() => {changeSelectedIndex(index, isPlaylist) }} key={`${key}_${item._id}_${index}`}>
                <CartoonListItem
                  playlistId={playlistId}
                  imageUrl={item.imageUrl}
                  duration={calculateVideoDuraion(item.duration)}
                  title={item?.title && item?.title[language] ? item?.title[language] : ''}
                  uploder={item?.uploader?.displayName}
                  id={item._id}
                  index={index}
                  isSelected={isSelected}
                  isPlaylist={isPlaylist}
                  setUpdatePlaylist={setUpdatePlaylist}
                  removeFromPlaylists={removeFromPlaylist}
                  setShowAddToPlaylist={setShowAddToPlaylist}
                  setShowId={setShowId}
                  showId={showId}
                  selectedIndex={selectedIndex}
                  length={data.length - 1}
                  isFirstItem={index === 0 && isPlaylist}
                  setCheckedCartoonId={setCheckedCartoonId}
                  cartoonCategories={item.categories}
                  categories={categories}
                />
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CartoonPlayer;