export const changeTextShape = {
    toKebabcase(str, key) {
        if (str) {
            return str[key].toLowerCase().split(' ').join('-')
        }
    },
    fisrtCharackterToUpperCase(str, key){
        if (str) {
            let array = str[key].split(' ')
            array = array.map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
            return array.join(' ')
        }
    }

}