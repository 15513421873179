import EditTitle from './editTitle/EditTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LanguageContext } from '../../../../../context/LanguageContext';
import PlayListNameActions from './playListNameActions/PlayListNameActions';
import deletePlayList from '../../../../../store/cartoons/deletePlaylist/DeletePlaylist';
import ConfirmationModal from '../../../../../components/modal/confirmationModal/ConfirmationModal';
import fetchUpdatePlayList from '../../../../../store/cartoons/updateCartoonPlaylist/FetchUpdatePlaylist';

const PlaylistTitle = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [editTitle, setEditTitle] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const playLists = useSelector((state) => state.allPlayLists.allPlayLists);
  const singlePlaylist = playLists?.allPlayLists.find((item) => item._id === params.id);
  const deletePlayListData = useSelector(
    (state) => state.deletePlaylist.deletePlaylist
  );
  const updatePlayList = useSelector(
    (state) => state.updatePlayList.updatePlayList
  );
  const singlePlayList = useSelector(
    (state) => state.playList.playList
  );


  window.scroll(0, 0)

  const titleHandler = (e) => {
    setEditTitle(e.target.value);
  };

  useEffect(() => {
    if (updatePlayList.status === 200) {
      setIsEdit(false);
    }
  }, [updatePlayList, singlePlayList]);

  // useEffect(() => {
  //   dispatch(fetchAllPlayLists());
  // }, [dispatch, updatePlayList]);

  const saveTitleHandler = () => {
    dispatch(fetchUpdatePlayList(params.id, editTitle, null, null));
  };

  const cancelTitleHandler = () => {
    setIsEdit(false);
  };

  const deletePlayListHandler = () => {
    setIsDelete(true);
    dispatch(deletePlayList(params.id));
  };

  useEffect(() => {
    if (isDelete && deletePlayListData.status === 200) {
      setIsShow(false);
      navigate('/my-playlists');
      setIsDelete(false);
    }
  }, [deletePlayListData, navigate, isDelete]);

  return (
    <LanguageContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div className={'d-flex w-100 justify-content-between'}>
              {!isEdit ? (
                <>
                  <PlayListNameActions
                    deleteHandler={() => setIsShow(true)}
                    editHandler={() => setIsEdit(true)}
                    title={singlePlaylist?.title}
                  />
                </>
              ) : (
                <EditTitle
                  saveTitleHandler={saveTitleHandler}
                  cancelTitleHandler={cancelTitleHandler}
                  defaultValue={singlePlaylist?.title}
                  titleHandler={titleHandler}
                />
              )}
            </div>
              <ConfirmationModal
                show={isShow}
                cartoonPlaylist={true}
                onHide={() => setIsShow(false)}
                logOutHandler={deletePlayListHandler}
                titles={{
                  max_title:
                    ctx.data?.are_you_delete_playlist[ctx.language],
                }}
              />
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

export default PlaylistTitle;
